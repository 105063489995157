import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { IsSpEmailVisible, IsSpEmailVisibleVariables } from '../../../../../api/types/IsSpEmailVisible';
import { IS_SP_EMAIL_VISIBLE } from '../../../bundle/queries/isSpEmailVisibleQuery';

export const useIsEmailVisible = (orgId: number, adminForceShowSpEmail?: boolean) => {
  const [
    getIsSpEmailVisible,
    {
      data: isSpEmailVisible,
      loading,
      error,
    }] = useLazyQuery<IsSpEmailVisible, IsSpEmailVisibleVariables>(IS_SP_EMAIL_VISIBLE, {
      fetchPolicy: 'no-cache',
    });

  useEffect(() => {
    if (orgId && !adminForceShowSpEmail) {
      getIsSpEmailVisible({
        variables: { organizationId: orgId },
      });
    }
  }, [orgId, adminForceShowSpEmail, getIsSpEmailVisible]);

  const isEmailVisible = adminForceShowSpEmail || !!isSpEmailVisible?.fmBids.total_count;

  return {
    loading,
    error,
    isEmailVisible,
  };
};
