import React from 'react';
import { createPortal } from 'react-dom';

export interface LoadingPanelProps {
  tableWrapper: React.MutableRefObject<HTMLDivElement | null>;
}

export const LoadingPanel: React.FC<LoadingPanelProps> = ({
  tableWrapper,
}) => {
  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image" />
      <div className="k-loading-color" />
    </div>
  );

  const gridContent = tableWrapper?.current && tableWrapper.current.querySelector('.k-grid-content');
  return gridContent
    ? createPortal(loadingPanel, gridContent)
    : loadingPanel;
};
