import React from 'react';
import { ApolloError } from '@apollo/client';
import { DataResult, SortDescriptor } from '@progress/kendo-data-query';
import { ScrollMode } from '@progress/kendo-react-grid/dist/npm/ScrollMode';
import { MappingProps, WithMapProps } from './map/MappingProps';
import { MultipleSelectionProps, SelectionProps, SingleSelectionProps } from './select/SelectionProps';
import { FilteredLocationsLeafletMapProps } from '../map/FilteredLocationsLeafletMap';
import { ColumnProps } from './column/Column';
import { Filter } from './filter/types';
import { DataGridState, PageSize } from './DataGrid';

interface DataGridBaseProps {
  id: string;
  columns: ColumnProps[];
  /**
   * These filters:
   *
   * 1. Are unrelated to columns.
   *    You can pass anything, even filters for fields which do not have designated columns.
   * 2. Will not be returned as part of the DataGrid state.
   *    It's a consequence of #1: DataGrid is only handling its internal state,
   *    which is affected by filter widgets, and these filters are applied from the outside
   * 3. Cannot be edited by users.
   *    Also a consequence of #1: no filter widgets are associated with these,
   *    so there's no way to edit these.
   * 4. Are shown in a special way, indicating their role with colors and tooltips.
   */
  overrideFilters?: Filter[];
  /**
   * This is called when a user clicks a delete button on {@link overrideFilters}
   * Provide this together with {@link overrideFilters}
   */
  onOverrideFiltersDelete?: () => void;
  /**
   * These filters are for cases when nothing else was set, and a user
   * sees a particular datagrid for the first time. It's different
   * from {@link overrideFilters} because these default filters
   * are simply "pre-selected" on user's behalf.
   * Note that also unlike {@link overrideFilters}, this accepts
   * the "raw" internal format, an object, and not simply an array of filters.
   * This means that these filters MUST apply to existing columns.
   */
  defaultFilters?: { [field: string]: Filter };
  defaultSort?: SortDescriptor[];
  pageSize?: PageSize;
  maxPageSize?: PageSize;
  maxPagerButtons?: number;
  fixedPagerButtons?: boolean;
  /**
   * A callback to trigger loading table data.
   * Should be wrapped in useCallback to prevent unnecessary queries.
   * Called only when the table is shown (in 'table' mode).
   * @param {DataGridState} state Table state: sorting, pagination, filters
   */
  loadTableData: undefined | ((state: DataGridState) => void);
  tableData: DataResult | undefined;
  tableLoading: boolean;
  tableError?: ApolloError;
  beforeFilters?: React.ReactNode;
  afterFilters?: React.ReactNode;
  actions?: (row: any) => React.ReactNode[];
  actionsWidth?: number;
  /**
   * Sometimes we don't need to adjust columns width i.e. because of the two-columns layout
   */
  enableColumnsWidthAdjustment?: boolean;
  additionalResets?: (() => void)[];
  clearPaginationFlag?: boolean;
  noBorder?: boolean;
  noPanel?: boolean;
  noColumnSettings?: boolean;
  tinyFilters?: boolean;
  topPageNavigation?: boolean;
  bottomPageNavigation?: boolean;
  pageNavigationInfo?: boolean;
  hidePageNavigationIfOnePageOnly?: boolean;
  scrollable?: ScrollMode;
  resizable?: boolean;
  emptyLabel?: string;
}

export type MapProps = Omit<
  FilteredLocationsLeafletMapProps<any, any, any>,
  'id' | 'height' | 'fillScreenHeight'
>;

// Combined union for selection- and map-related props
export type DataGridProps = DataGridBaseProps & MappingProps & SelectionProps;

export function isWithMapProps(
  props: DataGridProps,
): props is WithMapProps & DataGridBaseProps & SelectionProps {
  return props.withMap;
}

export function isWithSingleSelection(
  props: DataGridProps,
): props is SingleSelectionProps & DataGridBaseProps & MappingProps {
  return props.selectionType === 'single';
}

export function isWithMultipleSelection(
  props: DataGridProps,
): props is MultipleSelectionProps & DataGridBaseProps & MappingProps {
  return props.selectionType === 'multiple';
}

export function isWithSelection(
  props: DataGridProps,
): props is (SingleSelectionProps | MultipleSelectionProps) & DataGridBaseProps & MappingProps {
  return isWithSingleSelection(props) || isWithMultipleSelection(props);
}
