export const FILE_TYPES_IMAGES = ['image/jpeg', 'image/png', 'image/webp'];
export const FILE_TYPES_IMAGES_STRING = 'JPEG, PNG, or WebP';

const FILE_TYPES_XLSX = [
  'application/vnd.ms-excel', // XLS
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // XLSX
];

export const FILE_TYPES_ALL = [
  'application/pdf',
  'text/csv',
  'application/msword', // DOC
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // DOCX
  'application/vnd.ms-powerpoint', // PPT
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', // PPTX
  ...FILE_TYPES_XLSX,
  ...FILE_TYPES_IMAGES,
];
export const FILE_TYPES_ALL_STRING = `PDF, CSV, XLS/XLSX (Excel), DOC/DOCX (Word),  PPT/PPTX (Powerpoint), ${FILE_TYPES_IMAGES_STRING}`;

export function isImage(mimeType: string): boolean {
  return FILE_TYPES_IMAGES.includes(mimeType);
}

export function isPdf(mimeType: string): boolean {
  return mimeType === 'application/pdf';
}

export function isXlsx(mimeType: string): boolean {
  return FILE_TYPES_XLSX.includes(mimeType);
}

export const FILE_DOCUSIGN_MIME = 'docusign_template';
export function isDocuSignTemplate(mimeType: string): boolean {
  return mimeType === FILE_DOCUSIGN_MIME;
}

export function mimeTypeDescription(mimeType: string): string {
  switch (mimeType) {
    case 'image/jpeg':
      return 'JPEG image';
    case 'image/png':
      return 'PNG image';
    case 'image/webp':
      return 'WebP image';
    case 'application/pdf':
      return 'PDF document';
    case 'text/csv':
      return 'CSV table';
    case FILE_DOCUSIGN_MIME:
      return 'DocuSign template';
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'Microsoft Excel spreadsheet';
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'Microsoft Word document';
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return 'Microsoft Powerpoint presentation';
    default:
      return 'Unknown type';
  }
}
