import React from 'react';
import { Badge, IconButton, IconButtonProps } from '@mui/material';
import { Link } from 'react-router-dom';
import { MenuListItemOnClick, MenuListItemUrl } from './menu';

export interface MenuIconButtonProps extends Pick<IconButtonProps, 'size'> {
  item: MenuListItemUrl | MenuListItemOnClick;
  toggleMenu?: (value: boolean)=> void;
}

export const MenuIconButton: React.FC<MenuIconButtonProps> = ({
  item,
  size,
  toggleMenu,
}) => {
  // console.log(item.url);
  if (item.type === '_menu_item_url') {
    return (
      <IconButton
        aria-label={item.badge ? item.badge.label : undefined}
        title={item.badge ? item.badge.label : item.title}
        component={Link as any}
        to={item.url}
        target={/^https?[:]/gi.test(item.url) ? '_blank' : undefined}
        disabled={item.disabled}
        onClick={() => toggleMenu && toggleMenu(false)}
        size="large"
      >
        <Badge badgeContent={item.badge?.number} color="secondary">
          {item.icon}
        </Badge>
      </IconButton>
    );
  }
  return (
    <IconButton
      aria-label={item.badge ? item.badge.label : undefined}
      title={item.badge ? item.badge.label : item.title}
      size={size}
      onClick={item.onClick}
      disabled={item.disabled}
    >
      <Badge badgeContent={item.badge?.number} color="secondary">
        {item.icon}
      </Badge>
    </IconButton>
  );
};
