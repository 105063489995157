import { gql } from '@apollo/client';

export const PROFILE_CHAT_QUERY = 'ProfileChat';
export const PROFILE_CHAT = gql`
  query ProfileChat {
      chat: chatToken {
          user_id
          token
      }
  }
`;
