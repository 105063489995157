import { ServiceFields } from './types/ServiceFields';
import { SelectFilterOption } from '../components/table/filters/MultiCheckboxFilter';

export function getFieldOptions<ID extends string | number>(
  fieldsData: ServiceFields | undefined,
  fieldName: string,
): SelectFilterOption<ID>[] {
  if (fieldsData?._serviceFields) {
    const field = fieldsData._serviceFields.items.find((f) => f.name === fieldName);
    return field?.context?.options
      ? field.context.options.map((o: any) => ({
        key: o.value,
        title: o.text,
      }))
      : [];
  }
  return [];
}

export enum FieldType {
  text = 'text',
  number = 'number',
  select = 'select',
  wysiwig = 'wysiwig',
  checkbox = 'checkbox',
  multiCheckbox = 'multiCheckbox',
  multiSelect = 'multiSelect',

  // informational types
  label = 'label',
  html = 'html',
}

export function convertValue(value: any, type: string, context: any): string {
  let parsedValue: string | string[] = value;

  // Re-map to a view values
  if (type === FieldType.multiSelect || type === FieldType.multiCheckbox) {
    const valueJson = typeof value === 'string' ? JSON.parse(value) : value;
    if (!Array.isArray(valueJson)) {
      throw new Error(
        `For a question of type "${type}", `
                + `expected JSON array, got ${JSON.stringify(valueJson)} `
                + `(original value = ${value})`,
      );
    }
    parsedValue = valueJson.map(
      (fieldValue: string) => context.options.find((opt: any) => opt?.value === fieldValue)?.text,
    ).filter((a) => !!a);
  } else if (context?.options) {
    const targetOption = context.options.find((opt: any) => opt?.value === value);
    parsedValue = targetOption ? targetOption.text : value;
  }

  if (typeof parsedValue === 'string') return parsedValue;

  return parsedValue.join(', ');
}
