import { createStyles, makeStyles } from '@mui/styles';
import { green } from '@mui/material/colors';

export const useStyles = makeStyles((theme) => createStyles({
  paper: {
    padding: theme.spacing(2, 2),
  },
  fieldGroup: {
    marginBottom: theme.spacing(2),
  },
  sectionTitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(0),
  },
  tablesWrapper: {
    marginBottom: theme.spacing(2),
  },
  successMsg: {
    color: green[500],
  },
}));
