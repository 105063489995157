import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';

const FMS_QUERY_PARAM = 'fm';
const INVITED_BY_QUERY_PARAM = 'invited_by';

export const useIncumbentForFms = (): { fm: number[], invited_by?: number | null } => {
  const { location } = useHistory();
  return useMemo(() => {
    const s = new URLSearchParams(location.search);
    const fm = s.get(FMS_QUERY_PARAM);
    const invitedBy = s.get(INVITED_BY_QUERY_PARAM);
    return {
      fm: fm ? fm.split(',')
        .map((probNumber) => parseInt(probNumber, 10))
        .filter((n) => !Number.isNaN(n))
        : [],
      invited_by: invitedBy ? parseInt(invitedBy, 10) : null,
    };
  }, [location.search]);
};
