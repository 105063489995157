import React, { useMemo } from 'react';
import {
  Typography, Card, CardActionArea, CardContent, colors, Link, Grid,
} from '@mui/material';
import { NotificationProps, useFeedContext } from 'react-activity-feed';
import { makeStyles } from '@mui/styles';
import ReactMarkdown from 'react-markdown';
import { EnrichedActivity } from 'getstream/lib/feed';
import { generatePath, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { PATHS } from 'paths';
import { useProfile } from 'api/user/profile';
import { isoDateTimeToLocale } from 'utils/datetime';
import cn from 'classnames';
import { FeedGenerics } from './feedTypes';
import { useNotificationBox } from './NotificationBoxState';

/**
 * By default notification feed are keeping groups.
 * They grouped like "{{ verb }}_{{ time.strftime('%Y-%m-%d') }}" (eg. invited_y-m-d)
 * for example: "You've been invited to 5 bundles" etc
 * For now there is no requirement to do that so they forcibly expanded to a flat list.
 */

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(2),
  },
  cardContent: {
    padding: theme.spacing(1),
  },
  cardUnread: {
    backgroundColor: colors.grey.A100,
  },
  noTextMsg: { fontStyle: 'italic' },
}));

export type NotificationGroupProps = NotificationProps<FeedGenerics['userType'], FeedGenerics['activityType']>;

export const getMentionChannelName = (
  { message }: FeedGenerics['activityType'],
  myOrgId: number,
): string => {
  if (!message) return 'N/A';
  const channelName = message.fm_id === myOrgId
    ? message.sp_name
    : message?.fm_name;
  return channelName ?? 'N/A';
};

export const NotificationGroup: React.FC<NotificationGroupProps> = observer(({ activityGroup }) => {
  const classes = useStyles();
  const feed = useFeedContext<FeedGenerics['userType'], FeedGenerics['activityType']>();
  const history = useHistory();
  const store = useNotificationBox();
  const { organization: { organization_id: myOrgId, is_service_provider: isSP } } = useProfile();

  const markGroupAsRead = (e?: React.MouseEvent<any>) => {
    e?.stopPropagation();
    if (!activityGroup.is_read) {
      feed.feedManager.onMarkAsRead({ id: activityGroup.id });
    }
  };

  const handleClick = (activity: EnrichedActivity<FeedGenerics['userType'], FeedGenerics['activityType']>) => {
    markGroupAsRead();

    if (activity.verb === 'mention') {
      history.push(generatePath(
        isSP ? PATHS.sp.chatChannel : PATHS.fm.chatChannel,
        { channelId: activity.message!.channel_id },
      ));
      return;
    }
    if (typeof (activity.bid_id) === 'number') {
      history.push(generatePath(PATHS.sp.bid, { bidId: activity.bid_id }));
    }
  };

  const list = useMemo(() => (
    store.showUnreadOnly && activityGroup.is_read
      ? []
      : activityGroup.activities
  ), [activityGroup, store.showUnreadOnly]);

  return (
    <>
      {list.map((activity) => (
        <Card
          key={activity.id}
          className={cn(
            classes.card,
            !activityGroup.is_read && classes.cardUnread,
          )}
        >
          <CardActionArea onClick={() => handleClick(activity)}>
            <CardContent className={classes.cardContent}>
              {activity.verb === 'mention' && (
                <span>
                  <strong>{activity.message?.sender_name}</strong>
                  {' mentioned you in '}
                  <strong>
                    #
                    {getMentionChannelName(activity, myOrgId)}
                  </strong>
                </span>
              )}
              {activity.text ? (
                <ReactMarkdown>{activity.text}</ReactMarkdown>
              ) : (
                <Typography className={classes.noTextMsg}>
                  Notification doesn&apos;t have a text
                </Typography>
              )}
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography component="div" color="textSecondary" variant="caption">
                    {isoDateTimeToLocale(`${activity.time}Z`)}
                  </Typography>
                </Grid>
                {!activityGroup.is_read && (
                  <Grid item>
                    <Link component="button" variant="body2" onClick={markGroupAsRead}>
                      mark as read
                    </Link>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </CardActionArea>
        </Card>
      ))}
    </>
  );
});
