import { gql } from '@apollo/client';

export const UPLOAD_SP_PROFILE_FILE = gql`
  mutation UploadSpProfileFile(
    $file: Upload!
    $name: String
    $type: SpProfileFileType!
    $rank: Float
  ) {
    uploadSpProfileFile(
      file: $file
      name: $name
      type: $type
      rank: $rank
    ) {
      file_id
    }
  }
`;
