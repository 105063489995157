import React from 'react';
import { useFeedContext } from 'react-activity-feed';
import { observer } from 'mobx-react';
import { Typography, Link, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FeedGenerics } from './feedTypes';
import { useNotificationBox } from './NotificationBoxState';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export const NotificationBoxHeader: React.FC = observer(() => {
  const feed = useFeedContext<FeedGenerics['userType'], FeedGenerics['activityType']>();
  const store = useNotificationBox();
  const classes = useStyles();

  const markAllAsRead = async () => {
    feed.feedManager.onMarkAsRead(true);
  };

  const toggleUnreadFilter = async () => {
    store.setShowUnreadOnly(!store.showUnreadOnly);
  };

  return (
    <Box p={2} pb={0} className={classes.container}>
      <Typography color="textPrimary" variant="subtitle2">Notifications</Typography>
      <div>
        <Link component="button" variant="body2" onClick={toggleUnreadFilter}>
          {store.showUnreadOnly ? 'Show all' : 'Only show unread'}
        </Link>
        {feed.unread > 0 && (
          <>
            <Typography component="span" color="textSecondary">{' | '}</Typography>
            <Link component="button" variant="body2" onClick={markAllAsRead}>Mark all as read</Link>
          </>
        )}
      </div>
    </Box>
  );
});
