import * as Yup from 'yup';

// eslint-disable-next-line func-names
Yup.addMethod(Yup.array, 'unique', function (message: string, mapper = (a: unknown) => a) {
  return this.test(
    'unique',
    message,
    (list = []) => list.length === new Set(list.map(mapper)).size,
  );
});
