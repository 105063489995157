import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { isBefore, sub } from 'date-fns';
import { Tooltip } from '@mui/material';
import { isoDateTimeToLocale, isoDateToLocate } from '../../utils/datetime';
import { colors } from '../theme';
import { pluralize } from '../../utils/inflection';

const useStyles = makeStyles((theme) => createStyles({
  date: {
    display: 'inline-block',
    padding: `0 ${theme.shape.borderRadius}px`,
    borderRadius: theme.shape.borderRadius,
  },
  normal: {
    backgroundColor: 'transparent',
  },
  warningMin: {
    backgroundColor: colors.statusYellow,
  },
  warningMid: {
    backgroundColor: colors.statusAmber,
  },
  warningMax: {
    backgroundColor: colors.statusOrange,
  },
  expired: {
    backgroundColor: colors.grey,
  },
}));

export interface DateTimeProps {
  value?: string | Date | null;
  short?: boolean;
  block?: boolean;
  /**
   * Number of days before expiration, affects the color:
   *
   * - last value -> orange color
   * - 2nd to last value -> amber color
   * - 3rd to last value -> yellow color
   *
   * In case of just one value, it corresponds to orange.
   */
  expirationWarningDays?: number | [number, number] | [number, number, number];
}

export const DateTime: React.FC<DateTimeProps> = ({
  value,
  short = false,
  block = false,
  expirationWarningDays,
}) => {
  const classes = useStyles();
  if (!value) return null;
  const isoDateTime = typeof value === 'string' ? value : value.toISOString();
  const stringDateTime = isoDateTimeToLocale(value);

  let warningClass = classes.normal;
  let warningMessage: string | undefined;
  if (expirationWarningDays) {
    const now = new Date();
    const dateValue = value instanceof Date ? value : new Date(isoDateTime);
    if (isBefore(dateValue, now)) {
      warningClass = classes.expired;
      warningMessage = 'Expired';
    } else if (typeof expirationWarningDays === 'number') {
      const afterMax = isBefore(sub(dateValue, { days: expirationWarningDays }), now);
      if (afterMax) {
        warningClass = classes.warningMax;
        warningMessage = `Less than ${expirationWarningDays} ${pluralize(expirationWarningDays, 'day')} left`;
      }
    } else if (expirationWarningDays.length === 2) {
      const afterMid = isBefore(sub(dateValue, { days: expirationWarningDays[0] }), now);
      if (afterMid) {
        warningClass = classes.warningMid;
        warningMessage = `Less than ${expirationWarningDays[0]} ${pluralize(expirationWarningDays[0], 'day')} left`;
      }
      const afterMax = isBefore(sub(dateValue, { days: expirationWarningDays[1] }), now);
      if (afterMax) {
        warningClass = classes.warningMax;
        warningMessage = `Less than ${expirationWarningDays[1]} ${pluralize(expirationWarningDays[1], 'day')} left`;
      }
    } else {
      const afterMin = isBefore(sub(dateValue, { days: expirationWarningDays[0] }), now);
      if (afterMin) {
        warningClass = classes.warningMin;
        warningMessage = `Less than ${expirationWarningDays[0]} ${pluralize(expirationWarningDays[0], 'day')} left`;
      }
      const afterMid = isBefore(sub(dateValue, { days: expirationWarningDays[1] }), now);
      if (afterMid) {
        warningClass = classes.warningMid;
        warningMessage = `Less than ${expirationWarningDays[1]} ${pluralize(expirationWarningDays[1], 'day')} left`;
      }
      const afterMax = isBefore(sub(dateValue, { days: expirationWarningDays[2] }), now);
      if (afterMax) {
        warningClass = classes.warningMax;
        warningMessage = `Less than ${expirationWarningDays[2]} ${pluralize(expirationWarningDays[2], 'day')} left`;
      }
    }
  }
  return (
    <Tooltip
      arrow
      placement="top"
      title={warningMessage ? `${warningMessage}, ${stringDateTime}` : stringDateTime}
    >
      <time
        dateTime={isoDateTime}
        className={block ? [classes.date, warningClass].join(' ') : undefined}
      >
        {short ? isoDateToLocate(value) : stringDateTime}
      </time>
    </Tooltip>
  );
};
