import React, { useMemo } from 'react';
import {
  IconButton, InputAdornment, TextField, TextFieldProps,
} from '@mui/material';
import { Search } from '@mui/icons-material';

import Close from '@mui/icons-material/Close';

export interface SearchInputProps {
  onClear: () => void;
}

export const SearchInput: React.FC<SearchInputProps & TextFieldProps> = ({
  value, onClear, ...props
}) => {
  const hasValue = useMemo(() => (!!(value as string)?.trim().length), [value]);
  return (
    <TextField
      name="search"
      value={value}
      placeholder="Find a room, message..."
      fullWidth
      InputProps={{
        autoComplete: 'off',
        startAdornment: (
          <InputAdornment position="start">
            <Search color="disabled" />
          </InputAdornment>
        ),
        endAdornment: hasValue ? (
          <InputAdornment position="end">
            <IconButton
              disabled={!hasValue}
              aria-label="Clear search"
              onClick={onClear}
              size="small"
              edge="end"
            >
              <Close />
            </IconButton>
          </InputAdornment>
        ) : undefined,
      }}
      {...props}
    />
  );
};
