import { useCallback, useState } from 'react';
import { omit } from 'lodash';

export interface UseFilterWidgetsResult {
  filterWidgetOpen: { [field: string]: boolean };
  toggleFilterWidget: (field: string, open: boolean) => void;
}

/**
 * Handling widgets' shown/hidden state.
 */
export function useFilterWidgets(): UseFilterWidgetsResult {
  const [filterWidgetOpen, setFilterWidgetOpen] = useState<{ [field: string]: boolean }>({});

  const toggleFilterWidget = useCallback((field: string, open: boolean) => {
    setFilterWidgetOpen((prev) => {
      if (open) {
        return {
          ...prev,
          [field]: true,
        };
      }
      return omit(prev, field);
    });
  }, []);

  return {
    filterWidgetOpen,
    toggleFilterWidget,
  };
}
