import { useForm } from 'react-hook-form';
import { FormHelperText } from '@mui/material';
import React from 'react';
import { CheckboxGroup } from '../../form/CheckBoxGroup';
import { getError, hasError } from '../helpers';
import { CustomFieldFormLabel } from '../CustomFieldFormLabel';
import { CustomFieldRenderComponentProps } from '../types';

interface CustomFieldMultiCheckboxProps extends CustomFieldRenderComponentProps {
  value: string[];
}

export const CustomFieldMultiCheckbox: React.FC<CustomFieldMultiCheckboxProps> = ({
  field,
  disabled,
  value,
  onChange,
  error,
}) => {
  const { errors } = useForm();

  return (
    <>
      <CustomFieldFormLabel field={field} checkLabelOnTop={false} />
      <CheckboxGroup
        disabled={disabled}
        options={field.context.options}
        value={value}
        onChange={onChange}
      />
      {error && (
        <FormHelperText error>
          {error}
        </FormHelperText>
      )}
      {hasError(errors, field) && (
        <FormHelperText error>
          {getError(errors, field)}
        </FormHelperText>
      )}
    </>
  );
};
