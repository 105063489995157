import { gql } from '@apollo/client';

export const BOARDING_COMPLETENESS_QUERY = 'BoardingCompleteness';
export const BOARDING_COMPLETENESS = gql`
  query BoardingCompleteness {
    organization: myOrganization {
      organization_id
      onboarding {
        profile_percentage
        key_contacts_percentage
        services_percentage
        diversity_percentage
        documents_percentage
      }
    }
  }
`;
