import React, { createContext, useContext, useEffect } from 'react';
import { TitleProps } from '../layout/Title';

export type HeaderTitleSetter = React.Dispatch<React.SetStateAction<TitleProps | undefined>>;

export const HeaderTitleContext = createContext<HeaderTitleSetter | undefined>(undefined);

export const useHeaderTitle = (title: TitleProps | undefined, disabled?: boolean) => {
  const setHeaderTitle = useContext(HeaderTitleContext);

  useEffect(() => {
    let mounted = true;
    if (mounted && !disabled) {
      if (setHeaderTitle === undefined) {
        throw new Error('Header title setter is undefined in this context');
      }
      setHeaderTitle(title);
    }
    return () => {
      mounted = false;
    };
  }, [setHeaderTitle, title, disabled]);
};
