import React from 'react';
import { createPortal } from 'react-dom';
import { Backdrop } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Files__organizationFiles_items } from '../../../api/types/Files';

export interface ImagePreviewBackdropProps {
  file?: Files__organizationFiles_items | null;
  onClose: () => void;
}

const useStyles = makeStyles((theme) => createStyles({
  backdrop: {
    zIndex: theme.zIndex.tooltip + 1,
    cursor: 'pointer',
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
}));

const portalContainerElm = document.querySelector('body')!;

export const FileImagePreview: React.FC<ImagePreviewBackdropProps> = ({ file, onClose }) => {
  const classes = useStyles();
  return createPortal((
    <Backdrop className={classes.backdrop} open={!!file} onClick={onClose}>
      {file && (
        <img src={file.url} alt="Click to close" />
      )}
    </Backdrop>
  ), portalContainerElm);
};
