import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const InfoTooltip: React.FC<{ title: string }> = ({ title }) => (
  <Tooltip title={title}>
    <IconButton size="small" style={{ marginTop: -3, marginLeft: 5 }}>
      <div style={{ borderRadius: 50, padding: '0px 8px', backgroundColor: 'grey' }}>
        <FontAwesomeIcon icon="info" size="2xs" color="white" style={{ margin: '-3px 0 -1px' }} />
      </div>
    </IconButton>
  </Tooltip>
);
