import React from 'react';
import {
  Tooltip, TooltipProps, SwitchProps, FormControlLabel, Switch,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { colors } from 'components/theme';

const useStyles = makeStyles(() => createStyles({
  component: {
    marginLeft: 0,
  },
  switchLabel: {
    color: colors.blue,
    textTransform: 'uppercase',
  },
}));

export interface TooltipSwitchProps extends Omit<SwitchProps, 'onChange'> {
  tooltip: string;
  label: string;
  tooltipProps?: Omit<TooltipProps, 'title' | 'children'>;
  onChange: (newState: boolean) => void;
}

export const TooltipSwitch: React.FC<TooltipSwitchProps> = ({
  tooltip,
  label,
  tooltipProps,
  onChange,
  ...switchProps
}) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  const switchControl = (
    <FormControlLabel
      className={classes.switchLabel}
      control={(
        <Switch
          {...switchProps}
          onChange={handleChange}
        />
      )}
      label={label}
    />
  );

  if (!tooltip) {
    return switchControl;
  }

  return (
    <Tooltip
      className={classes.component}
      arrow
      title={tooltip}
      placement="top"
      {...tooltipProps}
    >
      {switchControl}
    </Tooltip>
  );
};
