import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import * as Sentry from '@sentry/browser';
import { ErrorPageFallback } from 'components/notifications/ErrorPageFallback';

export const withErrorBoundary = <P extends object>(
  Component: React.ComponentType<P>,
): React.FC<P> => (props: P) => (
  <ErrorBoundary
    FallbackComponent={ErrorPageFallback}
    onError={(error) => {
      Sentry.captureException(error);
    }}
  >
    <Component {...props} />
  </ErrorBoundary>
);
