import {
  MutableRefObject, useEffect, useRef, useState,
} from 'react';
import useWindowSize from '@rooks/use-window-size';
import useBoundingClientRect from '@rooks/use-boundingclientrect';
import { useDebounce } from '../../../utils/effects';

export const useFillScreenHeight = (
  minHeight: number,
  fillScreenHeight: boolean,
): [
  MutableRefObject<HTMLDivElement | null>,
  number | undefined,
] => {
  const { innerHeight } = useWindowSize();
  const ref = useRef<HTMLDivElement>(null);
  const mapBoundingClientRect = useBoundingClientRect(ref);
  const [height, setHeight] = useState<number>();
  const top = useDebounce(mapBoundingClientRect?.top, 300);
  const bottom = useDebounce(mapBoundingClientRect?.bottom, 300);
  useEffect(() => {
    if (top !== undefined && bottom !== undefined) {
      if (innerHeight && fillScreenHeight && bottom < innerHeight) {
        const newHeight = innerHeight - top;
        if (height !== newHeight) {
          setHeight(newHeight);
        }
      }
    } else if (height !== minHeight) {
      setHeight(minHeight);
    }
  }, [top, bottom, fillScreenHeight, height, innerHeight, minHeight]);
  return [ref, height];
};
