import React, { useMemo } from 'react';
import { CustomDialog, CustomDialogProps } from 'components/CustomDialog';
import { Users__users_items } from 'api/types/Users';
import { useQuery } from '@apollo/react-hooks';
import { PermissionsAutocomplete, PermissionsAutocompleteVariables } from 'api/types/PermissionsAutocomplete';
import { PERMISSIONS_AUTOCOMPLETE } from 'api/permissions/permissions';
import { UserType } from 'api/types/globalTypes';
import { UserPermissionsComponent, UserPermissionType } from 'components/organization/UserPermissionsComponent';
import { sortBy } from 'lodash';

type Props = Omit<
  CustomDialogProps, 'id' | 'title' | 'loading'
  > & {
  user: Users__users_items | null,
  navType: 'fm' | 'sp',
};

export const UserPermissionsDialog: React.FC<Props> = (
  {
    user,
    navType,
    onClose,
    ...props
  }) => {
  const { data: availablePermsData, error: permsError } = useQuery<
    PermissionsAutocomplete,
    PermissionsAutocompleteVariables
    >(PERMISSIONS_AUTOCOMPLETE, {
      variables: { type: navType === 'fm' ? UserType.fm : UserType.sp },
      fetchPolicy: 'no-cache',
    });

  const sortedPermissions = useMemo(() => {
    const availablePermissions = [] as UserPermissionType[];
    if (!availablePermsData) return [];

    availablePermsData.permissions.forEach((permission) => {
      const globalPermission = {
        ...permission,
        name: `Global: ${permission.name}`,
        permission: { is_regional: permission.is_regional },
      };
      availablePermissions.push(globalPermission);

      if (permission.is_regional) {
        // There we creating fake names with RegionName + PermissionName
        // And append region_id for new objects
        availablePermsData._organizationRegions.items.forEach((region) => {
          const regionalPermission = {
            name: `${region.name}: ${permission.name}`,
            permission_id: permission.permission_id,
            region_id: region.region_id,
            permission: {
              is_regional: true,
            },
          };

          availablePermissions.push(regionalPermission);
        });
      }
    });

    return sortBy(availablePermissions, [(o) => o.name]);
  }, [availablePermsData]);

  return (
    <CustomDialog
      id="user-permissions"
      loading={false}
      error={permsError}
      title={`User Permissions for ${user?.fullName}`}
      fullWidth
      maxWidth="sm"
      onClose={() => {
        onClose();
        // form.resetForm();
      }}
      {...props}
    >
      {user && (
        <UserPermissionsComponent
          userId={user.user_id}
          availablePermissions={sortedPermissions}
          userPermissions={user.userPermissions}
        />
      )}
    </CustomDialog>
  );
};
