import React, { useCallback, useMemo, useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar, ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';
import {
  Attachment, Delete, Image, Publish,
} from '@mui/icons-material';
import { useMutation } from '@apollo/react-hooks';
import { max } from 'lodash';

import { isImage, mimeTypeDescription } from 'utils/files';
import { SpProfile, SpProfile_publicOrganization_spProfile_files } from 'api/types/SpProfile';
import { SpProfileFileType } from 'api/types/globalTypes';
import { DeleteSpProfileFile, DeleteSpProfileFileVariables } from 'api/types/DeleteSpProfileFile';
import { DELETE_SP_PROFILE_FILE } from 'api/organization/profile/deleteSpProfileFIle';
import { SP_PROFILE_QUERY } from 'api/organization/profile/spProfile';
import { UploadSpProfileFile, UploadSpProfileFileVariables } from 'api/types/UploadSpProfileFile';
import { UPLOAD_SP_PROFILE_FILE } from 'api/organization/profile/uploadSpProfileFile';

import { ErrorSnackbar, useErrorSnackbar } from 'components/notifications/ErrorSnackbar';
import { CustomDialog, CustomDialogProps } from 'components/CustomDialog';
import { EllipsisText } from '../../../text/EllipsisText';
import { FileDropZone } from './FileDropZone';
import { FileImageThumb } from '../../../documentRepository/filesList/FileImageThumb';

const AVATAR_SIZE = 40;

const useStyles = makeStyles(() => createStyles({
  filesList: {},
  avatar: {
    width: AVATAR_SIZE,
    height: AVATAR_SIZE,
    borderRadius: AVATAR_SIZE / 2,
  },
}));

export interface EditFilesDialogProps extends Pick<CustomDialogProps, 'open' | 'onClose'> {
  data: SpProfile;
  type: SpProfileFileType.ATTACHMENT | SpProfileFileType.GALLERY | SpProfileFileType.BANNER_GALLERY;
}

const DISPLAY_NAMES = {
  [SpProfileFileType.ATTACHMENT]: 'attachments',
  [SpProfileFileType.GALLERY]: 'gallery',
  [SpProfileFileType.BANNER_GALLERY]: 'featured gallery',
};

export const EditFilesDialog: React.FC<EditFilesDialogProps> = ({
  data,
  type,
  open,
  onClose,
}) => {
  const classes = useStyles();

  const typeString = type === SpProfileFileType.ATTACHMENT
    ? 'attachments'
    : type.toLowerCase().replace(/_+/g, '-');
  const typeDisplay = DISPLAY_NAMES[type];

  const org = data.publicOrganization;

  const [filesInProgress, setFilesInProgress] = useState<File[] | undefined>();

  const files: SpProfile_publicOrganization_spProfile_files[] = useMemo(() => {
    if (!data.publicOrganization.spProfile) {
      return [];
    }
    return data.publicOrganization.spProfile.files
      .filter((f) => f.type === type)
      .sort((f1, f2) => (f1.rank ?? 0) - (f2.rank ?? 0));
  }, [data.publicOrganization.spProfile, type]);

  const maxRank = useMemo(
    () => max(files.map(({ rank }) => rank ?? 0)) ?? 0,
    [files],
  );

  const [
    deleteFile,
    { loading: deleteFileLoading, error: deleteFileError },
  ] = useMutation<DeleteSpProfileFile, DeleteSpProfileFileVariables>(DELETE_SP_PROFILE_FILE, {
    refetchQueries: [SP_PROFILE_QUERY],
  });
  const deleteFileErrorSnackbar = useErrorSnackbar(deleteFileError);

  const [deletingFileId, setDeletingFileId] = useState<number>();

  const deleteFileWithLoading = useCallback(
    (fileId: number) => async () => {
      setDeletingFileId(fileId);
      try {
        await deleteFile({
          variables: {
            fileIds: [fileId],
          },
        });
      } finally {
        setDeletingFileId(undefined);
      }
    },
    [deleteFile],
  );

  const [
    uploadFile,
    { loading: uploadFileLoading, error: uploadFileError },
  ] = useMutation<UploadSpProfileFile, UploadSpProfileFileVariables>(UPLOAD_SP_PROFILE_FILE, {
    refetchQueries: [SP_PROFILE_QUERY],
  });
  const uploadFileErrorSnackbar = useErrorSnackbar(uploadFileError);

  const [filesLoading, setFilesLoading] = useState(false);

  const uploadFilesInProgress = useCallback(async () => {
    if (filesInProgress && filesInProgress.length > 0) {
      let rank = maxRank + 1;
      const uploadedFiles: File[] = [];
      setFilesLoading(true);
      try {
        filesInProgress.forEach(async (f) => {
          await uploadFile({
            variables: {
              file: f,
              name: f.name,
              type,
              rank,
            },
          });
          uploadedFiles.push(f);
          rank += 1;
        });
      } finally {
        setFilesLoading(false);
        // Remove successfully uploaded files from the in-progress list
        setFilesInProgress((prev) => (
          prev?.filter((f) => (
            !!uploadedFiles.find((uf) => uf.name === f.name && uf.type === f.type)
          ))
        ));
      }
    }
  }, [filesInProgress, maxRank, type, uploadFile]);

  const loading = useMemo(
    () => deleteFileLoading || uploadFileLoading || filesLoading,
    [deleteFileLoading, filesLoading, uploadFileLoading],
  );

  return (
    <>
      <CustomDialog
        id={`profile-edit-${typeString}-${org.organization_id}`}
        title={`Edit ${typeDisplay}`}
        loading={loading}
        showLoadingSpinner={false}
        open={open}
        onClose={onClose}
        closeButtonTitle="Cancel"
        actions={(
          <Button
            color="primary"
            disabled={loading || !filesInProgress?.length}
            startIcon={<Publish />}
            onClick={() => uploadFilesInProgress()}
          >
            {loading ? 'Loading...' : 'Upload'}
          </Button>
        )}
      >
        <Box mb={2} className={classes.filesList}>
          <List>
            {files.length === 0 && (
              <ListItem disabled>
                <ListItemText primary="No files" />
              </ListItem>
            )}
            {files.map((f) => (
              <ListItem key={f.file_id}>
                {isImage(f.mime_type) && f.thumb_url && (
                  <ListItemAvatar>
                    <FileImageThumb
                      alt={`Preview for file "${f.name}"`}
                      url={f.thumb_url}
                      skeletonProps={{
                        variant: 'circular',
                        width: AVATAR_SIZE,
                        height: AVATAR_SIZE,
                      }}
                      className={classes.avatar}
                    />
                  </ListItemAvatar>
                )}
                {!f.thumb_url && (
                  <ListItemIcon>
                    {isImage(f.mime_type) ? <Image /> : <Attachment />}
                  </ListItemIcon>
                )}
                <ListItemText
                  primary={<EllipsisText maxWidth={380} fullText={f.name}>{f.name}</EllipsisText>}
                  secondary={mimeTypeDescription(f.mime_type)}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    disabled={deletingFileId === f.file_id}
                    onClick={deleteFileWithLoading(f.file_id)}
                    size="large"
                  >
                    <Delete />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Box>
        <FileDropZone
          type={type === SpProfileFileType.ATTACHMENT ? 'all' : 'images'}
          maxFiles={10}
          maxSize={10 * 1024 * 1024}
          filesInProgress={filesInProgress}
          setFilesInProgress={setFilesInProgress}
        />
      </CustomDialog>
      <ErrorSnackbar {...deleteFileErrorSnackbar}>
        Failed deleting a file
      </ErrorSnackbar>
      <ErrorSnackbar {...uploadFileErrorSnackbar}>
        Failed uploading a file
      </ErrorSnackbar>
    </>
  );
};
