import React from 'react';
import { GridColumnProps } from '@progress/kendo-react-grid';
import { FilterWidgetProps } from '../filter/FilterWidgetProps';

type KendoGridColumnProps = Omit<
  GridColumnProps,
  'field' | 'title' | 'columnMenu' | 'filter' | 'filterable' | 'filterTitle' | 'filterCell' | 'cell'>;

export interface ColumnProps extends KendoGridColumnProps {
  field: string;
  export?: { alias?: string, field: string; args?: Record<string, any> } | false;
  title: string;
  render?: (item: any) => React.ReactNode;
  hiddenByDefault?: boolean;
  /**
   * In case the field is sorted by a different field,
   * e.g. a status is sorted by its rank instead of its label.
   */
  sortByField?: string;
  filterWidget?: FilterWidgetProps;
}

export const COLUMN_MAX_WIDTH = 1000;
