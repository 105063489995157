import React, {
  useCallback, useMemo,
} from 'react';
import {
  Box,
  Checkbox,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { useMutation } from '@apollo/react-hooks';
import { useFormik } from 'formik';

import { Profile_profile_organization_services } from 'api/types/Profile';
import {
  Approvals_userApprovals_items_user_organization_services,
} from 'api/types/Approvals';
import {
  UserNotificationSettings_userNotificationSettings_items as SettingItemType,
} from 'api/types/UserNotificationSettings';
import {
  SyncUserNotificationSettings,
  SyncUserNotificationSettingsVariables,
} from 'api/types/SyncUserNotificationSettings';
import { FormikHelpers } from 'formik/dist/types';
import { colors } from 'components/theme';
import { Loading } from 'components/Loading';
import { ErrorMessage } from 'components/notifications/ErrorMessage';
import { ErrorSnackbar, useErrorSnackbar } from 'components/notifications/ErrorSnackbar';
import { ButtonLoading } from 'components/ButtonLoading';

import { CustomDialog, CustomDialogProps } from 'components/CustomDialog';
import {
  USER_NOTIFICATION_SETTINGS_QUERY,
} from './queries/userNotificationSettings';
import { SYNC_USER_NOTIFICATION_SETTINGS } from './queries/syncUserNotificationSettings';
import { useInitialValues, UserNotificationsFormData } from './useInitialValues';
import {
  validationSchema,
  NotificationType,
  isChecked,
  buildSettingInput,
  isAllChecked,
} from './helpers';

const useStyles = makeStyles(() => createStyles({
  tableHeaderCell: {
    textAlign: 'center',
  },
  tableTitleCell: {
    width: 300,
  },
  serviceSpan: {
    paddingLeft: 32,
  },
  warningText: {
    color: colors.orange,
  },
}));

type UserNotificationsDialogProps = Omit<CustomDialogProps, 'id' | 'title' | 'loading'>;
interface UserNotificationsProps {
  user_id: number;
  fullName?: string;
  services: Profile_profile_organization_services[]
  | Approvals_userApprovals_items_user_organization_services[];
  onSuccess: (message: string) => void;
  dialogProps?: UserNotificationsDialogProps;
}

export const UserNotifications:React.FC<UserNotificationsProps> = (
  {
    user_id,
    fullName,
    services,
    onSuccess,
    dialogProps,
  }) => {
  const classes = useStyles();
  const [{ data, error, loading }, initialValues] = useInitialValues(user_id);

  const createCheckUncheckHandler = useCallback((
    type: NotificationType,
    eventId: number,
    values: UserNotificationsFormData,
    setFieldValue: FormikHelpers<UserNotificationsFormData>['setFieldValue'],
  ) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    const existingServices = new Set<number>();
    const ne = data?.notificationEvents.items.find((ev) => ev.event_id === eventId)!;
    const settings = values.settings.map((item) => {
      if (item.event_id === eventId) {
        if (item.service_id) {
          existingServices.add(item.service_id);
        }
        return { ...item, [type]: checked };
      }
      return item;
    });

    if (ne?.per_service) {
      const newItems = services
        .filter((s) => !existingServices.has(s.service_id))
        .map((s) => buildSettingInput(ne, s.service_id, {
          [type]: checked,
        }));

      settings.push(...newItems);
    } else {
      settings.push(buildSettingInput(ne, null, {
        [type]: checked,
      }));
    }

    setFieldValue('settings', settings);
  }, [services, data?.notificationEvents]);

  const createHandleOnChange = useCallback((
    values: UserNotificationsFormData,
    type: NotificationType,
    {
      event_id,
      service_id,
    }: Pick<SettingItemType, 'event_id' | 'service_id'>,
    setFieldValue: FormikHelpers<UserNotificationsFormData>['setFieldValue'],
  ) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const { settings: [...settings] } = values;
    const ne = data?.notificationEvents.items.find((ev) => ev.event_id === event_id)!;

    const existingSettingIdx = settings.findIndex(
      (s) => s.event_id === event_id && s.service_id === service_id,
    );
    const { checked } = e.target;

    if (existingSettingIdx !== -1) {
      settings[existingSettingIdx][type] = checked;
    } else {
      settings.push(buildSettingInput(ne, service_id, { [type]: checked }));
    }
    setFieldValue('settings', settings);
  }, [data?.notificationEvents]);

  const [syncNotificationPreferences, {
    error: syncError,
    loading: syncLoading,
  }] = useMutation<SyncUserNotificationSettings,
      SyncUserNotificationSettingsVariables>(SYNC_USER_NOTIFICATION_SETTINGS, {
        refetchQueries: [USER_NOTIFICATION_SETTINGS_QUERY],
      });
  const syncErrorSnackbar = useErrorSnackbar(syncError);

  const {
    handleSubmit, setFieldValue, values, submitForm,
  } = useFormik<UserNotificationsFormData>({
    validationSchema,
    enableReinitialize: true,
    initialValues,
    onSubmit: async ({ settings }: UserNotificationsFormData) => {
      const { data: saveResult } = await syncNotificationPreferences({
        variables: {
          user_id,
          settings,
        },
      });

      if (saveResult) {
        onSuccess('Successfully saved notification settings');
      }
    },
  });

  const form = useMemo(() => {
    if (loading) {
      return (
        <Loading center />
      );
    }

    if (error) {
      return (
        <ErrorMessage devMessage={error.message}>
          Failed loading user notification settings
        </ErrorMessage>
      );
    }

    return (
      <form onSubmit={handleSubmit}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell classes={{ root: classes.tableHeaderCell }}>
                Get SMS
              </TableCell>
              <TableCell classes={{ root: classes.tableHeaderCell }}>
                Get Email
              </TableCell>
              <TableCell classes={{ root: classes.tableHeaderCell }}>
                Get Email Reminders
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.notificationEvents.items.map((ev) => (
              <React.Fragment key={ev.event_id}>
                <TableRow>
                  <TableCell />
                  <TableCell colSpan={2} classes={{ root: classes.tableHeaderCell }}>
                  &nbsp;
                  </TableCell>
                  <TableCell />
                  <TableCell />
                </TableRow>
                <TableRow>
                  <TableCell classes={{ root: classes.tableTitleCell }}>
                    <Typography variant="body1">
                      <strong>{ev.name}</strong>
                    </Typography>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableHeaderCell }} padding="checkbox">
                    {ev.has_sms && (
                      <Tooltip placement="top" title={`Check/Uncheck all SMS notifications for ${ev.name}`}>
                        <Checkbox
                          checked={isAllChecked(values, NotificationType.SMS, ev.event_id)}
                          onChange={createCheckUncheckHandler(
                            NotificationType.SMS, ev.event_id, values, setFieldValue,
                          )}
                        />
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell classes={{ root: classes.tableHeaderCell }} padding="checkbox">
                    {ev.has_email && (
                      <Tooltip placement="top" title={`Check/Uncheck all email notifications for ${ev.name}`}>
                        <Checkbox
                          checked={isAllChecked(values, NotificationType.Email, ev.event_id)}
                          onChange={createCheckUncheckHandler(
                            NotificationType.Email, ev.event_id, values, setFieldValue,
                          )}
                        />
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell classes={{ root: classes.tableHeaderCell }} padding="checkbox">
                    {ev.has_email_reminder && (
                      <Tooltip placement="top" title={`Check/Uncheck all email reminders for ${ev.name}`}>
                        <Checkbox
                          checked={isAllChecked(
                            values, NotificationType.EmailReminder, ev.event_id,
                          )}
                          onChange={createCheckUncheckHandler(
                            NotificationType.EmailReminder, ev.event_id, values, setFieldValue,
                          )}
                        />
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell />
                </TableRow>
                {ev.per_service && services.map(({ name, service_id }) => {
                  const checkProps = { event_id: ev.event_id, service_id };
                  const isCheckedSMS = isChecked(values, NotificationType.SMS, checkProps);
                  const isCheckedEmail = isChecked(values, NotificationType.Email, checkProps);
                  const isCheckedEmailReminder = isChecked(
                    values, NotificationType.EmailReminder, checkProps,
                  );

                  return (
                    <TableRow key={`${ev.event_id}-${service_id}`}>
                      <TableCell
                        classes={{ root: [classes.tableTitleCell, classes.serviceSpan].join(' ') }}
                      >
                        {name}
                      </TableCell>
                      <TableCell classes={{ root: classes.tableHeaderCell }} padding="checkbox">
                        {ev.has_sms && (
                          <Checkbox
                            checked={isCheckedSMS}
                            onChange={createHandleOnChange(
                              values, NotificationType.SMS, checkProps, setFieldValue,
                            )}
                          />
                        )}
                      </TableCell>
                      <TableCell classes={{ root: classes.tableHeaderCell }} padding="checkbox">
                        {ev.has_email && (
                          <Checkbox
                            checked={isCheckedEmail}
                            onChange={createHandleOnChange(
                              values, NotificationType.Email, checkProps, setFieldValue,
                            )}
                          />
                        )}
                      </TableCell>
                      <TableCell classes={{ root: classes.tableHeaderCell }} padding="checkbox">
                        {ev.has_email_reminder && (
                          <Checkbox
                            checked={isCheckedEmailReminder}
                            onChange={createHandleOnChange(values,
                              NotificationType.EmailReminder,
                              checkProps,
                              setFieldValue)}
                          />
                        )}
                      </TableCell>
                      <TableCell />
                      <TableCell>
                        {!isCheckedEmail && !isCheckedEmailReminder && (
                          <Typography className={classes.warningText} variant="body2">
                            Warning: By opting out of all messages, you will not be notified of
                            {' '}
                            any contract updates that you are invited or reinvited to bid
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
        {!dialogProps && (
          <Box mt={2}>
            <Button type="submit" color="primary">
              <ButtonLoading loading={syncLoading} />
              Save Settings
            </Button>
          </Box>
        )}
        <ErrorSnackbar {...syncErrorSnackbar}>
          Error saving user notification settings
        </ErrorSnackbar>
      </form>
    );
  }, [
    classes,
    createCheckUncheckHandler,
    createHandleOnChange,
    data?.notificationEvents.items,
    dialogProps,
    error,
    handleSubmit,
    loading,
    services,
    setFieldValue,
    syncErrorSnackbar,
    syncLoading,
    values,
  ]);

  if (dialogProps) {
    return (
      <CustomDialog
        id="manage-notifications"
        loading={false}
        title={`Manage notifications for ${fullName}`}
        fullWidth
        maxWidth="md"
        actions={(
          <Button
            disabled={loading || syncLoading}
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              submitForm();
            }}
          >
            <ButtonLoading loading={syncLoading} />
            Save settings
          </Button>
        )}
        {...dialogProps!}
      >
        {form}
      </CustomDialog>
    );
  }

  return form;
};
