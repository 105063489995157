import { FieldValues, useForm } from 'react-hook-form';
import { TextField } from '@mui/material';
import React from 'react';
import { getError, hasError, labelIsOnTop } from '../helpers';
import { CustomFieldFormLabel } from '../CustomFieldFormLabel';
import { CustomFieldRenderComponentProps } from '../types';

interface CustomFieldNumberProps extends CustomFieldRenderComponentProps{
  value: FieldValues;
}

export const CustomFieldNumber: React.FC<CustomFieldNumberProps> = ({
  field,
  disabled,
  value,
  onChange,
}) => {
  const { errors } = useForm();

  return (
    <>
      <CustomFieldFormLabel field={field} checkLabelOnTop />
      <TextField
        disabled={disabled}
        type="number"
        inputProps={{ min: field.context?.min, max: field.context?.max }}
        value={value || ''}
        onChange={onChange}
        fullWidth
        placeholder={field.placeholder || undefined}
        error={hasError(errors, field)}
        helperText={getError(errors, field) || ' '}
        label={(labelIsOnTop(field) ? field.placeholder : field.name) || undefined}
      />
    </>
  );
};
