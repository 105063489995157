import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { ApolloError } from '@apollo/client';
import * as H from 'history';
import * as Sentry from '@sentry/browser';
import { Profile } from '../api/types/Profile';
import { PROFILE } from '../api/user/profile';
import { FirebaseAuth, useFirebaseAuth } from '../utils/auth';
import { REDIRECT_PARAM } from '../components/auth/pages/login/hooks/useLoginSearchParams';
import { PATHS } from '../paths';

export interface UseProfileWithAuthResult {
  profileLoading: boolean;
  profileData: Profile | undefined;
  profileError: ApolloError | undefined;
  firebaseAuth: FirebaseAuth;
}

export const useProfileWithAuth = (history: H.History): UseProfileWithAuthResult => {
  const [
    getProfile,
    {
      loading: profileLoading,
      data: profileData,
      error: profileError,
    },
  ] = useLazyQuery<Profile>(PROFILE, {
    fetchPolicy: 'network-only',
  });

  const firebaseAuth = useFirebaseAuth();

  useEffect(() => {
    if (firebaseAuth.loggedIn) {
      getProfile();
    } else if (firebaseAuth.loggedIn === false) {
      // Not logged in - redirect to login screen
      const { location: { pathname, search } } = history;
      const params = new URLSearchParams({
        [REDIRECT_PARAM]: pathname + search,
      });
      // Timeout prevents an infinite loop of redirects
      const timeout = setTimeout(() => {
        history.push(`${PATHS.home}?${params.toString()}`);
      }, 1);
      return () => {
        clearTimeout(timeout);
      };
    } // else (firebaseAuth.loggedIn === undefined) - auth check has not completed yet
    return () => {};
  }, [firebaseAuth, getProfile, history]);

  useEffect(() => {
    if (profileData) {
      const { reviewed_on, is_approved } = profileData.profile.approval;
      if (!reviewed_on || !is_approved) {
        // This user was either not approved yet or rejected
        // Push them back to the front page
        history.push(PATHS.home);
      }
    }
  }, [history, profileData]);

  useEffect(() => {
    if (profileData) {
      Sentry.configureScope((scope) => {
        const { profile } = profileData;
        scope.setUser({
          id: profile.user_id.toString(),
          email: profile.login_email,
          notification_email: profile.work_email,
          username: `${profile.first_name} ${profile.last_name}`,
          is_system_admin: profile.is_system_admin,
          organization_id: profile.organization.organization_id,
          organization_name: profile.organization.name,
        });
      });
    }
  }, [profileData]);

  return {
    profileLoading,
    profileData,
    profileError,
    firebaseAuth,
  };
};
