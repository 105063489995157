import React, { useCallback, useEffect, useState } from 'react';
import {
  Box, Snackbar, SnackbarProps, SnackbarCloseReason, Alert, AlertTitle,
  AlertColor as AlertSeverityColor,
} from '@mui/material';
import { ApolloError, isApolloError } from '@apollo/client';
import { trim, get } from 'lodash';
import { config } from '../../config';

type AnyError = string | Error | ApolloError | object;

export interface UseErrorSnackbarResult {
  open: boolean;
  onClose: (event: any, reason?: SnackbarCloseReason) => void;
  // This is supposed to be shown only in development environments
  devError: AnyError | null | undefined;
}

export const useErrorSnackbar = (
  error: AnyError | null | undefined = undefined,
  initialState: boolean = false,
): UseErrorSnackbarResult => {
  const [open, setOpen] = useState(initialState);
  useEffect(() => {
    if (error) {
      setOpen(true);
    }
  }, [error]);

  return {
    open,
    onClose: useCallback((event: any, reason?: SnackbarCloseReason) => {
      if (reason === 'clickaway') { // Only close by pressing some "close" button
        return;
      }
      setOpen(false);
    }, [setOpen]),
    devError: error,
  };
};

function trimTrailingPunctuation(message: string): string {
  return trim(message).replace(/\s*[.,:;-]*$/i, '');
}

export interface ErrorSnackbarProps extends Omit<SnackbarProps, 'children'> {
  title?: string;
  devError?: AnyError | null;
  onClose?: (event: any, reason?: SnackbarCloseReason) => void;
  children: React.ReactNode;
  severity?: AlertSeverityColor;
}

export const useErrorDetails = (
  { devError, children }: { devError?: AnyError | null, children: React.ReactNode },
) => {
  const { showDevMessages } = config;

  let stripeError: string | undefined;
  let isUserFriendly = false;
  if (devError && devError instanceof Error && isApolloError(devError)) {
    (devError as ApolloError).graphQLErrors.forEach((e) => {
      if (e.extensions?.isUserFriendly) {
        isUserFriendly = true;
      }
      if (e.extensions?.exception.type === 'StripeCardError') {
        stripeError = e.extensions?.exception.raw.message;
      }
    });
  }

  const childrenNoTrailingPunctuation = (typeof children === 'string' && showDevMessages && devError)
    ? trimTrailingPunctuation(children)
    : children;
  let devMessage: string | undefined;
  if (devError) {
    devMessage = typeof devError === 'string'
      ? trim(devError)
      : get(devError, 'message', devError.toString());
  }
  const isMadeUpError = childrenNoTrailingPunctuation === `Error: ${devMessage}`;
  const showMessage = !stripeError && !isMadeUpError && (showDevMessages || isUserFriendly);

  return {
    showMessage,
    devMessage,
    isMadeUpError,
    isUserFriendly,
    stripeError,
    childrenNoTrailingPunctuation,
  };
};

export const ErrorSnackbar: React.FC<ErrorSnackbarProps> = ({
  title,
  devError,
  onClose,
  children,
  severity = 'error',
  ...snackbarProps
}) => {
  const {
    showMessage,
    stripeError,
    devMessage,
    isUserFriendly,
    childrenNoTrailingPunctuation,
  } = useErrorDetails({ devError, children });

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={snackbarProps.action ? null : 10000}
      onClose={onClose}
      {...snackbarProps}
    >
      <Alert severity={severity} onClose={onClose} variant="filled" action={snackbarProps.action}>
        {title && (<AlertTitle>{title}</AlertTitle>)}
        {
          stripeError
            ? `Payment processing error: ${trimTrailingPunctuation(stripeError)}`
            : childrenNoTrailingPunctuation
        }
        {(showMessage && devMessage) ? (
          <Box mt={2}>
            {!isUserFriendly ? (
              <>
                <b>Dev details:</b>
                <br />
              </>
            ) : null}
            {devMessage}
          </Box>
        ) : ''}
      </Alert>
    </Snackbar>
  );
};
