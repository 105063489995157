import { useEffect } from 'react';
import { ResizeObserver } from '@juggle/resize-observer';
import { ResizeObserverCallback } from '@juggle/resize-observer/lib/ResizeObserverCallback';

export const useResizeObserver = (id: string, onResize: ResizeObserverCallback) => {
  useEffect(() => {
    const el = document.getElementById(id);
    if (el) {
      const ro = new ResizeObserver(onResize);
      ro.observe(el);
      return () => {
        ro.disconnect();
      };
    }
    return () => {
      // Nothing
    };
  }, [id, onResize]);
};
