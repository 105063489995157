import { includes } from 'lodash';

import { BidStatus } from 'api/bids/bidStatuses';
import { Profile_profile } from 'api/types/Profile';
import { BundleStatus } from 'api/bundles/bundleStatuses';

export function isDisabledSubmitBidButton(bidStatus: BidStatus): boolean {
  return includes([
    BidStatus.Submitted,
    BidStatus.Declined,
    BidStatus.Rejected,
    BidStatus.Expired,
    BidStatus.Awarded,
  ], bidStatus);
}

export function canSubmitBidAsSP(bundleStatus: BundleStatus, bidStatus: BidStatus): boolean {
  return includes([BidStatus.Draft, BidStatus.Reopened, BidStatus.Negotiating], bidStatus)
    && [BundleStatus.OutForBid, BundleStatus.Negotiating].includes(bundleStatus);
}

export function canReviewBidPricesAsSP(bundleStatus: BundleStatus, bidStatus: BidStatus): boolean {
  return includes([
    BidStatus.Invited, BidStatus.Draft, BidStatus.Reopened, BidStatus.Negotiating,
  ], bidStatus)
    && [BundleStatus.OutForBid, BundleStatus.Negotiating].includes(bundleStatus);
}

export function canViewBidAsSP(profile: Profile_profile, bidOrgId: number): boolean {
  const org = profile.organization;
  return org.is_service_provider && (org.organization_id === bidOrgId);
}

export const canDeclineBidAsSP = (bundleStatus: BundleStatus, bidStatus: BidStatus) => (
  bundleStatus === BundleStatus.OutForBid
  && [BidStatus.Invited, BidStatus.Draft].includes(bidStatus)
);

export function canViewBidAsFM(
  profile: Profile_profile,
  bundleOrgId: number,
  bidStatus: BidStatus,
  bidSubmittedOn: Date | null,
): boolean {
  const org = profile.organization;
  return org.is_facility_manager
    && org.organization_id === bundleOrgId
    && bidStatus !== BidStatus.Draft
    && bidStatus !== BidStatus.Invited
    && bidStatus !== BidStatus.Declined
    && bidStatus !== BidStatus.Expired
    && (bidStatus !== BidStatus.Rejected || !!bidSubmittedOn);
}
