import { useEffect, useMemo, useState } from 'react';
import L from 'leaflet';
import { GeoJsonObject } from 'geojson';
import * as Sentry from '@sentry/browser';
import {
  ServiceAreas_organizationServiceAreas_items as AreaItem,
} from 'api/types/ServiceAreas';

import { isSatelliteLike } from '../leaflet/googleMapLayers';
import { AREA_STYLE_NORMAL, AREA_STYLE_SATELLITE, createLayer } from '../leaflet/selectedAreas';

export const useServiceAreasRender = (
  map: L.Map | undefined,
  items?: AreaItem[],
  fitBounds?: boolean,
) => {
  // Service areas layer state
  const [serviceAreasLayer, setServiceAreasLayer] = useState<L.GeoJSON>();

  // Caching to prevent back-and-forward conversions b/w GeoJSON and Leaflet layer format
  const [areaLayersCache, setAreaLayersCache] = useState<{ [code: string]: L.GeoJSON }>({});

  // Initialize Service areas layer
  useEffect(() => {
    if (!map) {
      return () => {};
    }
    const layer = new L.GeoJSON();
    map.addLayer(layer);
    setServiceAreasLayer(layer);

    const baseLayerChangeHandler = (e: L.LayersControlEvent) => {
      layer.setStyle(
        isSatelliteLike(e.name) ? AREA_STYLE_SATELLITE : AREA_STYLE_NORMAL,
      );
    };
    map.on('baselayerchange', baseLayerChangeHandler);

    return () => {
      layer.clearLayers();
      map.removeLayer(layer);
      map.removeEventListener('baselayerchange', baseLayerChangeHandler);
    };
  }, [map]);

  // Render Service areas
  useEffect(() => {
    if (!map || !serviceAreasLayer || !items || !items.length) {
      return () => {};
    }
    try {
      serviceAreasLayer.clearLayers();

      const cache: Record<string, L.GeoJSON> = {};
      let addedSomething = false;
      items.forEach(({ postalCode, state }) => {
        const shape = (postalCode?.shape?.postalShape || state?.stateShape) as GeoJsonObject | null;
        const code = postalCode?.postal_code || state?.state_code;
        if (shape && code) {
          const l = createLayer(shape, code);
          cache[code] = l;
          serviceAreasLayer.addLayer(l);
          addedSomething = true;
        }
      });
      if (addedSomething) {
        setAreaLayersCache(cache);
        serviceAreasLayer.setStyle(AREA_STYLE_NORMAL);
        if (fitBounds) {
          map.fitBounds(serviceAreasLayer.getBounds());
        }
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
    return () => {};
  }, [map, fitBounds, serviceAreasLayer, items]);

  return useMemo(() => ({
    serviceAreasLayer,
    areaLayersCache,
    setAreaLayersCache,
  }), [serviceAreasLayer, areaLayersCache, setAreaLayersCache]);
};
