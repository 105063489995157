import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { State } from '@progress/kendo-data-query';
import {
  Grid as KendoGrid, GridCell, GridCellProps, GridColumn, GridColumnMenuWrapper, GridNoRecords,
} from '@progress/kendo-react-grid';
import {
  Button, Checkbox, Radio, Tooltip,
} from '@mui/material';
import {
  omit, pick, fromPairs, every, set, get,
} from 'lodash';

import { OrderInput } from 'api/types/globalTypes';

import { NoData } from 'components/text/NoData';
import { EllipsisText } from '../text/EllipsisText';
import { ErrorSnackbar, useErrorSnackbar } from '../notifications/ErrorSnackbar';
import { ActionsList } from '../ActionsList';
import { FilteredLocationsLeafletMap } from '../map/FilteredLocationsLeafletMap';
import { useDialog } from '../Dialog';
import { TableAndMapLayout, TableAndMapLayoutMode } from './TableAndMapLayout';
import { LoadingPanel } from './LoadingPanel';
import { ColumnMenu } from './column/ColumnMenu';
import { useFilters, UseFiltersResult } from './filter/useFilters';
import { buildFilterWidget } from './filter/widget/FilterWidgets';
import { CustomCell } from './column/CustomCell';
import { useHiddenColumns } from './column/useHiddenColumns';
import { useFilterWidgets } from './filter/useFilterWidgets';
import { Filter } from './filter/types';
import { useReorderableColumns } from './column/useReorderableColumns';
import { useSorting } from './sort/useSorting';
import { usePagination } from './paginate/usePagination';
import { useResizeableColumns } from './column/useResizeableColumns';
import { useFreezing } from './column/useFreezing';
import { WithMapProps } from './map/MappingProps';
import { useStyles } from './styles';
import {
  DataGridProps,
  isWithMapProps,
  isWithMultipleSelection,
  isWithSelection,
  isWithSingleSelection,
} from './DataGridProps';
import { CustomPager } from './paginate/CustomPager';
import { useMap } from './map/useMap';
import { isFilterWidgetPropsSimple, isFilterWidgetPropsWithOptions } from './filter/FilterWidgetProps';

export type PageSize = 5 | 10 | 25 | 50 | 100 | 200 | 300 | 400 | 500;
const PAGE_SIZES = [5, 10, 25, 50, 100, 200, 300, 400, 500];
export const ACTIONS_TITLE = 'Actions';
export const NONE_TEXT = 'None';

/**
 * DataGrid state.
 * Filtering and sorting APIs are intentionally replaced with more suitable for our API.
 * See {@link UseFiltersResult} for description of filtering fields.
 */
export interface DataGridState extends Pick<State, 'skip' | 'take'> {
  apiFilters: UseFiltersResult['apiFilters'];
  /**
   * IMPORTANT: Use {@link apiFilters} in normal use cases.
   * This is for advanced use, e.g. performance optimizations.
   */
  rawFilters: UseFiltersResult['rawFilters'];
  order?: OrderInput[];
}

const SELECT_COL_WIDTH: number = 42;

export type DataGridRefHandle = {
  reload: () => void,
  clearPagination: () => void;
};

export const DataGrid = React.forwardRef<DataGridRefHandle, DataGridProps>(
  (dataGridProps, forwardedRef) => {
    const {
      id: tableId,
      columns,
      overrideFilters,
      onOverrideFiltersDelete,
      defaultFilters,
      defaultSort,
      pageSize = 10,
      maxPageSize = 100,
      maxPagerButtons = 10,
      resizable = true,
      fixedPagerButtons = false,
      loadTableData,
      tableData,
      tableLoading,
      tableError,
      beforeFilters,
      afterFilters,
      actions,
      actionsWidth = 100,
      withMap,
      enableColumnsWidthAdjustment = false,
      additionalResets,
      clearPaginationFlag,
      noBorder,
      noPanel = false,
      noColumnSettings = false,
      tinyFilters = false,
      topPageNavigation = true,
      bottomPageNavigation = true,
      pageNavigationInfo,
      hidePageNavigationIfOnePageOnly = false,
      scrollable = 'scrollable',
      emptyLabel = 'No records available',
    } = dataGridProps;

    const classes = useStyles();

    // A hack to get map-related props without an infinite loop of hook dependencies
    const {
      loadMapData,
    } = dataGridProps as WithMapProps;

    const [mode, setMode] = useState<TableAndMapLayoutMode>('table');
    const [filterClearedTimestamp, setFilterClearedTimestamp] = useState<{
      [key: string]: number;
    }>(() => ({}));

    const pageSizes = useMemo(() => {
      if (maxPageSize) {
        return PAGE_SIZES.filter((s) => s <= maxPageSize);
      }
      return PAGE_SIZES;
    }, [maxPageSize]);

    const { page, onPageChange, clearPagination } = usePagination(tableId, pageSize);
    useEffect(() => {
      clearPagination();
    }, [clearPagination, clearPaginationFlag]);

    const total = useMemo(
      () => tableData?.total ?? 0,
      [tableData?.total],
    );

    const {
      rawFilters,
      apiFilters,
      displayFilters,
      getFilter,
      setFilter: setFilterFunction,
      clearFilter: clearFilterFunction,
      clearAllFilters,
    } = useFilters(tableId, defaultFilters);

    const setFilter = useCallback((field: string, filter: Filter) => {
      setFilterFunction(field, filter);
      clearPagination();
    }, [clearPagination, setFilterFunction]);

    const clearFilter = useCallback((field: string) => {
      const timestamp = new Date().getTime();
      setFilterClearedTimestamp((prev) => ({
        ...prev,
        [field]: timestamp,
      }));
      clearFilterFunction(field);
      clearPagination();
    }, [setFilterClearedTimestamp, clearFilterFunction, clearPagination]);

    const { filterWidgetOpen, toggleFilterWidget } = useFilterWidgets();

    const {
      visibleColumns,
      hiddenColumns,
      toggleColumn,
      resetColumnVisibility,
    } = useHiddenColumns(tableId, columns);

    const {
      columnOrder,
      maxOrder,
      onColumnReorder,
      onColumnToggle,
      resetOrder,
    } = useReorderableColumns(tableId, visibleColumns);

    const additionalColumnsWidth = (isWithSingleSelection(dataGridProps) ? SELECT_COL_WIDTH : 0)
    + (isWithMultipleSelection(dataGridProps) ? SELECT_COL_WIDTH : 0)
    + (actions ? actionsWidth : 0);

    const {
      columnWidth,
      onColumnResize,
      resetWidths,
    } = useResizeableColumns(
      tableId, columns, visibleColumns, additionalColumnsWidth, enableColumnsWidthAdjustment,
    );

    const {
      order,
      sort,
      onSortChange,
      clearSorting,
    } = useSorting(tableId, columns, defaultSort);

    const {
      widthsFrozen,
      setWidthsFrozen,
      orderFrozen,
      setOrderFrozen,
      resetFreezeSettings,
    } = useFreezing(tableId);

    const dataGridState: DataGridState = useMemo(() => ({
      skip: page.skip,
      take: page.take,
      rawFilters,
      apiFilters,
      order,
    }), [apiFilters, order, rawFilters, page]);

    const handleReload = useCallback(async () => {
      if (mode === 'table') {
        if (loadTableData) loadTableData(dataGridState);
      } else if (loadMapData) {
        loadMapData(dataGridState);
      }
    }, [dataGridState, loadMapData, loadTableData, mode]);

    useEffect(() => {
      handleReload();
    }, [handleReload]);

    React.useImperativeHandle(forwardedRef, () => ({
      reload: handleReload,
      clearPagination,
    }));

    const tableErrorSnackbar = useErrorSnackbar(tableError);

    const {
      mapData,
      mapLoading,
      mapError,
    } = useMap(dataGridProps);

    const mapErrorSnackbar = useErrorSnackbar(mapError);

    const tableWrapper = useRef<HTMLDivElement | null>(null);

    const columnTitles = useMemo(
      () => fromPairs(columns.map(({ field, title }) => [field, title])),
      [columns],
    );

    const onReset = useCallback(() => {
      const timestamp = new Date().getTime();
      setFilterClearedTimestamp((prev) => ({
        ...prev,
        all: timestamp,
      }));
      clearAllFilters();
      clearSorting();
      resetOrder();
      resetColumnVisibility();
      resetWidths();
      resetFreezeSettings();
      additionalResets?.forEach((reset) => {
        reset();
      });
      clearPagination();
    }, [
      clearAllFilters,
      clearSorting,
      resetColumnVisibility,
      resetOrder,
      resetWidths,
      resetFreezeSettings,
      additionalResets,
      clearPagination,
    ]);

    const { confirm } = useDialog();

    const onResetWithConfirm = useCallback(async () => {
      const confirmed = await confirm({
        title: 'Are you sure?',
        content: 'This action will erase all your current settings for this table',
        okText: 'Yes, reset',
      });
      if (confirmed) {
        onReset();
      }
    }, [confirm, onReset]);

    // Offset for indexes of columns, in cases like when we have selection enabled,
    // which creates an additional column before main columns
    const orderIndexOffset = useMemo(
      () => (isWithSelection(dataGridProps) ? 1 : 0),
      [dataGridProps],
    );

    const builtColumns = useMemo(() => (columns.map((columnProps) => ({
      ...columnProps,
      onCloseFilter: () => toggleFilterWidget(columnProps.field, false),
    }))), [toggleFilterWidget, columns]);

    const showPageNavigation = !hidePageNavigationIfOnePageOnly || total > pageSize;

    const buttonCount = useMemo(() => {
      if (!fixedPagerButtons) return maxPagerButtons;

      const currentPage = page.skip / page.take + 1;
      const totalPages = Math.ceil(total / pageSize);

      let count = maxPagerButtons;

      if (currentPage > maxPagerButtons) count -= 1;
      if (currentPage <= totalPages - count) count -= 1;

      return count < 0 ? maxPagerButtons : count;
    },
    [fixedPagerButtons, maxPagerButtons, page.skip, page.take, total, pageSize]);

    return (
      <>
        <TableAndMapLayout
          withMap={withMap}
          mode={mode}
          onModeChange={setMode}
          beforeFilters={beforeFilters}
          afterFilters={afterFilters}
          filters={displayFilters}
          clearFilter={clearFilter}
          clearAllFilters={clearAllFilters}
          toggleFilterWidget={toggleFilterWidget}
          overrideFilters={overrideFilters}
          onOverrideFiltersDelete={onOverrideFiltersDelete}
          columns={columns}
          columnTitles={columnTitles}
          hiddenColumns={hiddenColumns}
          toggleColumn={(field, show) => {
            toggleColumn(field, show);
            onColumnToggle(field, show);
          }}
          noBorder={noBorder}
          noPanel={noPanel}
          noColumnSettings={noColumnSettings}
          tinyFilters={tinyFilters}
          table={(
            <div ref={tableWrapper}>
              {topPageNavigation && showPageNavigation && (
                <CustomPager
                  page={page}
                  onPageChange={onPageChange}
                  total={total}
                  pageSizes={pageSizes}
                  info={pageNavigationInfo}
                  buttonCount={buttonCount}
                />
              )}
              <KendoGrid
                data={tableData}
                sortable
                sort={sort}
                onSortChange={onSortChange}
                reorderable={!orderFrozen}
                onColumnReorder={onColumnReorder}
                onColumnResize={onColumnResize}
                className={classes.kendoGrid}
                scrollable={scrollable}
                resizable={resizable && !widthsFrozen}
              >
                <GridNoRecords>
                  {!tableLoading ? emptyLabel : <div style={{ height: 50 }} />}
                </GridNoRecords>
                {isWithSingleSelection(dataGridProps) && (
                  <GridColumn
                    title=" "
                    width={SELECT_COL_WIDTH}
                    resizable={false}
                    sortable={false}
                    reorderable={false}
                    orderIndex={0}
                    className={classes.selectionCell}
                    cell={(props: GridCellProps) => (
                      <CustomCell {...props}>
                        {(dataItem) => (
                          <Tooltip
                            title={
                              dataGridProps.selectionControlTooltip
                                ? dataGridProps.selectionControlTooltip(dataItem)
                                : false
                            }
                            placement="right"
                            arrow
                          >
                            <span>
                              <Radio
                                checked={dataGridProps.isSelected(dataItem)}
                                disabled={
                                  dataGridProps.disableRowSelection
                                    ? dataGridProps.disableRowSelection(dataItem)
                                    : false
                                }
                                onChange={(event, checked) => {
                                  dataGridProps.toggle(event, checked, dataItem);
                                }}
                                inputProps={{ 'aria-label': 'Select this item' }}
                              />
                            </span>
                          </Tooltip>
                        )}
                      </CustomCell>
                    )}
                  />
                )}
                {isWithMultipleSelection(dataGridProps) && (
                  <GridColumn
                    width={SELECT_COL_WIDTH}
                    resizable={false}
                    sortable={false}
                    reorderable={false}
                    orderIndex={0}
                    className={classes.selectionCell}
                    headerClassName={classes.selectionCell}
                    headerCell={() => (
                      <Tooltip
                        title={dataGridProps.toggleAllControlTooltip ?? false}
                        placement="right"
                        arrow
                      >
                        <span>
                          <Checkbox
                            className={classes.checkbox}
                            checked={
                              tableData?.data
                                ? every(tableData?.data, (item) => dataGridProps.isSelected(item)
                                || (dataGridProps.disableRowSelection
                                && dataGridProps.disableRowSelection(item)))
                                : false
                            }
                            disabled={dataGridProps.disableAllSelection === true}
                            onChange={(event, checked) => {
                              const { disableRowSelection } = dataGridProps;

                              if (!tableData) {
                                return;
                              }

                              dataGridProps.toggleAll(
                                event,
                                checked,
                                disableRowSelection
                                  ? tableData.data.filter((item) => !disableRowSelection(item))
                                  : tableData.data,
                              );
                            }}
                            inputProps={{ 'aria-label': 'Select all visible rows' }}
                          />
                        </span>
                      </Tooltip>
                    )}
                    cell={(props: GridCellProps) => (
                      <CustomCell {...props}>
                        {(dataItem) => (
                          <Tooltip
                            title={
                              dataGridProps.selectionControlTooltip
                                ? dataGridProps.selectionControlTooltip(dataItem)
                                : false
                            }
                            open={dataGridProps.selectionControlTooltip ? undefined : false}
                            placement="right"
                            arrow
                          >
                            <span>
                              <Checkbox
                                className={classes.checkbox}
                                checked={dataGridProps.isSelected(dataItem)}
                                disabled={
                                  dataGridProps.disableRowSelection
                                    ? dataGridProps.disableRowSelection(dataItem)
                                    : (dataGridProps.disableAllSelection ?? false)
                                }
                                onChange={(event, checked) => {
                                  dataGridProps.toggle(event, checked, dataItem);
                                }}
                                inputProps={{ 'aria-label': 'Select this item' }}
                              />
                            </span>
                          </Tooltip>
                        )}
                      </CustomCell>
                    )}
                  />
                )}
                {visibleColumns.map((columnProps) => {
                  const { filterWidget } = columnProps;
                  const substituteLabels = isFilterWidgetPropsWithOptions(filterWidget)
                  && filterWidget.substituteOptionLabels;
                  const customRender = columnProps.render;
                  const needsCustomCell = substituteLabels || customRender;
                  const orderIndex = columnOrder[columnProps.field] + orderIndexOffset;
                  const passThroughColumnProps = omit(columnProps, ['filter']);
                  const needsMenu = filterWidget || columnProps.sortable !== false;
                  return (
                  /**
                   * We have to use Kendo's {@link GridColumn} component
                   * without wrapping into any facade component
                   * because it's the only way the grid will recognize it as a column.
                   * Any wrappers, even w/o extra JSX around {@link GridColumn}, do not work
                   */
                    <GridColumn
                      key={columnProps.field}
                      {...passThroughColumnProps}
                      minResizableWidth={columnProps.minResizableWidth ?? 100}
                      width={columnWidth[columnProps.field]}
                      orderIndex={orderIndex}
                      headerCell={({
                        title, onClick, columnMenuWrapperProps, children,
                      }) => (
                        <span className="k-cell-inner">
                          <span className="k-link" aria-hidden="true" onClick={onClick}>
                            {title ? (
                              <Tooltip
                                title={title}
                                placement="top-start"
                                arrow
                              >
                                <span>{title}</span>
                              </Tooltip>
                            ) : <span>&nbsp;</span>}
                            {children}
                          </span>
                          {needsMenu && (
                            <GridColumnMenuWrapper {...columnMenuWrapperProps} />
                          )}
                        </span>
                      )}
                      {...(needsCustomCell ? {
                        cell: (props) => {
                          if (customRender) {
                            return (
                              <CustomCell {...props}>
                                {(dataItem) => (
                                  customRender(dataItem) ?? <NoData>{NONE_TEXT}</NoData>
                                )}
                              </CustomCell>
                            );
                          }
                          if (
                            isFilterWidgetPropsWithOptions(filterWidget)
                          && filterWidget.substituteOptionLabels
                          ) {
                            const { field = '', dataItem } = props;
                            const filterOption = filterWidget.options
                              .find((o) => o.key === get(dataItem, field));
                            const value = filterOption?.label ?? get(dataItem, field);
                            return (
                              <GridCell
                                {...props}
                                dataItem={set(dataItem, field, value)}
                              />
                            );
                          }
                          return null;
                        },
                      } : {
                        cell: (props) => (
                          <CustomCell {...props}>
                            {(dataItem) => {
                              const value = get(dataItem, columnProps.field);
                              return value ? (
                                <EllipsisText
                                  fullText={value}
                                  insideDataGrid
                                  variant="body2"
                                >
                                  {value}
                                </EllipsisText>
                              ) : (
                                <NoData>{NONE_TEXT}</NoData>
                              );
                            }}
                          </CustomCell>
                        ),
                      })}
                      columnMenu={needsMenu ? (columnMenuProps) => {
                        if (isFilterWidgetPropsSimple(filterWidget)) {
                          return (
                            <ColumnMenu
                              {...columnMenuProps}
                              filterType={filterWidget.filterType}
                              filter={getFilter(columnProps.field)}
                              clearFilter={() => clearFilter(columnProps.field)}
                              openFilterWidget={() => toggleFilterWidget(columnProps.field, true)}
                            />
                          );
                        }
                        if (isFilterWidgetPropsWithOptions(filterWidget)) {
                          return (
                            <ColumnMenu
                              {...columnMenuProps}
                              filterType={filterWidget.filterType}
                              filter={getFilter(columnProps.field)}
                              clearFilter={() => clearFilter(columnProps.field)}
                              openFilterWidget={() => toggleFilterWidget(columnProps.field, true)}
                              filterOptions={filterWidget.options}
                            />
                          );
                        }
                        return (
                          <ColumnMenu
                            {...columnMenuProps}
                            filterType={undefined}
                          />
                        );
                      } : undefined}
                    />
                  );
                })}
                {actions && (
                  <GridColumn
                    title={ACTIONS_TITLE}
                    width={columnWidth.actions ?? actionsWidth}
                    // The magic number 210 adjusted for the INVITE SERVICE PROVIDERS button
                    minResizableWidth={Math.min(actionsWidth ?? 100, 210)}
                    sortable={false}
                    reorderable={false}
                    orderIndex={maxOrder + 1 + orderIndexOffset}
                    headerCell={() => (
                      <Tooltip
                        title={ACTIONS_TITLE}
                        placement="top-start"
                        arrow
                      >
                        <span>{ACTIONS_TITLE}</span>
                      </Tooltip>
                    )}
                    cell={(props: GridCellProps) => (
                      <CustomCell {...props}>
                        {(dataItem) => <ActionsList actions={actions(dataItem)} />}
                      </CustomCell>
                    )}
                  />
                )}
              </KendoGrid>
              {tableLoading && <LoadingPanel tableWrapper={tableWrapper} />}
              {bottomPageNavigation && showPageNavigation && (
                <CustomPager
                  bottom
                  page={page}
                  onPageChange={onPageChange}
                  total={total}
                  pageSizes={pageSizes}
                  info={pageNavigationInfo}
                  buttonCount={buttonCount}
                />
              )}
            </div>
          )}
          map={isWithMapProps(dataGridProps) && mode === 'map' ? (
            <FilteredLocationsLeafletMap
              {...pick(
                dataGridProps,
                [
                // from BaseMapProps:
                // 'id', // omitted in the type above
                  'zoom',
                  // 'height', // omitted in the type above
                  'homePlaceIdOrCoordinates',
                  'homeTitle',
                  // from ViewMapProps:
                  'maxZoomWhenFitting',
                  // 'fillScreenHeight', // omitted in the type above
                  // from FilteredLocationsLeafletMapProps:
                  'groupMarkers',
                  'groupMarkersMaxZoomLevel',
                  'setActiveItem',
                  'setActiveItemOnClick',
                  'getItemById',
                  'convertDataIntoMarkers',
                  'createMarkerPopupContents',
                  'onSelect',
                  'enableBidsTooltip',
                ],
              )}
              id={`${tableId}-map`}
              height={600}
              fillScreenHeight
              loading={mapLoading}
              data={mapData}
              withSearch
            />
          ) : undefined}
          onReset={onResetWithConfirm}
          widthsFrozen={widthsFrozen}
          setWidthsFrozen={setWidthsFrozen}
          orderFrozen={orderFrozen}
          setOrderFrozen={setOrderFrozen}
        />
        {builtColumns.map((columnProps) => buildFilterWidget(
          tableId,
          columnProps,
          columnTitles[columnProps.field] ?? columnProps.field,
          getFilter(columnProps.field),
          Math.max(
            filterClearedTimestamp[columnProps.field] ?? 0,
            filterClearedTimestamp.all ?? 0,
          ),
          setFilter,
          clearFilter,
          filterWidgetOpen[columnProps.field] ?? false,
          columnProps.onCloseFilter,
        ))}
        <ErrorSnackbar
          {...tableErrorSnackbar}
          action={(
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                onReset();
                tableErrorSnackbar.onClose({});
              }}
            >
              Reset table
            </Button>
          )}
        >
          Failed loading table data. You can try resetting the table to fix this.
        </ErrorSnackbar>
        <ErrorSnackbar
          {...mapErrorSnackbar}
          action={(
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                clearAllFilters();
                mapErrorSnackbar.onClose({});
              }}
            >
              Reset filters
            </Button>
          )}
        >
          Failed loading map data. You can try resetting filters to fix this.
        </ErrorSnackbar>
      </>
    );
  });
