import React, { useMemo } from 'react';
import {
  Box, Button,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useSpSidebarHandlers } from '../SpSidebarProvider';
import { ProfileGallery } from '../../../../profile/ProfileGallery';

interface GallerySubPageProps {
  organization: any;
}

export const GallerySubPage = ({ organization }: GallerySubPageProps) => {
  const { modifyExtra } = useSpSidebarHandlers();

  const galleryPayload = useMemo(() => ({
    publicOrganization: organization,
    commonFields: {},
  }) as any, [organization]);

  return (
    <Box key="gallery" m={2}>
      <Button
        size="large"
        variant="text"
        color="primary"
        startIcon={<ArrowBack />}
        onClick={() => modifyExtra((p) => ({ ...p, page: 'main' }))}
      >
        Gallery
      </Button>

      <Box ml={1} mt={3}>
        <ProfileGallery data={galleryPayload} edit={false} small />
      </Box>
    </Box>
  );
};
