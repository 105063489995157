import React, {
  createContext, ReactNode, useCallback, useMemo, useState,
} from 'react';
import { FormikErrors } from 'formik';
import { SuccessSnackbar } from '../../../notifications/SuccessSnackbar';
import { PageProgressState } from './components/HelpSidebar/checklist/PageProgress.types';

type OnboardingContextValues = {
  validateForm?: () => Promise<FormikErrors<any>>;
  submitForm?: () => Promise<any>;
  state?: PageProgressState;
};

type OnboardingContextValue = OnboardingContextValues & {
  setValue: (value: OnboardingContextValues) => void;
  setSuccessMsg: (str: string) => void;
  dirty: boolean;
  setDirty: (str: boolean) => void;
  viewedState: Record<string, number>;
  setViewedState: (key: string, v: number) => void;
};

export const OnboardingContext = createContext<OnboardingContextValue>({
  setValue: () => {},
  setSuccessMsg: () => {},
  dirty: false,
  setDirty: () => {},
  viewedState: {},
  setViewedState: () => {},
});

export const OnboardingProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [value, setValue] = useState<OnboardingContextValues>({});
  const [successMsg, setSuccessMsg] = useState<string>();
  const [dirty, setDirty] = useState<boolean>(false);

  const [viewedState, setLocalStateInternal] = useState<Record<string, number>>(() => {
    try {
      return JSON.parse(window.localStorage.getItem('onboarding_viewed') ?? '{}');
    } catch (e) {
      return {};
    }
  });
  const setViewedState = useCallback((key: string, v: number) => {
    setLocalStateInternal((prev) => {
      const result = { ...prev, [key]: v };
      window.localStorage.setItem('onboarding_viewed', JSON.stringify(result));
      return result;
    });
  }, []);

  const ctx = useMemo(() => ({
    ...value,
    dirty,
    setDirty,
    setValue,
    setSuccessMsg,
    viewedState,
    setViewedState,
  }), [value, dirty, viewedState, setViewedState]);
  return (
    <OnboardingContext.Provider value={ctx}>
      {children}
      <SuccessSnackbar
        open={!!successMsg}
        autoHideDuration={5000}
        onClose={() => setSuccessMsg(undefined)}
      >
        {successMsg}
      </SuccessSnackbar>
    </OnboardingContext.Provider>
  );
};
