import React from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { GridOn, Map } from '@mui/icons-material';
import { TableAndMapLayoutMode } from './TableAndMapLayout';
import { FilterChips } from './filter/FilterChips';
import { Filter } from './filter/types';
import { ColumnProps } from './column/Column';
import { UseFiltersResult } from './filter/useFilters';
import { UseFreezingResult } from './column/useFreezing';
import { DataGridProps } from './DataGridProps';
import { FiltersMenuButton } from './FiltersMenuButton';
import { SettingsMenuButton } from './SettingsMenuButton';

const useStyles = makeStyles((theme) => createStyles({
  searchBar: {
    padding: theme.spacing(1),
    overflowY: 'auto',
  },
}));

export interface ControlPanelProps extends UseFreezingResult {
  /** If true, button group ode switch will be shown */
  withMap: boolean;
  mode: TableAndMapLayoutMode;
  onModeChange: (mode: TableAndMapLayoutMode) => void;
  /** Additional UI element before filters */
  beforeFilters?: React.ReactNode;
  afterFilters?: React.ReactNode;
  filters: Filter[];
  clearFilter: UseFiltersResult['clearFilter'];
  clearAllFilters: UseFiltersResult['clearAllFilters'];
  toggleFilterWidget: (field: string, open: boolean) => void;
  overrideFilters?: DataGridProps['overrideFilters'];
  onOverrideFiltersDelete?: DataGridProps['onOverrideFiltersDelete'];
  columns: ColumnProps[];
  columnTitles: { [name: string]: string };
  hiddenColumns: { [field: string]: boolean };
  toggleColumn: (field: string, show: boolean) => void;
  onReset: () => void;
  tinyFilters?: boolean;
  noColumnSettings?: boolean;
}

export const ControlPanel: React.FC<ControlPanelProps> = ({
  withMap,
  mode,
  onModeChange,
  beforeFilters,
  afterFilters,
  filters,
  clearFilter,
  clearAllFilters,
  toggleFilterWidget,
  overrideFilters,
  onOverrideFiltersDelete,
  columns,
  columnTitles,
  hiddenColumns,
  toggleColumn,
  onReset,
  widthsFrozen,
  setWidthsFrozen,
  orderFrozen,
  setOrderFrozen,
  tinyFilters,
  noColumnSettings,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.searchBar}>
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        alignContent="flex-start"
        wrap="nowrap"
      >
        {beforeFilters && (
          <Grid item>
            {beforeFilters}
          </Grid>
        )}
        {withMap && (
          <Grid item>
            <ButtonGroup
              variant="outlined"
              fullWidth
              aria-label="Switch view mode"
            >
              <Button
                onClick={() => onModeChange('table')}
                color="primary"
                variant={mode === 'table' ? 'contained' : undefined}
                startIcon={<GridOn />}
              >
                Table
              </Button>
              <Button
                onClick={() => onModeChange('map')}
                color="primary"
                variant={mode === 'map' ? 'contained' : undefined}
                startIcon={<Map />}
              >
                Map
              </Button>
            </ButtonGroup>
          </Grid>
        )}
        <Grid item>
          <FiltersMenuButton
            filters={filters}
            toggleFilterWidget={toggleFilterWidget}
            columns={columns}
            columnTitles={columnTitles}
            tiny={tinyFilters}
          />
        </Grid>
        {!noColumnSettings && (
          <Grid item>
            <SettingsMenuButton
              onReset={onReset}
              columns={columns}
              columnTitles={columnTitles}
              hiddenColumns={hiddenColumns}
              toggleColumn={toggleColumn}
              widthsFrozen={widthsFrozen}
              setWidthsFrozen={setWidthsFrozen}
              orderFrozen={orderFrozen}
              setOrderFrozen={setOrderFrozen}
            />
          </Grid>
        )}

        <Grid item style={{ flexGrow: 1 }}>
          {!tinyFilters && (
            <FilterChips
              filters={filters}
              clearFilter={clearFilter}
              clearAllFilters={clearAllFilters}
              toggleFilterWidget={toggleFilterWidget}
              overrideFilters={overrideFilters}
              onOverrideFiltersDelete={onOverrideFiltersDelete}
              columns={columns}
              columnTitles={columnTitles}
            />
          )}
        </Grid>
        {afterFilters && (
          <Grid item>
            {afterFilters}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
