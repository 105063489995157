import React from 'react';
import { Tooltip, Typography, TypographyProps } from '@mui/material';

import { COLUMN_MAX_WIDTH } from 'components/datagrid/column/Column';
import { createStyles, makeStyles } from '@mui/styles';
import cn from 'classnames';
import { colors } from 'components/theme';

const useStyles = makeStyles(() => createStyles({
  disabledLink: {
    textDecoration: 'underline',
    textDecorationStyle: 'dotted',
    cursor: 'not-allowed',
    color: colors.textGrey,
  },
}));

export interface EllipsisTextProps extends TypographyProps {
  maxWidth?: string | number;
  children: React.ReactNode;
  fullText: React.ReactNode;
  insideDataGrid?: boolean;
  wrap?: boolean;
  disabledLink?: boolean;
}

export const EllipsisText: React.FC<EllipsisTextProps> = ({
  maxWidth = '200px',
  children,
  fullText,
  insideDataGrid = false,
  wrap = false,
  disabledLink = false,
  ...typographyProps
}) => {
  const classes = useStyles();
  return (
    <Tooltip title={fullText!} arrow placement="top-start">
      <Typography
        noWrap={!wrap}
        style={{ maxWidth: insideDataGrid ? COLUMN_MAX_WIDTH : maxWidth }}
        className={cn(disabledLink && classes.disabledLink)}
        {...typographyProps}
      >
        {children}
      </Typography>
    </Tooltip>
  );
};
