import { useMemo } from 'react';
import { QueryResult, useQuery } from '@apollo/react-hooks';

import {
  UserNotificationSettings,
  UserNotificationSettingsVariables,
} from 'api/types/UserNotificationSettings';
import { UserNotificationSettingInput } from 'api/types/globalTypes';
import { USER_NOTIFICATION_SETTINGS } from './queries/userNotificationSettings';

export interface UserNotificationsFormData {
  settings: UserNotificationSettingInput[];
}

export const useInitialValues = <
  T extends UserNotificationSettings = UserNotificationSettings,
>(user_id: number): [QueryResult<T>, UserNotificationsFormData] => {
  const response = useQuery<T, UserNotificationSettingsVariables>(USER_NOTIFICATION_SETTINGS, {
    variables: { where: { user_id: { eq: user_id } } },
    fetchPolicy: 'no-cache',
  });
  const { data } = response;

  const initialValues = useMemo(() => ({
    settings: data?.userNotificationSettings.items.map(({ __typename, ...n }) => n) ?? [],
  }), [data]);

  return [
    response,
    initialValues,
  ];
};
