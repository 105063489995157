import { gql } from '@apollo/client';

export const UPLOAD_FILES = gql`
  mutation UploadFiles(
    $files: [FileUploadInput!]!,
    $context: FileUploadContext!
  ) {
    uploadFiles(files: $files, context: $context) {
      file_id
      name
      category {
        category_id
        name
      }
    }
  }
`;
