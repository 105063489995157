import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  FormControl, Grid, MenuItem, Select, TextField, Typography,
  Autocomplete,
} from '@mui/material';
import { useApolloClient } from '@apollo/react-hooks';
import { Filter, FilterAutocompleteOption } from '../types';
import { FilterWidgetProps } from './FilterWidgetProps';
import { FilterWidgetDialog } from './FilterWidgetDialog';
import { WhereOperationsInput } from '../../../../api/types/globalTypes';

export const AutocompleteFilterWidget: React.FC<FilterWidgetProps> = ({
  tableId,
  field,
  filter: externalFilter,
  filterClearedTimestamp,
  title,
  onApply,
  onClear,
  onClose,
  open,
  loadAutocompleteOptions,
  query,
  getVariables,
  mapResults,
  apiFilterType,
  multiple,
}) => {
  const client = useApolloClient();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [
    selectedOptions,
    setSelectedOptions,
  ] = useState<FilterAutocompleteOption[]>([]);
  const [options, setOptions] = useState<FilterAutocompleteOption[]>([]);

  const labelId = `${tableId}-${field}-autocomplete-filter-operator-label`;

  const initialFilter: Filter = useMemo(() => externalFilter ?? {
    field,
    type: 'autocomplete',
    logic: undefined,
    where: { [apiFilterType === 'json' ? 'jsArrayAll' : 'eq']: '' },
  }, [apiFilterType, externalFilter, field]);
  const [filter, setFilter] = useState<Filter>(initialFilter);

  useEffect(() => {
    if (filterClearedTimestamp) {
      setFilter(initialFilter);
    }
  }, [filterClearedTimestamp, initialFilter]);

  const handleSubmit = useCallback((ev) => {
    ev.preventDefault();
    onApply(field, filter);
    onClose(field);
  }, [field, filter, onApply, onClose]);

  useEffect(() => {
    const q = searchQuery.trim();
    if (q.length) {
      const loadData = async (): Promise<FilterAutocompleteOption[]> => {
        if (loadAutocompleteOptions) {
          return loadAutocompleteOptions(q);
        }
        if (query && getVariables && mapResults) {
          const { data } = await client.query({
            query,
            fetchPolicy: 'no-cache',
            variables: getVariables(q),
          });
          return mapResults(data);
        }
        return [];
      };

      loadData().then(setOptions);
    } else {
      setOptions([]);
    }
  }, [client, getVariables, loadAutocompleteOptions, mapResults, query, searchQuery]);

  const op = useMemo(
    () => Object.keys(filter.where)[0] as keyof WhereOperationsInput,
    [filter],
  );

  const handleApply = () => {
    if (selectedOptions.length) {
      onApply(field, {
        logic: undefined,
        field,
        type: 'autocomplete',
        where: { [op]: selectedOptions?.map(({ value: v }) => v.toString() || '') },
      });
    }
  };

  const operationOptions = useMemo(() => (apiFilterType === 'json' ? [
    { title: 'Equals (exact match)', operation: 'jsArrayAll' },
    { title: 'Not Equals (exact match)', operation: 'jsArrayNotSome' },
    ...(multiple ? [
      { title: 'In (at least one match)', operation: 'jsArraySome' },
      { title: 'Not in (does not include any of selected)', operation: 'jsArrayNotAll' },
    ] : []),
  ] : [
    { title: 'Equals (exact match)', operation: multiple ? 'in' : 'eq' },
    { title: 'Not Equals (exact match)', operation: multiple ? 'notIn' : 'not' },
  ]), [apiFilterType, multiple]);

  return (
    <FilterWidgetDialog
      tableId={tableId}
      field={field}
      title={title}
      onApply={handleApply}
      onClear={() => onClear(field)}
      onClose={onClose}
      open={open}
    >
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <FormControl fullWidth variant="outlined">
              <Select
                fullWidth
                labelId={labelId}
                value={op}
                onChange={(e) => {
                  const newOp = e.target.value as keyof WhereOperationsInput | 'isNotNull';
                  const values = selectedOptions?.map((v) => v.value);
                  setFilter({
                    logic: undefined,
                    field,
                    type: 'autocomplete',
                    where: { [newOp]: values },
                  });
                }}
              >
                {operationOptions.map((o) => (
                  <MenuItem key={o.operation} value={o.operation}>{o.title}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={7}>
            <Autocomplete
              options={options}
              getOptionLabel={(option: FilterAutocompleteOption) => option.label}
              renderOption={(liProps, opt: FilterAutocompleteOption) => (
                <li {...liProps}>
                  <Typography component="span" variant="inherit">
                    {opt.label}
                  </Typography>
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Type to search..."
                />
              )}
              inputValue={searchQuery}
              onInputChange={(e, value) => setSearchQuery(value)}
              value={multiple ? selectedOptions : selectedOptions[0]}
              onChange={(
                e,
                value: FilterAutocompleteOption | FilterAutocompleteOption[] | null,
              ) => {
                const v = value ?? [];
                setSelectedOptions(Array.isArray(v) ? v : [v]);
              }}
              multiple={multiple} // These last 3 define the type, so can't be overridden
              disableClearable={false}
              freeSolo={false}
            />
          </Grid>
        </Grid>
        <input type="submit" hidden />
      </form>
    </FilterWidgetDialog>
  );
};
