import {
  RefObject, useCallback, useMemo, useState,
} from 'react';
import { DataGridRefHandle } from 'components/datagrid/DataGrid';

const DEFAULT_VALUE = true;

export interface UseActiveOnlyItemsResult {
  activeItemsOnly: boolean;
  setActiveItemsOnly: (activeOnly: boolean) => void;
  resetActiveItemsOnly: () => void;
}

export function useActiveOnlyItems(
  tableId: string,
  gridRef?: RefObject<DataGridRefHandle>,
): UseActiveOnlyItemsResult {
  const storageKey = useMemo(() => `${tableId}.activeItemsOnly`, [tableId]);

  const storedValue: boolean | undefined = useMemo(() => {
    const stringValue = window.localStorage.getItem(storageKey);
    if (stringValue && stringValue !== 'undefined') {
      return JSON.parse(stringValue);
    }
    return undefined;
  }, [storageKey]);

  const [activeItemsOnlyValue, setActiveItemsOnlyValue] = useState(storedValue ?? DEFAULT_VALUE);

  const setActiveItemsOnly = useCallback(
    (activeOnly: boolean | ((prevState: boolean) => boolean)) => {
      window.localStorage.setItem(storageKey, JSON.stringify(activeOnly));
      setActiveItemsOnlyValue(activeOnly);
      if (gridRef?.current) gridRef.current.clearPagination();
    }, [gridRef, storageKey],
  );

  const resetActiveItemsOnly = useCallback(() => {
    window.localStorage.removeItem(storageKey);
    setActiveItemsOnlyValue(DEFAULT_VALUE);
  }, [storageKey]);

  return {
    activeItemsOnly: activeItemsOnlyValue,
    setActiveItemsOnly,
    resetActiveItemsOnly,
  };
}
