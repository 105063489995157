import { makeAutoObservable } from 'mobx';

export class NotificationBoxStore {
  public showUnreadOnly: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setShowUnreadOnly(value: boolean) {
    this.showUnreadOnly = value;
  }
}

export const store = new NotificationBoxStore();

export const useNotificationBox = (): NotificationBoxStore => store;
