import { useEffect, useState } from 'react';
import GeoJSON from 'geojson';

export const useGoogleLocation = (
  placeIdOrCoordinates: string | GeoJSON.Point | null | undefined,
): [google.maps.LatLng | undefined, string | undefined] => {
  const [location, setLocation] = useState<google.maps.LatLng>();
  const [locationError, setLocationError] = useState<string>();
  useEffect(() => {
    // no need to call google decode for empty placeid
    if (!placeIdOrCoordinates) {
      return;
    }

    if (typeof placeIdOrCoordinates === 'string') {
      setLocationError(undefined);
      const geocoder = new google.maps.Geocoder();
      const placeId = placeIdOrCoordinates ?? '';
      geocoder.geocode({ placeId }, (results, status) => {
        if (status === 'OK') {
          if (results?.[0]) {
            setLocation(results[0].geometry.location);
          } else {
            setLocationError(`Place with id=${placeIdOrCoordinates} was not found`);
          }
        } else {
          setLocationError(`Geolocation failed with status '${status}'`);
        }
      });
    } else {
      setLocation(new google.maps.LatLng(
        placeIdOrCoordinates.coordinates[0],
        placeIdOrCoordinates.coordinates[1],
      ));
    }
  }, [placeIdOrCoordinates]);
  return [location, locationError];
};
