import { useCallback, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';

import { UPSERT_FM_STAT_MUTATION } from './queries/upsertFmStat';
import { FM_SP_STATS_QUERY } from '../../../../api/fmSpStats/fmSpStats';
import { SERVICE_PROVIDERS_QUERY } from '../../../../api/organization/fm/serviceProviders';
import { FmSpStatPreferenceLevel } from '../../../../api/types/globalTypes';
import { FM_SP_STAT_QUERY } from './queries/fmSpStat';
import { UpsertFmStat, UpsertFmStatVariables } from '../../../../api/types/UpsertFmStat';

export const useUpsertFmStat = () => {
  const [
    upsertFmStat,
    { error, loading },
  ] = useMutation<UpsertFmStat, UpsertFmStatVariables>(UPSERT_FM_STAT_MUTATION, {
    refetchQueries: [FM_SP_STATS_QUERY, FM_SP_STAT_QUERY, SERVICE_PROVIDERS_QUERY],
  });

  const [successUpsert, setSuccessUpsert] = useState<boolean>();
  const successUpsertClose = useCallback(() => setSuccessUpsert(false), []);

  const upsert = useCallback(async (
    organization_id: number,
    level: FmSpStatPreferenceLevel,
  ) => {
    const { errors } = await upsertFmStat({
      variables: {
        sp_organization_id: organization_id,
        input: { preference_level: level },
      },
    });
    if (!errors?.length) {
      setSuccessUpsert(true);
    }
  }, [upsertFmStat]);

  return {
    successUpsert,
    successUpsertClose,

    error,
    loading,
    upsert,
  };
};
