import React from 'react';
import { Typography, TypographyProps } from '@mui/material';

export interface NoDataProps extends TypographyProps {
  // props
}

export const NoData: React.FC<NoDataProps> = ({
  children,
  ...props
}) => (
  <Typography variant="inherit" color="textSecondary" {...props}>
    {children}
  </Typography>
);
