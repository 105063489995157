import React, {
  useEffect, useMemo, useState,
} from 'react';
import { Box, Grid } from '@mui/material';
import { makeStyles, createStyles, useTheme } from '@mui/styles';
import cn from 'classnames';

import { useProfile } from 'api/user/profile';
import { useHeaderTitle } from 'components/nav/headerTitle';
import { APP_BAR_HEIGHT } from 'components/nav/SignedInAppBar';

export const TITLE_MARGIN = 2;
const BOTTOM_MARGIN = 10;
const BLOCKS_SPACING = 2;

const ID_TOP_ACTIONS = 'single-paper-top-actions';

const useStyles = makeStyles((theme) => createStyles({
  content: {
    maxHeight: '100%',
    flexGrow: 1,
    flexShrink: 1,
  },
  marginBottom: {
    marginBottom: theme.spacing(BOTTOM_MARGIN),
  },
}));

export interface SingleBoxLayoutProps {
  title?: string | JSX.Element;
  // Displayed as a pencil icon next to the title, allowing to edit it
  titleEditButton?: {
    title: string;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
  };
  titleBadge?: JSX.Element; // Displayed after the title, inline, unlike titleRight
  inDialog?: boolean;
  topActions?: JSX.Element;
  fullPageHeight?: boolean; // fill the page vertically, but do not overflow
  // In cases when we next layouts, to prevent duplicate margins, we can set this to false
  marginBottom?: boolean;
  showServiceName?: boolean;
}

export const SingleBoxLayout: React.FC<SingleBoxLayoutProps> = ({
  title,
  titleEditButton,
  titleBadge,
  inDialog,
  topActions,
  fullPageHeight = false,
  marginBottom = true,
  showServiceName = false,
  children,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [pageHeight, setPageHeight] = useState<number>();
  const [contentHeight, setContentHeight] = useState<number>();

  useEffect(() => {
    const resizeListener = () => {
      if (fullPageHeight) {
        const appBarHeight = theme.spacing(APP_BAR_HEIGHT);
        const margins = parseInt(theme.spacing(2), 10);
        setPageHeight(window.innerHeight - parseInt(appBarHeight, 10) - margins);
      }
    };
    resizeListener();
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, [fullPageHeight, theme]);

  useEffect(() => {
    if (pageHeight) {
      setContentHeight(
        pageHeight - parseInt(theme.spacing(BOTTOM_MARGIN), 10),
      );
    }
  }, [pageHeight, theme]);

  const profile = useProfile();
  const { organization } = profile;
  const currentService = useMemo(
    () => organization.services
      .find((s) => s.service_id === profile.extra.last_service_id),
    [organization.services, profile.extra.last_service_id],
  );

  const serviceName = useMemo(
    () => currentService?.name,
    [currentService],
  );

  const headerTitle = useMemo(() => ({
    title,
    titleEditButton,
    titleBadge,
    serviceName: showServiceName ? serviceName : undefined,
  }), [serviceName, showServiceName, title, titleBadge, titleEditButton]);

  useHeaderTitle(headerTitle, inDialog);

  return (
    <Grid
      container
      direction="column"
      spacing={BLOCKS_SPACING}
      style={{ height: pageHeight }}
      wrap="nowrap"
    >
      {topActions && (
        <Grid item id={ID_TOP_ACTIONS}>
          {topActions}
        </Grid>
      )}
      <Grid item className={cn(classes.content, marginBottom && classes.marginBottom)}>
        <Box style={{ height: contentHeight }}>
          {children}
        </Box>
      </Grid>
    </Grid>
  );
};
