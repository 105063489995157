import {
  FieldError, FieldValues, NestDataObject, ValidationOptions,
} from 'react-hook-form';
import {
  isNumber, toPairs, isEmpty, isArray, trim,
} from 'lodash';
import { CustomFieldType } from './types';

interface ServiceFieldPayload {
  field_id: number;
  service_id: number | null;
  value: any;
}

enum LabelBehavior {
  floating = 'floating',
  top = 'top',
}

export const getFieldName = (field: { field_id: number }) => `field_${field.field_id}`;

export const labelIsOnTop = (field: CustomFieldType): boolean => field.context
  && field.context.labelBehavior === LabelBehavior.top;

export const getValidationParams = (
  field: CustomFieldType,
  requiredErrorFieldName?: string,
): ValidationOptions => {
  const validationRules: ValidationOptions = {};
  if ('is_required' in field && field.is_required) {
    validationRules.required = {
      value: true,
      message: `${requiredErrorFieldName ?? field.name} is required`,
    };
  }
  if (field.context?.maxLength) {
    validationRules.maxLength = {
      value: field.context.maxLength,
      message: `The value is too long, ${field.context.maxLength} char. max`,
    };
  }
  if (field.context?.minLength) {
    validationRules.minLength = {
      value: field.context.maxLength,
      message: `The value is too short, ${field.context.minLength} char. min`,
    };
  }
  if (isNumber(field.context?.min)) {
    validationRules.min = { value: field.context.min, message: `Number must be bigger than ${field.context?.min}` };
  }
  if (isNumber(field.context?.max)) {
    validationRules.max = { value: field.context.max, message: `Number must be less than ${field.context?.max}` };
  }

  return validationRules;
};

export const hasError = (
  errors: NestDataObject<Record<string, any>>,
  field: CustomFieldType,
): boolean => !!errors[getFieldName(field)];

export const getError = (
  errors: NestDataObject<Record<string, any>>,
  field: CustomFieldType,
): string | undefined => {
  const error = errors[getFieldName(field)] as FieldError | null;
  if (error) {
    return error.message;
  }
  return undefined;
};

export const prepareValues = (
  values: FieldValues,
  fields: CustomFieldType[],
  serviceId?: number | null,
): ServiceFieldPayload[] => toPairs(values).map(([key, value]) => {
  const fieldId = parseInt(key.replace('field_', ''), 10);
  if (value !== undefined) {
    const valueEmpty = isEmpty(isArray(value) ? value : trim(value));
    if (!valueEmpty) {
      const field = fields.find((f) => f.field_id === fieldId);
      if (field) {
        return {
          field_id: field.field_id,
          service_id: serviceId,
          value,
        } as ServiceFieldPayload;
      }
    }
  }
  return undefined;
}).filter((v) => v !== undefined) as ServiceFieldPayload[];
