import { has, get } from 'lodash';
import { DocumentNode } from 'graphql';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { OperationVariables } from '@apollo/client/core/types';
import {
  FilterAutocompleteOption,
  FilterOption, FilterTypeSimple, FilterTypeWithOptions, isWithOptions,
} from './types';

export interface FilterWidgetSimpleProps {
  filterType: FilterTypeSimple;
}

export interface FilterWidgetWithOptionsProps {
  filterType: FilterTypeWithOptions;
  options: FilterOption[];
  /**
   * Whenever to use option labels instead of keys when rendering cell contents
   * - `true` to use option labels in rendered cell instead of values
   * - `false` to use original values
   */
  substituteOptionLabels: boolean; // defaults to true
}

export interface FilterWidgetAutocompleteGql<TVariables = OperationVariables, TData = any> {
  filterType: 'autocomplete';
  query: DocumentNode | TypedDocumentNode<TData, TVariables>;
  getVariables: (query: string) => TVariables;
  mapResults: (data: TData) => FilterAutocompleteOption[];
}

export interface FilterWidgetAutocompleteLoadFn {
  filterType: 'autocomplete';
  loadAutocompleteOptions: (query: string) => Promise<FilterAutocompleteOption[]>;
}

export type FilterWidgetBaseProps =
  | FilterWidgetSimpleProps
  | FilterWidgetWithOptionsProps
  | FilterWidgetAutocompleteGql
  | FilterWidgetAutocompleteLoadFn;

/**
 * Filter widget props for filters supporting `isNull` operator.
 */
export interface NullableFilterWidgetProps {
  isNullEnabled?: boolean;
  isNullTitle?: string;
  isNotNullEnabled?: boolean;
  isNotNullTitle?: string;
  logicType?: 'and' | 'or';
}

export type ApiFilterType = 'regular' | 'json';

export interface ApiFilterTypeProps {
  apiFilterType?: ApiFilterType;
  multiple?: boolean;
}

export type FilterWidgetProps = FilterWidgetBaseProps
& NullableFilterWidgetProps
& ApiFilterTypeProps;

export function isFilterWidgetPropsSimple(
  filterWidget: FilterWidgetProps | undefined,
): filterWidget is FilterWidgetSimpleProps {
  return !!filterWidget && typeof filterWidget === 'object'
    && has(filterWidget, 'filterType')
    && !isWithOptions(get(filterWidget, 'type'));
}

export function isFilterWidgetPropsWithOptions(
  filterWidget: FilterWidgetProps | undefined,
): filterWidget is FilterWidgetWithOptionsProps {
  return !!filterWidget && typeof filterWidget === 'object'
    && has(filterWidget, 'filterType')
    && isWithOptions(get(filterWidget, 'filterType'));
}

export function isFilterWidgetProps(
  filterWidget: FilterWidgetProps | undefined,
): filterWidget is FilterWidgetProps {
  return isFilterWidgetPropsSimple(filterWidget) || isFilterWidgetPropsWithOptions(filterWidget);
}

export function isFilterWidgetPropsWithAutocompleteLoadFn(
  filterWidget: FilterWidgetProps | undefined,
): filterWidget is FilterWidgetAutocompleteLoadFn {
  return filterWidget?.filterType === 'autocomplete' && 'loadOptions' in filterWidget;
}

export function isFilterWidgetPropsWithAutocompleteGql(
  filterWidget: FilterWidgetProps | undefined,
): filterWidget is FilterWidgetAutocompleteGql {
  return filterWidget?.filterType === 'autocomplete' && 'getVariables' in filterWidget;
}
