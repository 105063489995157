import { useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import * as Yup from 'yup';
import { ORGANIZATION_CONTACT_TYPES } from './OrganizationContactTypesQuery';
import { ORGANIZATION_CONTACTS } from './OrganizationContactsQuery';
import { OrganizationContactTypes } from '../../../../../api/types/OrganizationContactTypes';
import { OrganizationContacts } from '../../../../../api/types/OrganizationContacts';
import { validatePhone } from '../../../../../utils/phone';

export interface ContactStruct {
  contact_id: number | null;
  type_id: number;
  same_as_primary: boolean;
  title?: string | null;
  first_name: string;
  last_name: string;
  email: string;
  phone: string | null;
}

export enum ContactTypeEnum {
  Primary = 3,
  Signatory = 1,
  Invoices = 2,
}

export interface ContactTypeStruct {
  type_id: number;
  name: string;
  allow_multiple_per_organization: number;
  contacts: ContactStruct[];
}

export const contactValidationSchema = Yup.object().shape<ContactStruct>({
  contact_id: Yup.number().nullable(),
  type_id: Yup.number(),
  same_as_primary: Yup.boolean(),
  title: Yup.string().nullable(),
  first_name: Yup.string().required('First Name is required'),
  last_name: Yup.string().required('Last Name is required'),
  email: Yup.string().email('Email is not valid').required('Email is required'),
  phone: Yup.string().trim().test(
    'phoneValid',
    'Please provide a valid number',
    validatePhone,
  ).nullable(),
});

export const emptyContact = (type_id: number) => ({
  contact_id: null,
  same_as_primary: false,
  type_id,
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
});

export const useData = () => {
  const {
    data: orgContactTypesData,
    error: orgContactTypesError,
    loading: orgContactTypesLoading,
  } = useQuery<OrganizationContactTypes>(
    ORGANIZATION_CONTACT_TYPES,
    { fetchPolicy: 'no-cache' },
  );
  const { data, error, loading } = useQuery<OrganizationContacts>(ORGANIZATION_CONTACTS, {
    fetchPolicy: 'no-cache',
  });

  const types = useMemo((): ContactTypeStruct[] => {
    if (!orgContactTypesData || !data) {
      return [];
    }

    const { orgContactTypes } = orgContactTypesData;
    const { org: { contacts: orgContacts } } = data;
    return orgContactTypes.map((type) => {
      const typeContacts = orgContacts
        .filter(({ type_id }) => type_id === type.type_id)
        .map(({ __typename, ...contact }) => contact);
      return {
        ...type,
        contacts: typeContacts.length
          ? typeContacts
          : [{ ...emptyContact(type.type_id) }],
      };
    });
  }, [data, orgContactTypesData]);

  const typesMap = useMemo(() => (
    types.reduce((acc, type) => ({
      ...acc,
      [type.type_id]: type.contacts,
    }), {} as {
      [ContactTypeEnum.Primary]: ContactStruct[],
      [ContactTypeEnum.Invoices]: ContactStruct[],
      [ContactTypeEnum.Signatory]: ContactStruct[],
    })
  ), [types]);

  const typeIdToNameMap: Record<string, string> = useMemo(() => {
    if (!orgContactTypesData) {
      return {};
    }
    const { orgContactTypes } = orgContactTypesData;
    return orgContactTypes.reduce((acc, type) => ({
      ...acc,
      [type.type_id]: type.name,
    }), {});
  }, [orgContactTypesData]);

  return {
    loading: orgContactTypesLoading || loading,
    orgContactTypesError,
    orgContactsError: error,
    types,
    typesMap,
    typeIdToNameMap,
  };
};
