import { useCallback, useMemo, useState } from 'react';
import { GridColumnResizeEvent } from '@progress/kendo-react-grid/dist/npm/interfaces/events';
import { fromPairs, debounce } from 'lodash';

import { useResizeObserver } from 'utils/useResizeObserver';
import { ACTIONS_TITLE } from '../DataGrid';
import { ColumnProps, COLUMN_MAX_WIDTH } from './Column';

export interface UseResizeableColumnsResult {
  columnWidth: { [field: string]: string | number };
  onColumnResize: (e: GridColumnResizeEvent) => void;
  resetWidths: () => void;
}

export function useResizeableColumns(
  tableId: string,
  columns: ColumnProps[],
  visibleColumns: ColumnProps[],
  additionalColumnsWidth: number,
  enableAdjustment: boolean,
): UseResizeableColumnsResult {
  const storageKey = useMemo(() => `${tableId}.columnWidth`, [tableId]);

  const storedValue: { [field: string]: string | number } | undefined = useMemo(() => {
    if (enableAdjustment) {
      return undefined;
    }
    const stringValue = window.localStorage.getItem(storageKey);
    if (stringValue && stringValue !== 'undefined') {
      return JSON.parse(stringValue);
    }
    return undefined;
  }, [enableAdjustment, storageKey]);

  const [columnWidth, setColumnWidth] = useState<{ [field: string]: string | number }>(
    storedValue ?? fromPairs(
      columns.map(({ field, width }) => [field, width ?? 100]),
    ),
  );

  const RESIZE_TIMEOUT: number = 500;

  const reducer = (sum: number, col: ColumnProps) => sum + Number(col.width);
  const visibleGridWidth = useMemo(() => visibleColumns.reduce(reducer, 0), [visibleColumns]);

  const adjustWidth = useCallback((width: number | string) => {
    if (!enableAdjustment) {
      return width;
    }

    const realGridWidth = visibleGridWidth + additionalColumnsWidth;

    // TODO: create a better solution, these are too hacky
    const mainElement: HTMLElement | null = document.querySelector('main');
    const contentElement: HTMLElement | null = document.querySelector('main > div > div');
    const toolbarElement: HTMLElement | null = document.querySelector('#toolbar');
    const padding = mainElement && contentElement
      ? mainElement.offsetWidth - contentElement.offsetWidth
      : 0;
    const minGridWidth = toolbarElement ? toolbarElement.offsetWidth - padding : 0;

    if (realGridWidth < minGridWidth) {
      const ratio = (minGridWidth - additionalColumnsWidth) / visibleGridWidth;
      return Number(width) * ratio;
    }

    return width;
  }, [additionalColumnsWidth, enableAdjustment, visibleGridWidth]);

  const onColumnResize = useCallback((e: GridColumnResizeEvent) => {
    // 'dblclick' checking prevents disappearing of the column
    // TODO: it should minimize the column instead of setting width to 0
    if (e.end && e.nativeEvent.type !== 'dblclick') {
      const col = e.columns.find((c) => c.orderIndex === e.index);
      const field = col?.field;
      const isAction = col?.title === ACTIONS_TITLE;
      if (field || isAction) {
        const columnKey = field ?? 'actions';
        setColumnWidth((prev) => {
          const newValue = {
            ...prev,
            // NOTE: we are intentionally not using the adjustWidth here
            // for avoiding unexpectable behavior of the table for users
            [columnKey]: Math.min(e.newWidth, COLUMN_MAX_WIDTH),
          };
          if (!enableAdjustment) {
            window.localStorage.setItem(storageKey, JSON.stringify(newValue));
          }
          return newValue;
        });
      }
    }
  }, [storageKey, enableAdjustment]);

  const resetWidths = useCallback(() => {
    window.localStorage.removeItem(storageKey);
    setColumnWidth(fromPairs(
      columns.map(({ field, width }) => [field, adjustWidth(width ?? 100)]),
    ));
  }, [storageKey, columns, adjustWidth]);

  const resizeListenerDebounced = useMemo(
    () => {
      if (enableAdjustment) {
        return debounce(resetWidths, RESIZE_TIMEOUT);
      }
      return () => {};
    },
    [enableAdjustment, resetWidths],
  );

  useResizeObserver('toolbar', resizeListenerDebounced);

  return {
    columnWidth,
    onColumnResize,
    resetWidths,
  };
}
