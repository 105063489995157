import { useCallback } from 'react';
import { DocumentNode } from 'graphql';
import { useApolloClient, OperationVariables, FetchPolicy } from '@apollo/react-hooks';

export function useLazyQueryPromise<TData = any, TVariables = OperationVariables>(
  query: DocumentNode,
  fetchPolicy: FetchPolicy = 'network-only',
) {
  const client = useApolloClient();
  return useCallback(
    (variables: TVariables) => client.query<TData, TVariables>({
      query,
      variables,
      fetchPolicy,
    }),
    [client, query, fetchPolicy],
  );
}
