import React from 'react';
import {
  FormControl, Grid, MenuItem, Select, TextField,
} from '@mui/material';

import { WhereOperationsInput } from 'api/types/globalTypes';
import { Filter } from '../types';
import { ApiFilterType } from '../FilterWidgetProps';

export interface StringFilterProps {
  field: string;
  labelId: string;
  /** Actual value can be boolean in case of `isNull`-like filters */
  value: string | boolean;
  /**
   * Text field value must adapt to `isNull`-like filters by showing a different text
   * when the actual value is a boolean, e.g. showing "N/A" instead of "true"
   */
  textFieldValue: string;
  op: keyof WhereOperationsInput;
  setFilter: React.Dispatch<React.SetStateAction<Filter>>;
  isNullEnabled?: boolean;
  isNullTitle?: string;
  isNotNullEnabled?: boolean;
  isNotNullTitle?: string;
  apiFilterType?: ApiFilterType;
}

type FilterDropdownOptions = Array<[keyof WhereOperationsInput, string]>;

const regularFilterOptions: FilterDropdownOptions = [
  ['contains', 'Contains'],
  ['notContains', 'Does not contain'],
  ['eq', 'Equals (exact match)'],
  ['startsWith', 'Starts with'],
  ['endsWith', 'Ends with'],
];

const jsonFilterOptions: FilterDropdownOptions = [
  ['jsArrayMatch', 'Contains'],
  ['jsArrayNotMatch', 'Does not contain'],
  ['jsArrayIncludes', 'Equals (exact match)'],
  ['jsArrayStartsWith', 'Starts with'],
  ['jsArrayEndsWith', 'Ends with'],
];

export const StringFilter: React.FC<StringFilterProps> = ({
  field,
  labelId,
  value,
  textFieldValue,
  op,
  setFilter,
  isNullEnabled,
  isNullTitle,
  isNotNullEnabled,
  isNotNullTitle,
  apiFilterType = 'regular',
}) => (
  <Grid
    container
    spacing={1}
    direction="column"
    justifyContent="flex-start"
    alignContent="stretch"
    alignItems="stretch"
  >
    <Grid item>
      <FormControl fullWidth variant="outlined">
        <Select
          fullWidth
          labelId={labelId}
          value={op === 'isNull' && value === false ? 'isNotNull' : op}
          onChange={(e) => {
            const newOp = e.target.value as keyof WhereOperationsInput | 'isNotNull';
            if (['isNull', 'isNotNull'].includes(newOp)) {
              setFilter({
                logic: undefined,
                field,
                type: 'string',
                where: { isNull: newOp === 'isNull' },
              });
            } else {
              setFilter({
                logic: undefined,
                field,
                type: 'string',
                where: { [newOp]: value },
              });
            }
          }}
        >
          {(apiFilterType === 'regular' ? regularFilterOptions : jsonFilterOptions).map(([val, label]) => (
            <MenuItem key={val} value={val}>{label}</MenuItem>
          ))}
          {isNullEnabled && (
            <MenuItem value="isNull">{isNullTitle ?? 'Is null'}</MenuItem>
          )}
          {isNotNullEnabled && (
            <MenuItem value="isNotNull">{isNotNullTitle ?? 'Is not null'}</MenuItem>
          )}
        </Select>
      </FormControl>
    </Grid>
    <Grid item>
      <TextField
        fullWidth
        autoFocus
        label="Value"
        value={op === 'isNull' ? 'N/A' : textFieldValue}
        disabled={op === 'isNull'}
        onChange={(e) => {
          setFilter({
            logic: undefined,
            field,
            type: 'string',
            where: { [op]: e.target.value as string },
          });
        }}
      />
    </Grid>
  </Grid>
);
