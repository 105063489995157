import { gql } from '@apollo/client';

export const PUBLIC_FM_ORGANIZATION = gql`
    query PublicFmOrganization($organizationId: Int!) {
        publicFmOrganization(
            organization_id: $organizationId
        ) {
            organization_id
            name
            logo_url
        }
    }
`;
