import React from 'react';
import {
  Box, IconButton, Typography, Tooltip,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import Edit from '@mui/icons-material/Edit';

import { textContent } from 'utils/text';

import PageHelmet from './PageHelmet';
import { TITLE_MARGIN } from './SingleBoxLayout';

const useStyles = makeStyles((theme) => createStyles({
  heading: {
    margin: theme.spacing(TITLE_MARGIN, 0, TITLE_MARGIN, 4),
    color: theme.palette.text.primary,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

export interface TitleProps {
  serviceName?: string;
  title?: string | JSX.Element;
  titleEditButton?: {
    title: string;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
  };
  titleBadge?: JSX.Element;
}

export const Title: React.FC<TitleProps> = ({
  serviceName,
  title,
  titleEditButton,
  titleBadge,
}) => {
  const classes = useStyles();
  const titleText = (
    <>
      {title ?? ''}
      {serviceName ? ` - ${serviceName}` : ''}
    </>
  );
  const titleElement = (
    <Typography variant="h6" className={classes.heading}>{titleText}</Typography>
  );
  const pageTitle = textContent(titleText);

  return (
    <>
      <PageHelmet title={pageTitle} />
      {title || serviceName ? (
        <Tooltip arrow title={titleText} placement="bottom">{titleElement}</Tooltip>
      ) : titleElement}
      {titleEditButton && (
        <IconButton
          onClick={titleEditButton.onClick}
          title={titleEditButton.title}
          aria-label={titleEditButton.title}
          size="large"
        >
          <Edit fontSize="inherit" />
        </IconButton>
      )}
      {titleBadge && (
        <Box pl={1}>
          {titleBadge}
        </Box>
      )}
    </>
  );
};
