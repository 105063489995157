import React from 'react';
import clsx from 'clsx';
import {
  Chip, ChipProps, SvgIcon, Tooltip,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import {
  BID_DATE_TITLE, BID_STATUS_HELP_FM, BID_STATUS_HELP_SP, BID_BY_TITLE,
} from 'api/bids/bidStatuses';
import {
  BUNDLE_DATE_TITLE, BUNDLE_STATUS_HELP_FM, BUNDLE_STATUS_HELP_SP, BUNDLE_BY_TITLE,
} from 'api/bundles/bundleStatuses';
import { SITE_STATUS_HELP_FM } from 'api/site/siteStatuses';
import { NavTypeNoAdmin } from 'components/nav/navType';
import { DateTime } from 'components/text/DateTime';
import { orange } from '@mui/material/colors';

interface Status {
  name: string;
  status_id: number;
  icon?: string | null;
  color?: string | null;
}

type LatestStatusLogType = {
  changed_on: Date;
  changedBy: { first_name: string, last_name: string } | null;
};

export interface StatusProps extends Omit<ChipProps, 'size'> {
  status: Status;
  type?: 'bid' | 'bundle' | 'site';
  tooltipFor?: NavTypeNoAdmin;
  reason?: string | null; // for rejections, etc.
  reasonNote?: string | null; // for decline note
  latestStatusLog?: LatestStatusLogType | null;
  awardedPartially?: boolean;
  date?: Date; // updated on date
  size?: ChipProps['size'] | 'tiny'; // tiny == extra small, no icon
}

const useStyles = makeStyles((theme) => createStyles({
  chip: (props: Status) => ({
    position: 'relative',
    backgroundColor: props.color || theme.palette.primary.main,
    color: theme.palette.getContrastText(props.color || theme.palette.primary.main),
  }),
  icon: (props: Status) => ({
    '& .MuiChip-icon': {
      marginLeft: 10,
      fontSize: '1rem',
      color: theme.palette.getContrastText(props.color || theme.palette.primary.main),
    },
  }),
  iconSmall: (props: Status) => ({
    '& .MuiChip-icon': {
      marginLeft: 8,
      fontSize: '0.8rem',
      width: '0.8rem',
      color: theme.palette.getContrastText(props.color || theme.palette.primary.main),
    },
  }),
  tinyChip: (props: Status) => ({
    cursor: 'default',
    whiteSpace: 'nowrap',
    backgroundColor: props.color || theme.palette.primary.main,
    color: theme.palette.getContrastText(props.color || theme.palette.primary.main),
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.shape.borderRadius,
  }),
  awardedPartiallyBlock: {
    display: 'block',
    position: 'absolute',
    borderRadius: '50%',
    right: -2,
    top: 0,
    width: 10,
    height: 10,
    background: orange['500'],
  },
}));

export const StatusChip: React.FC<StatusProps> = ({
  status,
  type,
  tooltipFor,
  reason,
  reasonNote,
  date: dateProp,
  size,
  latestStatusLog,
  awardedPartially,
  ...chipProps
}) => {
  const classes = useStyles(status);

  // NOTE: we should not set latestStatusLog along with date
  const date = latestStatusLog?.changed_on ?? dateProp;
  let by = '';
  if (latestStatusLog) {
    by = latestStatusLog.changedBy ? `${latestStatusLog.changedBy.first_name} ${latestStatusLog.changedBy.last_name}` : 'System';
  }

  let Wrapper: React.FC;
  if (type && tooltipFor) {
    let help: { [index: number]: string };
    let textTitle: { [index: number]: string };
    let byTitle: { [index: number]: string };
    if (type === 'bid') {
      help = tooltipFor === 'sp' ? BID_STATUS_HELP_SP : BID_STATUS_HELP_FM;
      textTitle = BID_DATE_TITLE;
      byTitle = BID_BY_TITLE;
    } else if (type === 'bundle') {
      help = tooltipFor === 'sp' ? BUNDLE_STATUS_HELP_SP : BUNDLE_STATUS_HELP_FM;
      textTitle = BUNDLE_DATE_TITLE;
      byTitle = BUNDLE_BY_TITLE;
    } else {
      help = tooltipFor === 'sp' ? {} : SITE_STATUS_HELP_FM;
      textTitle = {};
      byTitle = {};
    }
    Wrapper = ({ children }) => (
      <Tooltip
        arrow
        placement="top"
        title={(
          <>
            {help[status.status_id]}
            {!!(reason || reasonNote) && (
              <>
                <br />
                {reason ? (
                  <>
                    <br />
                    <em>Reason</em>
                    {': '}
                    {reason}
                  </>
                ) : null}
                {reasonNote ? (
                  <>
                    <br />
                    <em>Note</em>
                    {': '}
                    {reasonNote}
                  </>
                ) : null}
              </>
            )}
            {date && (
              <>
                <br />
                <br />
                <em>
                  {textTitle[status.status_id]}
                  :
                </em>
                {' '}
                <DateTime value={date} />
              </>
            )}
            {by && (
              <>
                {date ? <br /> : (
                  <>
                    <br />
                    <br />
                  </>
                )}
                <em>
                  {byTitle[status.status_id]}
                  :
                </em>
                {' '}
                {by}
              </>
            )}
            {awardedPartially ? (
              <>
                <br />
                <br />
                Warning: This bid was awarded partially, contact support for explanation.
              </>
            ) : null}
          </>
        )}
      >
        {children as any}
      </Tooltip>
    );
  } else {
    Wrapper = ({ children }) => (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>{children}</>
    );
  }

  if (size === 'tiny') {
    return (
      <Wrapper>
        <small
          className={clsx([classes.tinyChip, chipProps.classes?.root])}
          style={{ backgroundColor: status.color || '' }}
        >
          {status.name}
        </small>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Chip
        label={(
          <>
            {status.name}
            {awardedPartially ? <div className={classes.awardedPartiallyBlock} /> : null}
          </>
        )}
        icon={(
          <SvgIcon>
            <FontAwesomeIcon icon={status.icon as IconProp} />
          </SvgIcon>
        )}
        size={size}
        {...chipProps}
        classes={{
          ...chipProps.classes,
          root: clsx([
            classes.chip,
            size === 'small' ? classes.iconSmall : classes.icon,
            chipProps.classes?.root,
          ]),
        }}
      />
    </Wrapper>
  );
};
