import React from 'react';
import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

interface StyledLinkProps extends MuiLinkProps {
  navigate: any
}

export const StyledLink: React.FC<StyledLinkProps> = ({
  children,
  navigate, // ignored because of this bug: https://github.com/ReactTraining/react-router/issues/6962
  ...props
}) => (
  <MuiLink {...props as any}>
    {children}
  </MuiLink>
);

export const StyledRouterLink: React.FC<RouterLinkProps & Omit<StyledLinkProps, 'navigate'>> = ({
  children,
  ...props
}) => (
  <RouterLink component={StyledLink} {...props as any}>
    {children}
  </RouterLink>
);
