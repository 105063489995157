import { gql } from '@apollo/client';

export enum Permissions {
  ORGANIZATION_OWNER = 1,
  APPROVE_USERS = 2,
  INVITE_USERS = 3,
  UPLOAD_SITE_FILE = 4,
  UPLOAD_SP_FILE = 5,
  SEND_MESSAGE = 6,
  ADD_SITES = 7,
  EDIT_SITE_INFO = 8,
  EDIT_SITE_PRICING = 9,
  MANAGE_BUNDLES = 10,
  AWARD_BUNDLES = 11,
  EXPORT_TABLES = 12,
  BULK_ACTIONS = 13,
  SIGN_CONTRACT = 14,
  EXPORT_CONTRACT = 15,
  CREATE_SITE_LABEL = 16,
}

export const PERMISSIONS_AUTOCOMPLETE = gql`
    query PermissionsAutocomplete($type: UserType!, $query: String) {
      permissions(type: $type, query: $query) {
        is_regional
        permission_id
        name
      }
      _organizationRegions {
        items {
          region_id
          name
        }
      }
    }
`;
