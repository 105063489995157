import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Page } from '@progress/kendo-react-grid/dist/npm/paging/Page';
import { PageChangeEvent } from '@progress/kendo-react-data-tools';

import { PageSize } from '../DataGrid';

export interface UsePaginationResult {
  page: Page,
  onPageChange: (event: PageChangeEvent) => void;
  clearPagination: () => void;
}

const usePageStorage = (tableId: string): [number | undefined, (skip: number) => void] => {
  const history = useHistory();
  const skipKey = `${tableId}-page`;

  const skipValue = useMemo(() => {
    const qs = new URLSearchParams(history.location.search);
    const qsSkip = qs.get(skipKey);
    return qsSkip ? +qsSkip : undefined;
  }, [skipKey, history]);
  const skipHandle = useCallback((take: number) => {
    const qs = new URLSearchParams(history.location.search);
    if (take && take !== 1) {
      qs.set(skipKey, take.toString());
    } else {
      qs.delete(skipKey);
    }
    history.replace({ ...history.location, search: qs.toString() });
  }, [skipKey, history]);

  return [skipValue, skipHandle];
};

const useTakeStorage = (tableId: string): [number | undefined, (skip: number) => void] => {
  const takeKey = `${tableId}.pageSize`;

  const takeStoredValue = useMemo(() => {
    const stringValue = window.localStorage.getItem(takeKey);
    if (stringValue && stringValue !== 'undefined') {
      return JSON.parse(stringValue);
    }
    return undefined;
  }, [takeKey]);

  return [
    takeStoredValue,
    useCallback((take: number) => {
      window.localStorage.setItem(takeKey, JSON.stringify(take));
    }, [takeKey]),
  ];
};

export function usePagination(
  tableId: string,
  pageSize: PageSize,
): UsePaginationResult {
  const [pageStored, storePage] = usePageStorage(tableId);
  const [takeStored, storeTake] = useTakeStorage(tableId);

  const [pagination, setPagination] = useState<Page>(() => {
    const initialPageSize = takeStored ?? pageSize;
    return {
      skip: initialPageSize * (pageStored ? pageStored - 1 : 0),
      take: initialPageSize,
    };
  });

  const onPageChange = useCallback((event: PageChangeEvent) => {
    const { skip, take } = event;
    setPagination(() => {
      storePage((skip / take) + 1);
      storeTake(take);
      return { skip, take };
    });
  }, [storeTake, storePage, setPagination]);

  const clearPagination = useCallback(() => {
    storePage(1);
    setPagination((p) => ({ ...p, skip: 0 }));
  }, [storePage, setPagination]);

  return {
    page: pagination,
    onPageChange,
    clearPagination,
  };
}
