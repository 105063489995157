import React from 'react';
import {
  Box, Grid, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import maintenanceImage from 'assets/maintenance.png';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundImage: `url(${maintenanceImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  fullHeight: {
    height: '100vh',
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.down('lg')]: {
      paddingBottom: theme.spacing(6),
    },
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing(2),
    },
  },
  title: {
    // padding: theme.spacing(2, 4, 0, 4),
    color: theme.palette.primary.main,
    // [theme.breakpoints.up('sm')]: {
    //   padding: 0,
    // },
  },
  subtitle: {
    // padding: theme.spacing(0, 4),
    color: theme.palette.primary.light,
    // [theme.breakpoints.up('sm')]: {
    //   padding: 0,
    // },
  },
}));

export const MaintenancePage: React.FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        alignContent="center"
        className={classes.fullHeight}
      >
        <Grid item>
          <Typography variant="h3" align="center" paragraph className={classes.title}>
            Oops, the website is down.
          </Typography>
          <Typography variant="h4" align="center" paragraph className={classes.subtitle}>
            We’re having technical difficulties and should be back soon.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
