import { useEffect, useState } from 'react';
import { useChatContext } from 'stream-chat-react';

interface UseChatUnreadCountHook {
  unreadMessagesCount: number;
  unreadChannelsCount: number;
}

export const useChatUnreadCount = (): UseChatUnreadCountHook => {
  const { client } = useChatContext();
  const [count, setCount] = useState<UseChatUnreadCountHook>({
    unreadChannelsCount: 0,
    unreadMessagesCount: 0,
  });

  useEffect(() => {
    if (!client) {
      throw new Error('chat client is undefined in the context');
    }

    const { user } = client;

    // Set on initial load
    if (user?.total_unread_count !== undefined || user?.unread_channels !== undefined) {
      setCount({
        unreadMessagesCount: user.total_unread_count as number ?? 0,
        unreadChannelsCount: user.unread_channels as number ?? 0,
      });
    }

    const sub = client?.on((e) => {
      if (e.total_unread_count !== undefined || e.unread_channels !== undefined) {
        setCount((prev) => ({
          unreadMessagesCount: e.total_unread_count ?? prev.unreadMessagesCount,
          unreadChannelsCount: e.unread_channels ?? prev.unreadChannelsCount,
        }));
      }
    });
    return () => {
      sub?.unsubscribe();
    };
  }, [client, setCount]);

  return count;
};
