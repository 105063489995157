import React from 'react';
import {
  Box, Button,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useQuery } from '@apollo/react-hooks';
import { useSpSidebarHandlers } from '../SpSidebarProvider';
import { useProfile } from '../../../../../../api/user/profile';
import { FmSpFieldValue, FmSpFieldValueVariables } from '../../../../../../api/types/FmSpFieldValue';
import { FM_SP_FIELD_VALUE } from '../../../../../../api/fmSpFieldValues/fmSpFieldValue';
import { CustomFieldForm } from '../../../../../customFieldForm/CustomFieldForm';
import { CustomFormTypes } from '../../../../../customFieldForm/const';
import { FmCustomFields_fmCustomFields_items } from '../../../../../../api/types/FmCustomFields';

interface FmFieldsSubPageProps {
  organizationId: number;
  fieldArray: FmCustomFields_fmCustomFields_items[];
}

export const FmFieldsSubPage = ({ organizationId, fieldArray }: FmFieldsSubPageProps) => {
  const { modifyExtra } = useSpSidebarHandlers();
  const profile = useProfile();

  const {
    data: fieldValues,
  } = useQuery<FmSpFieldValue, FmSpFieldValueVariables>(
    FM_SP_FIELD_VALUE,
    {
      variables: {
        skip: 0,
        take: 9999,
        where: {
          organization_id: { eq: organizationId },
        },
      },
      fetchPolicy: 'no-cache',
    },
  );

  return (
    <Box key="contacts" m={2}>
      <Button
        size="large"
        variant="text"
        color="primary"
        startIcon={<ArrowBack />}
        onClick={() => modifyExtra((p) => ({ ...p, page: 'main' }))}
      >
        Facility Manager Notes
      </Button>

      <Box ml={1} mt={2}>
        <CustomFieldForm
          options={{ organization_name: profile.organization.name }}
          targetOrganizationID={organizationId}
          fieldArray={fieldArray}
          fieldValues={fieldValues?.fmSpFieldValues.items || []}
          type={CustomFormTypes.FMSP}
          formDisabled={!fieldArray.length}
          columns={1}
        />
      </Box>
    </Box>
  );
};
