import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import cn from 'classnames';

const useStyles = makeStyles((theme) => createStyles({
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fullScreen: {
    width: '100vw',
    height: '100vh',
  },
  margin: {
    margin: theme.spacing(2),
  },
}));

export interface LoadingProps {
  center?: boolean;
  fullScreen?: boolean;
  margin?: boolean;
}

export const Loading: React.FC<LoadingProps> = ({
  center,
  fullScreen,
  margin = true,
}) => {
  const classes = useStyles();
  return (
    <Box className={cn(
      center && classes.center,
      fullScreen && classes.fullScreen,
    )}
    >
      <CircularProgress className={margin ? classes.margin : ''} />
    </Box>
  );
};
