import React, { useState, useEffect, useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { createStyles, makeStyles } from '@mui/styles';
import {
  Box, Button, Zoom, Link, Alert, AlertTitle,
} from '@mui/material';

import { TERMS_OF_USE } from 'mainConstants';
import { IS_LATEST_EULA_ACCEPTED, ACCEPT_LATEST_EULA } from 'api/user/eulas';
import { AcceptLatestEula } from 'api/types/AcceptLatestEula';

import { ErrorMessage } from '../notifications/ErrorMessage';

const useStyles = makeStyles((theme) => createStyles({
  acceptAction: {
    marginRight: theme.spacing(1),
    alignSelf: 'center',
  },
}));

export const LatestEula: React.FC = () => {
  const classes = useStyles();
  const { data, loading, error } = useQuery<{ isLatestEulaAccepted: boolean }>(
    IS_LATEST_EULA_ACCEPTED,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [showAlert, setShowAlert] = useState<boolean>(false);

  useEffect(() => {
    if (!loading && data) {
      setShowAlert(!data.isLatestEulaAccepted);
    }
  }, [data, loading]);

  const [acceptAction, { error: acceptError }] = useMutation<AcceptLatestEula>(ACCEPT_LATEST_EULA);
  const acceptSubmit = useCallback(async () => {
    await acceptAction();
    setShowAlert(false);
  }, [acceptAction]);

  if (error) {
    return (
      <Box m={4} mb={0}>
        <ErrorMessage devMessage={error.message}>
          Failed loading current Terms of Service status
        </ErrorMessage>
      </Box>
    );
  }

  if (acceptError) {
    return (
      <Box m={4} mb={0}>
        <ErrorMessage devMessage={acceptError.message}>
          Failed accepting Terms of Service
        </ErrorMessage>
      </Box>
    );
  }

  if (loading || !data || data.isLatestEulaAccepted) {
    // we don't want to show anything while loading a EULA status
    return null;
  }

  return (
    <Zoom in={showAlert} unmountOnExit>
      <Box mb={2}>
        <Alert
          severity="info"
          variant="filled"
          action={(
            <Button
              className={classes.acceptAction}
              onClick={acceptSubmit}
            >
              Accept
            </Button>
          )}
        >
          <AlertTitle>Terms of Service update</AlertTitle>
          <span>
            New
            {' '}
            <Link
              href={TERMS_OF_USE}
              component="a"
              target="_blank"
              color="inherit"
            >
              Terms of Service
            </Link>
            {' '}
            released, please accept it.
          </span>
        </Alert>
      </Box>
    </Zoom>
  );
};
