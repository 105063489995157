import { CustomFormSettingsType } from './types';

export enum CustomFormTypes {
  SERVICE = 'service',
  FMSP = 'fmCustomFieldsForSp',
}

/**
 * Lodash have template function, which we use for text below
 */
export const CustomFormSettings: CustomFormSettingsType = {
  [CustomFormTypes.SERVICE]: {
    DISPLAY_HEADER: false,
    HEADER_TEXT: 'The following profile information is used in the system '
      + 'by facility managers to learn about your capabilities. '
      + 'The more you fill out the better your opportunity to get work!',
  },
  [CustomFormTypes.FMSP]: {
    DISPLAY_HEADER: true,
    HEADER_ICON: 'lock-alt',
    HEADER_TEXT: 'These fields are only visible to <%- organization_name%> users',
  },
};
