import React, { MouseEventHandler } from 'react';
import {
  Badge, ListItem, ListItemIcon, ListItemText, ListItemButton,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { colors } from 'components/theme';
import { MenuListItem } from './menu';

interface MenuItemStyleProps {
  selected: boolean;
  newStyle?: boolean;
}

const useStyles = makeStyles((theme) => createStyles({
  item: {
    borderRadius: ({ newStyle, selected }: MenuItemStyleProps) => {
      const rightEdge = selected ? 0 : 1;
      return newStyle ? theme.spacing(1, rightEdge, rightEdge, 1) : 0;
    },
    marginRight: ({ newStyle, selected }: MenuItemStyleProps) => (
      newStyle && !selected ? theme.spacing(3) : 0
    ),
    backgroundColor: ({ newStyle }: MenuItemStyleProps) => (newStyle ? 'rgba(255, 255, 255, 0.2)' : 'transparent'),
  },
  noIcon: {
    marginLeft: ({ newStyle }: MenuItemStyleProps) => theme.spacing(newStyle ? 5 : 7),
  },
  listItemIcon: {
    minWidth: ({ newStyle }: MenuItemStyleProps) => theme.spacing(newStyle ? 5 : 7),
  },
  selectedItem: {
    '&.Mui-selected': {
      backgroundColor: ({ newStyle }: MenuItemStyleProps) => (newStyle ? '#f5f5f5' : 'rgba(255, 255, 255, 0.8)'),
      '&:hover': {
        backgroundColor: ({ newStyle }: MenuItemStyleProps) => (newStyle ? '#f5f5f5' : 'rgba(255, 255, 255, 0.8)'),
      },
      '& > div > span': {
        color: colors.blue,
      },
    },
  },
}));

export interface MenuItemProps {
  item: MenuListItem;
  onMouseEnter?: MouseEventHandler;
  onMouseLeave?: MouseEventHandler;
  selected?: boolean;
  toggleMenu?: (value: boolean)=> void;
  newStyle?: boolean;
}

export const MenuItem: React.FC<MenuItemProps> = ({
  item,
  onMouseEnter,
  onMouseLeave,
  selected = false,
  toggleMenu,
  newStyle,
}) => {
  const classes = useStyles({ newStyle, selected });
  const Inside = () => (
    <>
      {item.icon && (
        <ListItemIcon aria-label={item.badge?.label} classes={{ root: classes.listItemIcon }}>
          <Badge
            variant={item.badge?.variant}
            badgeContent={item.badge?.number}
            color={item.badge?.color ?? 'secondary'}
          >
            {item.icon}
          </Badge>
        </ListItemIcon>
      )}
      <ListItemText
        className={!item.icon ? classes.noIcon : undefined}
        primary={item.text}
        primaryTypographyProps={{
          color: (item.type === '_menu_item_url' || item.type === '_menu_item_onclick')
            ? 'textPrimary'
            : 'textSecondary',
        }}
        secondary={item.secondaryText}
      />
      {item.iconRight}
    </>
  );

  if (item.type === '_menu_item_url') {
    return (
      <ListItemButton
        classes={{ root: classes.item, selected: classes.selectedItem }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        component={Link as any}
        to={item.url}
        selected={selected}
        disabled={item.disabled}
        title={item.title}
        onClick={() => toggleMenu && toggleMenu(false)}
      >
        <Inside />
      </ListItemButton>
    );
  }

  if (item.type === '_menu_item_onclick') {
    return (
      <ListItemButton
        classes={{ selected: classes.selectedItem }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={item.onClick}
        selected={selected}
        disabled={item.disabled}
        title={item.title}
      >
        <Inside />
      </ListItemButton>
    );
  }

  return (
    <ListItem title={item.title}>
      <Inside />
    </ListItem>
  );
};
