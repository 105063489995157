import { gql } from '@apollo/client';

export const ADMIN_BANNERS_QUERY = 'AdminBannersList';
export const ADMIN_BANNERS = gql`
  query AdminBannersList($skip: Int, $take: Int, $where: WhereBannerInput, $order: [OrderInput!]) {
    banners(skip: $skip, take: $take, where: $where, order: $order) {
      total_count
      items {
        banner_id
        type
        title
        body
        start_on
        end_on
        show_to_fms
        show_to_sps

        active

        show_to_fm_organizations
        show_to_sp_organizations

        fmOrganizations: organizations(type: "fm") {
          organization_id
          name
        }
        spOrganizations: organizations(type: "sp") {
          organization_id
          name
        }
      }
    }
  }
`;

export const BANNERS_QUERY = 'BannersList';
export const BANNERS = gql`
  query BannersList($skip: Int, $take: Int, $where: WhereBannerInput, $order: [OrderInput!]) {
    banners(skip: $skip, take: $take, where: $where, order: $order) {
      total_count
      items {
        banner_id
        type
        title
        body
      }
    }
  }
`;
