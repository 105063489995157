import { CircularProgress } from '@mui/material';
import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => createStyles({
  defaultMargin: {
    marginRight: 7,
  },
}));

export interface ButtonLoadingProps {
  loading?: boolean;
  size?: number;
}

export const ButtonLoading: React.FC<ButtonLoadingProps> = ({ loading, size }) => {
  const classes = useStyles();

  if (!loading) {
    return null;
  }

  return (
    <CircularProgress size={size || 19} classes={{ root: classes.defaultMargin }} />
  );
};
