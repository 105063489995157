import React, { useMemo } from 'react';
import {
  Channel,
  MessageList,
  MessageInput as DefaultMessageInput,
  Window,
} from 'stream-chat-react';
import './ChatSidebar.scss';
import { MessageInput } from '../MessageInput/MessageInput';
import { ChannelList } from '../ChannelList/ChannelList';
import { ChatProvider } from '../ChatContext';
import { ThreadHeader } from '../Thread/ThreadHeader';

export interface ChatSidebarProps {
  channelId: string;
  defaultTopic?: string;
}

export const ChatSidebar: React.FC<ChatSidebarProps> = ({
  channelId,
  defaultTopic,
}) => (
  <ChatProvider
    channelId={channelId}
    additionalProps={useMemo(() => (defaultTopic ? {
      defaultMessageValue: `RE: ${defaultTopic} - `,
    } : {}), [defaultTopic])}
  >
    <div style={{ display: 'none' }}>
      <ChannelList
        customActiveChannel={channelId}
      />
    </div>
    <Channel Input={MessageInput} ThreadHeader={ThreadHeader}>
      <Window>
        <MessageList />
        <DefaultMessageInput />
      </Window>
    </Channel>
  </ChatProvider>
);
