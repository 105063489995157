import React, { useEffect, useMemo } from 'react';

import { useIncumbentForFms } from 'components/auth/useIncumbentForFms';
import { useLazyQuery } from '@apollo/react-hooks';
import { PublicFmOrganization, PublicFmOrganizationVariables } from 'api/types/publicFmOrganization';
import { PUBLIC_FM_ORGANIZATION } from 'api/organization/publicFmOrganization';
import { Loading } from 'components/Loading';
import { FmLogo } from 'components/organization/bid/view/FmLogo';
import { BlankPage } from './BlankPage';

export const InvitationPage: React.FC<{ preApproved?: boolean }> = ({
  children,
  preApproved = false,
}) => {
  const { fm } = useIncumbentForFms();

  const [fetchPublicOrganization, { data: fmData, loading: fmLoading }] = useLazyQuery<
    PublicFmOrganization,
    PublicFmOrganizationVariables
    >(PUBLIC_FM_ORGANIZATION, {
      variables: { organizationId: fm[0] },
      fetchPolicy: 'no-cache',
    });

  useEffect(() => {
    if (fm && fm.length) fetchPublicOrganization();
  }, [fetchPublicOrganization, fm]);

  const headerContent = useMemo(() => (fmData ? (
    <FmLogo logoUrl={fmData.publicFmOrganization?.logo_url} />
  ) : null), [fmData]);

  if (fmLoading) {
    return (
      <Loading center />
    );
  }

  return (
    <BlankPage
      title="Congratulations!"
      noindex={preApproved}
      subtitle={preApproved ? (
        <>
          You have been pre-approved to join
          the Procursys Service Provider Network
          on behalf of
          {' '}
          <strong>{fmData?.publicFmOrganization?.name ?? 'Procursys'}</strong>
          .
          <br />
          Please submit the required information
          below to begin your profile set up.
        </>
      ) : (
        <>
          You have been invited
          {' '}
          {fmData?.publicFmOrganization?.name ? (
            <>
              by
              {' '}
              <strong>{fmData?.publicFmOrganization?.name}</strong>
            </>
          ) : ''}
          {' '}
          to join the Procursys Service Provider Network!
          <br />
          Please submit the required information below which will be reviewed by a member of
          our network membership team.
          If approved, you will receive an email with instructions to begin your profile set up.
        </>
      )}
      headerContent={headerContent}
    >
      {children}
    </BlankPage>
  );
};
