import { useCallback, useMemo, useState } from 'react';
import { fromPairs, toPairs } from 'lodash';
import { ColumnProps } from './Column';

export interface UseHiddenColumnsResult {
  visibleColumns: ColumnProps[];
  hiddenColumns: { [field: string]: boolean };
  toggleColumn: (field: string, show: boolean) => void;
  resetColumnVisibility: () => void;
}

/**
 * Columns are shown by default, so we use "hidden" instead of "visible",
 * so that we only need to toggle this state when we want to hide something.
 */
export function useHiddenColumns(
  tableId: string,
  columns: ColumnProps[],
): UseHiddenColumnsResult {
  const storageKey = useMemo(() => `${tableId}.hiddenColumns`, [tableId]);

  const storedValue: { [field: string]: boolean } | undefined = useMemo(() => {
    const stringValue = window.localStorage.getItem(storageKey);
    if (stringValue && stringValue !== 'undefined') {
      return JSON.parse(stringValue);
    }
    return undefined;
  }, [storageKey]);

  const [hiddenColumns, setHiddenColumns] = useState<{ [field: string]: boolean }>(
    storedValue ?? fromPairs(
      columns
        .filter((c) => c.hiddenByDefault)
        .map(({ field }) => [field, true]),
    ),
  );

  const toggleColumn = useCallback((field: string, show: boolean) => {
    setHiddenColumns((prev) => {
      let newValue: { [field: string]: boolean };
      if (show) {
        newValue = fromPairs(
          toPairs(prev).filter(([f]) => f !== field),
        );
      } else {
        newValue = {
          ...prev,
          [field]: true,
        };
      }
      window.localStorage.setItem(storageKey, JSON.stringify(newValue));
      return newValue;
    });
  }, [storageKey]);

  const visibleColumns = useMemo(
    () => columns.filter(({ field }) => !hiddenColumns[field]),
    [columns, hiddenColumns],
  );

  const resetColumnVisibility = useCallback(() => {
    setHiddenColumns(() => {
      window.localStorage.removeItem(storageKey);
      return fromPairs(
        columns
          .filter((c) => c.hiddenByDefault)
          .map(({ field }) => [field, true]),
      );
    });
  }, [columns, storageKey]);

  return {
    visibleColumns,
    hiddenColumns,
    toggleColumn,
    resetColumnVisibility,
  };
}
