import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';

const useBundlePropStyles = makeStyles(() => createStyles({
  title: {
    textTransform: 'uppercase',
  },
}));

export interface SidebarPropInterface {
  title: string;
  titleVariant?: Variant;
  titleFontWeight?: number;
  value?: string | JSX.Element | null;
}

export function SidebarProp({
  title,
  titleVariant,
  titleFontWeight,
  value,
}: SidebarPropInterface) {
  const classes = useBundlePropStyles();
  return (
    <Box mb={3} pr={2}>
      <Typography
        variant={titleVariant ?? 'subtitle2'}
        fontWeight={titleFontWeight}
        color="textSecondary"
        className={classes.title}
      >
        {title}
      </Typography>
      <Typography variant="body1" component="div">
        {value}
      </Typography>
    </Box>
  );
}
