import React from 'react';
import { Grid, SvgIcon, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SupportButton } from '../../../../notifications/SupportButton';

export const PageHeader = ({
  title,
  mode,
}: { title: string, mode: 'integrated' | 'standalone' }) => (
  <Grid
    container
    direction="row"
    alignItems="center"
  >
    <Grid item flex={1}>
      <Typography variant="h4">
        {title}
      </Typography>
    </Grid>
    {mode === 'integrated' ? (
      <Grid item>
        <SupportButton
          color="secondary"
          startIcon={(
            <SvgIcon>
              <FontAwesomeIcon icon="headset" />
            </SvgIcon>
          )}
        />
      </Grid>
    ) : null}
  </Grid>
);
