import React from 'react';
import {
  FormControl, FormHelperText, StandardTextFieldProps, TextField, Autocomplete, AutocompleteProps,
} from '@mui/material';

export interface SelectOption {
  value: number | string;
  text: string | JSX.Element | null;
}

interface SelectFieldProps {
  options: SelectOption[];
  helperText?: string;
  error?: boolean;
  value?: string[];
  inputProps?: StandardTextFieldProps,
  onChange?: (values: any[] | null) => void;
}

type MultiSelectFieldAllProps
  = SelectFieldProps
  & Omit<
    AutocompleteProps<SelectOption, true, undefined, undefined>,
    'multiple' | 'value' | 'onChange' | 'options' | 'getOptionLabel' | 'renderInput'
  >;

export const MultiSelectField: React.FC<MultiSelectFieldAllProps> = ({
  options,
  value,
  error,
  onChange,
  helperText,
  inputProps,
  ...rest
}) => {
  const selectedValue = (value || []).reduce((opts: any[], item: string) => {
    const opt = options.find((o: SelectOption) => o.value === item);
    return opt ? opts.concat(opt) : opts;
  }, []);

  return (
    <FormControl
      fullWidth
      variant="outlined"
      error={error}
    >
      <Autocomplete
        multiple
        value={selectedValue}
        onChange={(e: React.ChangeEvent<any>, values: any) => {
          if (onChange) onChange(values ? values.map((opt: SelectOption) => opt.value) : values);
        }}
        options={options as any}
        getOptionLabel={(option: any) => option.text}
        renderInput={(params) => (
          <TextField {...params} {...inputProps} />
        )}
        {...rest}
      />
      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormControl>
  );
};
