import { gql } from '@apollo/client';

const OrganizationFileFragment = gql`
    fragment OrganizationFileFragment on OrganizationFile {
        file_id
        name
        path
        mime_type
        url
        type
        thumb_url
        category {
          category_id
          name
        }
        organization_id
        created_by
        created_on
        is_public

        # context fields
        contract_term_id
        property_owner_id
        site_id
        bid_id
        service_id
  }
`;

export const FILES_QUERY = 'Files';
export const FILES = gql`
  ${OrganizationFileFragment}
  query Files($where: WhereOrganizationFileInput, $take: Int, $skip: Int, $order: [OrderInput!]) {
    _organizationFiles(where: $where, take: $take, skip: $skip, order: $order) {
      items {
          ...OrganizationFileFragment
      }
      total_count
      has_more
    }
  }
`;

export const ADMIN_FILES_QUERY = 'AdminFiles';
export const ADMIN_FILES = gql`
  ${OrganizationFileFragment}
  query AdminFiles($where: WhereOrganizationFileInput, $take: Int, $skip: Int, $order: [OrderInput!]) {
    adminOrganizationFiles(where: $where, take: $take, skip: $skip, order: $order) {
        items {
          ...OrganizationFileFragment
        }
        total_count
        has_more
    }
  }
`;
