import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { FmSpStat, FmSpStatVariables } from '../../../../api/types/FmSpStat';
import { FM_SP_STAT } from './queries/fmSpStat';
import { FmSpStatPreferenceLevel } from '../../../../api/types/globalTypes';
import { PreferenceButtonPrefetchProps } from './BasePreferenceButtonTypes';
import { NavTypeNoAdmin } from '../../../nav/navType';

export const useStatData = (
  role: NavTypeNoAdmin,
  fm_organization_id: number,
  sp_organization_id: number,
  {
    preferenceLevel: prefetchPreferenceLevel,
    levels: prefetchLevels,
  }: PreferenceButtonPrefetchProps,
) => {
  const [pl, setPl] = useState(prefetchPreferenceLevel);
  useEffect(() => setPl(prefetchPreferenceLevel), [prefetchPreferenceLevel]);
  const [levels, setLevels] = useState(prefetchLevels);

  const [
    getFmSpStat,
    { data, error, loading },
  ] = useLazyQuery<FmSpStat, FmSpStatVariables>(FM_SP_STAT, {
    variables: {
      fm_organization_id,
      sp_organization_id,
    },
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    if (!pl || !prefetchLevels) {
      getFmSpStat();
    }
  }, [getFmSpStat, prefetchLevels, pl]);

  useEffect(() => {
    if (data) {
      const pref = role === 'fm'
        ? data.fmSpStat?.preference_level
        : data.fmSpStat?.sp_preference_level;
      setPl(pref ?? FmSpStatPreferenceLevel.Default);
      setLevels(data.fmSpLevels);
    }
  }, [role, data]);

  return {
    preference: pl,
    levels,
    error,
    loading,
  };
};
