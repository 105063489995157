import React, { useCallback } from 'react';
import { CustomDialog, CustomDialogProps } from '../CustomDialog';
import { DocumentRepository } from './DocumentRepository';
import { FileUploadContext } from '../../api/types/globalTypes';
import { useDocumentRepository, UseDocumentRepositoryHookProps } from './useDocumentRepository';
import { useDocRepoDialogStyles } from './dialog/useDocRepoDialogStyles';
import { AttachDocumentsButton } from './dialog/AttachDocumentsButton';

export interface DocumentRepositoryModalProps extends Omit<CustomDialogProps, 'loading' | 'id' | 'actions'> {
  uploadContext?: FileUploadContext;
  onFilesUploaded?: UseDocumentRepositoryHookProps['onFilesUploaded'];
  allowToAddDocusignTemplates?: boolean;
  refetchQueries?: string[];
}

export const DocumentRepositoryDialog: React.FC<DocumentRepositoryModalProps> = ({
  uploadContext,
  onFilesUploaded,
  refetchQueries,
  onClose,
  allowToAddDocusignTemplates,
  ...props
}) => {
  const classes = useDocRepoDialogStyles();
  const repositoryProps = useDocumentRepository({
    mode: 'selection',
    allowToAddDocusignTemplates,
    uploadContext,
    onFilesUploaded: useCallback((files) => {
      onFilesUploaded?.(files);
      onClose();
    }, [onFilesUploaded, onClose]),
    refetchQueries,
  });

  const {
    form: {
      isValid, isSubmitting, submitForm, resetForm,
    },
    totalSelectedFiles: totalFiles,
    hasRejectedFiles,
  } = repositoryProps;

  const handleClose = useCallback(() => {
    resetForm();
    onClose();
  }, [onClose, resetForm]);

  const canSubmit = !!totalFiles && isValid && !isSubmitting && !hasRejectedFiles;

  return (
    <CustomDialog
      id="document-repo-modal"
      title="Add Files"
      fullWidth
      maxWidth="lg"
      loading={false}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
      actions={(
        <AttachDocumentsButton
          totalFiles={totalFiles}
          isSubmitting={isSubmitting}
          canSubmit={canSubmit}
          submitForm={submitForm}
        />
      )}
      {...props}
    >
      <DocumentRepository {...repositoryProps} />
    </CustomDialog>
  );
};
