import React from 'react';
import { Helmet } from 'react-helmet';

import { DEFAULT_TITLE } from 'mainConstants';

export interface PageHelmetProps {
  title?: string;
  noindex? :boolean;
}

const PageHelmet: React.FC<PageHelmetProps> = ({ title, noindex = false }) => (
  <Helmet>
    <title>{title ? `${title} | ${DEFAULT_TITLE}` : DEFAULT_TITLE}</title>
    {noindex && (
      <meta name="robots" content="noindex" data-react-helmet="true" />
    )}
  </Helmet>
);

export default PageHelmet;
