import React, { useCallback, useMemo, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { Send, Cancel } from '@mui/icons-material';
import {
  UserInvites,
  UserInvites__userInvites_items,
  UserInvitesVariables,
} from 'api/types/UserInvites';
import { USER_INVITES, USER_INVITES_QID } from 'components/organization/team/invites/queries/UserInvitesQuery';
import { WhereUserInput } from 'api/types/globalTypes';
import { ColumnProps } from 'components/datagrid/column/Column';
import { DataGrid, DataGridState } from 'components/datagrid/DataGrid';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { DataResult } from '@progress/kendo-data-query';
import { NoData } from '../../../text/NoData';
import { DateTime } from '../../../text/DateTime';
import { RESEND_USER_INVITE } from './queries/ResendUserInviteMutation';
import { ResendUserInvite, ResendUserInviteVariables } from '../../../../api/types/ResendUserInvite';
import { ErrorSnackbar, useErrorSnackbar } from '../../../notifications/ErrorSnackbar';
import { SuccessSnackbar } from '../../../notifications/SuccessSnackbar';
import { CANCEL_INVITE } from './queries/CancelInviteMutation';
import { CancelInvite, CancelInviteVariables } from '../../../../api/types/CancelInvite';
import { useDialog } from '../../../Dialog';

export interface InvitesTableProps {
  currentUserId: number;
  afterFilters?: React.ReactNode;
}

export const InvitesTable: React.FC<InvitesTableProps> = ({
  currentUserId,
  afterFilters,
}) => {
  const { confirm } = useDialog();
  const [successMessage, setSuccessMessage] = useState<string>();
  const onSuccessMessageClose = () => setSuccessMessage(undefined);

  const buildWhere = useCallback((state: DataGridState): WhereUserInput => ({
    and: [
      {
        created_user_id: { isNull: true },
      },
      ...state.apiFilters,
    ],
  }), []);

  const [fetchTableData, {
    data: tableData,
    loading: tableLoading,
    error: tableError,
  }] = useLazyQuery<UserInvites, UserInvitesVariables>(USER_INVITES, {
    fetchPolicy: 'no-cache',
  });

  const tableDataResult: DataResult = useMemo(() => ({
    data: tableData?._userInvites ? tableData._userInvites.items : [],
    total: tableData?._userInvites?.total_count ?? 0,
  } as DataResult), [tableData]);

  const loadTableData = useCallback((state: DataGridState) => {
    fetchTableData({
      variables: {
        skip: state.skip ?? 0,
        take: state.take ?? 0,
        order: state.order,
        where: buildWhere(state),
      },
    });
  }, [buildWhere, fetchTableData]);

  const [
    resendUserInvite,
    { error: resendError },
  ] = useMutation<ResendUserInvite, ResendUserInviteVariables>(RESEND_USER_INVITE, {
    refetchQueries: [USER_INVITES_QID],
  });
  const resendErrorSnackbar = useErrorSnackbar(resendError);
  const resendInvite = (invite: UserInvites__userInvites_items) => async () => {
    await resendUserInvite({
      variables: {
        inviteId: invite.invite_id,
      },
    });
    setSuccessMessage('Invitation has been resend');
  };

  const [
    cancelUserInvite,
    { error: cancelError },
  ] = useMutation<CancelInvite, CancelInviteVariables>(CANCEL_INVITE, {
    refetchQueries: [USER_INVITES_QID],
  });
  const cancelErrorSnackbar = useErrorSnackbar(cancelError);
  const cancelInvite = (invite: UserInvites__userInvites_items) => async () => {
    const confirmed = await confirm({
      title: 'Cancel Invite',
      content: 'Are you sure? This action cannot be undone.',
      okText: 'Cancel Invite',
      cancelText: 'Don\'t Cancel Invite',
      okColor: 'secondary',
    });
    if (confirmed) {
      await cancelUserInvite({
        variables: { invite_id: invite.invite_id },
      });
      setSuccessMessage('Invitation has been canceled');
    }
  };

  const columns: ColumnProps[] = [
    {
      field: 'email',
      title: 'Email',
      width: 300,
      render: ({ email, createdUser }) => createdUser?.login_email ?? email,
      filterWidget: { filterType: 'string' },
    },
    {
      field: 'invitedByName',
      title: 'Invited By',
      width: 300,
      render: ({ invitedByName, invitedBy }) => (
        <>
          {invitedByName ?? (<NoData>Deleted</NoData>)}
          {currentUserId === invitedBy?.user_id && (
            <>
              {' '}
              <Typography component="span" variant="body2" color="textSecondary">
                (you)
              </Typography>
            </>
          )}
        </>
      ),
    },
    {
      field: 'invited_on',
      title: 'Invited On',
      width: 150,
      render: ({ invited_on }) => <DateTime block value={invited_on} short />,
    },
    {
      field: 'failed_on',
      title: 'Status',
      sortByField: 'failed_on',
      width: 170,
      render: ({ failed_on }) => (failed_on ? (
        <>
          Failed on
          {' '}
          <DateTime block value={failed_on} short />
        </>
      ) : (
        <>
          Pending
        </>
      )),
    },
  ];

  return (
    <>
      <DataGrid
        id="invites"
        columns={columns}
        loadTableData={loadTableData}
        tableData={tableDataResult}
        tableLoading={tableLoading}
        tableError={tableError}
        actions={(item: UserInvites__userInvites_items) => ([
          <Button
            size="small"
            variant="text"
            onClick={resendInvite(item)}
            startIcon={<Send />}
          >
            Resend
          </Button>,
          <Button
            size="small"
            variant="text"
            onClick={cancelInvite(item)}
            startIcon={<Cancel />}
          >
            Cancel
          </Button>,
        ])}
        actionsWidth={230}
        withMap={false}
        selectionType="none"
        afterFilters={afterFilters}
        topPageNavigation={false}
      />
      <SuccessSnackbar open={!!successMessage} onClose={onSuccessMessageClose}>
        {successMessage}
      </SuccessSnackbar>
      <ErrorSnackbar {...resendErrorSnackbar}>
        Failed to resend invitation
      </ErrorSnackbar>
      <ErrorSnackbar {...cancelErrorSnackbar}>
        Failed to cancel invitation
      </ErrorSnackbar>
    </>
  );
};
