import React, { useCallback } from 'react';
import {
  Box, Button, Grid, IconButton, Typography,
} from '@mui/material';
import { Check, Close } from '@mui/icons-material';

export enum FilterOperation {
  eq = 'eq',
  not = 'not',
  gt = 'gt',
  gte = 'gte',
  lt = 'lt',
  lte = 'lte',
  in = 'in',
  contains = 'contains',
  notContains = 'notContains',
  startsWith = 'startsWith',
  endsWith = 'endsWith',
  between = 'between',
  isNull = 'isNull',
}

export interface BaseFilterProps<T> {
  title: string;
  initialValue?: T;
  initialOperation?: FilterOperation;
  close: () => void;
  onApply: (operation?: FilterOperation, value?: T) => void;
  onCancel?: () => void;
}

export interface FilterWrapperProps {
  title: string;
  onApply: () => void;
  onClear: () => void;
  onCancel?: () => void;
  disabled?: boolean;
  applyDisabled?: boolean;
  children?: any;
}

export function FilterWrapper({
  title,
  onApply,
  onClear,
  onCancel,
  disabled,
  applyDisabled,
  children,
}: FilterWrapperProps) {
  const handleSubmit = useCallback((ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    onApply();
  }, [onApply]);

  return (
    <>
      <Box mb={2}>
        <Grid
          container
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignContent="center"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="subtitle2">
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton size="small" disabled={disabled} onClick={onCancel}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <Box mb={2}>
        <form onSubmit={handleSubmit}>
          {children}
          <input type="submit" hidden />
        </form>
      </Box>
      <Grid
        container
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        alignContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item>
          <Button
            variant="outlined"
            color="secondary"
            disabled={disabled}
            onClick={onClear}
            startIcon={<Close />}
          >
            Clear
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            disabled={disabled || applyDisabled}
            onClick={onApply}
            startIcon={<Check />}
          >
            Apply
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
