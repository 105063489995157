import React, { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { ApolloError } from '@apollo/client';
import { Files__organizationFiles_items } from '../../../api/types/Files';
import { FILES_QUERY } from '../queries/files';
import { useDialog } from '../../Dialog';
import { DeleteFiles, DeleteFilesVariables } from '../../../api/types/DeleteFiles';
import { DELETE_FILES } from '../queries/deleteFiles';

interface UseFileDeleteHandlerHook {
  handleDelete: (file: Files__organizationFiles_items) => Promise<boolean>,
  error?: ApolloError;
}

export const useFileDeleteHandler = (
  refetchQueries: string[] = [],
): UseFileDeleteHandlerHook => {
  const { confirm } = useDialog();

  const [
    deleteFiles, { error },
  ] = useMutation<DeleteFiles, DeleteFilesVariables>(DELETE_FILES, {
    refetchQueries: [FILES_QUERY, ...refetchQueries],
  });

  const handleDelete = useCallback(async (file: Files__organizationFiles_items) => {
    const confirmed = await confirm({
      title: 'Delete File?',
      content: (
        <span>
          Are you sure you want to remove
          {' '}
          <strong>{file.name}</strong>
          {'? '}
          This action cannot be undone.
        </span>
      ),
      okText: 'Delete',
    });

    if (!confirmed) return false;

    await deleteFiles({
      variables: {
        fileIds: [file.file_id],
      },
    });
    return true;
  }, [confirm, deleteFiles]);

  return {
    handleDelete,
    error,
  };
};
