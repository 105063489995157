import { createStyles, makeStyles } from '@mui/styles';

export const useTabsStyles = makeStyles((theme) => createStyles({
  tabsRoot: {
    boxShadow: 'none',
  },
  tabsContainer: {
    gap: theme.spacing(2),
  },
  tab: {
    minWidth: 'auto',
    padding: theme.spacing(1.5, 0),
  },
}));
