import React from 'react';
import {
  Button, Checkbox, FormControlLabel, Grid, TextField,
} from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useMutation, useQuery } from '@apollo/react-hooks';

import { CustomDialog } from '../../CustomDialog';
import { ErrorMessage } from '../../notifications/ErrorMessage';
import { UPDATE_FILE } from '../queries/updateFile';
import { FILES_QUERY } from '../queries/files';
import { Files__organizationFiles_items } from '../../../api/types/Files';
import { UpdateFile, UpdateFileVariables } from '../../../api/types/UpdateFile';
import { SelectField } from '../../form/SelectField';
import { FileCategories, FileCategoriesVariables } from '../../../api/types/FileCategories';
import { FILE_CATEGORIES } from '../queries/categories';
import { FileType, Order } from '../../../api/types/globalTypes';

interface EditFileModalFormData {
  name: string;
  category_id: number;
  is_public: boolean;
}

const formValidationSchema = Yup.object<EditFileModalFormData>().shape<EditFileModalFormData>({
  name: Yup.string().trim().required('File name is required'),
  category_id: Yup.number().required('File category is required'),
  is_public: Yup.boolean(),
});

export interface EditFileModalProps {
  open?: boolean;
  file?: Files__organizationFiles_items | null;
  onClose: () => void;
}

export const FileEditDialog: React.FC<EditFileModalProps> = ({
  onClose,
  file,
  open = true,
}) => {
  const [
    updateFile, { error, loading },
  ] = useMutation<UpdateFile, UpdateFileVariables>(UPDATE_FILE);

  const {
    data: categoriesData,
    error: categoriesError,
    loading: categoriesLoading,
  } = useQuery<FileCategories, FileCategoriesVariables>(FILE_CATEGORIES, {
    variables: {
      order: [{ field: 'rank', order: Order.ASC }, { field: 'name', order: Order.ASC }],
    },
    fetchPolicy: 'no-cache',
  });

  const categories = categoriesData
    ? categoriesData._fileCategories.items.map((c) => ({ text: c.name, value: c.category_id }))
    : [];

  /**
   * hide "Show in organization profile" checkbox file files that tied to some specific context
   */
  const disableMakePublic = [
    file?.bid_id, file?.contract_term_id, file?.property_owner_id, file?.service_id, file?.site_id,
  ].some(Boolean);

  if (!file) {
    return null;
  }

  return (
    <Formik
      initialValues={{
        name: file.name,
        category_id: file.category.category_id,
        is_public: file.is_public,
      } as EditFileModalFormData}
      validationSchema={formValidationSchema}
      onSubmit={async (values, { resetForm }) => {
        await updateFile({
          variables: {
            fileId: file.file_id,
            organizationFile: values,
          },
          refetchQueries: [FILES_QUERY],
        });
        resetForm();
        onClose();
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        submitForm,
      }) => (
        <CustomDialog
          id="edit-file-modal"
          title="Edit File"
          open={open}
          actions={(
            <Button
              color="primary"
              disabled={loading || isSubmitting}
              onClick={submitForm}
            >
              Update
            </Button>
          )}
          loading={loading}
          onClose={onClose}
          fullWidth
          maxWidth="md"
        >
          {!loading && (
            <Grid
              container
              component="form"
              spacing={2}
              onSubmit={handleSubmit}
            >
              <Grid item xs={12} md={6}>
                <TextField
                  name="name"
                  label="File Name *"
                  variant="standard"
                  fullWidth
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.name}
                  helperText={errors.name}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SelectField
                  formControlProps={{ variant: 'standard' }}
                  label="File category *"
                  name="category_id"
                  placeholder="Select category"
                  options={categories}
                  value={categories.length ? values.category_id : ''}
                  onBlur={handleBlur}
                  disabled={categoriesLoading}
                  onChange={(e) => {
                    setFieldValue('category_id', e.target.value as number);
                  }}
                />
              </Grid>
              {file.type === FileType.DocuSignTemplate ? (
                <Grid item xs={12}>
                  <TextField
                    name="name"
                    label="DocuSign Id *"
                    variant="standard"
                    fullWidth
                    value={file.path}
                    disabled
                  />
                </Grid>
              ) : null}
              {!disableMakePublic && (
                <Grid item>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={values.is_public}
                        onChange={(e) => {
                          setFieldValue('is_public', e.target.checked, false);
                        }}
                        color="primary"
                      />
                    )}
                    label="Show in organization profile"
                  />
                </Grid>
              )}
            </Grid>
          )}
          {error && (
            <ErrorMessage devMessage={error.message}>
              Failed to update file
            </ErrorMessage>
          )}
          {categoriesError && (
            <ErrorMessage devMessage={categoriesError.message}>
              Failed loading a list of file categories
            </ErrorMessage>
          )}
        </CustomDialog>
      )}
    </Formik>
  );
};
