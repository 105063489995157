import * as Yup from 'yup';

import {
  UserNotificationSettings_notificationEvents_items as NotificationEventType,
  UserNotificationSettings_userNotificationSettings_items as SettingItemType,
} from 'api/types/UserNotificationSettings';
import { UserNotificationSettingInput } from 'api/types/globalTypes';

import { UserNotificationsFormData } from './useInitialValues';

export const validationSchema = Yup.object().shape<UserNotificationsFormData>({
  settings: Yup.array().of(Yup.object().shape({
    event_id: Yup.number().required('Event required'),
    service_id: Yup.number().nullable(),
    sms_enabled: Yup.boolean().required('SMS Notification required'),
    email_reminder_enabled: Yup.boolean().required('Email Notification required'),
    email_enabled: Yup.boolean().required('Email Notification required'),
  })),
});

export enum NotificationType {
  SMS = 'sms_enabled',
  Email = 'email_enabled',
  EmailReminder = 'email_reminder_enabled',
}

export const isChecked = (
  values: UserNotificationsFormData,
  type: NotificationType,
  {
    event_id,
    service_id,
  }: Pick<SettingItemType, 'event_id' | 'service_id'>,
) => {
  const existingRecord = values.settings.find(
    (pref) => pref.event_id === event_id && pref.service_id === service_id,
  );
  // default = true
  return existingRecord ? existingRecord[type] : true;
};

export const buildSettingInput = (
  ne: NotificationEventType,
  serviceId: number | null | undefined,
  overrides: Partial<UserNotificationSettingInput>,
): UserNotificationSettingInput => ({
  event_id: ne.event_id,
  service_id: serviceId,
  [NotificationType.SMS]: ne.has_sms,
  [NotificationType.Email]: ne.has_email,
  [NotificationType.EmailReminder]: ne.has_email_reminder,
  ...overrides,
});

export const isAllChecked = (
  values: UserNotificationsFormData,
  type: NotificationType,
  eventId: number,
) => values.settings
  .filter((s) => s.event_id === eventId)
  .every((s) => s[type]);
