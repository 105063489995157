import React, { useState } from 'react';
import {
  capitalize, Chip, CircularProgress, TextField, Autocomplete,
} from '@mui/material';
import { useMutation } from '@apollo/react-hooks';
import { remove } from 'lodash';
import { Permissions } from 'api/permissions/permissions';
import { UPDATE_USER_PERMISSIONS } from 'api/permissions/updateUserPermissions';
import { PROFILE_QUERY, useProfile } from 'api/user/profile';
import { UpdateUserPerms, UpdateUserPermsVariables } from 'api/types/UpdateUserPerms';
import { ErrorSnackbar, useErrorSnackbar } from '../notifications/ErrorSnackbar';

export const filterUserPermissions = (
  ap: UserPermissionType,
  up: any,
) => {
  if (ap.permission_id === up.permission_id) {
    const isRegional = ap.permission.is_regional === up.permission.is_regional;
    const isGlobal = !ap.region_id && !up.region_id && isRegional;
    const sameRegion = ap.region_id
      && up.region_id
      && ap.region_id === up.region_id;

    if (isGlobal || sameRegion) {
      return ap;
    }

    if (!isGlobal && sameRegion) {
      return ap;
    }
  }

  return false;
};

export interface UserPermissionType {
  permission_id: number;
  name: string;
  permission: { is_regional: boolean; };
  region_id?: number;
}
export type UserPermissionShortType = Omit<UserPermissionType, 'name' | 'region_id'> & {
  region_id?: number | null;
};

export interface UserPermissionsComponentProps {
  userId: number;
  availablePermissions: UserPermissionType[];
  userPermissions: UserPermissionShortType[];
}

export const UserPermissionsComponent = (
  {
    userId,
    availablePermissions,
    userPermissions: initialPermissions,
  }: UserPermissionsComponentProps,
) => {
  const profile = useProfile();
  const yourPermissions = profile.user_id === userId;
  const [permissions, setPermissions] = useState(() => initialPermissions);

  const [savePermissions, { error: savePermError, loading: savePermLoading }] = useMutation<
    UpdateUserPerms,
    UpdateUserPermsVariables
  >(UPDATE_USER_PERMISSIONS, { refetchQueries: [PROFILE_QUERY] });
  const saveErrorSnackbar = useErrorSnackbar(savePermError);

  // Filter permissions
  // (we filter available permissions cause need to have same arrays for initial values
  // and available values
  // We have Global permissions, global regional permissions, just regional permissions
  // If we select global regional permission, we remove all `just regional`
  // permissions from the list
  const value = availablePermissions.filter((ap) => permissions.find(
    (up) => filterUserPermissions(ap, up),
  ));

  return (
    <>
      <Autocomplete
        multiple
        disableCloseOnSelect
        size="small"
        selectOnFocus
        value={value}
        options={availablePermissions}
        renderInput={(params) => {
          const { inputProps }: any = params;
          inputProps.autoComplete = 'nope';
          return (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: savePermLoading ? <CircularProgress color="inherit" size={20} /> : null,
              }}
              size="small"
              label="Permissions"
            />
          );
        }}
        getOptionLabel={
          (opt: UserPermissionType) => capitalize(opt.name)
        }
        getOptionDisabled={
          (option) => yourPermissions && option.permission_id === Permissions.ORGANIZATION_OWNER
        }
        renderTags={(tags: UserPermissionType[], getTagProps) => (
          tags.map((option, index: number) => {
            const isOwnerPermission = Permissions.ORGANIZATION_OWNER === option.permission_id;
            const {
              onDelete,
              ...tagProps
            } = getTagProps({ index }) as { onDelete: (e: React.MouseEvent<any>) => void };
            return (
              <Chip
                size="small"
                label={capitalize(option.name)}
                {...tagProps}
                onDelete={yourPermissions && isOwnerPermission ? undefined : onDelete}
              />
            );
          })
        )}
        onChange={async (e, newVal: UserPermissionType[]) => {
          // We need to remove regional permissions if `global regional` was selected
          const globalRegional = newVal.filter(
            (nv) => nv.permission.is_regional && nv.region_id === undefined,
          );
          globalRegional.forEach((gr) => {
            remove(newVal, (nv) => nv.permission_id === gr.permission_id && nv.region_id);
          });

          setPermissions(newVal);

          await savePermissions({
            variables: {
              user_id: userId,
              permissions: newVal.map(({ permission_id, region_id }) => ({
                permission_id,
                region_id,
              })),
            },
          });
        }}
      />
      <ErrorSnackbar {...saveErrorSnackbar}>
        Could not save permissions
      </ErrorSnackbar>
    </>
  );
};
