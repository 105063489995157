/**
 * Source: https://stackoverflow.com/a/63173682
 */
export function textContent(elem?: string | JSX.Element): string {
  if (!elem) {
    return '';
  }
  if (typeof elem === 'string') {
    return elem;
  }
  const children = elem.props && elem.props.children;
  if (children instanceof Array) {
    return children.map(textContent).join('');
  }
  return textContent(children);
}
