import { gql } from '@apollo/client';

export const SP_PROFILE_QUERY = 'SpProfile';
export const SP_PROFILE = gql`
  query SpProfile($organizationId: Int!) {
    publicOrganization(
      organization_id: $organizationId
    ) {
      organization_id
      name
      phone_number
      website_url
      about
      default_signatory_name
      default_signatory_email
      address {
        address_line_1
        address_line_2
        city
        state_code
        postal_code
        country_code
        operation_hours
        google_place_id
        latlong
      }
      spProfile {
        profile_id
        about
        banner_title
        banner_subtitle
        phone_number
        years_in_business
        facebook
        instagram
        twitter
        linkedin
        files {
          file_id
          path
          type
          mime_type
          name
          type
          rank
          url
          thumb_url
        }
      }
      services {
        service_id
        name
        slug
        icon
        rank
        fields {
          field_id
          name
          type
          context
          rank
        }
      }
      serviceFieldValues {
        service_id
        field_id
        value
      }
    }
    commonFields: _serviceFields(
      where: { service_id: { isNull: true } }
      skip: 0
      take: 9999
    ) {
      items {
        field_id
        name
        type
        context
        rank
      }
      total_count
      has_more
    }
  }
`;
