import { Grid } from '@mui/material';
import React from 'react';
import { CustomFieldType } from '../types';

interface CustomFieldHTMLProps {
  field: CustomFieldType;
}

export const CustomFieldHTML: React.FC<CustomFieldHTMLProps> = ({ field }) => (
  <Grid
    item
    xs={12}
    md={12}
    dangerouslySetInnerHTML={{ __html: field.context?.text || field.name }}
  />
);
