import {
  find, intersection, trim, negate, isEmpty, includes, padStart,
} from 'lodash';
import { Point } from 'geojson';
import countryCodes from './countryCodes.json';

/**
 * Note that this is used because we know exactly that fields are going to be there
 * due to configuration set for Autocomplete object.
 * If you get errors of undefined fields, make sure to adjust that configuration.
 */
type GooglePlaceFields
  = 'place_id'
  | 'geometry'
  | 'address_components'
  | 'formatted_address'
  | 'name'
  | 'international_phone_number'
  | 'website';
export type GooglePlace = Pick<Required<google.maps.places.PlaceResult>, GooglePlaceFields>;

export interface GoogleAddress {
  name: string;
  placeId: string;
  streetNumber: string;
  route: string;
  postalCode: string;
  latitude: number;
  longitude: number;
  locality: string; // city/town
  administrativeAreaLevel2: string; // county
  administrativeAreaLevel1: string; // state
  country: string;
}

export interface GoogleBusiness extends GoogleAddress {
  phone: string;
  website: string;
}

function getGeocoderAddressComponent(
  types: string[],
  placeResult: google.maps.places.PlaceResult,
  short: boolean = false,
): string {
  const component = find(
    placeResult.address_components,
    (c) => intersection(c.types, types).length === types.length,
  );
  if (component) {
    return short ? component.short_name : component.long_name;
  }
  return '';
}

export function toPlaceAddress(placeResult: GooglePlace): GoogleAddress {
  const countryCode = getGeocoderAddressComponent(['country'], placeResult, true);
  const stateCode = getGeocoderAddressComponent(['administrative_area_level_1'], placeResult, true);
  const isPr = ['PR', 'PRI'].includes(countryCode);
  return {
    placeId: placeResult.place_id,
    name: placeResult.name,
    streetNumber: getGeocoderAddressComponent(['street_number'], placeResult),
    route: getGeocoderAddressComponent(['route'], placeResult),
    postalCode: getGeocoderAddressComponent(['postal_code'], placeResult),
    latitude: placeResult.geometry.location!.lat(),
    longitude: placeResult.geometry.location!.lng(),
    locality: getGeocoderAddressComponent(['locality'], placeResult),
    administrativeAreaLevel2: getGeocoderAddressComponent(['administrative_area_level_2'], placeResult),
    administrativeAreaLevel1: isPr ? 'PR' : stateCode,
    country: isPr ? 'US' : countryCode,
  };
}

/**
 * Convert Google Maps API response into an address info.
 */
export function toGoogleAddress(placeResult: GooglePlace): GoogleAddress {
  if (!placeResult.place_id) {
    throw new Error('Autocompleted result does not have a place_id');
  }
  return toPlaceAddress(placeResult);
}

/**
 * Convert Google Maps API response into a business info, including address.
 */
export function toGoogleBusiness(placeResult: GooglePlace): GoogleBusiness {
  if (!placeResult.place_id) {
    throw new Error('Autocompleted result does not have a place_id');
  }
  return {
    phone: placeResult.international_phone_number,
    website: placeResult.website,
    ...toPlaceAddress(placeResult),
  };
}

/**
 * Convert 2-letter country code into 3-letter country code.
 * @param googleCountryCode Google country code, 2-letter format
 */
export function toISO3CountryCode(googleCountryCode: string): string {
  // See http://country.io/data/
  const code = (countryCodes as Record<string, string>)[googleCountryCode];
  if (!code) {
    throw new Error(`3-letter country code not found for '${googleCountryCode}'`);
  }
  return code;
}

export function formatAddress(
  addressLine1: string | null | undefined,
  city: string | null | undefined,
  stateCode: string | null | undefined,
  postalCode: string | null | undefined,
  countryCode?: string | null | undefined,
): string {
  return [
    addressLine1 || '',
    city || '',
    trim(`${trim(stateCode || '')} ${trim(postalCode || '')}`),
    countryCode || '',
  ].map(trim).filter(negate(isEmpty)).join(', ');
}

export function formatAddress2(
  addressLine1: string | null | undefined,
  addressLine2: string | null | undefined,
  city: string | null | undefined,
  stateCode: string | null | undefined,
  postalCode: string | null | undefined,
  countryCode?: string | null | undefined,
) {
  if (!isEmpty(addressLine2)) {
    return formatAddress(
      `${addressLine1}${addressLine2 ? `, ${addressLine2}` : ''}`,
      city,
      stateCode,
      postalCode,
      countryCode,
    );
  }
  return formatAddress(
    addressLine1,
    city,
    stateCode,
    postalCode,
    countryCode,
  );
}

export function formatOrgAddress(
  org: {
    address_line_1: string;
    city: string;
    state_code: string;
    postal_code: string;
    country_code: string;
  },
  withCountryCode: boolean = true,
): string {
  return [
    org.address_line_1,
    org.city,
    `${trim(org.state_code)} ${trim(org.postal_code)}`,
    withCountryCode ? org.country_code : '',
  ].map(trim).filter(negate(isEmpty)).join(', ');
}

export function formatGoogleAddress(address: GoogleAddress, withCounty: boolean = false): string {
  return [
    `${trim(address.streetNumber)} ${trim(address.route)}`,
    address.locality,
    withCounty ? address.administrativeAreaLevel2 : '',
    `${trim(address.administrativeAreaLevel1)} ${trim(address.postalCode)}`,
    address.country,
  ].map(trim).filter(negate(isEmpty)).join(', ');
}

export function validateState(state?: string | null): boolean {
  if (!state) return true;
  return includes([
    'AL',
    'AK',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'DC',
    'FL',
    'GA',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'OH',
    'OK',
    'OR',
    'PA',
    'PR',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY',
    // canadian provinces and territories
    'ON',
    'QC',
    'NS',
    'NB',
    'MB',
    'BC',
    'PE',
    'SK',
    'AB',
    'NL',
    'NT',
    'YT',
    'NU',
  ], state.toUpperCase());
}

export function validateZipCode(code?: string | null): boolean {
  if (!code) return true;
  return /^\d{5}(-\d{4})?$/.test(code)
    // canadian regex
    || /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i.test(code);
}

export interface PlaceLocation {
  placeId: string;
  coordinatesGeoJSON: Point;
}

export function coordinatesToGeoJSON(lat: number, lng: number): Point {
  return {
    type: 'Point',
    coordinates: [lat, lng],
  };
}

/**
 * Fiend a place_id by full address or ZIP code only (fallback) using Google Maps API.
 * @param address Full address string, e.g. "123 Some Street, City, ST 12345"
 * @param zip ZIP code, used when full address didn't return any results
 */
export function getPlaceLocation(address: string, zip: string): Promise<PlaceLocation> {
  return new Promise<PlaceLocation>((resolve, reject) => {
    const gc = new google.maps.Geocoder();
    gc.geocode({ address }, (results1, status1) => {
      if (status1 === google.maps.GeocoderStatus.OK && results1!.length > 0) {
        const loc1 = results1![0].geometry.location;
        resolve({
          placeId: results1![0].place_id,
          coordinatesGeoJSON: coordinatesToGeoJSON(loc1.lat(), loc1.lng()),
        });
      } else if (status1 === google.maps.GeocoderStatus.ZERO_RESULTS) {
        // Couldn't get any results for full address - try zip only
        gc.geocode({ address: zip }, (results2, status2) => {
          if (status2 === google.maps.GeocoderStatus.OK && results2!.length > 0) {
            const loc2 = results2![0].geometry.location;
            resolve({
              placeId: results2![0].place_id,
              coordinatesGeoJSON: coordinatesToGeoJSON(loc2.lat(), loc2.lng()),
            });
          } else {
            const resultsMsg = results2 ? `${results2.length} results` : `results field is ${results2}`;
            reject(new Error(`Could not validate address: ${status2}, ${resultsMsg}`));
          }
        });
      } else {
        const resultsMsg = results1 ? `${results1.length} results` : `results field is ${results1}`;
        reject(new Error(`Could not validate address: ${status1}, ${resultsMsg}`));
      }
    });
  });
}

function getWholeMinutes(coord: number): string {
  const frac = Math.abs(coord) - Math.abs(Math.trunc(coord));
  const minutes = Math.trunc(60 * frac);
  return padStart(minutes.toString(), 2, '0');
}

function getSeconds(coord: number): string {
  const frac = Math.abs(coord) - Math.abs(Math.trunc(coord));
  const minutes = Math.trunc(60 * frac);
  const seconds = 3600 * frac - 60 * minutes;
  return new Intl.NumberFormat(
    'en-US',
    {
      minimumIntegerDigits: 2,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  ).format(seconds);
}

function formatCoord(coord: number): string {
  const degrees = Math.abs(Math.trunc(coord));
  const minutes = getWholeMinutes(coord);
  const seconds = getSeconds(coord);
  return `${degrees}\xB0${minutes}'${seconds}"`;
}

export function prettyPrintCoordinates(lng: number, lat: number): string {
  return `${formatCoord(lat)}${lat >= 0 ? 'N' : 'S'}, ${formatCoord(lng)}${lng >= 0 ? 'E' : 'W'}`;
}

export function prettyPrintPoint(point: Point): string {
  return prettyPrintCoordinates(point.coordinates[0], point.coordinates[1]);
}
