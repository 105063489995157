import { gql } from '@apollo/client';

export const FM_BUNDLES_SUBMITTED_BIDS_QUERY = 'FmBundlesSubmittedBids';
export const FM_BUNDLES_SUBMITTED_BIDS = gql`
  query FmBundlesSubmittedBids(
    $siteBundleIds: [AnyScalar!]!
  ) {
    fmBundles(
      where: {
        bundle_id: { in: $siteBundleIds }
      }
    ) {
      items {
        summary {
          submitted
        }
      }
    }
  }
`;
