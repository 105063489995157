import React from 'react';
import {
  Checkbox, FormControlLabel, FormGroup, Grid, Typography,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Filter, FilterOption } from '../types';
import { NullableFilterWidgetProps } from '../FilterWidgetProps';
import { StatusChip } from '../../../organization/StatusChip';
import { WhereOperationsInput } from '../../../../api/types/globalTypes';

const useStyles = makeStyles(({ spacing }) => createStyles({
  bottom: {
    marginTop: spacing(2),
    paddingTop: spacing(1),
    borderTop: `1px solid ${grey[400]}`,
  },
}));

export interface CheckboxesProps extends NullableFilterWidgetProps {
  field: string;
  values: any[];
  isNullChecked?: boolean;
  filterOptions: FilterOption[];
  substituteOptionLabels: boolean;
  setFilter: React.Dispatch<React.SetStateAction<Filter>>;
  apiFilterType: 'regular' | 'json';
}

/**
 * List of checkboxes used as `in` filter
 */
export const CheckboxesFilter: React.FC<CheckboxesProps> = ({
  field,
  values,
  isNullChecked,
  filterOptions,
  substituteOptionLabels,
  isNullEnabled,
  isNullTitle,
  setFilter,
  apiFilterType,
  logicType,
}) => {
  const classes = useStyles();

  const setFilterValue = (value: string[], withNulls: boolean) => {
    const condition: WhereOperationsInput = apiFilterType === 'json'
      ? { jsArraySome: value }
      : { in: value };

    setFilter(withNulls ? {
      field,
      type: 'checkboxes',
      logic: logicType ?? 'or',
      where: [
        { isNull: true },
        condition,
      ],
    } : {
      field,
      type: 'checkboxes',
      logic: undefined,
      where: condition,
    });
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      justifyContent="flex-start"
      alignContent="stretch"
      alignItems="stretch"
    >
      {filterOptions.map(({
        key,
        label,
        icon,
        iconColor,
      }, index) => (
        <Grid item key={key}>
          <FormGroup>
            <FormControlLabel
              control={(
                <Checkbox
                  autoFocus={index === 0}
                  checked={values.includes(key)}
                  onChange={(e, checked) => {
                    const newValues = values
                      .filter((v) => v !== key)
                      .concat(checked ? [key] : []);

                    setFilterValue(newValues, !!isNullChecked);
                  }}
                  color="primary"
                />
              )}
              label={(
                <Typography variant="inherit" component="span">
                  <Grid
                    component="span"
                    container
                    direction="row"
                    spacing={1}
                    justifyContent="flex-start"
                    alignItems="center"
                    alignContent="flex-start"
                  >
                    {icon && iconColor && typeof key === 'number' ? (
                      <Grid item>
                        <StatusChip
                          status={{
                            name: substituteOptionLabels ? label : key.toString(),
                            status_id: key,
                            icon: (icon ?? null) as string | null,
                            color: iconColor,
                          }}
                        />
                      </Grid>
                    ) : (
                      <>
                        {icon && (
                          <Grid item>
                            <FontAwesomeIcon
                              style={{ width: 24 }}
                              icon={icon}
                              color={iconColor ?? undefined}
                            />
                          </Grid>
                        )}
                        <Grid item>
                          {substituteOptionLabels ? label : key}
                        </Grid>
                        {!substituteOptionLabels && label && (
                          <Grid item>
                            <Typography variant="inherit" component="span" color="textSecondary">
                              {label}
                            </Typography>
                          </Grid>
                        )}
                      </>
                    )}
                  </Grid>
                </Typography>
              )}
            />
          </FormGroup>
        </Grid>
      ))}
      {isNullEnabled && (
        <Grid item className={classes.bottom}>
          <FormControlLabel
            control={(
              <Checkbox
                checked={isNullChecked}
                onChange={(e, checked) => setFilterValue(values, checked)}
                color="primary"
              />
            )}
            label={(
              <Typography variant="inherit" component="span">
                <Grid
                  component="span"
                  container
                  direction="row"
                  spacing={1}
                  justifyContent="flex-start"
                  alignItems="center"
                  alignContent="flex-start"
                >
                  <Grid item>
                    <FontAwesomeIcon
                      style={{ width: 24 }}
                      icon="empty-set"
                    />
                  </Grid>
                  <Grid item>
                    {isNullTitle ?? 'Is null'}
                  </Grid>
                </Grid>
              </Typography>
            )}
          />
        </Grid>
      )}
    </Grid>
  );
};
