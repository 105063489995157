import { useCallback, useMemo, useState } from 'react';
import { SortDescriptor } from '@progress/kendo-data-query';
import { GridSortChangeEvent } from '@progress/kendo-react-grid/dist/npm/interfaces/events';
import { OrderInput } from '../../../api/types/globalTypes';
import { convertSortDescriptor, convertToSortDescriptor } from '../utils';
import { ColumnProps } from '../column/Column';

export interface UseSortingResult {
  /** For API */
  order?: OrderInput[];
  /** For KendoReact Grid */
  sort?: SortDescriptor[];
  onSortChange: (event: GridSortChangeEvent) => void;
  clearSorting: () => void;
}

export function useSorting(
  tableId: string,
  columns: ColumnProps[],
  defaultSort?: SortDescriptor[],
): UseSortingResult {
  const storageKey = useMemo(() => `${tableId}.sorting`, [tableId]);

  const storedValue: OrderInput[] | undefined = useMemo(() => {
    const stringValue = window.localStorage.getItem(storageKey);
    if (stringValue && stringValue !== 'undefined') {
      return JSON.parse(stringValue);
    }
    return undefined;
  }, [storageKey]);

  const [order, setOrder] = useState<OrderInput[]>(
    storedValue ?? defaultSort?.map(convertSortDescriptor) ?? [],
  );

  const onSortChange = useCallback((event: GridSortChangeEvent) => {
    setOrder(() => {
      const { sort: eventSort } = event;
      const newValue = eventSort.length ? eventSort.map((descriptor) => {
        const column = columns.find((c) => c.field === descriptor.field);
        return convertSortDescriptor({
          ...descriptor,
          // replace a field if a column overrides its sorting behavior,
          // e.g. it shows a status label but wants to sort by its rank
          field: column && column.sortByField ? column.sortByField : descriptor.field,
        });
      }) : [];
      if (newValue) {
        window.localStorage.setItem(storageKey, JSON.stringify(newValue));
      } else {
        window.localStorage.removeItem(storageKey);
      }
      return newValue;
    });
  }, [columns, storageKey]);

  const clearSorting = useCallback(() => {
    setOrder(() => {
      window.localStorage.removeItem(storageKey);
      return defaultSort?.map(convertSortDescriptor) ?? [];
    });
  }, [defaultSort, storageKey]);

  const sortConverted = useMemo(
    () => order?.map((o) => {
      const column = columns.find((c) => c.sortByField === o.field);
      return convertToSortDescriptor({
        ...o,
        // replace (back) a field if a column overrides its sorting behavior,
        // so that grid shows sorting arrows. See above
        field: column ? column.field : o.field,
      });
    }),
    [columns, order],
  );

  return {
    order,
    sort: sortConverted,
    onSortChange,
    clearSorting,
  };
}
