import React, { useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CustomDialog, CustomDialogProps } from '../../CustomDialog';
import { DocumentRepository, DocumentRepositoryForm, useDocumentRepository } from '../../documentRepository';
import { useDocRepoDialogStyles } from '../../documentRepository/dialog/useDocRepoDialogStyles';
import { AttachDocumentsButton } from '../../documentRepository/dialog/AttachDocumentsButton';

export interface DocumentRepositoryModalProps extends Omit<CustomDialogProps, 'loading' | 'id' | 'actions'> {
  onSelected: (files: File[]) => Promise<void>;
}

const validationSchema = Yup.object().shape({
  files: Yup.array().of(Yup.object().shape({
    name: Yup.string().required('Name is required'),
    category_id: Yup.number().when('show_in_repository', {
      is: (showInRepository) => showInRepository,
      then: Yup.number().required('Category is required'),
      otherwise: Yup.number().nullable(),
    }),
  })),
});

export const DocumentRepositoryDialog: React.FC<DocumentRepositoryModalProps> = ({
  onSelected,
  onClose,
  ...props
}) => {
  const classes = useDocRepoDialogStyles();

  const initialValues: DocumentRepositoryForm = useMemo(() => (
    { files: [], selectedFiles: [], categoryInitiallyHidden: true }
  ), []);

  const { uploadFiles: uploadFilesToRepository, uploadError } = useDocumentRepository({
    mode: 'selection',
  });

  const uploadFiles = useCallback(async (values: DocumentRepositoryForm) => {
    const { files, selectedFiles } = values;

    // Upload files to doc repository if needed
    const saveToRepoFiles = files.filter((f) => f.show_in_repository);
    if (saveToRepoFiles.length) {
      await uploadFilesToRepository(values);
    }

    const filesList = files.map((f) => f.file);

    // Fetch data binary for existing files from file repository
    const existingFiles = await Promise.all(
      selectedFiles.map((file, index) => fetch(file.url)
        .then((r) => r.blob())
        .then((blob) => {
          const { name, path, mime_type: mimeType } = selectedFiles[index];

          // GetStream gives error if file name don't contains extension
          let fileName = name;
          const ext = path.substring(path.lastIndexOf('.') + 1, path.length);
          if (!fileName.includes(ext)) {
            fileName = `${fileName}.${ext}`;
          }
          return new File([blob], fileName, { type: mimeType });
        })),
    );

    await onSelected(filesList.concat(existingFiles));
  }, [uploadFilesToRepository, onSelected]);

  const form = useFormik<DocumentRepositoryForm>({
    validationSchema,
    initialValues,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: useCallback(async (values, { resetForm }) => {
      await uploadFiles(values);
      resetForm();
    }, [uploadFiles]),
  });

  const { values: { files, selectedFiles } } = form;

  const totalFiles = useMemo(() => (
    files.length + selectedFiles.length
  ), [files, selectedFiles]);

  const hasRejectedFiles = useMemo(() => files.some((f) => !!f.rejectionReason), [files]);

  const {
    isValid, isSubmitting, submitForm, resetForm,
  } = form;

  const handleClose = useCallback(() => {
    resetForm();
    onClose();
  }, [onClose, resetForm]);

  const canSubmit = !!totalFiles && isValid && !isSubmitting && !hasRejectedFiles;

  return (
    <CustomDialog
      id="document-repo-modal"
      title="Add Files"
      fullWidth
      maxWidth="lg"
      loading={false}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
      actions={(
        <AttachDocumentsButton
          totalFiles={totalFiles}
          isSubmitting={isSubmitting}
          canSubmit={canSubmit}
          submitForm={submitForm}
        />
      )}
      {...props}
    >
      <DocumentRepository
        mode="selection"
        form={form}
        hasRejectedFiles={hasRejectedFiles}
        uploadError={uploadError}
      />
    </CustomDialog>
  );
};
