import React, { useEffect, useRef } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import L from 'leaflet';

const useLoadingElement = (
  id: string,
  map: L.Map | null | undefined,
  isLoading: boolean,
  loadingEl: HTMLElement | null | undefined,
  setLoadingElStyle: (loadingEl: HTMLElement, mapEl: HTMLElement) => void,
) => {
  useEffect(() => {
    const mapEl = document.getElementById(id);
    if (loadingEl && map && mapEl) {
      if (isLoading) {
        setLoadingElStyle(loadingEl, mapEl);
        const resizeHandler = () => {
          setLoadingElStyle(loadingEl, mapEl);
        };
        map.on('resize', resizeHandler);
        return () => {
          map.off('resize', resizeHandler);
        };
      }
      // eslint-disable-next-line no-param-reassign
      loadingEl.style.display = 'none';
    }
    return () => {};
  }, [id, isLoading, loadingEl, map, setLoadingElStyle]);
};

const useStyles = makeStyles((theme) => createStyles({
  loadingOverlay: {
    display: 'none', // hide by default, but use 'flex' to show
    position: 'absolute',
    zIndex: theme.zIndex.appBar - 1, // see https://material-ui.com/customization/z-index/#z-index
    backgroundColor: 'rgba(0,0,0,0.3)',
    color: 'white',
    textShadow: '0 0 2px black',
    fontWeight: 'bold',
    cursor: 'progress',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
}));

export interface LoadingOverlayProps {
  mapElId: string;
  map?: L.Map;
  isLoading: boolean;
}

function setLoadingOverlayStyle(overlay: HTMLElement, map: HTMLElement) {
  // eslint-disable-next-line no-param-reassign
  overlay.style.display = 'flex';
  // eslint-disable-next-line no-param-reassign
  overlay.style.top = `${map.offsetTop}px`;
  // eslint-disable-next-line no-param-reassign
  overlay.style.left = `${map.offsetLeft}px`;
  // eslint-disable-next-line no-param-reassign
  overlay.style.width = `${map.clientWidth}px`;
  // eslint-disable-next-line no-param-reassign
  overlay.style.height = `${map.clientHeight}px`;
}

export const LoadingOverlay: React.FC<LoadingOverlayProps> = ({
  mapElId,
  map,
  isLoading,
}) => {
  const classes = useStyles();
  const loadingOverlayRef = useRef<HTMLDivElement | null>(null);
  useLoadingElement(mapElId, map, isLoading, loadingOverlayRef.current, setLoadingOverlayStyle);
  return (
    <div ref={loadingOverlayRef} className={classes.loadingOverlay}>
      Loading...
    </div>
  );
};
