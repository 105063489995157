import { gql } from '@apollo/client';

export const FM_CUSTOM_FIELDS = gql`
  query FmCustomFields ($skip: Int!, $take: Int!, $where: WhereFmCustomFieldInput) {
    fmCustomFields(
      skip: $skip,
      take: $take,
      where: $where,
      order: [{ field: "rank" }]
    ) {
      items {
        field_id
        fm_organization_id
        name
        type
        context
        placeholder
      }
      total_count
      has_more
    }
  }
`;
