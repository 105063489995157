import React, { useMemo, useCallback, useRef } from 'react';
import {
  Grid, Box, Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { DataResult } from '@progress/kendo-data-query';
import { DateRange } from '@mui/icons-material';

import { useProfile } from 'api/user/profile';
import {
  FmSidebarBids,
  FmSidebarBidsVariables,
  FmSidebarBids_fmBids_items as TableItem,
} from 'api/types/FmSidebarBids';
import { BidStatuses } from 'api/types/BidStatuses';
import { BID_STATUSES, BidStatus } from 'api/bids/bidStatuses';
import { DataGrid, DataGridRefHandle, DataGridState } from 'components/datagrid/DataGrid';
import { ColumnProps } from 'components/datagrid/column/Column';
import { BiddingLink } from 'components/organization/sp/actions/BiddingLink';
import { RollingChip } from 'components/organization/bid/RollingChip';
import { StatusChip } from 'components/organization/StatusChip';
import { canViewBidAsFM } from 'components/organization/bid/bidPermissions';
import { NoData } from 'components/text/NoData';
import { DateTime } from 'components/text/DateTime';
import { ErrorSnackbar, useErrorSnackbar } from 'components/notifications/ErrorSnackbar';
import { canShowEnvelopeBidBadge, EnvelopeBidBadge } from 'components/envelope/EnvelopeBidBadge';

import { TooltipSwitch } from 'components/form/TooltipSwitch';
import { useActiveOnlyItems } from 'components/datagrid/useActiveOnlyItems';
import { FilterOption } from 'components/datagrid/filter/types';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Order, WhereUserInput } from 'api/types/globalTypes';
import { FM_SIDEBAR_BIDS } from '../queries/fmSidebarBids';

const useStyles = makeStyles(() => createStyles({
  bids: {
    '& .k-pager-sizes': {
      display: 'none',
    },
  },
  bundleName: {
    display: 'flex',
    alignItems: 'center',
  },
  date: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '8px',
  },
}));

export interface BidsTabProps {
  organizationId: number;
}

export const BidsTab: React.FC<BidsTabProps> = ({ organizationId }) => {
  const tableId = 'sidebar-bids';
  const gridRef = useRef<DataGridRefHandle>(null);

  const profile = useProfile();
  const { organization: org } = profile;
  const classes = useStyles();

  const {
    activeItemsOnly,
    setActiveItemsOnly,
    resetActiveItemsOnly,
  } = useActiveOnlyItems(tableId, gridRef);

  const [fetchTableData, {
    data: tableData,
    loading: tableLoading,
    error: tableError,
  }] = useLazyQuery<FmSidebarBids, FmSidebarBidsVariables>(
    FM_SIDEBAR_BIDS,
    { fetchPolicy: 'no-cache' },
  );

  const buildWhere = useCallback((state: DataGridState): WhereUserInput => ({
    and: [
      {
        organization_id: { eq: organizationId },
      },
      ...(activeItemsOnly ? [{
        active: { eq: true },
      }] : []),
      ...state.apiFilters.map((f) => {
        // rewrite name field to unit_id
        if (f.units) {
          return { units: { unit_id: { in: f.units.name.in } } };
        }
        return f;
      }),
    ],
  }), [activeItemsOnly, organizationId]);

  const loadTableData = useCallback((state: DataGridState) => {
    fetchTableData({
      variables: {
        skip: state.skip ?? 0,
        take: state.take ?? 0,
        order: [{ field: '_status.rank', order: Order.DESC }],
        where: buildWhere(state),
      },
    });
  }, [buildWhere, fetchTableData]);

  const {
    data: bidStatusesData,
    error: bidStatusesError,
  } = useQuery<BidStatuses>(BID_STATUSES, {
    fetchPolicy: 'no-cache',
  });
  const bidStatusesErrorSnackbar = useErrorSnackbar(bidStatusesError);
  const bidStatusesOptions: FilterOption[] = useMemo(() => (
    bidStatusesData?.bidStatuses.map((bs) => ({
      key: bs.status_id,
      label: bs.name,
      icon: bs.icon as IconProp | null,
      iconColor: bs.color,
    } as FilterOption)) ?? []
  ), [bidStatusesData]);

  const tableDataResult: DataResult = useMemo(() => ({
    data: tableData?.fmBids ? tableData.fmBids.items : [],
    total: tableData?.fmBids.total_count ?? 0,
  } as DataResult), [tableData]);

  const columns = useMemo((): ColumnProps[] => [
    {
      field: 'bundle.name',
      title: 'Bundle',
      width: 440,
      resizable: false,
      sortable: false,
      render: (item: TableItem) => {
        const {
          bundle,
          bid_id,
          status_id,
          submitted_on,
          end_date,
        } = item;
        const showLink = canViewBidAsFM(profile, bundle.organization_id, status_id, submitted_on);

        return (
          <div className={classes.bundleName}>
            <BiddingLink
              maxWidth={380}
              type="fm"
              inNewWindow
              linkAvailable={showLink}
              bid_id={bid_id}
              title={(
                <>
                  {bundle.name}
                  <br />
                  <Box className={classes.date}>
                    <DateRange />
                    {
                      end_date ? (
                        <DateTime
                          block
                          expirationWarningDays={[7, 3, 1]}
                          short
                          value={end_date}
                        />
                      ) : <RollingChip />
                    }
                  </Box>
                </>
              )}
            >
              {bundle.name}
            </BiddingLink>
          </div>
        );
      },
      filterWidget: { filterType: 'string' },
    },
    {
      field: 'status_id',
      title: 'Bid Status',
      width: 120,
      resizable: false,
      sortable: false,
      render: ({
        status_id,
        latestStatusLog,
        declineReason,
        envelope,
        end_date,
      }: TableItem) => (
        bidStatusesData ? (
          <Grid container spacing={1} direction="row" wrap="nowrap">
            <Grid item>
              <StatusChip
                type="bid"
                size="tiny"
                tooltipFor="sp"
                status={bidStatusesData.bidStatuses.find(
                  (s) => s.status_id === status_id,
                )!}
                reason={declineReason?.name || undefined}
                latestStatusLog={status_id !== BidStatus.Expired
                  ? latestStatusLog
                  : null}
                date={
                  status_id === BidStatus.Expired
                    ? end_date
                    : latestStatusLog?.changed_on
                }
              />
            </Grid>
            {canShowEnvelopeBidBadge('fm', status_id === BidStatus.Awarded, org, envelope) ? (
              <Grid item container alignItems="center">
                <EnvelopeBidBadge navType="fm" envelope={envelope} />
              </Grid>
            ) : null}
          </Grid>
        ) : (
          <NoData>-</NoData>
        )
      ),
      filterWidget: {
        filterType: 'checkboxes',
        options: bidStatusesOptions,
        substituteOptionLabels: true,
        isNullEnabled: false,
      },
    },
  ], [bidStatusesOptions, profile, classes.bundleName, classes.date, bidStatusesData, org]);

  return (
    <Box className={classes.bids}>
      <DataGrid
        ref={gridRef}
        id={tableId}
        columns={columns}
        loadTableData={loadTableData}
        tableData={tableDataResult}
        tableLoading={tableLoading}
        tableError={tableError}
        withMap={false}
        selectionType="none"
        noColumnSettings
        tinyFilters
        pageNavigationInfo={false}
        maxPagerButtons={7}
        fixedPagerButtons
        hidePageNavigationIfOnePageOnly
        scrollable="none"
        resizable={false}
        additionalResets={[resetActiveItemsOnly]}
        beforeFilters={(
          <Typography variant="h6">Bids</Typography>
        )}
        afterFilters={(
          <TooltipSwitch
            name="preview"
            tooltip="Show only current-season, In Progress or Awarded bids."
            color="primary"
            label="Only&nbsp;Active"
            checked={activeItemsOnly}
            onChange={setActiveItemsOnly}
          />
        )}
      />
      <ErrorSnackbar {...bidStatusesErrorSnackbar}>
        Failed loading bids
      </ErrorSnackbar>
    </Box>
  );
};
