import { LatLng } from 'leaflet';
import { BoundariesWhereInput } from 'api/types/globalTypes';

export const latLngToPoint = (latLng: LatLng) => ({
  type: 'Point',
  coordinates: [latLng.lat, latLng.lng],
});

export const getMapBoundaries = (map: L.Map): BoundariesWhereInput => ({
  ne: latLngToPoint(map.getBounds().getNorthEast()),
  sw: latLngToPoint(map.getBounds().getSouthWest()),
  size: { type: 'Point', coordinates: [map.getSize().x, map.getSize().y] },
});
