import React from 'react';
import { get } from 'lodash';
import {
  GridColumnMenuItemContent,
  GridColumnMenuItemGroup,
  GridColumnMenuProps,
  GridColumnMenuSort,
} from '@progress/kendo-react-grid';
import { Box, Button, Grid } from '@mui/material';
import {
  Filter, FilterOption, FilterTypeSimple, FilterTypeWithOptions,
} from '../filter/types';

type KendoGridColumnMenuProps = Omit<
  GridColumnMenuProps,
  'filter' | 'filterable' | 'filterOperators' | 'onFilterChange'>;

interface BaseColumnMenuProps extends KendoGridColumnMenuProps {
  //
}

export interface ColumnMenuWithoutFilterProps extends BaseColumnMenuProps {
  filterType: undefined;
}

export interface ColumnMenuWithFilterSimpleProps extends BaseColumnMenuProps {
  filterType: FilterTypeSimple;
  filter?: Filter;
  clearFilter: () => void;
  openFilterWidget: () => void;
}

export interface ColumnMenuWithFilterWithOptionsProps extends BaseColumnMenuProps {
  filterType: FilterTypeWithOptions;
  filter?: Filter;
  clearFilter: () => void;
  openFilterWidget: () => void;
  filterOptions: FilterOption[];
}

export type ColumnMenuProps
  = ColumnMenuWithoutFilterProps
  | ColumnMenuWithFilterSimpleProps
  | ColumnMenuWithFilterWithOptionsProps;

function isWithFilterSimple(
  props: ColumnMenuProps,
): props is ColumnMenuWithFilterSimpleProps {
  return !!get(props, 'filterType') && !get(props, 'filterOptions');
}

function isWithFilterWithOptions(
  props: ColumnMenuProps,
): props is ColumnMenuWithFilterWithOptionsProps {
  return !!get(props, 'filterType') && !!get(props, 'filterOptions');
}

function isWithFilter(
  props: ColumnMenuProps,
): props is ColumnMenuWithFilterSimpleProps | ColumnMenuWithFilterWithOptionsProps {
  return isWithFilterSimple(props) || isWithFilterWithOptions(props);
}

export const ColumnMenu: React.FC<ColumnMenuProps> = (props) => {
  if (isWithFilter(props)) {
    return (
      <div>
        {props.sortable && (<GridColumnMenuSort {...props} />)}
        <GridColumnMenuItemGroup>
          <GridColumnMenuItemContent show>
            <Box padding={2}>
              <Grid
                container
                spacing={1}
                direction="row"
                wrap="wrap"
                justifyContent="space-between"
                alignContent="flex-start"
                alignItems="center"
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    size="small"
                    disabled={!props.filter}
                    onClick={() => {
                      props.clearFilter();
                      if (props.onCloseMenu) {
                        props.onCloseMenu();
                      }
                    }}
                  >
                    Clear
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      props.openFilterWidget();
                      if (props.onCloseMenu) {
                        props.onCloseMenu();
                      }
                    }}
                  >
                    {props.filter ? 'Change' : 'Add'}
                    {' '}
                    Filter
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </GridColumnMenuItemContent>
        </GridColumnMenuItemGroup>
      </div>
    );
  }

  return (
    <div>
      <GridColumnMenuSort {...props} />
    </div>
  );
};
