import { parsePhoneNumberFromString, ParseError } from 'libphonenumber-js';

/**
 * Check if a number is a valid global or US-local number.
 */
export function validatePhone(number?: string | null): boolean {
  if (!number) return true;
  try {
    const phone = parsePhoneNumberFromString(number, 'US');
    if (phone) {
      return phone.isValid();
    }
    return false;
  } catch (e) {
    if (e instanceof ParseError) {
      return false;
    }
    throw e;
  }
}

/**
 * Convert a phone number to E.164 format: "+12223334455"
 * In case when number is invalid, the original string will be returned as-is.
 * Supports US-local phones.
 * @return Full number in E.164 format: plus, country code, rest of digits, e.g. "+12223334455"
 */
export function formatPhoneInternationalSimple(number: string): string {
  try {
    const phone = parsePhoneNumberFromString(number, 'US');
    if (phone) {
      return phone.format('E.164');
    }
    return number;
  } catch (e) {
    if (e instanceof ParseError) {
      return number;
    }
    throw e;
  }
}

/**
 * Convert a phone number to National format: "2223334455"
 * In case when number is invalid, the original string will be returned as-is.
 * Supports US-local phones.
 * @return phone number in National format: rest of digits, e.g. "2223334455"
 */
export function formatPhoneNationalSimple(number: string): string {
  try {
    const phone = parsePhoneNumberFromString(number, 'US');
    if (phone) {
      return phone.formatNational().replace(/-|\s|\)|\(/, '');
    }
    return number;
  } catch (e) {
    if (e instanceof ParseError) {
      return number;
    }
    throw e;
  }
}

/**
 * Convert a phone number to a full format: "+1 222 333 4455"
 * In case when number is invalid, the original string will be returned as-is.
 * Supports US-local phones.
 * @return Full phone number in a full format, e.g. "+1 222 333 4455"
 */
export function formatPhoneInternationalPretty(number: string): string {
  try {
    const phone = parsePhoneNumberFromString(number, 'US');
    if (phone) {
      return phone.formatInternational();
    }
    return number;
  } catch (e) {
    if (e instanceof ParseError) {
      return number;
    }
    throw e;
  }
}
