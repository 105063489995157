import L from 'leaflet';

/**
 * Event type: Change current mode (view, add, remove)
 */
export const CLICK_MODE_CHANGED_EVENT = 'mode:changed';

export type LeafletClickMode = 'view' | 'add' | 'remove';
export interface ClickModeSwitchEvent extends L.LeafletEvent {
  mode: LeafletClickMode;
}

function createModeButton(
  map: L.Map, buttonLabel: string, value: LeafletClickMode, checked = false,
) {
  const radioInput = L.DomUtil.create('input') as HTMLInputElement;
  radioInput.type = 'radio';
  radioInput.name = 'map-mode';
  radioInput.value = value;
  radioInput.checked = checked;

  radioInput.addEventListener('change', (e) => {
    const { value: mode } = e.target as HTMLInputElement;
    map.fire(CLICK_MODE_CHANGED_EVENT, { mode } as ClickModeSwitchEvent);
  });

  L.DomUtil.addClass(radioInput, 'procursys-leaflet-control-checkbox');

  const label = L.DomUtil.create('label') as HTMLLabelElement;
  L.DomUtil.addClass(label, 'procursys-leaflet-control-active procursys-leaflet-control-checkbox-label');
  label.append(radioInput, document.createTextNode(buttonLabel));
  label.addEventListener('click', (e) => {
    e.stopPropagation();
  });
  return label;
}

export const ClickModelSwitch = L.Control.extend({
  onAdd(map: L.Map) {
    const bar = L.DomUtil.create('div', 'leaflet-bar') as HTMLDivElement;
    L.DomUtil.addClass(bar, 'procursys-leaflet-control procursys-leaflet-control-text');

    const label = L.DomUtil.create('div') as HTMLDivElement;
    label.appendChild(document.createTextNode('Click Mode:'));
    bar.appendChild(label);

    [
      createModeButton(map, 'View', 'view', true),
      createModeButton(map, 'Add', 'add'),
      createModeButton(map, 'Remove', 'remove'),
    ].forEach((labelElement) => bar.appendChild(labelElement));

    return bar;
  },
});

export function addClickModeSwitch(
  map: L.Map,
  onChange: (mode: LeafletClickMode) => void,
) {
  const eraseControl = new ClickModelSwitch({ position: 'topleft' });
  map.addControl(eraseControl);
  map.on(CLICK_MODE_CHANGED_EVENT, (e: L.LeafletEvent) => {
    onChange((e as ClickModeSwitchEvent).mode);
  });
}
