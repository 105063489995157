import React, { useCallback, useState } from 'react';
import { Button, ButtonProps } from '@mui/material';

interface SupportButtonProps extends ButtonProps {
  children?: React.ReactNode;
  replyText?: string;
}

export const SupportButton: React.FC<SupportButtonProps> = ({
  replyText,
  children,
  ...props
}) => {
  const [supportWidgetLoading, setSupportWidgetLoading] = useState(false);
  const onContactTechSupportClick = useCallback(() => {
    setSupportWidgetLoading(true);
    const widget = window.fcWidget;
    if (!widget.isOpen()) {
      widget.open({
        name: 'Chat - with Support',
        replyText,
      });
    }
    setTimeout(() => setSupportWidgetLoading(false), 5000);
  }, [replyText]);
  return (
    <Button
      disabled={supportWidgetLoading}
      color="inherit"
      variant="outlined"
      size="small"
      onClick={onContactTechSupportClick}
      {...props}
    >
      {children ?? 'Support'}
    </Button>
  );
};
