import { gql } from '@apollo/client';

export const ACCEPT_LATEST_EULA = gql`
  mutation AcceptLatestEula {
      acceptLatestEula {
        accepted_on
      }
  }
`;

export const IS_LATEST_EULA_ACCEPTED = gql`
  query IsLatestEulaAccepted {
    isLatestEulaAccepted
  }
`;
