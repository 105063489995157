import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Grid, Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PageProgressState } from './PageProgress.types';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    border: '1px solid #21212114',
    borderRadius: 4,
    padding: theme.spacing(2),
  },
  step: {
    marginTop: theme.spacing(1),
  },
  lineItem: {
    marginTop: theme.spacing(1),
    borderBottom: '1px solid #21212114',
  },
  header: {
    margin: '0',
  },
}));

export const PageProgress = ({ state }: { state: PageProgressState }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2} flexDirection="column">
      {state.map(({ sectionName, steps }) => (
        <Grid item flex={1} key={sectionName}>
          <Box className={classes.container}>
            <Grid container flexDirection="row" justifyContent="space-between">
              <Grid item>
                <h3 className={classes.header}>{sectionName}</h3>
              </Grid>
              {state.length > 1 ? (
                <Grid item style={{ color: 'green' }}>
                  <b>{steps.filter((s) => s.progress === 1).length}</b>
                  /
                  {steps.length}
                </Grid>
              ) : null}
            </Grid>
            {steps.map(({
              name,
              description,
              progress,
            }, idx) => (
              <Grid
                key={name}
                container
                spacing={1}
                classes={{ root: classes.step }}
                flexDirection="column"
              >
                <Grid
                  item
                  container
                  spacing={1}
                  flexDirection="row"
                  alignItems="space-between"
                  flexWrap="nowrap"
                >
                  <Grid item container spacing={1}>
                    <Grid item>
                      <h4 className={classes.header}>{name}</h4>
                    </Grid>
                  </Grid>
                  {progress === 1 ? (
                    <Grid item>
                      <FontAwesomeIcon icon="check" color="green" />
                    </Grid>
                  ) : null}
                </Grid>
                {description ? (
                  <Grid item>
                    <span>{description}</span>
                  </Grid>
                ) : null}
                {idx + 1 !== steps.length ? (
                  <Grid item>
                    <div className={classes.lineItem} />
                  </Grid>
                ) : null}
              </Grid>
            ))}
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};
