import { useContext, useEffect } from 'react';
import { OnboardingContext } from './OnboardingContext';
import { PageProgressState } from './components/HelpSidebar/checklist/PageProgress.types';

export const useHookSubPageControls = (
  mode: 'standalone' | 'integrated',
  form?: {
    submitForm: () => Promise<any>;
    validateForm: () => Promise<any>;
    dirty: boolean;
  },
  getState?: () => PageProgressState | undefined,
) => {
  const boardingCtx = useContext(OnboardingContext);
  const { submitForm, validateForm, dirty } = form ?? {};
  useEffect(() => {
    if (mode === 'standalone') {
      return () => {};
    }
    boardingCtx.setValue({
      submitForm,
      validateForm,
      state: getState ? getState() : undefined,
    });
    return () => boardingCtx.setValue({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitForm, validateForm, getState]);

  useEffect(() => {
    boardingCtx.setDirty(dirty ?? false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dirty]);
};
