import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import {
  Box, Grid, Tab, Tabs, useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import { Sidebar } from '../../../../../sidebar/Sidebar';
import { a11yProps, TabPanel } from '../../../../../tabs/TabPanel';
import { useTabsStyles } from './useTabsStyles';
import { useCurrentPath } from '../useCurrentPath';
import { PATHS } from '../../../../../../paths';
import { CompletedAlert } from './checklist/ComplatedAlert';
import { PageProgress } from './checklist/PageProgress';
import { Loading } from '../../../../../Loading';
import { OnboardingContext } from '../../OnboardingContext';

interface HelpSidebarProps {
  children: React.ReactElement[];
}

export const HelpSidebar = ({ children }: HelpSidebarProps) => {
  const { state } = useContext(OnboardingContext);
  const tabStyles = useTabsStyles();
  const [page, setPage] = useState(1);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setPage(newValue);
  };

  const [openSidebar, setOpenSidebar] = useState(true);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  useEffect(() => {
    if (isDesktop) {
      setOpenSidebar(true);
    } else {
      setOpenSidebar(false);
    }
  }, [isDesktop]);

  const currentPath = useCurrentPath();
  const content = useMemo(() => {
    switch (currentPath) {
      case PATHS.sp.onboarding.profile:
        return null;
      case PATHS.sp.onboarding.key_contacts:
        return null;
      case PATHS.sp.onboarding.services:
        return null;
      case PATHS.sp.onboarding.diversity:
        return null;
      case PATHS.sp.onboarding.documents:
        return null;
      default: return null;
    }
  }, [currentPath]);

  useEffect(() => {
    if (!content && page === 0) {
      setPage(1);
    }
  }, [content, page]);

  const sidebarContent = (
    <Box ml={3} mr={3}>
      <Tabs
        value={page}
        onChange={handleChange}
        aria-label="Main Sidebar Details Tabs"
        variant="scrollable"
        scrollButtons="auto"
        classes={{ root: tabStyles.tabsRoot, flexContainer: tabStyles.tabsContainer }}
      >
        {content ? (
          <Tab
            label="Description"
            classes={{ root: tabStyles.tab }}
            {...a11yProps(0)}
          />
        ) : null}
        <Tab
          label="Checklist to complete"
          classes={{ root: tabStyles.tab }}
          {...a11yProps(1)}
        />
      </Tabs>
      {content ? (
        <TabPanel value={page} index={0}>
          <Box pt={2}>
            {content}
          </Box>
        </TabPanel>
      ) : null}
      <TabPanel value={page} index={1}>
        <Box pt={2}>
          {state ? (
            <Grid container spacing={2} flexDirection="column">
              <Grid item>
                <CompletedAlert state={state} />
              </Grid>
              <Grid item>
                <PageProgress state={state} />
              </Grid>
            </Grid>
          ) : <Loading center />}
        </Box>
      </TabPanel>
    </Box>
  );
  return (
    <Sidebar
      open={openSidebar}
      onClose={isDesktop ? undefined : () => setOpenSidebar((prev) => !prev)}
      title="Details"
      content={sidebarContent}
      disableTitleBorderBottom
    >
      {children}
    </Sidebar>
  );
};
