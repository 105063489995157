import React from 'react';
import {
  IconButton, Grid, Paper, Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Close as CloseIcon } from '@mui/icons-material';
import { ThreadHeaderProps } from 'stream-chat-react';

const getReplyCountText = (count?: number) => {
  if (!count) return '';
  if (count === 1) return '1 reply';
  return `${count} replies`;
};

const useStyles = makeStyles((theme) => createStyles({
  header: {
    padding: theme.spacing(2),
    borderRadius: 0,
    marginLeft: -1,
  },
}));

export const ThreadHeader: React.FC<ThreadHeaderProps> = ({ thread, closeThread }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.header}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography variant="h6">
            Thread
          </Typography>
          <Typography variant="body1">
            {getReplyCountText(thread.reply_count)}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={closeThread} size="large">
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  );
};
