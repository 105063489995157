import React from 'react';
import { useForm } from 'react-hook-form';
import { MultiSelectField } from '../../form/MultiSelectField';
import { SelectOption } from '../../form/SelectField';
import { getError, hasError } from '../helpers';
import { CustomFieldFormLabel } from '../CustomFieldFormLabel';
import { CustomFieldRenderComponentProps } from '../types';

interface CustomFieldMultiSelectProps extends CustomFieldRenderComponentProps {
  value: string[];
}

export const CustomFieldMultiSelect: React.FC<CustomFieldMultiSelectProps> = ({
  field,
  disabled,
  value,
  onChange,
}) => {
  const { errors } = useForm();

  return (
    <>
      <CustomFieldFormLabel field={field} checkLabelOnTop={false} />
      <MultiSelectField
        disabled={disabled}
        options={field.context.options as SelectOption[]}
        inputProps={{
          fullWidth: true,
          placeholder: field.placeholder || '',
        }}
        value={value}
        onChange={onChange}
        error={hasError(errors, field)}
        helperText={getError(errors, field)}
      />
    </>
  );
};
