import React, {
  useMemo, useState, useEffect, useCallback,
} from 'react';
import { has, flatten } from 'lodash';

import {
  Filter, isFilterAnd, isFilterOr, isFilterSimple,
} from '../types';
import { FilterWidgetProps } from './FilterWidgetProps';
import { FilterWidgetDialog } from './FilterWidgetDialog';
import { CheckboxesFilter } from './CheckboxesFilter';

export const CheckboxesFilterWidget: React.FC<FilterWidgetProps> = ({
  tableId,
  field,
  filter: externalFilter,
  filterClearedTimestamp,
  filterOptions,
  substituteOptionLabels,
  isNullEnabled,
  isNullTitle,
  logicType,
  title,
  onApply,
  onClear,
  onClose,
  open,
  apiFilterType = 'regular',
}) => {
  const initialFilter: Filter = useMemo(() => externalFilter ?? {
    field,
    type: 'checkboxes',
    logic: undefined,
    where: { in: [] },
  }, [externalFilter, field]);
  const [filter, setFilter] = useState<Filter>(initialFilter);

  useEffect(() => {
    if (filterClearedTimestamp) {
      setFilter(initialFilter);
    }
  }, [filterClearedTimestamp, initialFilter]);

  const values = useMemo((): any[] => {
    const isInFilter = isFilterSimple(filter) && filter.where.in;
    const isInFilterWithNull = (isFilterOr(filter) || isFilterAnd(filter))
      && filter.where.some((w) => has(w, 'in'))
      && filter.where.some((w) => has(w, 'isNull'));

    const isNullFilter = isFilterSimple(filter) && has(filter.where, 'isNull');

    const isJsonSimple = isFilterSimple(filter) && filter.where.jsArraySome;
    const isJsonWithNull = (isFilterOr(filter) || isFilterAnd(filter))
      && filter.where.some((w) => has(w, 'jsArraySome'))
      && filter.where.some((w) => has(w, 'isNull'));

    if (!isInFilter && !isInFilterWithNull && !isNullFilter && !isJsonSimple && !isJsonWithNull) {
      throw new Error(
        'Expected a simple `in` filter, an OR combination of `in` and `isNull` filters, or simple `isNull` filter'
        + `got ${JSON.stringify(filter)} on a field '${field}' in table with id '${tableId}'`,
      );
    }

    if (isJsonSimple) {
      return filter.where.jsArraySome as string[];
    }

    if (isJsonWithNull && isFilterOr(filter)) {
      return flatten(filter.where
        .filter((f) => has(f, 'jsonContains'))
        .map((f) => f.jsArraySome));
    }

    if (isInFilter && filter.where.in) {
      return filter.where.in;
    }
    if (isInFilterWithNull) {
      const whereIn = filter.where.find((w) => has(w, 'in'));
      return whereIn && whereIn.in ? whereIn.in : [];
    }
    return [];
  }, [field, filter, tableId]);

  const isNullChecked = useMemo((): boolean => {
    if ((isFilterOr(filter) || isFilterAnd(filter))) {
      return filter.where.find((w) => has(w, 'isNull'))?.isNull ?? false;
    }
    if (isFilterSimple(filter)) {
      return filter.where.isNull ?? false;
    }
    return false;
  }, [filter]);

  const handleSubmit = useCallback((ev) => {
    ev.preventDefault();
    onApply(field, filter);
    onClose(field);
  }, [field, filter, onApply, onClose]);

  return (
    <FilterWidgetDialog
      tableId={tableId}
      field={field}
      title={title}
      onApply={() => onApply(field, filter)}
      onClear={() => onClear(field)}
      onClose={onClose}
      open={open}
    >
      <form onSubmit={handleSubmit}>
        <CheckboxesFilter
          apiFilterType={apiFilterType}
          field={field}
          values={values}
          isNullChecked={isNullChecked}
          filterOptions={filterOptions}
          logicType={logicType}
          substituteOptionLabels={substituteOptionLabels}
          isNullEnabled={isNullEnabled}
          isNullTitle={isNullTitle}
          setFilter={setFilter}
        />
        <input type="submit" hidden />
      </form>
    </FilterWidgetDialog>
  );
};
