import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { config } from 'config';

const ENV_LABEL_HIDDEN_TIMEOUT = 300000;

const useStyles = makeStyles(() => createStyles({
  envLabel: {
    position: 'fixed',
    left: '50%',
    top: '0',
    transform: 'translateX(-50%)',
    borderRadius: '0 0 8px 8px',
    fontWeight: 'bold',
    minWidth: '75px',
    padding: '2px 6px 3px',
    lineHeight: '1em',
    fontSize: '10px',
    textTransform: 'uppercase',
    cursor: 'pointer',
    border: 'none',
    color: 'white',
  },
}),
);

export const EnvLabel: React.FC = () => {
  const classes = useStyles();

  const [isHidden, setHidden] = useState(config.env === 'prod');
  const timerRef = useRef<number | null>(null);

  useEffect(() => () => {
    if (timerRef.current) {
      window.clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  }, []);

  const handleLabelClick = useCallback(() => {
    setHidden(true);
    timerRef.current = window.setTimeout(() => setHidden(false), ENV_LABEL_HIDDEN_TIMEOUT);
  }, []);

  const getBackgroundColor = () => {
    switch (config.env) {
      case 'local': return '#f08842';
      case 'stg': return '#65b0b8';
      case 'demo': return '#6ac247';
      default: return '#b06d91';
    }
  };

  if (isHidden) return null;

  return (
    <button
      type="button"
      className={classes.envLabel}
      style={{ backgroundColor: getBackgroundColor() }}
      onClick={handleLabelClick}
    >
      {config.env}
    </button>
  );
};
