import React from 'react';
import { Grid } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => createStyles({
  spacer: {
    flexGrow: 1,
  },
}));

export interface ActionsListProps {
  // Note that the length must be constant and elements must not switch places,
  // because array indexes are used as keys.
  // Use `null` to hide an element.
  actions: React.ReactNode[];
  actionsRight?: React.ReactNode[];
}

export const ActionsList: React.FC<ActionsListProps> = ({
  actions,
  actionsRight,
}) => {
  const classes = useStyles();
  if (!actions.length && !actionsRight?.length) return null;
  return (
    <Grid item container direction="row" spacing={1} alignItems="center">
      {actions.map((action, index) => (action ? (
        // eslint-disable-next-line react/no-array-index-key
        <Grid item key={index}>
          {action}
        </Grid>
      ) : null))}
      {actionsRight && (
        <>
          <Grid item className={classes.spacer}>{' '}</Grid>
          {actionsRight.map((action, index) => (action ? (
            // eslint-disable-next-line react/no-array-index-key
            <Grid item key={index}>
              {action}
            </Grid>
          ) : null))}
        </>
      )}
    </Grid>
  );
};
