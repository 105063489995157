import React, { useCallback, useState } from 'react';
import { Box, Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import {
  DocumentRepositoryDialog,
  DocumentRepositoryModalProps,
} from './DocumentRepositoryDialog';
import { FilesList, FilesListProps } from './filesList/FilesList';

export type AttachmentsSectionProps =
  Pick<DocumentRepositoryModalProps, 'onFilesUploaded'>
  & Pick<FilesListProps, 'context' | 'getFileBadges' | 'editable'>
  & { allowToAddDocusignTemplates?: boolean };

/**
 * Helper attachment section to easily add attaching files for a specific context
 */
export const AttachDocumentsSection: React.FC<AttachmentsSectionProps> = ({
  context,
  onFilesUploaded,
  getFileBadges,
  editable,
  allowToAddDocusignTemplates,
}) => {
  const [dialogVisible, setDialogVisible] = useState(false);

  const handleOpen = useCallback(() => {
    setDialogVisible(true);
  }, []);

  const handleClose = useCallback(() => {
    setDialogVisible(false);
  }, []);

  return (
    <>
      <Box my={2}>
        <FilesList
          editable={editable}
          context={context}
          getFileBadges={getFileBadges}
        />
      </Box>
      {editable && (
        <Box mb={2}>
          <Button
            onClick={handleOpen}
            variant="outlined"
            startIcon={<Add />}
          >
            Add files
          </Button>
        </Box>
      )}
      <DocumentRepositoryDialog
        uploadContext={context}
        onFilesUploaded={onFilesUploaded}
        open={dialogVisible}
        onClose={handleClose}
        allowToAddDocusignTemplates={allowToAddDocusignTemplates}
      />
    </>
  );
};
