import { useCallback } from 'react';
import { Permissions } from '../../api/permissions/permissions';
import { useProfile } from '../../api/user/profile';

export type UseUserPermissionCheckerResult = (permission: Permissions | Permissions[]) => boolean;

export const useUserPermissionChecker = (): UseUserPermissionCheckerResult => {
  const { userPermissions } = useProfile();
  return useCallback((permission) => userPermissions
    .some((p) => (Array.isArray(permission)
      ? permission.includes(p.permission_id)
      : p.permission_id === permission
    )),
  [userPermissions],
  );
};
