import React from 'react';
import { ChannelPreviewProps, ChannelPreview as StreamChannelPreview } from 'stream-chat-react';
import { ChannelPreviewMessenger } from './ChannelPreviewMessenger';

export const ChannelPreview: React.FC<ChannelPreviewProps> = (props) => (
  <StreamChannelPreview
    {...props}
    Preview={ChannelPreviewMessenger as any}
  />
);
