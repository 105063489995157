import { UserResponse } from 'stream-chat';
import { ChatGenerics } from './streamTypes';

export const ProcBots = Object.freeze({
  Global: 'procursys-bot',
  FM: 'procursys-fm-bot',
  SP: 'procursys-sp-bot',
});

export const getIsMessageBot = (user: UserResponse<ChatGenerics>): boolean => (
  [ProcBots.FM, ProcBots.SP, ProcBots.Global].includes(user.id)
);
