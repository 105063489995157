import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Button, Grid } from '@mui/material';
import { FieldValues, useForm } from 'react-hook-form';
import { cloneDeep, get } from 'lodash';
import { ErrorMessage } from '../notifications/ErrorMessage';
import { SuccessSnackbar } from '../notifications/SuccessSnackbar';
import { getFieldName, getValidationParams } from './helpers';
import { CustomFieldFormHeader } from './CustomFieldFormHeader';
import { CustomFieldFormRender } from './CustomFieldFormRender';
import { CustomFormTypes } from './const';
import {
  CustomFieldFormColumns,
  CustomFieldFormOptions, CustomFieldType, CustomFieldTypes, CustomFieldValueTypes,
} from './types';
import { useCustomFieldRequest } from './requests';
import { useHookSubPageControls } from '../organization/sp/onboarding/useHookSubPageControls';

/**
 * If you want to add new Entities with specific logic, look at types, requests, helpers
 */

export interface CustomFieldFormProps {
  mode?: 'integrated' | 'standalone';
  targetOrganizationID: number;
  fieldArray: CustomFieldTypes;
  fieldValues: CustomFieldValueTypes;
  type: CustomFormTypes;
  formDisabled: boolean;
  options?: CustomFieldFormOptions;
  columns: CustomFieldFormColumns;
}

/**
 * Universal form for custom fields
 * @param props
 * @constructor
 */
export const CustomFieldForm: React.FC<CustomFieldFormProps> = ({
  mode,
  targetOrganizationID,
  options,
  fieldArray,
  fieldValues,
  type,
  formDisabled,
  columns,
}) => {
  const [formFieldValues, setFormFieldValues] = useState<FieldValues>({});
  const [error, setError] = useState<string>();
  const [successMessageOpen, setSuccessMessageOpen] = useState(false);

  // Queries and mutations
  // ===============================================================================================
  const {
    update: updateRequest,
    error: requestError,
    loading: requestLoading,
  } = useCustomFieldRequest({
    type,
    targetOrganizationID,
    options,
    fieldArray,
    fieldValues: formFieldValues,
  });

  const {
    register,
    unregister,
    setValue,
    errors,
    handleSubmit,
    triggerValidation,
    formState,
  } = useForm();

  const handleFormSubmit = useMemo(() => handleSubmit(async (): Promise<any> => {
    if (formDisabled) {
      return null;
    }

    try {
      const res = await updateRequest();
      setSuccessMessageOpen(true);
      return res;
    } catch (e: any) {
      setError(e.message ?? e.toString());
    }
    return null;
  }), [handleSubmit, formDisabled, updateRequest]);

  const getState = useCallback(() => {
    if (requestLoading) {
      return undefined;
    }
    const businessField = (fieldArray as Array<{ name: string, field_id: number }>)
      .find((f) => f.name === 'Classifications');
    const businessValue = businessField ? (formFieldValues as any)[`field_${businessField?.field_id}`] : null;

    const yourCompanyField = (fieldArray as Array<{ name: string, field_id: number }>)
      .find((f) => f.name === 'If your co'
        + 'mpany is at least 51 percent owned, controlled and operated by one or more of the following, please check all that apply:');
    const yourCompanyValue = businessField ? (formFieldValues as any)[`field_${yourCompanyField?.field_id}`] : null;
    return [
      {
        sectionName: 'Diversity',
        steps: [
          {
            name: 'Classifications',
            description: 'Select at least one value',
            progress: businessValue?.length ? 1 : 0,
          },
          {
            name: 'Company ownership',
            description: 'Select at least one value',
            progress: yourCompanyValue?.length ? 1 : 0,
          },
        ],
      },
    ];
  }, [requestLoading, fieldArray, formFieldValues]);
  useHookSubPageControls(mode ?? 'standalone', {
    dirty: formState.dirty,
    submitForm: useCallback(async () => {
      let outerRes: any = null;
      await handleSubmit(async (): Promise<any> => {
        if (formDisabled) {
          return null;
        }
        try {
          const res = await updateRequest();
          setSuccessMessageOpen(true);
          outerRes = res;
          return res;
        } catch (e: any) {
          setError(e.message ?? e.toString());
          return null;
        }
      })();
      return outerRes;
    }, [updateRequest, handleSubmit, formDisabled]),
    validateForm: useCallback(async () => {
      const fields = fieldArray.map((field) => getFieldName(field));
      const result = await triggerValidation(fields);
      return result ? {} : { error: result };
    }, [fieldArray, triggerValidation]),
  }, getState);

  // Handlers and events
  // ===============================================================================================
  const setFieldToForm = useCallback(async (
    fieldName: string,
    value: any,
  ) => {
    setFormFieldValues((vs) => {
      const newVs = cloneDeep(vs);
      newVs[fieldName] = value;
      return newVs;
    });
    setValue(fieldName, value);
    await triggerValidation(fieldName);
  }, [setValue, triggerValidation]);

  const closeSuccessMessage = (event: any, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessMessageOpen(false);
  };

  const handleFieldSet = (field: CustomFieldType, value: any) => {
    const fieldName = getFieldName(field);
    setFieldToForm(fieldName, value);
  };

  // Effects
  // ===============================================================================================
  useEffect(() => {
    const fieldNameMap = {
      ['If your company is at least 51 percent owned, controlled and operated by'
      + ' one or more of the following, please check all that apply:']: 'Company ownership',
    };

    if (fieldArray.length > 0) {
      const fieldNames: string[] = [];
      fieldArray.forEach((field) => {
        const fieldServiceID = get(field, 'service_id');
        const optionsServiceID = get(options, 'serviceId');

        if (type === CustomFormTypes.FMSP
          || (type === CustomFormTypes.SERVICE && fieldServiceID === optionsServiceID)
        ) {
          const name = getFieldName(field);
          const rules = getValidationParams(field, fieldNameMap[field.name]);
          if ((field as any).is_required && field.type === 'multiCheckbox') {
            rules.validate = (data: Array<string | null>) => (
              data.length ? true : `${
                fieldNameMap[field.name] ?? field.name
              } is required`
            );
          }
          register(name, rules);
          fieldNames.push(name);
          const val = fieldValues
            .find((fv) => getFieldName(fv.field) === name)?.value;
          setValue(name, val);
        }
      });
      return () => unregister(fieldNames);
    }
    return () => { };
  }, [fieldArray, fieldValues, register, options, setValue, unregister, type]);

  useEffect(() => {
    if (fieldValues.length > 0) {
      fieldValues.forEach((value) => {
        setFieldToForm(getFieldName(value.field), value.value);
      });
    }
  }, [fieldValues, setFieldToForm]);

  return (
    <>
      <CustomFieldFormHeader
        // isDirty={isDirty}
        type={type}
        options={options}
        disabled={formDisabled}
      />
      <Grid
        container
        component="form"
        onSubmit={handleFormSubmit}
        spacing={2}
      >
        <Grid item xs={12}>
          <Grid container direction="column" spacing={2}>
            {fieldArray.map((field) => CustomFieldFormRender({
              field,
              disabled: formDisabled,
              fieldValues: formFieldValues,
              onChange: handleFieldSet,
              columns,
              error: (errors[getFieldName(field)] as any)?.message,
            }))}
          </Grid>
        </Grid>
        {(error || requestError) && (
          <Grid item>
            <ErrorMessage devMessage={error || requestError?.message}>
              Failure on information update
            </ErrorMessage>
          </Grid>
        )}
        {mode === 'integrated' ? null : (
          <Grid item>
            <Button
              type="submit"
              color="primary"
              disabled={requestLoading || formDisabled}
            >
              Save Changes
            </Button>
          </Grid>
        )}
      </Grid>
      <SuccessSnackbar open={successMessageOpen} onClose={closeSuccessMessage}>
        Updated successfully
      </SuccessSnackbar>
    </>
  );
};
