import React from 'react';
import { generatePath } from 'react-router-dom';
import { Tooltip, IconButton, Grid } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Launch } from '@mui/icons-material';

import { LOCAL_URL_PREFIX } from 'App';
import { PATHS } from 'paths';
import { useProfile } from 'api/user/profile';

export interface ChatSidebarTitleProps {
  title: string;
  channelId: string;
}

const useStyles = makeStyles((theme) => createStyles({
  title: {
    [theme.breakpoints.down('md')]: {
      maxWidth: 'calc(100vw - 150px)',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '340px',
    },
    lineHeight: 2.4,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

export const ChatSidebarTitle: React.FC<ChatSidebarTitleProps> = ({ title, channelId }) => {
  const classes = useStyles();
  const { organization } = useProfile();
  const viewer = organization.is_facility_manager ? 'fm' : 'sp';

  const redirectPath = generatePath(
    viewer === 'fm'
      ? PATHS.fm.chatChannel
      : PATHS.sp.chatChannel,
    { channelId },
  );

  return (
    <Grid container spacing={2}>
      <Grid
        item
        className={classes.title}
      >
        <Tooltip title={title} arrow placement="bottom">
          <span>{title}</span>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip title="Open this thread in the messages page" arrow placement="bottom">
          <span>
            <IconButton
              edge="end"
              onClick={() => {
                window.open(`${window.location.origin}${LOCAL_URL_PREFIX}${redirectPath}`);
              }}
              size="large"
            >
              <Launch />
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
    </Grid>
  );
};
