import L from 'leaflet';
import {
  Feature, GeoJsonObject, MultiPolygon, Polygon,
} from 'geojson';
import { colors } from '../../theme';
import { isMultiPolygon, isPolygon } from '../utils/geojson';

export const AREA_STYLE_NORMAL = {
  color: colors.blue,
  weight: 1,
  opacity: 0.4,
  fillOpacity: 0.3,
};

export const AREA_STYLE_SATELLITE = {
  color: colors.yellowGreen,
  weight: 1,
  opacity: 0.9,
  fillOpacity: 0.4,
};

interface PostalCodeGeoJSONFeatureProperties {
  code: string;
}

export function createLayer(geoJson: GeoJsonObject, code: string): L.GeoJSON {
  if (isPolygon(geoJson)) {
    const feature: Feature<Polygon, PostalCodeGeoJSONFeatureProperties> = {
      type: 'Feature',
      properties: { code },
      geometry: geoJson,
    };
    return L.geoJSON(feature);
  }
  if (isMultiPolygon(geoJson)) {
    const feature: Feature<MultiPolygon, PostalCodeGeoJSONFeatureProperties> = {
      type: 'Feature',
      properties: { code },
      geometry: geoJson,
    };
    return L.geoJSON(feature);
  }
  throw new Error(`Unexpected shape type for code '${code}': ${geoJson.type} - expected Polygon or MultiPolygon`);
}

export function addAreasLayer(map: L.Map): L.GeoJSON {
  const areasLayer = L.geoJSON();
  map.addLayer(areasLayer);
  areasLayer.setStyle(AREA_STYLE_NORMAL);
  return areasLayer;
}
