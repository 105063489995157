import React, { useState } from 'react';
import { Button } from '@mui/material';
import { InviteModal } from './InviteModal';
import { useProfile } from '../../../../api/user/profile';
import { InvitesTable } from './InvitesTable';

export const InvitesPage = () => {
  const profile = useProfile();
  const [openInviteModal, setOpenInviteModal] = useState<boolean>(false);

  return (
    <>
      <InvitesTable
        currentUserId={profile.user_id}
        afterFilters={(
          <Button color="primary" onClick={() => setOpenInviteModal(true)}>
            Invite new members by email
          </Button>
        )}
      />
      <InviteModal
        open={openInviteModal}
        onClose={() => setOpenInviteModal(false)}
      />
    </>
  );
};
