import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Box, Chip, Grid, Paper, Popover, Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { FileError, useDropzone } from 'react-dropzone';
import { take } from 'lodash';

import {
  FILE_TYPES_ALL,
  FILE_TYPES_ALL_STRING,
  FILE_TYPES_IMAGES,
  FILE_TYPES_IMAGES_STRING,
} from 'utils/files';
import { bytesToMb } from 'utils/number';
import { colors } from 'components/theme';
import { ErrorSnackbar, useErrorSnackbar } from 'components/notifications/ErrorSnackbar';
import { MAX_UPLOAD_SIZE, MAX_FILES_COUNT } from 'components/user/constants';

import { InlineTextFieldForm } from './InlineTextFieldForm';

const useStyles = makeStyles(() => createStyles({
  dropZone: {
    backgroundColor: colors.bgGrey,
    cursor: 'pointer',
  },
}));

export interface FileDropZoneProps {
  type: 'images' | 'all',
  maxSize?: number; // in bytes
  maxFiles?: number;
  filesInProgress: File[] | undefined;
  setFilesInProgress: React.Dispatch<React.SetStateAction<File[] | undefined>>;
}

export const FileDropZone: React.FC<FileDropZoneProps> = ({
  type,
  maxSize = MAX_UPLOAD_SIZE,
  maxFiles = MAX_FILES_COUNT,
  filesInProgress,
  setFilesInProgress,
}) => {
  const classes = useStyles();

  const acceptedTypes = type === 'images' ? FILE_TYPES_IMAGES : FILE_TYPES_ALL;
  const acceptedTypesString = type === 'images' ? FILE_TYPES_IMAGES_STRING : FILE_TYPES_ALL_STRING;

  const validator = useCallback((file: File): FileError | FileError[] | null => {
    if (!acceptedTypes.includes(file.type)) {
      return {
        code: 'file-invalid-type',
        message: `You are trying to upload a file of type "${file.type}". Only files of these types are accepted: ${acceptedTypesString}`,
      };
    }
    if (file.size >= maxSize) {
      return {
        code: 'file-too-large',
        message: `File is too large (${bytesToMb(file.size)}MB). Max file size is ${bytesToMb(maxSize)}MB`,
      };
    }
    return null;
  }, [acceptedTypes, acceptedTypesString, maxSize]);

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: acceptedTypes,
    multiple: maxFiles > 1,
    maxFiles,
    maxSize,
    validator,
  });
  const { ref, ...rootProps } = getRootProps({ className: classes.dropZone });

  useEffect(() => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      setFilesInProgress((prev) => (
        prev
          ? take(acceptedFiles.concat(prev), maxFiles)
          : acceptedFiles
      ));
    }
  }, [acceptedFiles, maxFiles, setFilesInProgress]);

  const handleDelete = useCallback((file: File) => {
    setFilesInProgress((prev) => (prev ? prev.filter((f) => f !== file) : prev));
  }, [setFilesInProgress]);

  const [renamingFile, setRenamingFile] = useState<File>();
  const [renamingAnchorEl, setRenamingAnchorEl] = React.useState<HTMLElement | null>(null);

  const openPopover = (event: React.MouseEvent<any>, file: File) => {
    setRenamingAnchorEl(event.currentTarget);
    setRenamingFile(file);
  };

  const closePopover = () => {
    setRenamingAnchorEl(null);
  };

  const sizeRejectedFiles = useMemo(
    () => fileRejections
      .filter((r) => r.errors.find((e) => e.code === 'file-too-large'))
      .map((r) => r.file.name),
    [fileRejections],
  );

  const fileSizeRejectionError = useMemo(() => {
    if (sizeRejectedFiles.length > 0) {
      return new Error(`These files exceed maximum size limit: ${sizeRejectedFiles.join(', ')}`);
    }
    return null;
  }, [sizeRejectedFiles]);

  const fileSizeRejectionErrorSnackbar = useErrorSnackbar(fileSizeRejectionError);

  return (
    <>
      <Paper {...rootProps}>
        <Box padding={3}>
          <input {...getInputProps()} />
          <Typography variant="body1" color="textSecondary" align="center">
            Drag&apos;n&apos;drop a file here, or click to select a file from your computer
          </Typography>
          <Typography variant="body2" color="textSecondary" align="center">
            (Only files of types
            {' '}
            {acceptedTypesString}
            {' '}
            will be accepted. Max file size is
            {' '}
            {bytesToMb(maxSize)}
            MB)
          </Typography>
        </Box>
      </Paper>
      {filesInProgress && filesInProgress.length > 0 && (
        <Box mt={1} mb={2}>
          <Grid
            container
            direction="row"
            spacing={1}
            justifyContent="flex-start"
            alignItems="center"
            alignContent="center"
          >
            {filesInProgress.map((f) => (
              <Grid item key={f.name + f.size}>
                <Chip
                  label={`${f.name} (${bytesToMb(f.size)}MB)`}
                  onDelete={() => handleDelete(f)}
                  clickable
                  onClick={(e) => openPopover(e, f)}
                />
              </Grid>
            ))}
            {filesInProgress.length && (
              <Grid item>
                <Typography variant="body2" color="textSecondary">
                  (click to rename)
                </Typography>
              </Grid>
            )}
          </Grid>
        </Box>
      )}
      <Popover
        open={!!renamingAnchorEl && !!renamingFile}
        anchorEl={renamingAnchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box padding={1}>
          <Typography variant="body2" color="textSecondary">
            Renaming file:
            {' '}
            <Typography component="span" variant="inherit" color="textPrimary">
              {renamingFile?.name}
            </Typography>
          </Typography>
          <InlineTextFieldForm
            size="small"
            initialValue={renamingFile?.name}
            maxLength={255}
            style={{ width: 200 }}
            onSubmit={(v) => {
              if (renamingFile && v !== undefined) {
                const newFile = new File(
                  [renamingFile],
                  v,
                  { type: renamingFile.type },
                );
                setFilesInProgress((prev) => {
                  if (prev) {
                    return prev
                      .filter((fip) => !(
                        fip.name === renamingFile.name && fip.type === renamingFile.type
                      ))
                      .concat([newFile]);
                  }
                  return prev;
                });
              }
              setRenamingFile(undefined);
              closePopover();
            }}
            onCancel={() => {
              setRenamingFile(undefined);
              closePopover();
            }}
          />
        </Box>
      </Popover>
      <ErrorSnackbar {...fileSizeRejectionErrorSnackbar}>
        Some files you are trying to upload are too large:
        {' '}
        {sizeRejectedFiles.join(', ')}
        .
      </ErrorSnackbar>
    </>
  );
};
