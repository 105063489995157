import { useMemo } from 'react';
import logoWhite from 'assets/logo-white.png';
import logoIndependencyWhite from 'assets/logo-independency-white.png';
import logoHalloweenWhite from 'assets/logo-halloween-white.png';
import logo from 'assets/logo-color.png';
import logoIndependency from 'assets/logo-independency.png';
import logoHalloween from 'assets/logo-halloween.png';
import logoThanksGiving from 'assets/logo-thanksgiving.png';
import logoThanksGivingWhite from 'assets/logo-thanksgiving-white.png';

function createDateOfCurrentYear(dateOrigin: string) {
  const date = new Date(dateOrigin);
  date.setFullYear(new Date().getFullYear());
  return date;
}

export default function useEasterEggs(logoColor?: 'color' | 'white') {
  const isChristmasEgg = useMemo(() => {
    const now = new Date();
    const force = localStorage.getItem('christmas');
    return Boolean(force || (now > createDateOfCurrentYear('2022-12-01') && now < createDateOfCurrentYear('2022-12-31')));
  }, []);

  const isIndependencyEgg = useMemo(() => {
    const now = new Date();
    const force = localStorage.getItem('independence');
    return Boolean(force || (now > createDateOfCurrentYear('2021-06-27') && now < createDateOfCurrentYear('2021-07-08')));
  }, []);

  const isThanksGivingEgg = useMemo(() => {
    const now = new Date();
    const force = localStorage.getItem('thanksgiving');
    return Boolean(force || (now > createDateOfCurrentYear('2021-11-12') && now < createDateOfCurrentYear('2021-12-01')));
  }, []);

  const isHalloweenEgg = useMemo(() => {
    const now = new Date();
    const force = localStorage.getItem('halloween');
    return Boolean(force || (now > createDateOfCurrentYear('2023-10-15') && now < createDateOfCurrentYear('2023-11-01')));
  }, []);

  const appLogo = useMemo(() => {
    if (isIndependencyEgg) {
      return logoColor === 'white' ? logoIndependencyWhite : logoIndependency;
    }
    if (isHalloweenEgg) {
      return logoColor === 'white' ? logoHalloweenWhite : logoHalloween;
    }
    if (isThanksGivingEgg) {
      return logoColor === 'white' ? logoThanksGivingWhite : logoThanksGiving;
    }
    return logoColor === 'white' ? logoWhite : logo;
  }, [logoColor, isThanksGivingEgg, isIndependencyEgg, isHalloweenEgg]);

  return {
    isChristmasEgg,
    isIndependencyEgg,
    isThanksGivingEgg,
    isHalloweenEgg,
    appLogo,
  };
}
