import { gql } from '@apollo/client';
import { createContext, useContext } from 'react';
import { Profile, Profile_profile } from '../types/Profile';

export const PROFILE_QUERY = 'Profile';

export const PROFILE = gql`
query Profile {
  profile {
    user_id
    firebase_id
    login_email
    work_email
    first_name
    last_name
    phone_number
    is_system_admin
    is_login_as
    extra {
      last_service_id
    }
    userPermissions {
      region_id
      permission_id
      permission {
        name
        is_regional
      }
    }
    organization {
      name
      organization_id
      uuid
      is_service_provider
      is_facility_manager
      sp_bid_access
      sp_bid_import_access
      fm_docusign_access
      is_deactivated
      logo_url
      qbo_id
      services {
        service_id
        name
        slug
        icon
      }
      address {
        google_place_id
        latlong
      }
      stripe_subscription_id
      subscription {
        id
        status
      }
      onboarding {
        visited
        profile_percentage
        key_contacts_percentage
        services_percentage
        diversity_percentage
        documents_percentage
      }
    }
    approved_on
    approval {
      approval_id
      reviewed_on
      is_approved
      other_info
      rejection_note
    }
    emailVerification {
      is_verified
    }
    is_deactivated
    deactivated_on
  }
}
`;

export const ProfileContext = createContext<Profile | undefined>(undefined);

export const useProfile = (): Profile_profile => {
  const ctx = useContext(ProfileContext);
  if (ctx === undefined) {
    throw new Error('Profile is undefined in a context');
  }
  return ctx.profile;
};
