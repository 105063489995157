import React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';

export interface CustomCellProps extends GridCellProps {
  children: (item: any) => React.ReactNode;
}

export const CustomCell: React.FC<CustomCellProps> = ({
  colSpan,
  className,
  ariaColumnIndex,
  isSelected,
  columnIndex,
  dataItem,
  children,
}) => (
  <td
    colSpan={colSpan}
    role="gridcell"
    className={className}
    aria-colindex={ariaColumnIndex}
    aria-selected={isSelected}
    data-grid-col-index={columnIndex}
  >
    {children(dataItem)}
  </td>
);
