import React, { useMemo } from 'react';
import { Alert, CircularProgress, Grid } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { PageProgressState } from './PageProgress.types';

const useStyles = makeStyles(() => createStyles({
  alert: {
    backgroundColor: '#5FCC2C1A', border: 'none',
  },
  message: {
    flex: 1,
  },
  title: {
    margin: '0',
  },
}));

export const CompletedAlert = ({ state }: { state: PageProgressState }) => {
  const classes = useStyles();
  const total = useMemo(() => state.reduce((acc, section) => (
    acc + section.steps.length
  ), 0), [state]);
  const completed = useMemo(() => state.reduce((acc, section) => (
    acc + section.steps.filter((s) => s.progress === 1).length
  ), 0), [state]);
  return (
    <Alert
      severity="success"
      variant="outlined"
      icon={false}
      classes={{ root: classes.alert, message: classes.message }}
    >
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        flexDirection="row"
        flexWrap="nowrap"
      >
        <Grid item container spacing={2} flexDirection="row" flexShrink={1} alignItems="center">
          <Grid item style={{ margin: '2px 0 -2px' }}>
            <CircularProgress
              color="success"
              value={60}
              size={21}
              variant="determinate"
            />
          </Grid>
          <Grid item>
            <h3 className={classes.title}>
              Completed
            </h3>
          </Grid>
        </Grid>
        <Grid item justifyContent="flex-end" flex={1} style={{ textAlign: 'right' }}>
          <h3 className={classes.title}>
            <b>
              {completed}
            </b>
            /
            {total}
          </h3>
        </Grid>
      </Grid>
    </Alert>
  );
};
