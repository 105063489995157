import { isArray, toString as _toString, isNil } from 'lodash';
import { WhereOperationsInput } from 'api/types/globalTypes';
import { isoDateTimeToLocale, isoDateToLocate } from 'utils/datetime';
import {
  Filter,
  FilterAnd,
  FilterOption,
  FilterOr,
  FilterSimple,
  FilterType,
  isFilterAnd,
  isFilterOr,
  isWithOptions,
} from './types';

function simplifyCondition(condition: WhereOperationsInput): WhereOperationsInput | null {
  // Prevent empty arrays passed as an argument with the 'in' operator
  if (condition.in && isArray(condition.in) && condition.in.length === 0) {
    return null;
  }
  return condition;
}

function convertCondition(fieldPath: string[], condition: WhereOperationsInput): object {
  const [first, ...rest] = fieldPath;
  if (rest.length === 0) {
    return {
      [first]: condition,
    };
  }
  return {
    [first]: convertCondition(rest, condition),
  };
}

export function convertToApiFilter(filter: Filter): object | null {
  if (isFilterAnd(filter) || isFilterOr(filter)) {
    const simplifiedConditions = filter.where
      .map(simplifyCondition)
      .filter((c) => !!c) as WhereOperationsInput[];
    if (simplifiedConditions.length > 1) {
      return {
        [filter.logic]: simplifiedConditions.map((condition) => convertCondition(
          filter.field.split('.'),
          condition,
        )),
      };
    }
    if (simplifiedConditions.length === 1) {
      return convertCondition(
        filter.field.split('.'),
        simplifiedConditions[0],
      );
    }
    return null;
  }
  const simplifiedCondition = simplifyCondition(filter.where);
  if (simplifiedCondition) {
    return convertCondition(
      filter.field.split('.'),
      simplifiedCondition,
    );
  }
  return null;
}

export function convertToDisplayFilter(filter: Filter): Filter | null {
  if (isFilterAnd(filter) || isFilterOr(filter)) {
    const simplifiedConditions = filter.where.map(simplifyCondition).filter((c) => !!c);
    if (simplifiedConditions.length > 1) {
      return {
        logic: filter.logic,
        field: filter.field,
        type: filter.type,
        where: simplifiedConditions,
      } as FilterAnd | FilterOr;
    }
    if (simplifiedConditions.length === 1) {
      return {
        logic: undefined,
        field: filter.field,
        type: filter.type,
        where: simplifiedConditions[0],
      } as FilterSimple;
    }
    return null;
  }
  const simplifiedCondition = simplifyCondition(filter.where);
  if (simplifiedCondition) {
    return {
      logic: undefined,
      field: filter.field,
      type: filter.type,
      where: simplifiedCondition,
    };
  }
  return null;
}

function toString(
  value: any | null | undefined,
  filterType: FilterType,
  filterOptions?: FilterOption[],
) {
  if (value === null) {
    return 'null';
  }
  if (value === undefined) {
    return 'undefined';
  }
  if (filterType === 'date') {
    return isoDateToLocate(value);
  }
  if (filterType === 'datetime') {
    return isoDateTimeToLocale(value);
  }
  if (isWithOptions(filterType) && filterOptions) {
    // Make sure to compare strings in case of unexpected type conversions
    const v = _toString(value);
    return _toString(
      filterOptions.find((o) => _toString(o.key) === v)?.label ?? value,
    );
  }
  if (filterType === 'string') {
    return `"${_toString(value)}"`;
  }
  return _toString(value);
}

/**
 * Convert filter operator to a human-readable string.
 */
export function filterConditionToString(
  where: WhereOperationsInput,
  filterType: FilterType,
  filterOptions?: FilterOption[],
  isNullTitle?: string,
): [string, ...string[]] {
  const nullTitle = isNullTitle ?? 'null';
  if (!isNil(where.eq)) {
    if (where.eq === '') { // special case - empty string
      return ['is empty'];
    }
    return ['=', toString(where.eq, filterType, filterOptions)];
  }
  if (!isNil(where.not)) {
    if (where.not === '') { // special case - empty string
      return ['is not empty'];
    }
    return ['≠', toString(where.not, filterType, filterOptions)];
  }
  if (where.isNull === true) {
    return [`is ${nullTitle}`];
  }
  if (where.isNull === false) {
    return [`is not ${nullTitle}`];
  }
  if (!isNil(where.lt)) {
    return ['<', toString(where.lt, filterType)];
  }
  if (!isNil(where.lte)) {
    return ['≤', toString(where.lte, filterType)];
  }
  if (!isNil(where.gt)) {
    return ['>', toString(where.gt, filterType)];
  }
  if (!isNil(where.gte)) {
    return ['≥', toString(where.gte, filterType)];
  }
  if (!isNil(where.startsWith)) {
    return ['starts with', toString(where.startsWith, filterType)];
  }
  if (!isNil(where.endsWith)) {
    return ['ends with', toString(where.endsWith, filterType)];
  }
  if (!isNil(where.contains)) {
    return ['contains', toString(where.contains, filterType)];
  }
  if (!isNil(where.notContains)) {
    return ['does not contain', toString(where.notContains, filterType)];
  }
  if (!isNil(where.between)) {
    return ['is between', ...where.between.map((f) => toString(f, filterType))];
  }
  if (!isNil(where.notBetween)) {
    return ['is not between', ...where.notBetween.map((f) => toString(f, filterType))];
  }
  if (!isNil(where.in)) {
    return ['is in', ...where.in.map((f) => toString(f, filterType, filterOptions))];
  }

  if (!isNil(where.jsArrayAll)) {
    if (!where.jsArrayAll.length) { // special case - empty string
      return ['is empty'];
    }
    return ['=', ...where.jsArrayAll.map((v) => toString(v, filterType, filterOptions))];
  }

  if (!isNil(where.jsArrayNotAll)) {
    if (!where.jsArrayNotAll.length) { // special case - empty string
      return ['is empty'];
    }
    return ['is not in', ...where.jsArrayNotAll.map((v) => toString(v, filterType, filterOptions))];
  }

  // JSON operators, most of them repeating existing regular operators
  if (!isNil(where.jsArrayIncludes)) {
    if (where.jsArrayIncludes === '') { // special case - empty string
      return ['is empty'];
    }
    return ['=', toString(where.jsArrayIncludes, filterType, filterOptions)];
  }
  if (!isNil(where.jsArrayNotIncludes)) {
    if (where.jsArrayNotIncludes === '') { // special case - empty string
      return ['is not empty'];
    }
    return ['≠', toString(where.jsArrayNotIncludes, filterType, filterOptions)];
  }
  if (!isNil(where.jsArraySome)) {
    return ['is in', ...where.jsArraySome.map((f) => toString(f, filterType, filterOptions))];
  }
  if (!isNil(where.jsArrayNotSome)) {
    return ['≠', ...where.jsArrayNotSome.map((f) => toString(f, filterType, filterOptions))];
  }
  if (!isNil(where.jsArrayStartsWith)) {
    return ['starts with', toString(where.jsArrayStartsWith, filterType)];
  }
  if (!isNil(where.jsArrayEndsWith)) {
    return ['ends with', toString(where.jsArrayEndsWith, filterType)];
  }
  if (!isNil(where.jsArrayMatch)) {
    return ['contains', toString(where.jsArrayMatch, filterType)];
  }
  if (!isNil(where.jsArrayNotMatch)) {
    return ['does not contain', toString(where.jsArrayNotMatch, filterType)];
  }

  const op = Object.keys(where)[0] as keyof WhereOperationsInput;
  const values: any | any[] = where[op];
  if (isArray(values)) {
    return [op, ...(values.map((f) => toString(f, filterType, filterOptions)))];
  }
  return [op, toString(values, filterType, filterOptions)];
}
