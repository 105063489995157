import React from 'react';
import AttachmentIcon from '@mui/icons-material/InsertDriveFile';
import { createStyles, makeStyles } from '@mui/styles';
import { SvgIcon } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '../../theme';
import { FileType } from '../../../api/types/globalTypes';

const useStyles = makeStyles(() => createStyles({
  image: {
    width: 36,
    height: 25,
    borderRadius: 3,
    objectFit: 'cover',
  },
  fileIcon: {
    color: colors.blue,
  },
}));

export interface FilePreviewProps {
  type: FileType;
  file: File;
}

export const FileListPreview: React.FC<FilePreviewProps> = ({
  type,
  file,
}) => {
  const classes = useStyles();
  if (type === FileType.DocuSignTemplate) {
    return (
      <SvgIcon>
        <FontAwesomeIcon icon="signature" />
      </SvgIcon>
    );
  }

  if (file.type?.startsWith('image/')) {
    return (
      <img alt={file.name} className={classes.image} src={URL.createObjectURL(file)} />
    );
  }

  return (
    <AttachmentIcon className={classes.fileIcon} fontSize="inherit" />
  );
};
