import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import { useChatContext } from '../ChatContext';

export const EmptyStateIndicator: React.FC = () => {
  const { filters: { filters } } = useChatContext();
  const activeFiltersCount = useMemo(() => Object.values(filters).filter(Boolean).length,
    [filters]);

  return (
    <Typography variant="body1">
      {activeFiltersCount
        ? 'No rooms found matching active filter'
        : 'You have no rooms currently'}
    </Typography>
  );
};
