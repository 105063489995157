import { svgPathData as faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt';
import { svgPathData as faHome } from '@fortawesome/free-solid-svg-icons/faHome';
import { svgPathData as faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { DivIcon } from 'leaflet';
import { colors } from '@mui/material';
import { includes } from 'lodash';

function svgIcon(
  path: string,
  color: string,
  size: [number, number],
  strokeWidth: number,
  viewBox: string,
): string {
  const params = `width="${size[0]}" height="${size[1]}" viewBox="${viewBox}"`;
  const stroke = includes(['black', '#000', '#000000'], color)
    ? '#ffffff'
    : '#000000';
  return `<svg ${params}><path d="${path}" fill="${color}" stroke="${stroke}" stroke-width="${strokeWidth}" /></svg>`;
}

export function getPinIcon(color: string): DivIcon {
  return new DivIcon({
    html: svgIcon(faMapMarkerAlt, color, [22, 30], 10, '-10 -10 440 600'),
    iconSize: [20, 25],
    iconAnchor: [10, 25],
    className: '',
  });
}

export function getHomeIcon(color: string): DivIcon {
  return new DivIcon({
    html: svgIcon(faHome, color, [30, 30], 10, '-10 -10 600 600'),
    iconSize: [30, 25],
    iconAnchor: [15, 20],
    className: '',
  });
}

export function getPointIcon(color: string): DivIcon {
  return new DivIcon({
    html: svgIcon(faCircle, color, [16, 16], 20, '-10 -10 600 600'),
    iconSize: [14, 14],
    iconAnchor: [7, 7],
    className: '',
  });
}

export interface MapMarker<
  ID extends string | number,
  GID extends string | number = number,
> {
  id: ID;
  groups: { id: GID, name: string, status_id?: number }[];
  lat: number;
  lng: number;
  title: string;
  /**
   * Suffix may contain things like a marker group name (e.g. a bundle name).
   * DO NOT add any separators inside of it, formatting
   * is handled in components which display markers.
   */
  titleSuffix?: string;
  orgName?: string;
  color: string;
  icon?: DivIcon;
}

export function getMapMarkerIcon<
  ID extends string | number,
  GID extends string | number = number,
>(marker: MapMarker<ID, GID>): DivIcon {
  return marker.icon ?? getPointIcon(marker.color);
}

export function getMarkerFullTitle<
  ID extends string | number,
  GID extends string | number = number,
>(marker: MapMarker<ID, GID>): string {
  return marker.title + (marker.titleSuffix ? ` - ${marker.titleSuffix}` : '');
}

export function getMarkerBundleIds<
  ID extends string | number,
  GID extends string | number = number,
>(marker: MapMarker<ID, GID>): number[] {
  return marker.groups.map(({ id }) => Number(id));
}

export const MARKER_COLORS = [
  colors.red.A700,
  colors.pink.A700,
  colors.purple.A700,
  colors.deepPurple.A700,
  colors.indigo.A700,
  colors.blue.A700,
  colors.lightBlue.A700,
  colors.cyan.A700,
  colors.teal.A700,
  colors.green.A700,
  colors.lightGreen.A700,
  colors.lime.A700,
  colors.yellow.A700,
  colors.amber.A700,
  colors.orange.A700,
  colors.deepOrange.A700,
  colors.brown.A700,
  colors.grey.A700,
  colors.blueGrey.A700,
];
