import { useCallback, useMemo, useState } from 'react';
import {
  cloneDeep, omit, values, isEmpty,
} from 'lodash';
import { Filter } from './types';
import { convertToApiFilter, convertToDisplayFilter } from './utils';

export interface UseFiltersResult {
  /**
   * Filters which can be combined using 'and' operation and sent directly to the API.
   * You don't have to covert it any further to use in API calls.
   * Note: Cases like empty 'in' conditions are handled internally,
   * and in general, these filters can be optimized for the API use.
   */
  apiFilters: any[];
  /**
   * Display filters are internal state representation which can be used
   * to render filters in the UI.
   * Note: Filters can be simplified for display purposes.
   */
  displayFilters: Filter[];
  /**
   * Internal filter state.
   * IMPORTANT: Don't use this unless you need to build some custom filters,
   * for example when you need an optimized map query.
   * In normal circumstances, use {@link apiFilters} instead.
   */
  rawFilters: { [field: string]: Filter };
  getFilter: (field: string) => Filter | undefined;
  setFilter: (field: string, filter: Filter) => void;
  clearFilter: (field: string) => void;
  clearAllFilters: () => void;
}

export function useFilters(
  tableId: string,
  defaultFilters?: { [field: string]: Filter },
): UseFiltersResult {
  const storageKey = useMemo(() => `${tableId}.filters`, [tableId]);

  const storedValue: { [field: string]: Filter } | undefined = useMemo(() => {
    const stringValue = window.localStorage.getItem(storageKey);
    if (stringValue && stringValue !== 'undefined') {
      return JSON.parse(stringValue);
    }
    return undefined;
  }, [storageKey]);

  const [filters, setFilters] = useState<{ [field: string]: Filter }>(
    (isEmpty(storedValue) ? undefined : storedValue) ?? defaultFilters ?? {},
  );

  const rawFilters = useMemo(() => cloneDeep(filters), [filters]);

  const getFilter = useCallback((field: string) => filters[field], [filters]);

  const setFilter = useCallback((
    field: string,
    filter: Filter,
  ) => setFilters((prev) => {
    const newValue = {
      ...prev,
      [field]: filter,
    };
    window.localStorage.setItem(storageKey, JSON.stringify(newValue));
    return newValue;
  }), [storageKey, setFilters]);

  const clearFilter = useCallback((field: string) => setFilters((prev) => {
    const newValue = omit(prev, field);
    window.localStorage.setItem(storageKey, JSON.stringify(newValue));
    return newValue;
  }), [storageKey, setFilters]);

  const clearAllFilters = useCallback(() => setFilters(() => {
    window.localStorage.setItem(storageKey, JSON.stringify({}));
    return {};
  }), [storageKey, setFilters]);

  const apiFilters = useMemo(
    () => Object
      .keys(filters)
      .map((field) => (filters[field] ? convertToApiFilter(filters[field]) : null))
      .filter((f) => !!f),
    [filters],
  );

  const displayFilters = useMemo(
    () => values(filters)
      .map(convertToDisplayFilter)
      .filter((f) => !!f) as Filter[],
    [filters],
  );

  return {
    rawFilters,
    apiFilters,
    displayFilters,
    getFilter,
    setFilter,
    clearFilter,
    clearAllFilters,
  };
}
