import { gql } from '@apollo/client';

export enum FmSpLevelsEnum {
  Favorite = 1,
  Incumbent = 3,
  WorkedWith = 4,
  Default = 5,
  Blacklisted = 2,
}

export const FM_SP_LEVELS_QUERY = 'FmSpLevels';
export const FM_SP_LEVELS = gql`
    query FmSpLevels {
      fmSpLevels {
        level_id
        name
        description
        rank

        icon
        color
      }
    }
`;
