import React from 'react';
import { NotificationDropdown, StreamApp } from 'react-activity-feed';
import { SvgIcon } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MenuIconButton } from '../nav/MenuIconButton';
import { config } from '../../config';
import { EmptyNotificationsPlaceholder } from './EmptyNotificationsPlaceholder';
import { NotificationGroup } from './NotificationGroup';
import { NotificationBoxHeader } from './NotificationBoxHeader';
import { NotificationBoxFooter } from './NotificationBoxFooter';
import { useProfileChatData } from '../chat/ChatProvider';
import { useLoginAs } from '../../pages/useLoginAs';

const { getStream } = config;

export const NotificationsIconButton: React.FC = () => {
  const { loginAs } = useLoginAs();
  const { token } = useProfileChatData();

  if (!token || loginAs) {
    return null;
  }

  return (
    <StreamApp token={token} apiKey={getStream.key} appId={getStream.appid}>
      <NotificationDropdown
        right
        Icon={() => (
          <MenuIconButton
            item={{
              id: 'user-notifications',
              type: '_menu_item_onclick',
              icon: (
                <SvgIcon>
                  <FontAwesomeIcon icon="bell" />
                </SvgIcon>
              ),
              // required attrs
              text: '',
              onClick: () => {},
            }}
          />
        )}
        Header={NotificationBoxHeader}
        Footer={NotificationBoxFooter}
        Placeholder={EmptyNotificationsPlaceholder}
        Group={NotificationGroup}
        notify
      />
    </StreamApp>
  );
};
