import React, { useEffect, useState } from 'react';
import {
  Box, Tab, Tabs,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createStyles, makeStyles } from '@mui/styles';
import { LocationsAndAreasMap } from '../../../../../map/LocationsAndAreasMap';
import { a11yProps, TabPanel } from '../../../../../tabs/TabPanel';
import { QuestionsTab } from '../QuestionsTab';
import { BidsTab } from '../BidsTab';
import { colors } from '../../../../../theme';
import { ServiceProvider_organization } from '../../../../../../api/types/ServiceProvider';
import { MapMarker } from '../../../../../map/utils/marker';
import { NavTypeNoAdmin } from '../../../../../nav/navType';

const useStyles = makeStyles((theme) => createStyles({
  contactInfo: {
    color: colors.darkGrey,
  },
  orgFilesBlock: {
    maxWidth: '97%',
    overflow: 'auto',
  },
  photoList: {
    flexWrap: 'nowrap',
  },
  icon: {
    marginLeft: theme.spacing(1),
  },
  minitab: {
    minWidth: '120px',
  },
}));

interface ServiceSpecificProps {
  navType: NavTypeNoAdmin;
  organizationId: number;
  serviceId: number;
  org: ServiceProvider_organization;
  facilities?: MapMarker<number, number>[];
}

export const ServiceSpecific = ({
  navType,
  organizationId,
  serviceId,
  org,
  facilities,
}: ServiceSpecificProps) => {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
  };

  useEffect(() => { // Reset tab to "General" once sidebar org changed
    setTabIndex(0);
  }, [organizationId]);

  return (
    <>
      <LocationsAndAreasMap
        id={`sp-map-${org.organization_id}`}
        height={300}
        homePlaceIdOrCoordinates={org.address?.latlong || org.address?.google_place_id}
        homeTitle={org.name}
        markers={facilities}
        serviceAreaConfig={{
          mode: 'primary',
          organizationId,
          serviceId,
        }}
      />
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        aria-label="Main Site Tabs"
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab
          className={classes.minitab}
          label="Services"
          {...a11yProps(0)}
        />
        {navType === 'fm' ? (
          <Tab
            className={classes.minitab}
            label={(
              <div>
                Bids
                <FontAwesomeIcon icon="lock-alt" className={classes.icon} />
              </div>
            )}
            {...a11yProps(1)}
          />
        ) : null}
      </Tabs>
      <TabPanel value={tabIndex} index={0}>
        <Box padding={4}>
          <QuestionsTab
            organization={org}
            serviceId={serviceId}
          />
        </Box>
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <Box padding={4}>
          <BidsTab organizationId={org.organization_id} />
        </Box>
      </TabPanel>
    </>
  );
};
