import { Box, Alert } from '@mui/material';
import React from 'react';
import { template } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomFieldFormOptions } from './types';
import { CustomFormSettings, CustomFormTypes } from './const';

interface CustomFieldHeaderProps {
  // isDirty: boolean;
  options?: CustomFieldFormOptions;
  type: CustomFormTypes;
  disabled: boolean;
}

export const CustomFieldFormHeader: React.FC<CustomFieldHeaderProps> = ({
  // isDirty,
  type,
  options,
  disabled,
}) => {
  const { DISPLAY_HEADER, HEADER_TEXT, HEADER_ICON } = CustomFormSettings[type];
  const templateCompiler = template(HEADER_TEXT);
  const compiledTitle = templateCompiler(options);
  const icon = HEADER_ICON ? <FontAwesomeIcon icon={HEADER_ICON} /> : null;

  return (
    <>
      {/* <Prompt* /}
      {/*  when={isDirty}*/}
      {/*  message="You have unsaved changes, are you sure you want to leave?"*/}
      {/* /> */}
      {DISPLAY_HEADER && (
        <Box mb={4}>
          <Alert severity="info" icon={icon}>
            {compiledTitle}
          </Alert>
        </Box>
      )}

      {disabled && (
        <Box mb={4}>
          <Alert variant="filled" severity="warning">
            Only an organization owner can edit this form.
          </Alert>
        </Box>
      )}
    </>
  );
};
