import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Tooltip } from '@mui/material';

const useStyles = makeStyles((theme) => createStyles({
  tooltipUl: {
    padding: theme.spacing(0, 0, 0, 2),
  },
  tinyChip: ({
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.shape.borderRadius,
  }),
}));

interface RollingChipProps {
  multiple?: boolean;
}

export const RollingChip: React.FC<RollingChipProps> = ({ multiple }) => {
  const classes = useStyles();
  return (
    <Tooltip
      arrow
      placement="top"
      title={(
        <>
          Bid
          {multiple ? 's do ' : ' does '}
          not have a close date and can be awarded at any time.
        </>
      )}
    >
      <small className={classes.tinyChip}>
        Rolling
      </small>
    </Tooltip>
  );
};
