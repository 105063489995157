import { FormLabel } from '@mui/material';
import React from 'react';
import { labelIsOnTop } from './helpers';
import { useStyles } from './styles';
import { CustomFieldType } from './types';

interface CustomFieldFormLabelProps {
  field: CustomFieldType;
  checkLabelOnTop: boolean;
}

/**
 * Component for label above form field
 *
 * @param props
 * @constructor
 */
export const CustomFieldFormLabel: React.FC<CustomFieldFormLabelProps> = ({
  field,
  checkLabelOnTop,
}) => {
  const classes = useStyles();
  const labelIsValid = !checkLabelOnTop || labelIsOnTop(field);

  // Check if `context` field in DB contain settings for that
  if (!field.name || !labelIsValid) {
    return null;
  }

  return (
    <FormLabel component="legend" className={classes.fieldLabel}>
      {field.name}
    </FormLabel>
  );
};
