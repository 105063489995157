import React, { useCallback, useMemo, useState } from 'react';
import { Button, Grid, TextField } from '@mui/material';
import { OutlinedTextFieldProps } from '@mui/material/TextField/TextField';
import { createStyles, makeStyles } from '@mui/styles';
import { isEmpty, trim } from 'lodash';
import { Check } from '@mui/icons-material';

const useStyles = makeStyles((theme) => createStyles({
  input: {
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
    borderRadius: theme.shape.borderRadius,
    width: 'auto',
    [theme.breakpoints.up('sm')]: {
      width: 300,
    },
    [theme.breakpoints.up('md')]: {
      width: 500,
    },
    [theme.breakpoints.up('lg')]: {
      width: 700,
    },
  },
  buttonSmall: {
    height: theme.spacing(5),
  },
  buttonMedium: {
    height: theme.spacing(7),
  },
}));

export interface InlineTextFieldFormProps extends Omit<OutlinedTextFieldProps, 'variant' | 'onSubmit'> {
  initialValue?: string;
  maxLength?: number;
  onSubmit: (value?: string) => void;
  onCancel: () => void;
  loading?: boolean;
}

export const InlineTextFieldForm: React.FC<InlineTextFieldFormProps> = ({
  initialValue,
  maxLength,
  onSubmit,
  onCancel,
  loading,
  ...textFieldProps
}) => {
  const classes = useStyles();

  const [value, setValue] = useState<string | undefined>(initialValue);

  const maxLengthError = useMemo(
    () => (maxLength ? (value ?? '').length >= maxLength : false),
    [maxLength, value],
  );

  const submitHandler = useCallback(() => {
    const valueTrimmed = value ? trim(value) : undefined;
    if (isEmpty(valueTrimmed)) {
      onSubmit(undefined);
    } else {
      onSubmit(valueTrimmed);
    }
  }, [onSubmit, value]);

  return (
    <Grid
      container
      direction="row"
      spacing={1}
      justifyContent="flex-start"
      alignItems="flex-start"
      alignContent="flex-start"
    >
      <Grid item>
        <TextField
          disabled={loading}
          variant="outlined"
          {...textFieldProps}
          helperText={maxLength ? `${(value ?? '').length} of ${maxLength}` : undefined}
          error={maxLengthError}
          value={value ?? ''}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              submitHandler();
            }
          }}
          InputProps={{
            classes: {
              input: classes.input,
            },
          }}
        />
      </Grid>
      <Grid item>
        <Button
          onClick={submitHandler}
          startIcon={<Check color="action" />}
          disabled={loading || maxLengthError}
          className={textFieldProps.size === 'small' ? classes.buttonSmall : classes.buttonMedium}
        >
          Submit
        </Button>
      </Grid>
      <Grid item>
        <Button
          onClick={onCancel}
          disabled={loading}
          className={textFieldProps.size === 'small' ? classes.buttonSmall : classes.buttonMedium}
        >
          Cancel
        </Button>
      </Grid>
    </Grid>
  );
};
