import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import { useQuery } from '@apollo/react-hooks';

import {
  ServiceProvider_organization,
} from '../../../../../api/types/ServiceProvider';
import { SidebarProp } from '../../../../sidebar/SidebarProp';
import { ErrorMessage } from '../../../../notifications/ErrorMessage';
import { Loading } from '../../../../Loading';
import { ServiceFields, ServiceFieldsVariables } from '../../../../../api/types/ServiceFields';
import { SERVICE_FIELDS } from '../../../../../api/serviceFields/serviceFields';
import { NoData } from '../../../../text/NoData';
import { convertValue, FieldType } from '../../../../../api/customFields';

export interface QuestionsTabProps {
  organization: ServiceProvider_organization;
  serviceId: number;
}

export const QuestionsTab: React.FC<QuestionsTabProps> = ({
  organization,
  serviceId,
}) => {
  const {
    data: fieldsData,
    loading: fieldsLoading,
    error: fieldsError,
  } = useQuery<ServiceFields, ServiceFieldsVariables>(SERVICE_FIELDS, {
    fetchPolicy: 'no-cache',
    variables: {
      skip: 0,
      take: 300,
      where: {
        service_id: { eq: serviceId },
      },
    },
  });

  const values = useMemo(() => organization
    .serviceFieldValues
    .reduce((acc: any, { field, value }) => {
      const { type, context } = field;
      const parsedValue = convertValue(value, type, context);
      return { ...acc, [field.field_id]: parsedValue };
    }, {}), [organization]);

  const serviceFields = useMemo(() => (
    fieldsData?._serviceFields?.items
      ? fieldsData._serviceFields.items.filter((field) => (
        field.type !== FieldType.label && field.type !== FieldType.html
      ))
      : []
  ), [fieldsData]);

  if (fieldsLoading) {
    return (
      <Loading center />
    );
  }

  if (fieldsError) {
    return (
      <ErrorMessage center devMessage={fieldsError.message}>
        Failed loading fields data
      </ErrorMessage>
    );
  }

  if (!fieldsData) {
    return (
      <ErrorMessage center devMessage="data object is not set">
        Failed loading fields data
      </ErrorMessage>
    );
  }

  return (
    <Grid container spacing={1}>
      {serviceFields.map((field) => (
        <Grid key={field.field_id} item xs={6}>
          <SidebarProp
            title={field.name}
            value={values[field.field_id] ?? (<NoData>No answer</NoData>)}
          />
        </Grid>
      ))}
    </Grid>
  );
};
