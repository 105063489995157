import { useCallback, useMemo, useState } from 'react';

export interface UseFreezingResult {
  widthsFrozen: boolean;
  setWidthsFrozen: (_e: any) => void;
  orderFrozen: boolean;
  setOrderFrozen: (_e: any) => void;
}

interface UseFreezingProps extends UseFreezingResult {
  resetFreezeSettings: () => void;
}

export function useFreezing(tableId: string): UseFreezingProps {
  const storageKeyWidths = useMemo(() => `${tableId}.widthsFrozen`, [tableId]);
  const storedValueWidths: boolean | undefined = useMemo(() => {
    const stringValue = window.localStorage.getItem(storageKeyWidths);
    if (stringValue && stringValue !== 'undefined') {
      return JSON.parse(stringValue);
    }
    return undefined;
  }, [storageKeyWidths]);

  const storageKeyOrder = useMemo(() => `${tableId}.orderFrozen`, [tableId]);
  const storedValueOrder: boolean | undefined = useMemo(() => {
    const stringValue = window.localStorage.getItem(storageKeyOrder);
    if (stringValue && stringValue !== 'undefined') {
      return JSON.parse(stringValue);
    }
    return undefined;
  }, [storageKeyOrder]);

  const [widths, setWidths] = useState<boolean>(storedValueWidths ?? false);

  const setWidthsFrozen = useCallback(() => {
    const frozen = !widths;
    window.localStorage.setItem(storageKeyWidths, JSON.stringify(frozen));
    setWidths(frozen);
  }, [storageKeyWidths, widths]);

  const [order, setOrder] = useState<boolean>(storedValueOrder ?? false);

  const setOrderFrozen = useCallback(() => {
    const frozen = !order;
    window.localStorage.setItem(storageKeyOrder, JSON.stringify(frozen));
    setOrder(frozen);
  }, [storageKeyOrder, order]);

  const resetFreezeSettings = useCallback(() => {
    window.localStorage.removeItem(`${tableId}.widthsFrozen`);
    window.localStorage.removeItem(`${tableId}.orderFrozen`);
    setWidths(false);
    setOrder(false);
  }, [tableId]);

  return {
    widthsFrozen: widths,
    setWidthsFrozen,
    orderFrozen: order,
    setOrderFrozen,
    resetFreezeSettings,
  };
}
