import { createStyles, makeStyles } from '@mui/styles';
import { colors } from '../theme';

export const useStyles = makeStyles((theme) => createStyles({
  selectionCell: {
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
    paddingTop: '8px !important',
    paddingBottom: '8px !important',
  },
  kendoGrid: {
    border: 0,
    borderTop: `1px solid ${colors.lightGrey}`,
  },
  checkbox: {
    padding: '5px 9px',
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
}));
