import { gql } from '@apollo/client';

export const ORGANIZATION_CONTACT_TYPES = gql`
  query OrganizationContactTypes {
    orgContactTypes {
      type_id
      name
      allow_multiple_per_organization
    }
  }
`;
