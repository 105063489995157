import React, { useCallback, useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import L from 'leaflet';
import { Point } from 'geojson';
import * as Sentry from '@sentry/browser';
import { isEqual } from 'lodash';
import { getMarkerFullTitle, getPinIcon, MapMarker } from './utils/marker';
import { createMap } from './leaflet/map';
import { addGoogleMapLayers } from './leaflet/googleMapLayers';
import { useResizeObserver } from '../../utils/useResizeObserver';
import { useGoogleLocation } from './utils/useGoogleLocation';
import { addMeasureControl } from './leaflet/measureTools';
import { getGoogleMapUrl } from './utils/googleMap';
import { ViewMapProps } from './ViewMap';
import { addHomeIcon } from './leaflet/homeIcon';
import { ErrorSnackbar, useErrorSnackbar } from '../notifications/ErrorSnackbar';
import { useFillScreenHeight } from './utils/useFillScreenHeight';
import { ServiceAreaMapConfig, useServiceAreas } from './serviceAreas/useServiceAreas';

const useStyles = makeStyles(() => createStyles({
  wrapper: {},
  map: {
    margin: 0,
    padding: 0,
    overflow: 'hidden',
  },
}));

export interface LocationsAndAreasMapProps<
  ID extends string | number,
  GID extends string | number,
> extends ViewMapProps {
  // Close markers will appear as groups, i.e. markers with a number of "nested" markers under it
  groupMarkers?: boolean;
  groupMarkersMaxZoomLevel?: number;
  markers?: MapMarker<ID, GID>[];
  measureControl?: boolean;
  showPopup?: boolean,
  showGoogleMapLinks?: boolean,
  onClick?: (coordinates: Point) => void;
  /**
   * new way of querying service area of organization
   */
  serviceAreaConfig?: ServiceAreaMapConfig;
  defaultLayer?: 'hybrid' | 'roadmap';
}

/**
 * Using `React.memo` here to prevent rerunning effects (useEffect)
 * when an equal (but not the same as previous) markers object is passed into props,
 * which would cause a map to reset its zoom and other stuff unnecessarily.
 *
 * This is because often when this component is used, markers are not being
 * wrapped in useMemo/useCallback, so we need to add an explicit memoization here.
 */
export const LocationsAndAreasMap = React.memo(<
  ID extends string | number,
  GID extends string | number,
>({
  id,
  zoom = 11,
  withSearch,
  maxZoomWhenFitting = 13,
  height: minHeight,
  fillScreenHeight = false,
  groupMarkers = true,
  groupMarkersMaxZoomLevel = 13,
  homePlaceIdOrCoordinates,
  homeTitle,
  markers,
  measureControl = false,
  showPopup = true,
  showGoogleMapLinks = true,
  onClick,
  serviceAreaConfig = { mode: 'disabled' },
  defaultLayer,
  ...props
}: LocationsAndAreasMapProps<ID, GID>) => {
  const classes = useStyles();

  const [mapRef, height] = useFillScreenHeight(minHeight, fillScreenHeight);

  const [orgGeoLocation, orgGeoLocationError] = useGoogleLocation(homePlaceIdOrCoordinates);
  const geoErrorSnackbar = useErrorSnackbar(orgGeoLocationError);

  const [mapError, setMapError] = useState<Error>();
  const mapErrorSnackbar = useErrorSnackbar(mapError);

  const [map, setMap] = useState<L.Map>();
  const [markersLayer, setMarkersLayer] = useState<L.FeatureGroup>(); // markers

  const { queryTuple: { error: serviceAreaError } } = useServiceAreas(map, serviceAreaConfig);
  const serviceAreaErrorSnackbar = useErrorSnackbar(serviceAreaError);

  useEffect(() => {
    if (!orgGeoLocation) {
      return () => {};
    }
    let leafletMap: L.Map | undefined;
    try {
      leafletMap = createMap(id, zoom, orgGeoLocation, withSearch);
      setMap(leafletMap);
      addGoogleMapLayers(leafletMap, defaultLayer);

      const markersLayerGroup = groupMarkers ? L.markerClusterGroup({
        showCoverageOnHover: false,
        spiderfyOnMaxZoom: false,
        disableClusteringAtZoom: groupMarkersMaxZoomLevel,
      }) : new L.FeatureGroup();
      leafletMap.addLayer(markersLayerGroup);
      setMarkersLayer(markersLayerGroup);

      addHomeIcon(leafletMap, homeTitle, orgGeoLocation.toJSON());
      if (measureControl) {
        addMeasureControl(leafletMap);
      }
    } catch (error: any) {
      setMapError(error);
      Sentry.captureException(error);
    }

    return () => {
      setMarkersLayer(undefined);
      if (leafletMap) {
        try {
          leafletMap.remove();
        } catch (e) {
          Sentry.captureException(e);
        }
      }
    };
  }, [
    groupMarkers,
    groupMarkersMaxZoomLevel,
    homeTitle,
    id,
    measureControl,
    orgGeoLocation,
    zoom,
    withSearch,
    defaultLayer,
  ]);

  // On click handling
  useEffect(() => {
    if (!map) {
      return () => {};
    }
    const onClickHandler = (e: L.LeafletMouseEvent) => {
      if (onClick) {
        try {
          const latlong = map.mouseEventToLatLng(e.originalEvent);
          onClick({
            type: 'Point',
            coordinates: [latlong.lat, latlong.lng],
          });
        } catch (error: any) {
          setMapError(error);
          Sentry.captureException(error);
        }
      }
    };
    map.on('click', onClickHandler);

    return () => {
      map.off('click', onClickHandler);
    };
  }, [map, onClick]);

  const onResize = useCallback(() => {
    if (map) {
      map.fire('resize');
    }
  }, [map]);

  useResizeObserver(id, onResize);

  // Adding markers
  useEffect(() => {
    if (map && markersLayer && markers) {
      try {
        markersLayer.clearLayers();
        markers.forEach((marker) => {
          const icon = getPinIcon(marker.color);
          const fullTitle = getMarkerFullTitle(marker);
          const leafletMarker = L.marker(marker, { icon })
            .bindTooltip(fullTitle)
            .openTooltip();
          if (showPopup) {
            leafletMarker.bindPopup(
              fullTitle + (
                showGoogleMapLinks
                  ? `<br><a href="${getGoogleMapUrl(marker)}" target="_blank">Open in Google Maps</a>`
                  : ''
              ),
            );
          }
          markersLayer.addLayer(leafletMarker);
          // Update group bounds manually - just adding markers doesn't work.
          markersLayer.getBounds().extend(marker);
        });
        if (markersLayer.getBounds().isValid()) {
          try {
            map.fitBounds(markersLayer.getBounds(), { maxZoom: maxZoomWhenFitting });
          } catch (e) {
            // eslint-disable-next-line no-console
            console.warn('Could not fit bounds: ', e);
          }
        }
      } catch (error: any) {
        setMapError(error);
        Sentry.captureException(error);
      }
    }
  }, [map, markers, markersLayer, maxZoomWhenFitting, showGoogleMapLinks, showPopup]);

  return (
    <>
      <Box component="div" className={classes.wrapper}>
        <div
          id={id}
          ref={mapRef}
          className={classes.map}
          {...props}
          style={{ height }}
        />
      </Box>
      <ErrorSnackbar {...geoErrorSnackbar}>
        Could not geographically locate an organization
      </ErrorSnackbar>
      <ErrorSnackbar {...mapErrorSnackbar}>
        An error occurred in a map view
      </ErrorSnackbar>
      <ErrorSnackbar {...serviceAreaErrorSnackbar}>
        Could not load service area
      </ErrorSnackbar>
    </>
  );
}, isEqual);
