import React, {
  createContext, forwardRef, useContext, useEffect, useImperativeHandle, useMemo,
} from 'react';
import { useMediaQuery } from '@mui/material';
import { useLazyQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';

import { Sidebar } from '../../../../sidebar/Sidebar';
import { ServiceProviderSidebar } from './ServiceProviderSidebar';
import { SpSidebarTitle } from './SPSidebarTitle';
import { ISidebarSearch, useSidebarState } from '../../../../sidebar/useSidebarSearchState';
import { ServiceProvider, ServiceProviderVariables } from '../../../../../api/types/ServiceProvider';
import { SERVICE_PROVIDER } from '../../../../../api/organization/fm/serviceProvider';
import { SpProfileFileType } from '../../../../../api/types/globalTypes';
import { PATHS } from '../../../../../paths';
import { useProfile } from '../../../../../api/user/profile';
import { NavTypeNoAdmin } from '../../../../nav/navType';
import { SP_QUERY_SIDEBAR } from './queries/spQuerySidebar';

export interface SidebarAdditionalProps {
  page?: 'main' | 'documents' | 'diversity' | 'contacts';
  serviceId?: number;
  facilities?: any[];
}

export type SpSidebarExtraType = Record<string, any> | undefined;

export type SpSidebarExtraTypeValue = SpSidebarExtraType
| ((prev: SpSidebarExtraType) => SpSidebarExtraType);

const SpSidebarContext = createContext({
  state: undefined as undefined | ISidebarSearch,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  openYourOwnSidebar: (ctx: SidebarAdditionalProps) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  openSidebar: (serviceProviderId: number, name: string, ctx: SidebarAdditionalProps) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  modifyExtra: (v: SpSidebarExtraTypeValue) => {},
  closeSidebar: () => {},
});

interface SpSidebarProviderProps {
  navType: NavTypeNoAdmin;
  children: React.ReactNode;
}

export const useSpSidebarHandlers = () => useContext(SpSidebarContext);

export const sidebarInPopupPaths = [PATHS.fm.emptyPageForSidebar, PATHS.sp.emptyPageForSidebar];

export const SpSidebarProvider = forwardRef<any, SpSidebarProviderProps>(({
  navType,
  children,
}, ref) => {
  const profile = useProfile();
  const history = useHistory();
  const [state, setState] = useSidebarState();

  const value = useMemo(() => ({
    state,
    openYourOwnSidebar: (ctx: SidebarAdditionalProps) => {
      setState({
        sb_type: 'sp',
        sb_id: profile.organization.organization_id,
        sb_title: profile.organization.name,
        sb_extra: ctx,
      });
    },
    openSidebar: (serviceProviderId: number, name: string, ctx: SidebarAdditionalProps) => {
      setState({
        sb_type: 'sp',
        sb_id: serviceProviderId,
        sb_title: name,
        sb_extra: ctx,
      });
    },
    modifyExtra: (extra: SpSidebarExtraTypeValue) => {
      setState((prev) => (prev ? ({
        ...prev,
        sb_extra: typeof extra === 'function' ? extra(prev.sb_extra) : extra,
      }) : undefined));
    },
    closeSidebar: () => {
      setState(undefined);
    },
  }), [state, setState, profile.organization.organization_id, profile.organization.name]);

  useImperativeHandle(ref, () => value, [value]);

  const sidebarSpOpen = state?.sb_type === 'sp' && !!state?.sb_id;
  const id = state?.sb_id;
  const [getOrgData, orgData] = useLazyQuery<
    ServiceProvider,
    ServiceProviderVariables
  >(navType === 'sp' ? SP_QUERY_SIDEBAR : SERVICE_PROVIDER, {
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    if (sidebarSpOpen) {
      getOrgData({ variables: { organizationId: id! } });
    }
  }, [getOrgData, sidebarSpOpen, id]);

  const logo = orgData.data?.organization?.spProfile?.files
    .find((f) => f.type === SpProfileFileType.LOGO);

  const sm = useMediaQuery((t: any) => t.breakpoints.down('md'));

  const { pathname } = history.location;
  return (
    <SpSidebarContext.Provider value={value}>
      <Sidebar
        width={sidebarInPopupPaths.includes(pathname) || sm ? window.innerWidth : 633}
        disableTitleBorderBottom={!state?.sb_extra?.page || state?.sb_extra?.page === 'main'}
        open={sidebarSpOpen}
        onClose={sidebarInPopupPaths.includes(pathname) ? undefined : value.closeSidebar}
        title={sidebarSpOpen ? (
          <SpSidebarTitle
            navType={navType}
            spOrganizationId={state!.sb_id}
            title={state!.sb_title}
            logo={logo?.url}
          />
        ) : ''}
        content={(sidebarSpOpen ? (
          <ServiceProviderSidebar
            navType={navType}
            orgData={orgData}
            organizationId={state!.sb_id}
            serviceId={state!.sb_extra?.serviceId}
            facilities={state?.sb_extra?.facilities ?? []}
          />
        ) : null)}
      >
        {children}
      </Sidebar>
    </SpSidebarContext.Provider>
  );
});
