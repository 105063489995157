import React, { useEffect, useState } from 'react';
import {
  MessageInputProps,
  useChannelActionContext,
  useMessageInputContext,
  useTranslationContext,
  useChatContext as useStreamChatContext,
  useComponentContext,
  CooldownTimer as DefaultCooldownTimer,
  EmojiIconLarge as DefaultEmojiIcon,
  SendButton as DefaultSendButton,
  QuotedMessagePreview as DefaultQuotedMessagePreview,
  UploadsPreview,
  EmojiPicker,
  ChatAutoComplete,
} from 'stream-chat-react';
import type { Event } from 'stream-chat';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import { useChatContext } from 'components/chat/ChatContext';
import {
  IconButton, Tooltip,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useChannelStateContext } from '../hooks/streamHooks';
import { ChatGenerics } from '../streamTypes';
import { DocumentRepositoryDialog } from './DocumentRepositoryDialog';

/**
 * Based on original MessageInputFlat
 * @url https://github.com/GetStream/stream-chat-react/blob/develop/src/components/MessageInput/MessageInputFlat.tsx
 */

const useStyles = makeStyles(() => createStyles({
  attachFilesButton: {
    position: 'absolute',
    right: 5,
    top: 5,
  },
}));

export const MessageInput: React.FC<MessageInputProps> = () => {
  const classes = useStyles();
  const { additionalProps: { defaultMessageValue } = {} } = useChatContext();
  const [filesDialogVisible, setFilesDialogVisible] = useState<boolean>(false);

  const { quotedMessage } = useChannelStateContext('MessageInputFlat');
  const { setQuotedMessage } = useChannelActionContext('MessageInputFlat');
  const { t } = useTranslationContext('MessageInputFlat');
  const { channel } = useStreamChatContext('MessageInputFlat');
  const {
    closeEmojiPicker,
    cooldownRemaining,
    emojiPickerIsOpen,
    handleSubmit,
    isUploadEnabled,
    maxFilesLeft,
    numberOfUploads,
    openEmojiPicker,
    setCooldownRemaining,
    uploadNewFiles,
    insertText,
  } = useMessageInputContext<ChatGenerics>('MessageInputFlat');

  const {
    CooldownTimer = DefaultCooldownTimer,
    EmojiIcon = DefaultEmojiIcon,
    QuotedMessagePreview = DefaultQuotedMessagePreview,
    SendButton = DefaultSendButton,
  } = useComponentContext<ChatGenerics>('MessageInputFlat');

  useEffect(() => {
    const handleQuotedMessageUpdate = (e: Event<any>) => {
      if (e.message?.id !== quotedMessage?.id) return;
      if (e.type === 'message.deleted') {
        setQuotedMessage(undefined);
        return;
      }
      setQuotedMessage(e.message);
    };
    channel?.on('message.deleted', handleQuotedMessageUpdate);
    channel?.on('message.updated', handleQuotedMessageUpdate);

    return () => {
      channel?.off('message.deleted', handleQuotedMessageUpdate);
      channel?.off('message.updated', handleQuotedMessageUpdate);
    };
  }, [channel, quotedMessage, setQuotedMessage]);

  useEffect(() => {
    if (defaultMessageValue) {
      insertText(defaultMessageValue);
    }
  }, [insertText, defaultMessageValue]);

  return (
    <div
      className={`str-chat__input-flat ${
        SendButton ? 'str-chat__input-flat--send-button-active' : ''
      } ${quotedMessage && !quotedMessage.parent_id ? 'str-chat__input-flat-quoted' : ''}
      ${numberOfUploads ? 'str-chat__input-flat-has-attachments' : ''}
      `}
    >
      {quotedMessage && !quotedMessage.parent_id && (
        <QuotedMessagePreview quotedMessage={quotedMessage} />
      )}
      <div className="str-chat__input-flat-wrapper">
        {isUploadEnabled && <UploadsPreview />}
        <div className="str-chat__input-flat--textarea-wrapper">
          <div className="str-chat__emojiselect-wrapper">
            <Tooltip title={emojiPickerIsOpen ? t<string>('Close emoji picker') : t<string>('Open emoji picker')}>
              <button
                type="button"
                aria-label="Emoji picker"
                className="str-chat__input-flat-emojiselect"
                onClick={emojiPickerIsOpen ? closeEmojiPicker : openEmojiPicker}
              >
                {cooldownRemaining ? (
                  <div className="str-chat__input-flat-cooldown">
                    <CooldownTimer
                      cooldownInterval={cooldownRemaining}
                      setCooldownRemaining={setCooldownRemaining}
                    />
                  </div>
                ) : (
                  <EmojiIcon />
                )}
              </button>
            </Tooltip>
          </div>
          <EmojiPicker />
          <ChatAutoComplete />
          {isUploadEnabled && !cooldownRemaining && (
            <div className={`str-chat__fileupload-wrapper ${classes.attachFilesButton}`} data-testid="fileinput">
              <Tooltip title={maxFilesLeft ? t<string>('Attach files') : t<string>("You've reached the maximum number of files")}>
                <IconButton
                  onClick={() => setFilesDialogVisible(true)}
                  aria-label="Attach file"
                  size="large"
                >
                  <AttachFileIcon />
                </IconButton>
              </Tooltip>
              <DocumentRepositoryDialog
                onSelected={async (files) => {
                  uploadNewFiles(files);
                  setFilesDialogVisible(false);
                }}
                open={filesDialogVisible}
                onClose={() => setFilesDialogVisible(false)}
              />
            </div>
          )}
        </div>
        {!cooldownRemaining && <SendButton sendMessage={handleSubmit} />}
      </div>
    </div>
  );
};
