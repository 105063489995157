import React, { forwardRef } from 'react';
import MaskedInput from 'react-text-mask';

interface PhoneNumberMaskInputProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const PhoneNumberMaskInput = forwardRef<unknown, PhoneNumberMaskInputProps>((props, ref) => (
  <MaskedInput
    {...props}
    ref={(maskedRef: any) => {
      if (typeof ref === 'function') {
        ref(maskedRef?.inputElement ?? null);
      }
    }}
    // based on NANP agreement subscriber number never begin with 0 or 1
    // https://en.wikipedia.org/wiki/National_conventions_for_writing_telephone_numbers#United_States,_Canada,_and_other_NANP_countries
    mask={[/[2-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
    placeholderChar={'\u2000'}
  />
));
