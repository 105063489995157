import React, { useCallback, useMemo } from 'react';
import {
  Button, Box, Grid, Tooltip, Typography,
} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createStyles, makeStyles } from '@mui/styles';
import { useFormikContext } from 'formik';
import { colors } from '../theme';
import { bytesToMb } from '../../utils/number';
import { MaxFileWarning } from './MaxFileWarning';
import { DocumentRepositoryForm, FileItem } from './useDocumentRepository';
import { FILE_TYPES_ALL } from '../../utils/files';
import { FileType } from '../../api/types/globalTypes';
import { FileCategories } from './queries/categories';

const useStyles = makeStyles((theme) => createStyles({
  dropZoneContainer: {
    width: '100%',
  },
  dropZone: {
    padding: theme.spacing(2.5),
    position: 'relative',
    overflow: 'hidden',
    marginBottom: 20,
    cursor: 'pointer',
    borderRadius: 6,
    border: `1px dashed ${colors.grey}`,
  },
  dropZoneText: {
    color: colors.blue,
  },
  addDocuSignTemplateButton: {
    height: '62px',
  },
  muted: {
    colors: colors.lightGrey,
  },
  icon: {
    margin: theme.spacing(1, 0, 1, 0),
  },
  filesListText: {
    textAlign: 'center',
    color: theme.palette.grey[800],
    fontSize: '1rem',
  },
}));

interface FilesDropZoneProps {
  /**
   * Mime types that could be uploaded (coma separated)
   * default is: all images, csv, pdf, office docs like xls, doc/docx
   */
  acceptFilesMimeTypes?: string;
  /**
   * default is: 20
   */
  maxFiles?: number;
  /**
   * default is 25mb
   */
  maxFileSize?: number;

  /**
   * Allows user to add docusign template as file
   */
  allowToAddDocusignTemplates?: boolean;

  category?: FileCategories;
}

export const FilesDefaultMaxSize = 25 * 1024 * 1024; // 25 mb

export const FilesDropZone: React.FC<FilesDropZoneProps> = ({
  maxFiles = 20,
  maxFileSize = FilesDefaultMaxSize,
  acceptFilesMimeTypes = FILE_TYPES_ALL,
  allowToAddDocusignTemplates,
  category,
}) => {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext<DocumentRepositoryForm>();

  const onDropAccepted = useCallback((files: File[]) => {
    const filesMapped = files.map<FileItem>((file) => {
      // Check file size
      const rejectionReason = file.size >= maxFileSize
        ? `File is too large (${bytesToMb(file.size)}MB). Max file size is ${bytesToMb(maxFileSize)}MB`
        : undefined;

      return {
        key: Math.random().toString(16).slice(2),
        type: FileType.Storage,
        show_in_repository: false,
        category_id: category,
        is_public: false,
        file,
        name: file.name,
        rejectionReason,
      } as FileItem;
    });

    const availableSlots = maxFiles - values.files.length;
    const filesMerged = [
      ...values.files,
      ...(filesMapped.length > availableSlots ? filesMapped.slice(0, availableSlots) : filesMapped),
    ];
    setFieldValue('files', filesMerged, false);
  }, [maxFiles, category, values.files, setFieldValue, maxFileSize]);

  const filesInfo = useMemo(() => {
    const totalSize = values.files
      .reduce((size: number, { file }) => (size + (file?.size ?? 0)), 0);
    return {
      totalSize,
      isOutOfSize: totalSize > maxFileSize,
      isMaxFilesLimitReached: values.files.length >= maxFiles,
    };
  }, [maxFileSize, maxFiles, values.files]);

  const dropzoneDisabled = filesInfo.isOutOfSize || filesInfo.isMaxFilesLimitReached;
  const { getRootProps, getInputProps } = useDropzone({
    disabled: dropzoneDisabled,
    accept: acceptFilesMimeTypes,
    onDropAccepted,
  });

  const { ...rootProps } = getRootProps();

  return (
    <Grid container spacing={1} direction="row" wrap="nowrap">
      {category ? (
        <Grid item classes={{ root: classes.dropZoneContainer }} xs={12} md={6}>
          W9
          {category}
        </Grid>
      ) : null}
      <Grid item classes={{ root: classes.dropZoneContainer }}>
        <Box {...rootProps} className={classes.dropZone}>
          <input {...getInputProps()} />
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <FontAwesomeIcon icon="plus" color={colors.blue} size="1x" />
            </Grid>
            <Grid item>
              <Tooltip title={filesInfo.isMaxFilesLimitReached ? "You can't add more files" : ''}>
                <Typography
                  variant="body2"
                  className={`${classes.dropZoneText}${dropzoneDisabled ? classes.muted : ''}`}
                >
                  Drag and drop or click to add JPEG, PNG, PDF, XLS, CSV files.
                  {' '}
                  {bytesToMb(maxFileSize)}
                  MB or
                  {` ${maxFiles} `}
                  files max per one upload.
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
        {filesInfo.isOutOfSize && (
          <MaxFileWarning fileSize={filesInfo.totalSize} />
        )}
      </Grid>
      {allowToAddDocusignTemplates ? (
        <Grid item>
          <Button
            variant="outlined"
            classes={{ root: classes.addDocuSignTemplateButton }}
            onClick={() => {
              setFieldValue('files', [
                ...values.files,
                {
                  key: Math.random().toString(16).slice(2),
                  type: FileType.DocuSignTemplate,
                  show_in_repository: false,
                  is_public: false,
                },
              ]);
            }}
          >
            Add DocuSign Template
          </Button>
        </Grid>
      ) : null}
    </Grid>
  );
};
