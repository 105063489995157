import { gql } from '@apollo/client';

export const SYNC_USER_NOTIFICATION_SETTINGS = gql`
  mutation SyncUserNotificationSettings($user_id: Int!, $settings: [UserNotificationSettingInput!]!) {
    syncUserNotificationSettings(user_id: $user_id, settings: $settings) {
      event_id
      service_id
    }
  }
`;
