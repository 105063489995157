import { gql } from '@apollo/client';

export const SERVICE_FIELDS = gql`
  query ServiceFields ($skip: Int!, $take: Int!, $where: WhereServiceFieldInput) {
    _serviceFields(
      skip: $skip,
      take: $take,
      where: $where,
      order: [{ field: "rank" }]
    ) {
      items {
        field_id
        service_id
        name
        type
        context
        placeholder
        is_required
      }
      total_count
      has_more
    }
  }
`;
