import React from 'react';
import {
  Box, Button, Grid, IconButton, Link, Typography,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { createStyles, makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSpSidebarHandlers } from '../SpSidebarProvider';
import { SidebarProp } from '../../../../../sidebar/SidebarProp';
import { formatPhoneInternationalPretty } from '../../../../../../utils/phone';
import { GoogleMapLink } from '../../../../../map/utils/googleMap';
import { formatAddress2 } from '../../../../../../utils/places';
import { colors } from '../../../../../theme';
import { useIsEmailVisible } from '../useIsEmailVisible';

const useStyles = makeStyles((theme) => createStyles({
  icon: {
    marginLeft: theme.spacing(1),
  },
  contactInfo: {
    color: colors.darkGrey,
  },
}));

export const ContactsSubPage = ({ organizationId, organization, adminForceShowSpEmail }: any) => {
  const classes = useStyles();
  const { modifyExtra } = useSpSidebarHandlers();
  const { isEmailVisible } = useIsEmailVisible(organizationId, adminForceShowSpEmail);
  const {
    login_email,
    work_email,
    first_name,
    last_name,
    phone_number,
  } = organization.createdBy ?? {};
  const email = work_email ?? login_email;

  return (
    <Box key="contacts" m={2}>
      <Button
        size="large"
        variant="text"
        color="primary"
        startIcon={<ArrowBack />}
        onClick={() => modifyExtra((p) => ({ ...p, page: 'main' }))}
      >
        Contact Information
      </Button>

      <Box ml={1} mt={2}>
        <SidebarProp
          title="Organization Contact"
          value={(
            <Typography variant="body1" component="div" className={classes.contactInfo}>
              {organization.phone_number
                ? formatPhoneInternationalPretty(organization.phone_number)
                : null}
              <br />
              <GoogleMapLink
                latlongOrPlaceId={
                  organization.address?.latlong || organization.address?.google_place_id
                }
              >
                {organization.address ? formatAddress2(
                  organization.address.address_line_1,
                  organization.address.address_line_2,
                  organization.address.city,
                  organization.address.state_code,
                  organization.address.postal_code,
                ) : 'No Address'}
              </GoogleMapLink>
              {organization.website_url && (
                <>
                  <br />
                  <Link href={organization.website_url} component="a" target="_blank">
                    {organization.website_url}
                  </Link>
                </>
              )}
            </Typography>
          )}
        />
        <SidebarProp
          title="Owner Contact"
          value={(
            <Typography variant="body1" component="div" className={classes.contactInfo}>
              {isEmailVisible && email ? (
                <Grid container direction="column">
                  <Grid item>
                    {first_name}
                    {' '}
                    {last_name}
                  </Grid>
                  <Grid item>
                    <Link href={`mailto:${email}`} component="a">
                      {email}
                    </Link>
                    <IconButton
                      size="small"
                      className={classes.icon}
                      onClick={() => navigator.clipboard.writeText(email)}
                    >
                      <FontAwesomeIcon icon="copy" />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    {phone_number ? formatPhoneInternationalPretty(phone_number) : null}
                  </Grid>
                </Grid>
              ) : (
                <Typography color="textSecondary">
                  Can&apos;t See Email
                  <FontAwesomeIcon icon="lock-alt" className={classes.icon} />
                </Typography>
              )}
            </Typography>
          )}
        />
      </Box>
    </Box>
  );
};
