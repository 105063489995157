import { useForm } from 'react-hook-form';
import { Checkbox, FormControlLabel, FormHelperText } from '@mui/material';
import React from 'react';
import { getError, getFieldName, hasError } from '../helpers';
import { CustomFieldRenderComponentProps } from '../types';

interface CustomFieldCheckboxProps extends CustomFieldRenderComponentProps {
  value: boolean;
}

export const CustomFieldCheckbox: React.FC<CustomFieldCheckboxProps> = ({
  disabled,
  field,
  value,
  onChange,
}) => {
  const { errors } = useForm();

  return (
    <>
      <FormControlLabel
        disabled={disabled}
        label={field.name}
        control={(
          <Checkbox
            name={getFieldName(field)}
            checked={value || false}
            onChange={onChange}
          />
        )}
      />
      {hasError(errors, field) && (
        <FormHelperText error>
          {getError(errors, field)}
        </FormHelperText>
      )}
    </>
  );
};
