import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { WhereOperationsInput } from 'api/types/globalTypes';

export type FilterTypeSimple = 'string' | 'integer' | 'float' | 'date' | 'datetime' | 'boolean' | 'autocomplete';
export type FilterTypeWithOptions = 'checkboxes';
export type FilterType = FilterTypeSimple | FilterTypeWithOptions;

export function isWithOptions(filterType?: FilterType): filterType is FilterTypeWithOptions {
  return ['checkboxes'].includes(filterType ?? '');
}

export interface FilterOption {
  key: string | number;
  label: string;
  icon?: IconProp | null;
  iconColor?: string | null;
}

interface BasicFilter {
  field: string;
  type: FilterType;
}

export interface FilterSimple extends BasicFilter {
  logic: undefined;
  where: WhereOperationsInput;
}

export interface FilterAnd extends BasicFilter {
  logic: 'and';
  where: WhereOperationsInput[];
}

export interface FilterOr extends BasicFilter {
  logic: 'or';
  where: WhereOperationsInput[];
}

export type Filter = FilterSimple | FilterAnd | FilterOr;

export interface FilterAutocompleteOption {
  key: string | number;
  value: string | number;
  label: string;
}

export function isFilterSimple(f: Filter): f is FilterSimple {
  return !f.logic;
}

export function isFilterAnd(f: Filter): f is FilterAnd {
  return f.logic === 'and';
}

export function isFilterOr(f: Filter): f is FilterOr {
  return f.logic === 'or';
}
