import React, { PropsWithChildren } from 'react';
import { generatePath } from 'react-router-dom';

import { PATHS } from 'paths';
import { EllipsisText } from 'components/text/EllipsisText';
import { StyledRouterLink } from 'components/StyledRouterLink';

export interface BiddingLinkProps {
  linkAvailable: boolean;
  bid_id: number;
  title: React.ReactNode;
  type?: 'sp' | 'fm';
  inNewWindow?: boolean;
  maxWidth?: number;
}

export const BiddingLink: React.FC<PropsWithChildren<BiddingLinkProps>> = ({
  linkAvailable,
  bid_id,
  title,
  type = 'sp',
  inNewWindow = false,
  maxWidth,
  children,
}) => {
  const tooltip = !linkAvailable
    ? (
      <>
        {title && (
          <>
            {title}
            <br />
          </>
        )}
        {type === 'sp'
          ? 'This bundle is currently in a status that prevents you from bidding on it.'
          : 'This bid is currently in a status that prevents you from previewing or comparing it.'}
      </>
    )
    : title ?? '';

  if (linkAvailable) {
    return (
      <EllipsisText
        fullText={tooltip}
        variant="body2"
        {...maxWidth ? { maxWidth } : { insideDataGrid: true }}
      >
        <StyledRouterLink
          to={generatePath(PATHS[type].bid, {
            bidId: bid_id,
          })}
          style={{ cursor: 'pointer' }}
          target={inNewWindow ? '_blank' : undefined}
        >
          {children}
        </StyledRouterLink>
      </EllipsisText>
    );
  }

  return (
    <EllipsisText
      fullText={tooltip}
      variant="body2"
      disabledLink
      {...maxWidth ? { maxWidth } : { insideDataGrid: true }}
    >
      {children}
    </EllipsisText>
  );
};
