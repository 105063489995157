import React, { useState } from 'react';
import {
  every, isEmpty, negate, trim,
} from 'lodash';
import {
  Alert, Box, Button, Grid, TextField,
} from '@mui/material';
import { useMutation } from '@apollo/react-hooks';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { useProfile } from '../../../../api/user/profile';
import { InviteUsers, InviteUsers_inviteUsers_warnings, InviteUsersVariables } from '../../../../api/types/InviteUsers';
import { INVITE_USERS } from './queries/InviteUsersMutation';
import { CustomDialog } from '../../../CustomDialog';
import { USER_INVITES_QID } from './queries/UserInvitesQuery';
import { SuccessSnackbar } from '../../../notifications/SuccessSnackbar';
import { pluralize } from '../../../../utils/inflection';

export interface InviteFormData {
  emails: string;
}

const emailsListToArray = (emails: string | undefined): string[] => (emails
  ? emails.split(/[\s,]+/gi)
    .map(trim)
    .filter(negate(isEmpty))
  : []);

const inviteValidationSchema = Yup.object<InviteFormData>().shape<InviteFormData>({
  emails: Yup.string().trim()
    .test(
      'emailsValid',
      'All emails must be a valid email addresses',
      (emails) => every(
        emailsListToArray(emails),
        (item) => Yup.string().email().isValidSync(item),
      ),
    )
    .required('At least one email is required'),
});

const initialValues: InviteFormData = { emails: '' };

export const InviteModal: React.FC<{ open: boolean; onClose: () => void; }> = ({
  open,
  onClose,
}) => {
  const profile = useProfile();
  const organization = profile.organization!;

  const [
    inviteUsers,
    { loading: inviteUsersLoading, error: inviteUsersError },
  ] = useMutation<InviteUsers, InviteUsersVariables>(INVITE_USERS);

  const [inviteWarnings, setInviteWarnings] = useState<InviteUsers_inviteUsers_warnings[]>([]);
  const [successInvitationsCount, setSuccessInvitationsCount] = useState<number>(0);

  const closeSuccess = (event: any, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessInvitationsCount(0);
  };

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    submitForm,
    errors,
    isSubmitting,
  } = useFormik({
    initialValues,
    validationSchema: inviteValidationSchema,
    onSubmit: async (submitValues, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      try {
        const emails: string[] = emailsListToArray(submitValues.emails);
        const result = await inviteUsers({
          variables: {
            organization_id: organization.organization_id || 0,
            emails,
          },
          refetchQueries: [USER_INVITES_QID],
        });
        resetForm();
        if (result.data?.inviteUsers) {
          const { invites, warnings } = result.data?.inviteUsers ?? {};
          setSuccessInvitationsCount(invites.length);
          setInviteWarnings(warnings);
          if (!warnings.length) {
            onClose();
          }
        }
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <>
      <CustomDialog
        id="invite-members"
        title="Invite users by email"
        loading={inviteUsersLoading}
        open={open}
        onClose={onClose}
        actions={(
          <Button
            disabled={isSubmitting || inviteUsersLoading}
            color="primary"
            onClick={() => submitForm()}
          >
            Send Invitations
          </Button>
        )}
      >
        <form onSubmit={handleSubmit}>
          <Grid
            container
            spacing={2}
          >
            <Grid item xs={12}>
              <TextField
                style={{ minWidth: '383px' }}
                fullWidth
                multiline
                rows={4}
                name="emails"
                label="Enter multiple email addresses *"
                value={values.emails}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.emails || inviteUsersError)}
                helperText={
                  (errors.emails || inviteUsersError?.message)
                  || 'Please separate multiple addresses with commas or new lines'
                }
              />
            </Grid>
            {inviteWarnings.length > 0 && (
              <Grid item xs={12}>
                <Alert severity="warning">
                  {inviteWarnings.map((item) => (
                    <Box mb={1} key={item.email}>
                      <b>{item.email}</b>
                      {' - '}
                      {item.message}
                    </Box>
                  ))}
                </Alert>
              </Grid>
            )}
          </Grid>
        </form>
      </CustomDialog>

      <SuccessSnackbar open={!!successInvitationsCount} onClose={closeSuccess}>
        {successInvitationsCount}
        {' '}
        {pluralize(successInvitationsCount, 'invitation was', 'invitations were')}
        {' '}
        sent successfully
      </SuccessSnackbar>
    </>
  );
};
