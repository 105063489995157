import { SortDescriptor } from '@progress/kendo-data-query';
import { Order, OrderInput } from '../../api/types/globalTypes';

/**
 * Convert KendoReact Grid's {@link SortDescriptor} into API's {@link OrderInput}
 */
export function convertSortDescriptor(sort: SortDescriptor): OrderInput {
  return {
    field: sort.field,
    order: sort.dir === 'desc' ? Order.DESC : Order.ASC,
  };
}

export function convertToSortDescriptor(order: OrderInput): SortDescriptor {
  return {
    field: order.field,
    dir: order.order === Order.DESC ? 'desc' : 'asc',
  };
}
