import { useHistory } from 'react-router-dom';
import { useCallback, useMemo } from 'react';

export const REDIRECT_PARAM = 'redirect';
export const useLoginSearchParams = () => {
  const history = useHistory();
  const getSearchParams = useCallback(
    () => new URLSearchParams(history.location.search),
    [history.location.search],
  );
  const getRedirect = useCallback(
    () => getSearchParams().get(REDIRECT_PARAM),
    [getSearchParams],
  );

  return useMemo(() => ({
    getRedirect,
    forwarded: getSearchParams().get('ac')
      ? JSON.parse(Buffer.from(getSearchParams().get('ac') ?? '', 'base64').toString())
      : null,
  }), [getSearchParams, getRedirect]);
};
