import { gql } from '@apollo/client';

export const ORGANIZATION_CONTACTS_QUERY = 'OrganizationContacts';
export const ORGANIZATION_CONTACTS = gql`
  query OrganizationContacts {
    org: myOrganization {
      organization_id

      contacts {
        contact_id
        type_id
        same_as_primary

        first_name
        last_name
        title
        email
        phone
      }
    }
  }
`;
