import { useForm } from 'react-hook-form';
import React from 'react';
import { getError, hasError } from '../helpers';
import { CustomFieldRenderComponentProps } from '../types';

const CKEditorField = React.lazy(() => import('../../form/CKEditorField'));

interface CustomFieldWYSWIGProps extends CustomFieldRenderComponentProps {
  value: string;
}

export const CustomFieldWYSWIG: React.FC<CustomFieldWYSWIGProps> = ({
  disabled,
  field,
  value,
  onChange,
}) => {
  const { errors } = useForm();

  return (
    <CKEditorField
      disabled={disabled}
      label={field.name}
      value={value}
      onChange={onChange}
      error={hasError(errors, field)}
      helperText={getError(errors, field) || ' '}
    />
  );
};
