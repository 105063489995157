import { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import * as Sentry from '@sentry/browser';
import { trim, tail } from 'lodash';

export interface FirebaseAuthUnknown {
  loggedIn: undefined; // i.e. no auth request have completed yet
  user: undefined;
}

export interface FirebaseAuthLoggedIn {
  loggedIn: true;
  user: firebase.User;
}

export interface FirebaseAuthLoggedOut {
  loggedIn: false;
  user: undefined;
}

export type FirebaseAuth = FirebaseAuthUnknown | FirebaseAuthLoggedIn | FirebaseAuthLoggedOut;

export const useFirebaseAuth = (): FirebaseAuth => {
  const [firebaseAuth, setFirebaseAuth] = useState<FirebaseAuth>({
    loggedIn: undefined,
    user: undefined,
  });

  useEffect(() => {
    let mounted = true;
    const unsubscribe = firebase.auth().onAuthStateChanged(
      (user) => {
        if (user) {
          if (mounted) {
            setFirebaseAuth({
              loggedIn: true,
              user,
            });
          }
        } else if (mounted) {
          setFirebaseAuth({
            loggedIn: false,
            user: undefined,
          });
        }
      },
      (error) => {
        // eslint-disable-next-line no-alert
        window.alert(`Auth error: ${error.message}`);
      },
    );
    return () => {
      mounted = false;
      unsubscribe();
    };
  }, []);

  return firebaseAuth;
};

export function splitDisplayName(displayName: string): { first?: string; last?: string } {
  let first;
  let last;
  const parts = trim(displayName).split(' ');
  if (parts.length > 0) {
    // eslint-disable-next-line prefer-destructuring
    first = parts[0];
  }
  if (parts.length > 1) {
    last = tail(parts).join(' ');
  }
  return { first, last };
}

export function getDisplayNameOrEmail(user: firebase.User, defaultName: string = 'Anonymous'): string {
  return user.displayName || user.email || defaultName;
}

export function setFreshChatData(userId: number, userProperties: {
  firstName: string,
  lastName: string,
  [key: string]: string,
}) {
  const widget = (window as any).fcWidget;
  if (widget) {
    widget.setExternalId(userId);
    widget.user.setProperties(userProperties);
  }
}

/**
 * Try to create a new Firebase account given email/password,
 * and if that fails with `auth/email-already-in-use` error, try to log in instead.
 * The use case is when the same user failed registration the first time and retries,
 * so the Firebase thinks they're trying to register using an occupied email.
 *
 * Example usage:
 *
 * ```
 * try {
 *   const credentials = await createUserOrSignIn(emailValue, password);
 *   // ... do something with credentials ...
 * } catch (e) {
 *   if (e.code === 'auth/wrong-password') {
 *     alert(
 *       'Your account already exists, but the password is incorrect ' +
 *       'or you\'re using your Google account to sign in. ' +
 *       'Try a different password or use the "Sign in with Google" button above.'
 *     );
 *   } else {
 *     alert(e.message);
 *   }
 * }
 * ```
 */
export function createUserOrSignIn(
  email: string,
  password: string,
): Promise<firebase.auth.UserCredential> {
  return firebase.auth().createUserWithEmailAndPassword(email, password).catch((e) => {
    if (e.code === 'auth/email-already-in-use') {
      return firebase.auth().signInWithEmailAndPassword(email, password);
    }
    throw e;
  });
}

export async function signOut() {
  return firebase.auth().signOut().then(() => {
    Sentry.configureScope((scope) => {
      scope.setUser(null);
    });
  });
}

// DevTools utility functions:

(window as any).firebaseLogOut = () => {
  signOut();
};

(window as any).firebaseGetToken = async () => {
  const user = firebase.auth().currentUser;
  if (user) {
    return `Bearer ${await user.getIdToken()}`;
  }
  return 'User is not set';
};

(window as any).firebaseDeleteUser = () => {
  firebase.auth().currentUser?.delete();
};
