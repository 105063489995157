import React from 'react';
import { Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { TILE_WIDTH, TILE_HEIGHT } from './FilesListItem';

export interface FilesLoadingSkeletonProps {
  width?: number;
  height?: number;
  itemsCount: number;
}

export const FilesLoadingSkeleton: React.FC<FilesLoadingSkeletonProps> = ({
  itemsCount,
  width = TILE_WIDTH,
  height = TILE_HEIGHT,
}) => {
  const skeletonData = new Array(itemsCount).fill('');
  return (
    <Grid container spacing={2}>
      {skeletonData.map((__, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Grid key={index} item>
          <Skeleton
            variant="rectangular"
            width={width}
            height={height}
          />
          <Skeleton
            variant="text"
            width={width / 1.8}
          />
          <Skeleton
            variant="text"
            width={width}
          />
        </Grid>
      ))}
    </Grid>
  );
};
