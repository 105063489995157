import React, { useCallback, useMemo, useState } from 'react';
import { Sidebar } from '../../sidebar/Sidebar';
import { ChatSidebarTitle } from './ChatSidebarTitle';
import { ChatSidebar } from './ChatSidebar';

export interface ChatSidebarCtxProps {
  channelId: string;
  organizationName: string;
  defaultTopic?: string;
}

export const ChatSidebarContext = React.createContext<{
  open(props: ChatSidebarCtxProps): void;
  close(): void;
}>({ open: () => {}, close: () => {} });

export const ChatSidebarProvider: React.FC = ({ children }) => {
  const [props, setProps] = useState<ChatSidebarCtxProps>();

  const open = useCallback((p: ChatSidebarCtxProps) => setProps(p), [setProps]);
  const close = useCallback(() => setProps(undefined), [setProps]);

  const value = useMemo(() => ({
    open,
    close,
  }), [open, close]);

  return (
    <ChatSidebarContext.Provider value={value}>
      <Sidebar
        open={!!props}
        title={props
          ? <ChatSidebarTitle title={`Message ${props.organizationName}`} channelId={props.channelId} />
          : ''}
        content={props
          ? <ChatSidebar channelId={props.channelId} defaultTopic={props.defaultTopic} />
          : null}
        onClose={close}
      >
        {children}
      </Sidebar>
    </ChatSidebarContext.Provider>
  );
};
