import React, { useState } from 'react';
import {
  Alert, AlertColor, AlertTitle, Box, Grid, Button,
} from '@mui/material';
import { useQuery } from '@apollo/react-hooks';
import { makeStyles, createStyles } from '@mui/styles';

import { useProfile } from '../../api/user/profile';
import { BannersList, BannersListVariables } from '../../api/types/BannersList';
import { BANNERS } from './queries/BannersQuery';
import { ErrorSnackbar, useErrorSnackbar } from '../notifications/ErrorSnackbar';

const DISMISSED_BANNERS_STORAGE = 'dismissed_banners';

const useStyles = makeStyles((theme) => createStyles({
  alertBody: {
    '& > p': {
      margin: 0,
    },
  },
  dismissBtn: {
    marginRight: theme.spacing(1),
    alignSelf: 'center',
  },
}));

export const UserBannerList = () => {
  const classes = useStyles();
  const {
    organization: { is_facility_manager, is_service_provider, organization_id },
  } = useProfile();

  const {
    data: tableData,
    error: tableError,
  } = useQuery<BannersList, BannersListVariables>(BANNERS, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        active: { eq: true },
        ...(is_facility_manager ? {
          show_to_fms: { eq: true },
          or: [
            { show_to_fm_organizations: { isNull: true } },
            { show_to_fm_organizations: { jsArrayIncludes: `${organization_id}` } },
          ],
        } : {}),
        ...(is_service_provider ? {
          show_to_sps: { eq: true },
          or: [
            { show_to_sp_organizations: { isNull: true } },
            { show_to_sp_organizations: { jsArrayIncludes: `${organization_id}` } },
          ],
        } : {}),
      },
    },
  });
  const tableErrorSnackbar = useErrorSnackbar(tableError);
  // const cnt = tableData?.banners.total_count;

  const [dismissedBanners, setDismissedBanners] = useState(() => {
    const dismissedBannersRaw = localStorage.getItem(DISMISSED_BANNERS_STORAGE);
    return dismissedBannersRaw ? JSON.parse(dismissedBannersRaw) : [];
  });

  const hideBanner = (banner_id: number) => {
    const dismissed = JSON.stringify([...dismissedBanners, banner_id]);
    localStorage.setItem(DISMISSED_BANNERS_STORAGE, dismissed);
    setDismissedBanners(dismissed);
  };

  return (
    <>
      <Box mb={2}>
        <Grid container spacing={2} direction="column">
          {tableData?.banners.items
            .filter((b) => !dismissedBanners.includes(b.banner_id))
            .map((banner) => (
              <Grid item>
                <Alert
                  severity={banner.type.toLowerCase() as AlertColor}
                  action={(
                    <Button
                      variant="outlined"
                      onClick={() => hideBanner(banner.banner_id)}
                      classes={{ root: classes.dismissBtn }}
                    >
                      Dismiss
                    </Button>
                  )}
                >
                  <AlertTitle>{banner.title}</AlertTitle>
                  {/* eslint-disable-next-line */}
                  <span className={classes.alertBody} dangerouslySetInnerHTML={{ __html: banner.body }} />
                </Alert>
              </Grid>
            ))}
        </Grid>
      </Box>
      <ErrorSnackbar {...tableErrorSnackbar}>
        Could not load app information banners
      </ErrorSnackbar>
    </>
  );
};
