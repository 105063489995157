import React from 'react';
import { Grid } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => createStyles({
  imageContainer: {
    height: 64,
    padding: '10px 0',
  },
  image: {
    height: '100%',
  },
  imageContainerBid: {
    alignSelf: 'stretch',
    padding: '10px 18px 10px 10px',
  },
  imageBid: {
    height: 50,
    marginBottom: -6,
  },
}));

type Props = {
  logoUrl?: string | null;
  isBidLogo?: boolean;
};

export const FmLogo: React.FC<Props> = ({ logoUrl, isBidLogo }) => {
  const classes = useStyles();
  return logoUrl ? (
    <Grid item className={isBidLogo ? classes.imageContainerBid : classes.imageContainer}>
      <img
        src={logoUrl}
        alt="FM logo"
        className={isBidLogo ? classes.imageBid : classes.image}
      />
    </Grid>
  ) : null;
};
