import {
  Channel, StreamChat, UserResponse,
} from 'stream-chat';
import { Profile_profile } from 'api/types/Profile';
import { ChatGenerics } from './streamTypes';
import { getIsMessageBot, ProcBots } from './bots';

/**
 * Get channel name based on user profile
 */
export const getChannelName = (
  channel: Channel<ChatGenerics>,
  profile: Profile_profile,
): string => {
  const { organization: { organization_id: myOrgId } } = profile;
  const { data } = channel;
  const channelName = data?.fm_id === myOrgId
    ? data.sp_name
    : data?.fm_name;

  return channelName ?? `#${channel.id}`;
};

export const getProfileRoles = (profile: Profile_profile): { isFm: boolean; isSp: boolean } => {
  const { organization: { is_service_provider: isSp, is_facility_manager: isFm } } = profile;
  return { isFm, isSp };
};

export const getMessageSenderName = (
  user: UserResponse<ChatGenerics> | null | undefined,
  channel: Channel<ChatGenerics> | null | undefined,
  profile: Profile_profile,
): string => {
  if (!user) return 'Unknown Sender';
  const { isFm, isSp } = getProfileRoles(profile);
  if (channel && getIsMessageBot(user)) {
    // use my org name if bot belongs to my org - otherwise use the other org name
    if ((isFm && user.id === ProcBots.FM) || (isSp && user.id === ProcBots.SP)) {
      return profile.organization.name;
    }
    return getChannelName(channel, profile);
  }
  return user.name ?? user.id;
};

export const getFmSpChannelId = (org1: number, org2: number) => {
  const orgs = [org1, org2];
  return `fmsp-${Math.min(...orgs)}-${Math.max(...orgs)}`;
};

export const getChatUserId = (userId: number): string => `user-${userId}`;

export const getProcUserId = (memberId: string) => parseInt(memberId.replace('user-', ''), 10);

export const getChannelArchivedKey = (profile: Profile_profile): 'archived_by_fm' | 'archived_by_sp' => (getProfileRoles(profile).isSp ? 'archived_by_sp' : 'archived_by_fm');

/**
 * It simply pass-tru original array if it's not empty,
 * otherwise returns [-1] to make query return nothing
 */
export function safeInFilter<T extends unknown>(data: T[]): T[] | [-1] {
  return data.length ? data : [-1];
}

// Dev functions
export const registerDevFunctions = (client: StreamChat<ChatGenerics>) => {
  (window as any).muteFMBot = () => client.muteUser(ProcBots.FM);
  (window as any).muteSPBot = () => client.muteUser(ProcBots.SP);

  (window as any).unmuteFMBot = () => client.unmuteUser(ProcBots.FM);
  (window as any).unmuteSPBot = () => client.unmuteUser(ProcBots.SP);
};
