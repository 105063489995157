import React, { useMemo } from 'react';
import { SvgIcon } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { InnerPage } from './InnerPage';
import { MenuListItem } from '../components/nav/menu';
import { PATHS } from '../paths';
import { useLoginAs } from './useLoginAs';
import { Permissions } from '../api/permissions/permissions';
import { SpSidebarProvider } from '../components/organization/fm/inviteServiceProviders/sidebar/SpSidebarProvider';

const primaryNav: MenuListItem[] = [
  {
    id: 'fm-dashboard',
    type: '_menu_item_url',
    icon: (
      <SvgIcon>
        <FontAwesomeIcon icon="tachometer-alt" />
      </SvgIcon>
    ),
    text: 'Dashboard',
    url: PATHS.fm.dashboard,
    pathMatchPrefix: PATHS.fm.dashboard,
  },
  {
    id: 'fm-organization',
    type: '_menu_item_url',
    icon: (
      <SvgIcon>
        <FontAwesomeIcon icon="building" />
      </SvgIcon>
    ),
    text: 'Organization',
    url: PATHS.fm.organizationInfo.main,
    pathMatchPrefix: PATHS.fm.organizationInfo.main,
  },
];

export const FacilityManagerPage: React.FC = ({ children }) => {
  const { loginAs } = useLoginAs();

  const secondaryNav: MenuListItem[] = useMemo(() => ([
    {
      id: 'fm-sites',
      type: '_menu_item_url',
      icon: (
        <SvgIcon>
          <FontAwesomeIcon icon="map-marker-alt" />
        </SvgIcon>
      ),
      text: 'Sites',
      url: PATHS.fm.organizationSites,
    },
    {
      id: 'fm-bid-center',
      type: '_menu_item_url',
      icon: (
        <SvgIcon>
          <FontAwesomeIcon icon="megaphone" />
        </SvgIcon>
      ),
      text: 'Bid Center',
      url: PATHS.fm.organizationBundles,
    },
    {
      id: 'fm-bundler',
      type: '_menu_item_url',
      icon: (
        <SvgIcon>
          <FontAwesomeIcon icon="archive" />
        </SvgIcon>
      ),
      text: 'Bundler',
      url: PATHS.fm.bundler,
    },
    {
      id: 'fm-chat',
      type: '_menu_item_url',
      icon: (
        <SvgIcon>
          <FontAwesomeIcon icon="comment-alt-lines" />
        </SvgIcon>
      ),
      text: 'Messages',
      url: PATHS.fm.chat,
      disabled: loginAs !== null,
      title: loginAs !== null
        ? 'You cannot message as yourself from another organization'
        : undefined,
      permission: Permissions.SEND_MESSAGE,
    },
    {
      id: 'fm-contracts',
      type: '_menu_item_url',
      icon: (
        <SvgIcon>
          <FontAwesomeIcon icon="file-signature" />
        </SvgIcon>
      ),
      text: 'Contracts',
      url: PATHS.fm.organizationContractTerms,
    },
    {
      id: 'fm-screeners',
      type: '_menu_item_url',
      icon: (
        <SvgIcon>
          <FontAwesomeIcon icon="filter" />
        </SvgIcon>
      ),
      text: 'Screeners',
      url: PATHS.fm.organizationScreeners,
    },
    {
      id: 'fm-providers',
      type: '_menu_item_url',
      icon: (
        <SvgIcon>
          <FontAwesomeIcon icon="briefcase" />
        </SvgIcon>
      ),
      text: 'My Providers',
      url: PATHS.fm.organizationProviders,
    },
    {
      id: 'fm-reports',
      type: '_menu_item_url',
      icon: (
        <SvgIcon>
          <FontAwesomeIcon icon="chart-bar" />
        </SvgIcon>
      ),
      text: 'Reports',
      url: PATHS.fm.reports,
      permission: Permissions.ORGANIZATION_OWNER,
    },
  ]), [loginAs]);

  return (
    <InnerPage navType="fm" primaryNav={primaryNav} secondaryNav={secondaryNav}>
      <SpSidebarProvider navType="fm">
        {children}
      </SpSidebarProvider>
    </InnerPage>
  );
};
