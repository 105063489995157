import React, {
  useMemo, useState, useEffect, useCallback,
} from 'react';
import {
  FormControl, Grid, MenuItem, Select, TextField,
} from '@mui/material';
import {
  isArray, isNil, isNaN, toString,
} from 'lodash';

import { WhereOperationsInput } from 'api/types/globalTypes';

import { Filter, isFilterSimple } from '../types';
import { FilterWidgetProps } from './FilterWidgetProps';
import { FilterWidgetDialog } from './FilterWidgetDialog';

const OPS_WITH_2_VALUES = ['between', 'notBetween'] as (keyof WhereOperationsInput)[];

function getNumber(value: string): number {
  try {
    const v = Number.parseInt(value, 10);
    return isNaN(v) ? 0 : v;
  } catch {
    return 0;
  }
}

export const IntegerFilterWidget: React.FC<FilterWidgetProps> = ({
  tableId,
  field,
  filter: externalFilter,
  filterClearedTimestamp,
  title,
  onApply,
  onClear,
  onClose,
  open,
}) => {
  const labelId = useMemo(
    () => `${tableId}-${field}-number-filter-operator-label`,
    [field, tableId],
  );

  const initialFilter: Filter = useMemo(() => externalFilter ?? {
    field,
    type: 'integer',
    logic: undefined,
    where: { eq: 0 },
  }, [externalFilter, field]);
  const [filter, setFilter] = useState<Filter>(initialFilter);

  useEffect(() => {
    if (filterClearedTimestamp) {
      setFilter(initialFilter);
    }
  }, [filterClearedTimestamp, initialFilter]);

  const op = useMemo(
    () => Object.keys(filter.where)[0] as keyof WhereOperationsInput,
    [filter],
  );

  const values = useMemo(() => {
    if (!isFilterSimple(filter)) {
      throw new Error(
        'Expected a simple filter,'
          + `got ${JSON.stringify(filter)} on a field '${field}' in table with id '${tableId}'`,
      );
    }
    return isArray(filter.where[op])
      ? filter.where[op]
      : [filter.where[op]];
  }, [field, filter, op, tableId]);

  const twoValues = OPS_WITH_2_VALUES.includes(op);

  const handleSubmit = useCallback((ev) => {
    ev.preventDefault();
    onApply(field, filter);
    onClose(field);
  }, [field, filter, onApply, onClose]);

  return (
    <FilterWidgetDialog
      tableId={tableId}
      field={field}
      title={title}
      onApply={() => onApply(field, filter)}
      onClear={() => onClear(field)}
      onClose={onClose}
      open={open}
    >
      <form onSubmit={handleSubmit}>
        <Grid
          container
          spacing={1}
          direction="column"
          justifyContent="flex-start"
          alignContent="stretch"
          alignItems="stretch"
        >
          <Grid item>
            <FormControl fullWidth variant="outlined">
              <Select
                fullWidth
                labelId={labelId}
                value={op}
                onChange={(e) => {
                  const newOp = e.target.value as keyof WhereOperationsInput;
                  const newTwoValues = OPS_WITH_2_VALUES.includes(newOp);
                  const v1 = isNaN(values[0]) || isNil(values[0]) ? 0 : values[0];
                  const v2 = isNaN(values[1]) || isNil(values[1]) ? 0 : values[1];
                  setFilter({
                    logic: undefined,
                    field,
                    type: 'integer',
                    where: {
                      [newOp]: newTwoValues ? [v1, v2] : v1,
                    },
                  });
                }}
              >
                <MenuItem value="eq">Equals</MenuItem>
                <MenuItem value="not">Does not equal</MenuItem>
                <MenuItem value="gt">Greater than</MenuItem>
                <MenuItem value="gte">Greater than or equals</MenuItem>
                <MenuItem value="lt">Less than</MenuItem>
                <MenuItem value="lte">Less than or equals</MenuItem>
                <MenuItem value="between">Between (inclusive)</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              autoFocus
              label={twoValues ? 'Min. value' : 'Value'}
              value={toString(isNaN(values[0]) || isNil(values[0]) ? 0 : values[0])}
              type="number"
              onChange={(e) => {
                setFilter({
                  logic: undefined,
                  field,
                  type: 'integer',
                  where: {
                    [op]: twoValues
                      ? [getNumber(e.target.value), values[1]]
                      : getNumber(e.target.value),
                  },
                });
              }}
            />
          </Grid>
          {twoValues && (
            <Grid item>
              <TextField
                fullWidth
                label={twoValues ? 'Max. value' : 'Value'}
                value={toString(isNaN(values[1]) || isNil(values[1]) ? 0 : values[1])}
                type="number"
                onChange={(e) => {
                  setFilter({
                    logic: undefined,
                    field,
                    type: 'integer',
                    where: {
                      [op]: [
                        values[0],
                        getNumber(e.target.value),
                      ],
                    },
                  });
                }}
              />
            </Grid>
          )}
        </Grid>
        <input type="submit" hidden />
      </form>
    </FilterWidgetDialog>
  );
};
