import React from 'react';
import { MenuItem, Select } from '@mui/material';
import { useChatContext } from '../ChatContext';
import { ChannelSortType } from './useChannelSort';

export const ChannelSort: React.FC = () => {
  const {
    sort: {
      setSortBy,
      sortBy,
    },
  } = useChatContext();

  return (
    <Select
      label="Sort by"
      id="room-filters"
      value={sortBy}
      fullWidth
      variant="standard"
      onChange={(e) => {
        setSortBy(e.target.value as ChannelSortType);
      }}
    >
      <MenuItem value={ChannelSortType.RECENT_MESSAGE}>Sort by recent messages</MenuItem>
      <MenuItem value={ChannelSortType.UNREAD}>Sort by unreads</MenuItem>
      <MenuItem value={ChannelSortType.ARCHIVED}>Archived rooms first</MenuItem>
    </Select>
  );
};
