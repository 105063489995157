import React, { useMemo, useState } from 'react';
import { SvgIcon } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PATHS } from '../../../../paths';
import { BlankInnerPage } from '../../../../pages/BlankInnerPage';
import { OnboardingProvider } from './OnboardingContext';
import { OnBoardingContent } from './OnBoardingContent';

interface OnBoardingMainProps {
  children: React.ReactElement;
}

export const OnBoardingMain: React.FC<OnBoardingMainProps> = ({ children }) => {
  const [completeness, setCompleteness] = useState<any>({});

  const completeBadgeIcon = useMemo(() => (
    <SvgIcon
      style={{
        // width: '0.4em',
        // height: '0.4em',
        background: 'green',
        borderRadius: 40,
        padding: 5,
      }}
    >
      <FontAwesomeIcon icon="check" color="white" />
    </SvgIcon>
  ), []);
  const primaryNav = useMemo(() => ([
    {
      id: 'company_info',
      text: 'Company Info',
      title: 'Company Info',
      icon: (
        <SvgIcon>
          <FontAwesomeIcon icon="building-magnifying-glass" />
        </SvgIcon>
      ),
      type: '_menu_item_url' as const,
      url: PATHS.sp.onboarding.profile,
      iconRight: completeness.profile_percentage === 1 ? completeBadgeIcon : undefined,
    },
    {
      id: 'key_contacts',
      text: 'Key Contacts',
      icon: (
        <SvgIcon>
          <FontAwesomeIcon icon="user-plus" />
        </SvgIcon>
      ),
      title: 'Key Contacts',
      type: '_menu_item_url' as const,
      url: PATHS.sp.onboarding.key_contacts,
      iconRight: completeness.key_contacts_percentage === 1 ? completeBadgeIcon : undefined,
    },
    {
      id: 'services',
      text: 'Services',
      title: 'Services',
      icon: (
        <SvgIcon>
          <FontAwesomeIcon icon="screwdriver-wrench" />
        </SvgIcon>
      ),
      type: '_menu_item_url' as const,
      url: PATHS.sp.onboarding.services,
      iconRight: completeness.services_percentage === 1 ? completeBadgeIcon : undefined,
    },
    {
      id: 'diversity',
      text: 'Diversity',
      icon: (
        <SvgIcon>
          <FontAwesomeIcon icon="person-circle-question" />
        </SvgIcon>
      ),
      title: 'Diversity & Classification',
      type: '_menu_item_url' as const,
      url: PATHS.sp.onboarding.diversity,
      iconRight: completeness.diversity_percentage === 1 ? completeBadgeIcon : undefined,
    },
    {
      id: 'documents',
      text: 'Documents',
      title: 'Documents',
      icon: (
        <SvgIcon>
          <FontAwesomeIcon icon="file-alt" />
        </SvgIcon>
      ),
      type: '_menu_item_url' as const,
      url: PATHS.sp.onboarding.documents,
      iconRight: completeness.documents_percentage === 1 ? completeBadgeIcon : undefined,
    },
    // {
    //   id: 'review',
    //   text: 'Review',
    //   title: 'Review',
    //   type: '_menu_item_url' as const,
    //   url: PATHS.sp.onboarding.review,
    // },
  ]), [completeness, completeBadgeIcon]);
  return (
    <OnboardingProvider>
      <BlankInnerPage
        navType="sp"
        primaryNav={primaryNav}
      >
        <OnBoardingContent setCompleteness={setCompleteness}>
          {children}
        </OnBoardingContent>
      </BlankInnerPage>
    </OnboardingProvider>
  );
};
