import { gql } from '@apollo/client';

export const FM_SIDEBAR_BIDS_QUERY = 'FmSidebarBids';
export const FM_SIDEBAR_BIDS = gql`
  query FmSidebarBids(
    $skip: Int!
    $take: Int!
    $where: WhereBidInput
    $order: [OrderInput!]
  ) {
    fmBids(
      skip: $skip
      take: $take
      where: $where
      order: $order
    ) {
      items {
        bid_id
        end_date
        status_id
        _status { rank }
        submitted_on
        envelope {
          envelope_id
          sp_signed_on
          status: roleStatus(role: "fm") { status_id, name, icon, color }
        }
        bundle {
          name
          organization_id
          summary { sites }
        }
        declineReason { name }
        latestStatusLog {
          changed_on
          changedBy { first_name, last_name }
        }
      }
      total_count
      has_more
    }
  }
`;
