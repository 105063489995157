import React, { useCallback, useState } from 'react';
import {
  Snackbar, SnackbarProps, Alert, AlertTitle, SnackbarCloseReason,
} from '@mui/material';

export interface SuccessSnackbarProps extends Omit<SnackbarProps, 'children'> {
  title?: string;
  onClose?: (event: React.SyntheticEvent<any> | Event, reason?: SnackbarCloseReason) => void;
  children: React.ReactNode;
}

export const useSuccessMessage = () => {
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  return {
    successMessage,
    setSuccessMessage,
    open: !!successMessage,
    onClose: useCallback(() => setSuccessMessage(null), [setSuccessMessage]),
  };
};

export const SuccessSnackbar: React.FC<SuccessSnackbarProps> = ({
  title,
  onClose,
  children,
  ...snackbarProps
}) => (
  <Snackbar
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    autoHideDuration={10000}
    onClose={onClose}
    {...snackbarProps}
  >
    <Alert severity="success" onClose={onClose} variant="filled">
      {title && (<AlertTitle>{title}</AlertTitle>)}
      {children}
    </Alert>
  </Snackbar>
);
