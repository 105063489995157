import L from 'leaflet';
import { useEffect, useState } from 'react';
import { colors } from '../../theme';

export const LOADING_STARTED = 'map:loading_started';
export const LOADING_ENDED = 'map:loading_ended';

export const getLoadingControl = (title?: string) => L.Control.extend({
  onAdd(map: L.Map) {
    const bar = L.DomUtil.create('div', 'leaflet-bar') as HTMLDivElement;

    // label
    const label = L.DomUtil.create('div') as HTMLDivElement;
    label.appendChild(document.createTextNode(title ?? 'Loading...'));
    label.style.padding = '1px 4px';
    bar.appendChild(label);

    // bar styles
    bar.style.backgroundColor = colors.grey;
    bar.style.borderColor = colors.darkGrey;
    bar.style.border = 'none';

    // events
    map.on(LOADING_STARTED, () => {
      bar.style.display = 'block';
    });
    map.on(LOADING_ENDED, () => {
      bar.style.display = 'none';
    });
    return bar;
  },
});

export function useLoadingControl(
  map: L.Map | undefined,
  loading: boolean,
  title?: string,
) {
  const [loadingControl, setLoadingControl] = useState<L.Control>();
  useEffect(() => {
    if (!map) {
      return () => {};
    }

    const LoadingControl = getLoadingControl(title);
    const control = new LoadingControl({ position: 'bottomleft' });
    map.addControl(control);
    setLoadingControl(control);
    return () => map.removeControl(control);
  }, [map, title]);

  useEffect(() => {
    if (map && loadingControl) {
      if (loading) {
        map.fire(LOADING_STARTED);
      } else {
        map.fire(LOADING_ENDED);
      }
    }
  }, [map, loadingControl, loading]);
}
