import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

export const useCurrentPath = () => {
  const history = useHistory();
  const [currentPath, setCurrentPath] = useState(history.location.pathname);
  useEffect(() => {
    const unsubscribe = history.listen((l) => {
      setCurrentPath(l.pathname);
    });
    return () => unsubscribe();
  }, [history]);
  return currentPath;
};
