import { gql } from '@apollo/client';

export const FM_SP_STATS_QUERY = 'FmSpStats';
export const FM_SP_STATS = gql`
    query FmSpStats(
      $where: WhereFmSpStatInput
      $order: [OrderInput!]
      $skip: Int!
      $take: Int!
    ) {
      _fmSpStats(where: $where, order: $order, skip: $skip, take: $take) {
        total_count
        has_more

        items {
          sp_organization_id
          has_worked_with
          is_incumbent
          preference_level

          spStatLevel {
            levels
            blacklisted
            rank
          }

          awardedBundles {
            bundle_id
            name
            status_id
            status_name
            status_rank
            service_id
          }

          spOrg {
            organization_id
            name
            address {
              latlong
              address_line_1
              address_line_2
              city
              country_code
              postal_code
              state_code
            }
            services {
              service_id
              name
              rank
              icon
              color
            }
          }

          business_entity_type: serviceFieldValue(name: "Type")
          business_classifications: serviceFieldValue(name: "Classifications")
          business_owned_by: serviceFieldValue(name: "If your company is at least 51 percent owned, controlled and operated by one or more of the following, please check all that apply:")
        }
      }
    }
`;
