import { trim } from 'lodash';
import { ADMIN_UI_URL_PREFIX } from '../paths';

export const checkPathType = (path: string) => ({
  isAdminPath: new RegExp(`^/${ADMIN_UI_URL_PREFIX}/`).test(path),
  isSpPath: /^\/sp\//.test(path),
});

export const websiteErrorMessage = 'Please provide a valid URL, make sure your URL starts with a protocol, i.e. https://';

export const websiteInitialValue = 'https://';

export const clearWebsiteValue = (website: string | null | undefined): string | null => {
  if (!website || website === websiteInitialValue || !trim(website)) {
    return null;
  }
  return trim(website);
};
