import React, { useState } from 'react';
import { Box } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { SpProfile_publicOrganization_spProfile_files } from '../../../../api/types/SpProfile';
import { colors } from '../../../theme';

const useStyles = makeStyles(() => createStyles({
  galleryImage: {
    cursor: 'pointer',
  },
  image: {
    width: '100%',
  },
  title: {
    position: 'absolute',
    width: '100%',
    height: '2.5rem',
    padding: '0 0.5rem',
    backgroundColor: colors.transparentBlack,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  fileName: {
    color: colors.white,
    fontSize: '0.8rem',
    lineHeight: '1rem',
    overflow: 'hidden',
    display: 'box',
    width: '100%',
    boxOrient: 'vertical',
    lineClamp: 2,
  },
}));

interface GalleryImageProps {
  data: SpProfile_publicOrganization_spProfile_files & { onClick: () => void };
}

export const GalleryImage: React.FC<GalleryImageProps> = ({
  data: {
    file_id, name, url, onClick,
  },
}) => {
  const classes = useStyles();

  const [showTitle, setShowTitle] = useState(false);

  return (
    <Box
      className={classes.galleryImage}
      onMouseEnter={() => setShowTitle(true)}
      onMouseLeave={() => setShowTitle(false)}
      onClick={onClick}
    >
      <Box className={classes.title} style={{ display: showTitle ? 'flex' : 'none' }}>
        <span className={classes.fileName}>
          {name ?? `Image #${file_id}`}
        </span>
      </Box>
      <img
        src={url}
        alt={name ?? `Image #${file_id}`}
        className={classes.image}
      />
    </Box>
  );
};
