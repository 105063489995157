import React from 'react';
import { Box, Alert, AlertTitle } from '@mui/material';
import { bytesToMb } from '../../utils/number';
import { MAX_UPLOAD_SIZE } from '../user/constants';

export interface MaxFileWarningProps {
  fileSize: number;
}

export const MaxFileWarning: React.FC<MaxFileWarningProps> = ({ fileSize }) => (
  <Box mb={2}>
    <Alert severity="warning">
      <AlertTitle>Files size limit is reached</AlertTitle>
      Total size of all files has to be &lt;
      {' '}
      {bytesToMb(MAX_UPLOAD_SIZE)}
      MB
      {' '}
      per one upload.
      The total size of your files is
      {' '}
      <strong>
        {bytesToMb(fileSize)}
        MB
      </strong>
    </Alert>
  </Box>
);
