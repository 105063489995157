import { gql } from '@apollo/client';

export const USER_NOTIFICATION_SETTINGS_QUERY = 'UserNotificationSettings';
export const USER_NOTIFICATION_SETTINGS = gql`
  query UserNotificationSettings($where: WhereUserNotificationSettingInput) {
    userNotificationSettings(take: 1000, where: $where) {
      items {
        event_id
        service_id
        sms_enabled
        email_enabled
        email_reminder_enabled
      }
    }

    notificationEvents(order: [{ field: "rank" }]) {
      items {
        event_id
        name
        rank
        per_service
        has_sms
        has_email
        has_email_reminder
      }
    }
  }
`;
