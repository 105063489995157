import { Avatar, AvatarProps } from '@mui/material';
import React, { forwardRef } from 'react';
import cn from 'classnames';
import { createStyles, makeStyles } from '@mui/styles';
import { deepOrange } from '@mui/material/colors';

const useStyles = makeStyles((theme) => createStyles({
  avatarCircle: {
    fontSize: '0.9rem',
    minWidth: '1.3rem',
    height: '1.3rem',
    width: 'fit-content',
    padding: '0.1rem 0.2rem 0',
    margin: '0.1rem',
    borderRadius: '0.65rem',
  },
  withCount: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
}));

interface Props extends AvatarProps {
  count: number;
}

export const CircleCount = forwardRef<HTMLDivElement, Props>((
  {
    count,
    className,
    ...props
  }, ref,
) => {
  const classes = useStyles();
  return (
    <Avatar
      ref={ref}
      className={cn(classes.avatarCircle, count && classes.withCount)}
      {...props}
    >
      {count}
    </Avatar>
  );
});
