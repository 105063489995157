import React from 'react';
import {
  Box, Chip, Grid, Tooltip, Typography,
} from '@mui/material';
import { ClearAll, Close } from '@mui/icons-material';

import { DataGridProps } from '../DataGridProps';
import { ColumnProps } from '../column/Column';
import { FilterChipLabel } from './FilterChipLabel';
import { Filter, FilterOption } from './types';
import { UseFiltersResult } from './useFilters';
import { isFilterWidgetPropsWithOptions } from './FilterWidgetProps';

export interface FilterChipsProps {
  filters: Filter[];
  clearFilter: UseFiltersResult['clearFilter'];
  clearAllFilters: UseFiltersResult['clearAllFilters'];
  toggleFilterWidget: (field: string, open: boolean) => void;
  overrideFilters?: DataGridProps['overrideFilters'];
  onOverrideFiltersDelete?: DataGridProps['onOverrideFiltersDelete'];
  columns: ColumnProps[];
  columnTitles: { [name: string]: string };
}

function getFilterOptions(columns: ColumnProps[], field: string): FilterOption[] | undefined {
  const column = columns.find((c) => c.field === field);
  if (!column) return undefined;
  const { filterWidget } = column;
  if (isFilterWidgetPropsWithOptions(filterWidget) && filterWidget.substituteOptionLabels) {
    return filterWidget.options;
  }
  return undefined;
}

export const FilterChips: React.FC<FilterChipsProps> = ({
  filters,
  clearFilter,
  clearAllFilters,
  toggleFilterWidget,
  overrideFilters,
  onOverrideFiltersDelete,
  columns,
  columnTitles,
}) => (
  <Box padding={1}>
    <Grid
      container
      direction="row"
      wrap="wrap"
      spacing={1}
      alignContent="flex-start"
      justifyContent="flex-start"
      alignItems="center"
    >
      {overrideFilters && (
        <Grid item>
          <Tooltip title="This filter is applied via navigation. You can only delete it, but not edit.">
            <Chip
              color="secondary"
              variant="outlined"
              label={overrideFilters.map((f, idx) => (
                <React.Fragment key={f.field}>
                  <FilterChipLabel
                    filter={f}
                    columnTitles={columnTitles}
                    filterOptions={getFilterOptions(columns, f.field)}
                  />
                  {(idx < overrideFilters.length - 1) && (
                    <Grid item>
                      <Typography
                        component="span"
                        color="textSecondary"
                        variant="body2"
                      >
                        and
                      </Typography>
                    </Grid>
                  )}
                </React.Fragment>
              ))}
              size="small"
              onDelete={onOverrideFiltersDelete}
              deleteIcon={<Close />}
            />
          </Tooltip>
        </Grid>
      )}
      {filters.map((f, idx) => {
        const column = columns.find((c) => c.field === f.field);
        if (!column) {
          return null;
        }
        const { filterWidget } = column;
        // We need to pass options only if we want to substitute values with labels
        const needOptions = isFilterWidgetPropsWithOptions(filterWidget)
          && filterWidget.substituteOptionLabels;
        return (
          <React.Fragment key={f.field}>
            <Grid item>
              <Chip
                label={(
                  <FilterChipLabel
                    filter={f}
                    columnTitles={columnTitles}
                    filterOptions={needOptions ? filterWidget.options : undefined}
                    isNullTitle={filterWidget?.isNullTitle}
                  />
                )}
                size="small"
                onDelete={() => clearFilter(f.field)}
                deleteIcon={<Close />}
                onClick={() => toggleFilterWidget(f.field, true)}
              />
            </Grid>
            {(idx < filters.length - 1) && (
              <Grid item>
                <Typography
                  component="span"
                  color="textSecondary"
                  variant="body2"
                >
                  and
                </Typography>
              </Grid>
            )}
          </React.Fragment>
        );
      })}
      {filters.length > 1 && (
        <>
          <Grid item>
            <Typography
              component="span"
              color="textSecondary"
              variant="body2"
            >
              {' - '}
            </Typography>
          </Grid>
          <Grid item>
            <Chip
              label="Clear all"
              size="small"
              color="secondary"
              onDelete={clearAllFilters}
              deleteIcon={<ClearAll />}
              onClick={clearAllFilters}
            />
          </Grid>
        </>
      )}
    </Grid>
  </Box>
);
