import { gql } from '@apollo/client';

export const FM_SP_STAT_QUERY = 'FmSpStat';
export const FM_SP_STAT = gql`
  query FmSpStat($fm_organization_id: Int!, $sp_organization_id: Int!) {
    fmSpStat(fm_organization_id: $fm_organization_id, sp_organization_id: $sp_organization_id) {
      sp_organization_id
      has_worked_with
      is_incumbent
      preference_level
      sp_preference_level

      spStatLevel {
        levels
        blacklisted
        rank
      }
    }

    fmSpLevels {
      level_id
      icon
      color
      name
      description
    }
  }
`;
