import { useChannelStateContext as useDefaultChannelStateContext } from 'stream-chat-react/dist/context/ChannelStateContext';
import { useChatContext as useDefaultChatContext } from 'stream-chat-react/dist/context/ChatContext';
import { ChatGenerics } from '../streamTypes';

export const useChannelStateContext = (componentName?: string) => useDefaultChannelStateContext<
  ChatGenerics
>(componentName);

export const useChatContext = (componentName?: string) => useDefaultChatContext<
  ChatGenerics
  >(componentName);
