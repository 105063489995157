import { makeStyles } from '@mui/styles';
import { TILE_WIDTH } from '../filesList/FilesListItem';

export const useDocRepoDialogStyles = makeStyles((theme) => ({
  paper: {
    width: TILE_WIDTH * 4
      + parseInt(theme.spacing(2), 10) * 6
      + 2 + 17, // 4 items + spacing + 2*borders + scrollbar
  },
}));
