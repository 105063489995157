import React from 'react';
import { Box, Paper } from '@mui/material';
import { withErrorBoundary } from 'components/notifications/withErrorBoundary';

import { Filter } from './filter/types';
import { ColumnProps } from './column/Column';
import { UseFiltersResult } from './filter/useFilters';
import { UseFreezingResult } from './column/useFreezing';
import { DataGridProps } from './DataGridProps';
import { ControlPanel } from './ControlPanel';

export type TableAndMapLayoutMode = 'table' | 'map';

export interface TableAndMapLayoutProps extends UseFreezingResult {
  withMap: boolean;
  mode: TableAndMapLayoutMode;
  onModeChange: (mode: TableAndMapLayoutMode) => void;
  beforeFilters?: React.ReactNode;
  afterFilters?: React.ReactNode;
  filters: Filter[];
  clearFilter: UseFiltersResult['clearFilter'];
  clearAllFilters: UseFiltersResult['clearAllFilters'];
  toggleFilterWidget: (field: string, open: boolean) => void;
  overrideFilters?: DataGridProps['overrideFilters'];
  onOverrideFiltersDelete?: DataGridProps['onOverrideFiltersDelete'];
  columns: ColumnProps[];
  columnTitles: { [name: string]: string };
  hiddenColumns: { [field: string]: boolean };
  toggleColumn: (field: string, show: boolean) => void;
  table: JSX.Element;
  map?: JSX.Element;
  onReset: () => void;
  noBorder?: boolean;
  noPanel?: boolean;
  noColumnSettings?: boolean;
  tinyFilters?: boolean;
}

const TableAndMap: React.FC<Pick<TableAndMapLayoutProps, 'mode' | 'table' | 'map'>> = ({
  mode,
  table,
  map,
}) => (
  <>
    <Box style={{ display: mode === 'table' ? 'block' : 'none' }}>
      {table}
    </Box>
    {map && (
      <Box style={{ display: mode === 'map' ? 'block' : 'none' }}>
        {map}
      </Box>
    )}
  </>
);

const TableAndMapErrorBounded = withErrorBoundary(TableAndMap);

export const TableAndMapLayout: React.FC<TableAndMapLayoutProps> = ({
  withMap,
  mode,
  onModeChange,
  beforeFilters,
  afterFilters,
  filters,
  clearFilter,
  clearAllFilters,
  toggleFilterWidget,
  overrideFilters,
  onOverrideFiltersDelete,
  columns,
  columnTitles,
  hiddenColumns,
  toggleColumn,
  table,
  map,
  onReset,
  noBorder,
  noPanel,
  noColumnSettings,
  tinyFilters,
  ...freezingProps
}) => (
  <Paper {...noBorder && { variant: 'elevation', elevation: 0 }}>
    {!noPanel && (
      <ControlPanel
        withMap={withMap}
        mode={mode}
        onModeChange={onModeChange}
        beforeFilters={beforeFilters}
        afterFilters={afterFilters}
        filters={filters}
        clearFilter={clearFilter}
        clearAllFilters={clearAllFilters}
        toggleFilterWidget={toggleFilterWidget}
        overrideFilters={overrideFilters}
        onOverrideFiltersDelete={onOverrideFiltersDelete}
        columns={columns}
        columnTitles={columnTitles}
        hiddenColumns={hiddenColumns}
        toggleColumn={toggleColumn}
        onReset={onReset}
        noColumnSettings={noColumnSettings}
        tinyFilters={tinyFilters}
        {...freezingProps}
      />
    )}
    <TableAndMapErrorBounded
      mode={mode}
      table={table}
      map={map}
    />
  </Paper>
);
