import React from 'react';
import {
  FormControl, FormControlProps, FormHelperText, InputLabel, MenuItem, Select,
} from '@mui/material';
import { SelectProps } from '@mui/material/Select/Select';

export interface SelectOption {
  value: number | string;
  text: string | JSX.Element | null;
}

export interface SelectFieldProps {
  label?: string;
  options: SelectOption[];
  helperText?: React.ReactNode;
  error?: boolean;
  fullWidth?: boolean;
  formControlProps?: FormControlProps;
  allowEmpty?: boolean;
  emptyOption?: SelectOption;
}

export const SelectField: React.FC<SelectFieldProps & SelectProps> = ({
  label,
  options,
  value,
  error,
  onChange,
  helperText,
  fullWidth = true,
  formControlProps,
  allowEmpty = false,
  placeholder,
  emptyOption = { text: 'None', value: '' },
  ...props
}) => (
  <FormControl
    fullWidth={fullWidth}
    variant="outlined"
    error={error}
    {...formControlProps}
  >
    {label && <InputLabel>{label}</InputLabel>}
    <Select
      fullWidth
      label={label}
      value={value}
      onChange={onChange}
      {...props}
    >
      {placeholder && <MenuItem value="" disabled>{placeholder}</MenuItem>}
      {allowEmpty && <MenuItem value={emptyOption.value}><em>{emptyOption.text}</em></MenuItem>}
      {options.map((o) => (
        <MenuItem key={o.value} value={o.value}>{o.text}</MenuItem>
      ))}
    </Select>
    {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
  </FormControl>
);
