import { useCallback, useMemo, useState } from 'react';
import { GridColumnReorderEvent } from '@progress/kendo-react-grid';
import { fromPairs, toPairs, max } from 'lodash';
import { ColumnProps } from './Column';

export interface UseReorderableColumnsResult {
  columnOrder: { [field: string]: number };
  // Max. value of order. Useful for positioning "Actions" column to the right of all other columns
  maxOrder: number;
  onColumnReorder: (e: GridColumnReorderEvent) => void;
  onColumnToggle: (field: string, show: boolean) => void;
  resetOrder: () => void;
}

/**
 * Normalizing field indices. Effectively it removes gaps b/w indices.
 * @param items
 */
function normalizeIndexes(items: [string, number][]): [string, number][] {
  return items
    .sort((a, b) => a[1] - b[1])
    .map(([f], idx) => [f, idx]);
}

export function useReorderableColumns(
  tableId: string,
  visibleColumns: ColumnProps[],
): UseReorderableColumnsResult {
  const storageKey = useMemo(() => `${tableId}.columnOrder`, [tableId]);

  const storedValue: { [field: string]: number } | undefined = useMemo(() => {
    const stringValue = window.localStorage.getItem(storageKey);
    if (stringValue && stringValue !== 'undefined') {
      return JSON.parse(stringValue);
    }
    return undefined;
  }, [storageKey]);

  const [columnOrder, setColumnOrder] = useState<{ [field: string]: number }>(
    storedValue ?? fromPairs(
      visibleColumns.map(({ field }, idx) => [field, idx]),
    ),
  );

  const maxOrder = useMemo(
    () => max(toPairs(columnOrder).map(([, o]) => o)) ?? 0,
    [columnOrder],
  );

  const onColumnReorder = useCallback((e: GridColumnReorderEvent) => {
    // this condition prevents unexpected column moving to the left
    if (!e.nativeEvent.target.className.includes('k-column-resizer')) {
      setColumnOrder(() => {
        const newValue = fromPairs(
          normalizeIndexes(
            e.columns
              .filter((c) => !!c.field) // To exclude Actions column
              .map(({ field, orderIndex }) => [field as string, orderIndex as number]),
          ),
        );
        window.localStorage.setItem(storageKey, JSON.stringify(newValue));
        return newValue;
      });
    }
  }, [storageKey]);

  const onColumnToggle = useCallback((field: string, show: boolean) => {
    setColumnOrder(() => {
      let newValue: { [field: string]: number };
      if (show) {
        // Always add newly shown columns before the Actions column (after the rest)
        const addedColumn: [string, number][] = [[field, maxOrder + 1]];
        newValue = fromPairs(
          normalizeIndexes(
            addedColumn.concat(toPairs(columnOrder)),
          ),
        );
      } else {
        newValue = fromPairs(
          normalizeIndexes(
            toPairs(columnOrder).filter(([f]) => f !== field),
          ),
        );
      }
      window.localStorage.setItem(storageKey, JSON.stringify(newValue));
      return newValue;
    });
  }, [columnOrder, maxOrder, storageKey]);

  const resetOrder = useCallback(() => {
    setColumnOrder(() => {
      window.localStorage.removeItem(storageKey);
      return fromPairs(
        visibleColumns.map(({ field }, idx) => [field, idx]),
      );
    });
  }, [storageKey, visibleColumns]);

  return {
    columnOrder,
    maxOrder,
    onColumnReorder,
    onColumnToggle,
    resetOrder,
  };
}
