import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText, ListSubheader,
  Popover,
  Tooltip,
} from '@mui/material';
import { RotateLeft, Settings } from '@mui/icons-material';
import { UseFreezingResult } from './column/useFreezing';
import { ColumnProps } from './column/Column';
import { useStyles } from './styles';

export interface SettingsMenuButtonProps extends UseFreezingResult {
  onReset: () => void;
  columns: ColumnProps[];
  columnTitles: { [name: string]: string };
  hiddenColumns: { [field: string]: boolean };
  toggleColumn: (field: string, show: boolean) => void;
}

export const SettingsMenuButton: React.FC<SettingsMenuButtonProps> = ({
  onReset,
  columns,
  columnTitles,
  hiddenColumns,
  toggleColumn,
  widthsFrozen,
  setWidthsFrozen,
  orderFrozen,
  setOrderFrozen,
}) => {
  const classes = useStyles();

  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement>();
  const onMenuClose = () => {
    setMenuAnchor(undefined);
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        startIcon={<Settings />}
        onClick={(e) => setMenuAnchor(e.currentTarget)}
      >
        Column Settings
      </Button>
      <Popover
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={onMenuClose}
      >
        <List>
          <ListItem
            button
            onClick={setWidthsFrozen}
          >
            <ListItemIcon>
              <Checkbox
                className={classes.checkbox}
                edge="start"
                checked={widthsFrozen}
                tabIndex={-1}
                disableRipple
                color="primary"
              />
            </ListItemIcon>
            <ListItemText
              primary="Freeze column widths"
            />
          </ListItem>
          <ListItem
            button
            onClick={setOrderFrozen}
          >
            <ListItemIcon>
              <Checkbox
                className={classes.checkbox}
                edge="start"
                checked={orderFrozen}
                tabIndex={-1}
                disableRipple
                color="primary"
              />
            </ListItemIcon>
            <ListItemText
              primary="Freeze column order"
            />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              onReset();
              onMenuClose();
            }}
          >
            <ListItemIcon>
              <RotateLeft />
            </ListItemIcon>
            <ListItemText
              primary="Reset to defaults"
            />
          </ListItem>
          <Divider />
          <ListSubheader className={classes.subheader}>
            Visible Columns
          </ListSubheader>
          {columns.map(({ field }) => {
            const labelId = `settings-${field}-checkbox-list-label`;
            const visible = !hiddenColumns[field];
            return (
              <ListItem
                key={field}
                button
                onClick={() => toggleColumn(field, !visible)}
              >
                <ListItemIcon>
                  <Tooltip title="Toggle column visibility" arrow placement="top">
                    <Checkbox
                      className={classes.checkbox}
                      edge="start"
                      checked={visible}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                      color="primary"
                    />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={columnTitles[field] ?? field}
                />
              </ListItem>
            );
          })}
        </List>
      </Popover>
    </>
  );
};
