import { gql } from '@apollo/client';

export const FM_SP_FIELD_VALUE_QUERY = 'FmSpFieldValue';
export const FM_SP_FIELD_VALUE = gql`
  query FmSpFieldValue ($skip: Int!, $take: Int!, $where: WhereFmSpFieldValueInput) {
    fmSpFieldValues(
      skip: $skip,
      take: $take,
      where: $where,
    ) {
      items {
        field_id
        organization_id
        value
        field{
          field_id
          name
          type
        }
      }
      total_count
      has_more
    }
  }
`;
