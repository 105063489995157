import React from 'react';
import {
  Box, Checkbox, Grid, IconButton, Link, Tooltip, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import AttachmentIcon from '@mui/icons-material/InsertDriveFile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkIcon from '@mui/icons-material/Link';
import { Files__organizationFiles_items } from 'api/types/Files';
import { isoDateTimeToLocale, isoDateToLocate } from 'utils/datetime';
import { isDocuSignTemplate, isImage, mimeTypeDescription } from 'utils/files';
import { colors } from '../../theme';
import { FileImageThumb } from './FileImageThumb';

export interface FileBadge {
  backgroundColor: string;
  color?: string;
  title: string;
}

export interface FilesListItemProps {
  file: Files__organizationFiles_items;

  small?: boolean;
  showCategory?: boolean;

  showDateAdded?: boolean;
  width?: number;
  height?: number;

  // select
  selectable?: boolean;
  selected?: boolean;
  onSelectChange?: (selected: boolean) => void;

  // edit
  editable?: boolean;
  onEdit?: (e: React.MouseEvent<any>) => void;

  // delete
  deletable?: boolean;
  onDelete?: (e: React.MouseEvent<any>) => void;

  onImagePreviewClick: (e: React.MouseEvent<any>) => void;
  badges?: FileBadge[];

  disabledNameLink?: boolean;
}

export const TILE_WIDTH = 261;
export const TILE_HEIGHT = 108;

type StyleProps = { width: number; height: number, small?: boolean };

const useStyles = makeStyles((theme) => ({
  listItem: ({ small }: any) => ({
    borderRadius: 5,
    border: small ? 'none' : '1px solid rgba(33, 33, 33, 0.08)',
    width: ({ width }: StyleProps) => width,
    position: 'relative',
    backgroundColor: small ? colors.bgGrey : colors.white,
    padding: small ? theme.spacing(0, 1) : theme.spacing(1),
  }),
  selected: {
    borderColor: `${colors.blue}`,
  },
  selectable: {
    '&:hover': {
      borderColor: colors.blue,
      '& $previewContainer': {
        background: 'rgba(33, 33, 33, 0.04)',
      },
    },
  },
  topActions: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  previewContainer: {
    width: ({ width }: StyleProps) => width - 2, // width - 2*border
    height: ({ height }: StyleProps) => height,
    color: colors.darkGrey,
    fontSize: 29,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    background: 'rgba(33, 33, 33, 0.08)',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  selectablePreview: {
    cursor: 'pointer',
  },
  checkboxRoot: {
    // override checkbox background
    '& .MuiIconButton-label': {
      position: 'relative',
      zIndex: 0,
    },
    '&:not($checked) .MuiIconButton-label:after': {
      content: '""',
      left: 4,
      top: 4,
      height: 15,
      width: 15,
      position: 'absolute',
      backgroundColor: colors.white,
      zIndex: -1,
    },
  },
  previewImage: {
    cursor: 'pointer',
    objectFit: 'cover',
    objectPosition: 'top',
    width: 'inherit',
    height: 'inherit',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  fileName: ({ small }: StyleProps) => ({
    fontSize: small ? 12 : undefined,
    fontWeight: small ? theme.typography.fontWeightRegular : theme.typography.fontWeightBold,
    textDecoration: 'none',
    overflowWrap: 'break-word',
    overflow: 'hidden',
    // Limit text length to (n) lines with ellipsis, works good everywhere, but IE
    display: '-webkit-box',
    '-webkitBoxOrient': 'vertical',
    '-webkitLineClamp': 1,
  }),
  fileType: {
    overflowWrap: 'break-word',
    overflow: 'hidden',
    // Limit text length to (n) lines with ellipsis, works good everywhere, but IE
    display: '-webkit-box',
    '-webkitBoxOrient': 'vertical',
    '-webkitLineClamp': 1,
  },
  badges: {
    position: 'absolute',
    left: 5,
    top: 5,
  },
  badge: {
    marginRight: 3,
    borderRadius: 4,
    padding: '0 5px',
  },
  badeTitle: { color: '#fff' },
  icon: {
    fill: '#000000',
    height: 18,
    width: 18,
  },
  linkIcon: {
    marginBottom: '-4px',
  },
}));

export const FilesListItem: React.FC<FilesListItemProps> = ({
  file,
  small,
  showCategory,
  selected,
  selectable,
  onSelectChange,
  onImagePreviewClick,
  editable,
  onEdit,
  deletable,
  onDelete,
  badges,
  width = TILE_WIDTH,
  height = TILE_HEIGHT,
  showDateAdded,
  disabledNameLink,
}) => {
  const classes = useStyles({ width, height, small });
  const boxId = `file-list-item-${file.file_id}`;

  const preview = (() => {
    if (isImage(file.mime_type)) {
      return (
        <FileImageThumb
          onClick={selectable ? undefined : onImagePreviewClick}
          url={file.thumb_url!}
          alt={file.name}
          skeletonProps={{ classes: { root: classes.previewImage } }}
          className={classes.previewImage}
        />
      );
    }
    if (isDocuSignTemplate(file.mime_type)) {
      return (
        <>
          <FontAwesomeIcon icon="signature" />
          <Box mt={1}>
            <Typography align="center" variant="body2" color="textSecondary">{mimeTypeDescription(file.mime_type)}</Typography>
          </Box>
        </>
      );
    }
    return (
      <>
        <AttachmentIcon fontSize="inherit" />
        <Box mt={1}>
          <Typography align="center" variant="body2" color="textSecondary">{mimeTypeDescription(file.mime_type)}</Typography>
        </Box>
      </>
    );
  })();

  return (
    <Box
      className={`${classes.listItem} ${selectable ? classes.selectable : ''} ${selected ? classes.selected : ''} `}
    >
      <label htmlFor={boxId} className={selectable ? classes.selectablePreview : ''}>
        {small ? null : (
          <Box className={classes.previewContainer}>
            {preview}
          </Box>
        )}
        <Box className={classes.badges}>
          {(badges ?? []).map((badge) => (
            <Box
              key={badge.title}
              className={classes.badge}
              color={badge.color}
              style={{ background: badge.backgroundColor || '#fff' }}
            >
              <Typography variant="body2" className={classes.badeTitle}>{badge.title}</Typography>
            </Box>
          ))}
        </Box>
        <Box className={classes.topActions}>
          {selectable && (
            <Checkbox
              id={boxId}
              checked={selected}
              onChange={(e) => {
                onSelectChange?.(e.target.checked);
              }}
              color="primary"
              classes={{ root: classes.checkboxRoot }}
            />
          )}
          {editable && (
            <IconButton onClick={onEdit} size="small" style={{ background: '#fff', borderRadius: 5, margin: '5px 0' }}>
              <EditIcon fontSize="inherit" />
            </IconButton>
          )}
          {deletable && (
            <IconButton onClick={onDelete} size="small" style={{ background: '#fff', borderRadius: 5, margin: 5 }}>
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          )}
        </Box>
      </label>
      <Box p={1}>
        <Grid container spacing={1} direction="row" alignItems="center">
          <Grid item>
            <Tooltip title={`Download ${file.name}`}>
              <Typography
                component={disabledNameLink ? 'span' : Link}
                target="_blank"
                href={file.url}
                className={classes.fileName}
                color="textPrimary"
              >
                {file.name}
              </Typography>
            </Tooltip>
          </Grid>
          {small ? (
            <Grid item>
              <LinkIcon className={classes.linkIcon} fontSize="small" color="inherit" />
            </Grid>
          ) : null}
        </Grid>
        {!showCategory ? null : (
          <Grid container justifyContent="space-between" direction="row" wrap="nowrap" spacing={1}>
            <Grid item container spacing={1}>
              <Grid item>
                <Typography variant="body2" color="textSecondary" className={classes.fileType}>{file.category.name}</Typography>
              </Grid>
              {isDocuSignTemplate(file.mime_type) ? (
                <Grid item>
                  <Typography variant="body2" color="textSecondary">{file.path.slice(-4)}</Typography>
                </Grid>
              ) : null}
            </Grid>
            {showDateAdded && (
              <Grid item>
                <Tooltip title={`added on ${isoDateTimeToLocale(file.created_on)}`}>
                  <Typography variant="body2" color="textSecondary">{isoDateToLocate(file.created_on)}</Typography>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        )}
      </Box>
    </Box>
  );
};
