import { Location } from 'history';
import {
  useCallback, useEffect, useState,
} from 'react';
import { useHistory } from 'react-router-dom';

export type ISidebarSearch = {
  sb_type: string;
  sb_id: number;
  sb_title: string;
  sb_extra?: Record<string, any>;
};

const getSidebarSearchState = (location: Location): ISidebarSearch | undefined => {
  const search = new URLSearchParams(location.search);
  const type = search.get('sb_type');
  const id = search.get('sb_id');
  const title = search.get('sb_title');
  const extra = search.get('sb_extra');
  return id ? {
    sb_type: type!,
    sb_id: +id,
    sb_title: title!,
    sb_extra: extra ? JSON.parse(extra) : undefined,
  } : undefined;
};

type ISidebarSearchValue = ISidebarSearch | undefined;

export const useSidebarState = (): [
  ISidebarSearch | undefined,
  (newState: ISidebarSearchValue | ((prev: ISidebarSearchValue) => ISidebarSearchValue)) => void,
] => {
  const history = useHistory();
  // const sidebarOpenedOn = useRef<string>();
  const [state, setState] = useState<ISidebarSearch>();

  useEffect(() => {
    const listener = (location: Location) => setState(getSidebarSearchState(location));
    listener(history.location);
    const unregister = history.listen(listener);
    return () => unregister();
  }, [history]);

  const handleSetState = useCallback((
    newState: ISidebarSearchValue | ((prev: ISidebarSearchValue) => ISidebarSearchValue),
  ) => {
    const search = new URLSearchParams(history.location.search);
    setState((prev) => {
      const v = typeof newState === 'function' ? newState(prev) : newState;
      if (!v) {
        search.delete('sb_type');
        search.delete('sb_id');
        search.delete('sb_title');
        search.delete('sb_extra');
      } else {
        search.set('sb_type', v.sb_type);
        search.set('sb_id', `${v.sb_id}`);
        search.set('sb_title', v.sb_title);
        search.set('sb_extra', v.sb_extra ? JSON.stringify(v.sb_extra) : '');
      }
      history.push({ ...history.location, search: search.toString() });
      return v;
    });
  }, [history]);

  return [state, handleSetState];
};
