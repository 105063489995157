import { gql } from '@apollo/client';

export enum BundleStatus {
  Draft = 1,
  OutForBid = 2,
  Paused = 3,
  AwardPending = 200,
  Awarded = 8,
  Withdrawn = 9,
  Lost = 10,
  Expired = 11,
  Declined = 12,
  Negotiating = 20,
}

export const outForBidFunctionalStatuses = [
  BundleStatus.OutForBid,
  BundleStatus.Expired,
  BundleStatus.Negotiating,
  BundleStatus.AwardPending,
];

export const BUNDLE_DATE_TITLE: { [index: number]: string } = {
  [BundleStatus.Draft]: 'Started On',
  [BundleStatus.Paused]: 'Paused On',
  [BundleStatus.OutForBid]: 'Out For Bid On',
  [BundleStatus.Negotiating]: 'Negotiation Started On',
  [BundleStatus.Declined]: 'Out For Bid On',
  [BundleStatus.Expired]: 'Expired On',
  [BundleStatus.AwardPending]: 'Award Pending On',
  [BundleStatus.Awarded]: 'Awarded On',
  [BundleStatus.Withdrawn]: 'Withdrawn On',
  [BundleStatus.Lost]: 'Lost On',
};

export const BUNDLE_BY_TITLE: { [index: number]: string } = {
  [BundleStatus.Draft]: 'Started By',
  [BundleStatus.Paused]: 'Paused By',
  [BundleStatus.OutForBid]: 'Status Changed By',
  [BundleStatus.Negotiating]: 'Status Changed By',
  [BundleStatus.Declined]: 'Status Changed By',
  [BundleStatus.Expired]: 'Status Changed By',
  [BundleStatus.AwardPending]: 'Awarded By',
  [BundleStatus.Awarded]: 'Awarded By',
  [BundleStatus.Withdrawn]: 'Withdrawn By',
  [BundleStatus.Lost]: 'Status Changed By',
};

export const BUNDLE_STATUS_HELP_SP: { [index: number]: string } = {
  [BundleStatus.Draft]: 'This bundle is not ready yet', // They should not see such bundles at all
  [BundleStatus.OutForBid]: 'This bundle is ready for bidding, please proceed to review prices and submit your bid',
  [BundleStatus.OutForBid]: 'This bundle is in negotiating state',
  [BundleStatus.Paused]: 'This bundle is paused, Service Providers cannot bid on it',
  [BundleStatus.Awarded]: 'Your bid has won in this bundle',
  [BundleStatus.Withdrawn]: 'This bundle was withdrawn',
  [BundleStatus.Lost]: 'This bundle was awarded to a different service provider',
  [BundleStatus.Expired]: 'Bidding period for this bundle has ended',
  [BundleStatus.Declined]: 'You have declined to participate in this bundle',
};

export const BUNDLE_STATUS_HELP_FM: { [index: number]: string } = {
  [BundleStatus.Draft]: 'You have not submitted this bundle yet, it is invisible to Service Providers',
  [BundleStatus.OutForBid]: 'You have submitted this bundle for bidding, now Service Providers can submit their offers',
  [BundleStatus.Negotiating]: 'You have started negotiating',
  [BundleStatus.Paused]: '', // Never shown to FMs, Draft shown instead
  [BundleStatus.AwardPending]: 'This bundle is waiting for Service Provider accept',
  [BundleStatus.Awarded]: 'This bundle is complete',
  [BundleStatus.Withdrawn]: 'This bundle was withdrawn',
  [BundleStatus.Lost]: '', // Never shown to FMs
  [BundleStatus.Expired]: 'Bidding period for this Service Provider has ended',
  [BundleStatus.Declined]: '', // Never shown to FMs
};

export const BUNDLE_STATUSES = gql`
  query BundleStatuses($type: UserType) {
    bundleStatuses(type: $type) {
      status_id
      name
      icon
      color
    }
  }
`;
