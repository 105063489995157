import React, { useMemo } from 'react';
import { createStyles, makeStyles } from '@mui/styles';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number | string;
  value: number | string;
  className?: string;
  destroyChildren?: boolean;
}

const useStyles = makeStyles(() => createStyles({
  inactive: {
    display: 'none',
  },
}));

export function TabPanel({
  children,
  value,
  index,
  className,
  destroyChildren = true,
  ...other
}: TabPanelProps) {
  const classes = useStyles();
  const isActive = value === index;
  const tabClassNames = useMemo(() => (
    [!isActive ? classes.inactive : undefined, className].filter(Boolean).join(' ')
  ), [isActive, classes, className]);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      className={tabClassNames}
      {...other}
    >
      {!destroyChildren ? children : (
        value === index && children
      )}
    </div>
  );
}

export function a11yProps(index: number | string) {
  return {
    value: index,
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}
