import { gql } from '@apollo/client';

export const USER_INVITES_QID = 'UserInvites';

export const USER_INVITES = gql`
query UserInvites(
  $skip: Int!
  $take: Int!
  $where: WhereUserInviteInput
  $order: [OrderInput!]
) {
  _userInvites(
    skip: $skip
    take: $take
    where: $where
    order: $order
  ) {
    items {
      invite_id
      email
      invitedByName
      invitedBy { user_id, first_name, last_name }
      createdUser { user_id, first_name, last_name, login_email }
      invited_on
      failed_on
    }
    total_count
    has_more
  }
}
`;
