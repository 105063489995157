import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Box } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import L from 'leaflet';
import pointsWithinPolygon from '@turf/points-within-polygon';
import { Feature, FeatureCollection, Point } from 'geojson';
import { isEqual, intersectionBy } from 'lodash';
import * as Sentry from '@sentry/browser';

import { useProfile } from 'api/user/profile';
import { FM_BUNDLES_SUBMITTED_BIDS } from 'components/organization/bundle/queries/fmBundlesSubmittedBidsQuery';
import { useResizeObserver } from 'utils/useResizeObserver';
import { useLazyQueryPromise } from 'utils/useLazyQueryPromise';
import {
  FmBundlesSubmittedBids,
  FmBundlesSubmittedBidsVariables,
  FmBundlesSubmittedBids_fmBundles_items,
} from 'api/types/FmBundlesSubmittedBids';

import clsx from 'clsx';
import { ErrorSnackbar, useErrorSnackbar } from '../notifications/ErrorSnackbar';
import { ViewMapProps } from './ViewMap';
import { LoadingOverlay } from './utils/LoadingOverlay';
import { useFillScreenHeight } from './utils/useFillScreenHeight';
import { useGoogleLocation } from './utils/useGoogleLocation';
import { addBundlerSelectionControls, useDrawingProgress } from './leaflet/selectionTools';
import { createMap } from './leaflet/map';
import { addGoogleMapLayers } from './leaflet/googleMapLayers';
import { addHomeIcon } from './leaflet/homeIcon';
import {
  getPinIcon,
  getMapMarkerIcon,
  MapMarker,
  getMarkerBundleIds,
} from './utils/marker';

const markerTooltipStatData = (
  items: FmBundlesSubmittedBids_fmBundles_items[],
  bundlesCount: number,
): string => {
  const submittedBidsCount = items.reduce(
    (sum: number, value) => sum + value.summary.submitted,
    0);

  return `<br />${submittedBidsCount} submitted bid${submittedBidsCount === 1 ? '' : 's'} in ${bundlesCount} bundle${bundlesCount === 1 ? '' : 's'}`;
};

const useStyles = makeStyles((theme) => createStyles({
  wrapper: {
    marginBottom: theme.spacing(2),
  },
  map: {
    margin: 0,
    padding: 0,
    overflow: 'hidden',
  },
}));

/**
 * Cache item for internal component cache.
 */
interface MarkersCacheItem<
  ID extends string | number,
  GID extends string | number,
> {
  marker: MapMarker<ID, GID>;
  leafletMarker: L.Marker;
  leafletPopup: L.Popup;
}

interface MapDataStateProps<R extends object> {
  // Show a loading overlay if map data is loading
  loading: boolean;
  // Data records to show on the map
  data: R[];
}

export interface FilteredLocationsLeafletMapProps<
  ID extends string | number, // Record ID type
  GID extends string | number, // Record group ID type
  R extends object, // Record type
> extends ViewMapProps {
  // Close markers will appear as groups, i.e. markers with a number of "nested" markers under it
  groupMarkers?: boolean;
  groupMarkersMaxZoomLevel?: number;
  setActiveItem?: (item: R) => void;
  // Whether the setActiveItem should be called when clicking an item
  setActiveItemOnClick?: boolean;
  getItemById?: (data: R[], id: ID) => R | undefined;
  convertDataIntoMarkers: (items: R[]) => MapMarker<ID, GID>[];
  createMarkerPopupContents?: (marker: MapMarker<ID, GID>) => HTMLElement;
  onSelect?: (markers: FeatureCollection<Point, MapMarker<ID, GID>>) => void;

  /**
   * an easy way to disable tooltip with message about how many bids in bundles
   */
  enableBidsTooltip?: boolean;
  wrapperClassName?: string;
}

type Props<
  ID extends string | number,
  GID extends string | number,
  R extends object,
> = FilteredLocationsLeafletMapProps<ID, GID, R> & MapDataStateProps<R>;

export function FilteredLocationsLeafletMap<
  ID extends string | number,
  GID extends string | number,
  R extends object,
>({
  id,
  zoom = 11,
  maxZoomWhenFitting = 13,
  homePlaceIdOrCoordinates,
  homeTitle,
  height: minHeight,
  fillScreenHeight = false,
  loading,
  data,
  groupMarkers = true,
  groupMarkersMaxZoomLevel = 8,
  setActiveItem,
  setActiveItemOnClick = false,
  getItemById,
  convertDataIntoMarkers,
  createMarkerPopupContents,
  onSelect,
  withSearch,
  enableBidsTooltip = true,
  wrapperClassName,
  ...props
}: Props<ID, GID, R>) {
  const classes = useStyles();
  const profile = useProfile();
  const isFm = profile.organization.is_facility_manager;

  const [mapRef, height] = useFillScreenHeight(minHeight, fillScreenHeight);

  const [orgGeoLocation, orgGeoLocationError] = useGoogleLocation(homePlaceIdOrCoordinates);
  const geoErrorSnackbar = useErrorSnackbar(orgGeoLocationError);

  const [map, setMap] = useState<L.Map>();
  const [markersLayer, setMarkersLayer] = useState<L.FeatureGroup>();

  const getBundlesSubmittedBids = useLazyQueryPromise<
    FmBundlesSubmittedBids, FmBundlesSubmittedBidsVariables
  >(FM_BUNDLES_SUBMITTED_BIDS);

  const faultyMarkersError = useMemo(() => {
    const markers = convertDataIntoMarkers(data);
    const errorMarkers = markers.filter((m) => (!m.lat || !m.lng));
    if (errorMarkers.length) {
      return `There are sites with faulty location attributes, please contact support to fix them:${
        errorMarkers.map((m) => `${m.title ?? 'No Name'}`).join(', ')}`;
    }
    return null;
  }, [data, convertDataIntoMarkers]);
  const faultyMarkersErrorSnackbar = useErrorSnackbar(faultyMarkersError);

  // To prevent normal 'click' events to be misinterpreted while drawing shapes
  const [isDrawingShapeInProgress, setIsDrawingShapeInProgress] = useState(false);
  useDrawingProgress(map, setIsDrawingShapeInProgress);

  useEffect(() => {
    if (!orgGeoLocation) {
      return () => {};
    }
    const leafletMap = createMap(id, zoom, orgGeoLocation, withSearch);
    setMap(leafletMap);
    addGoogleMapLayers(leafletMap);
    const markersLayerGroup = groupMarkers ? L.markerClusterGroup({
      showCoverageOnHover: false,
      spiderfyOnMaxZoom: false,
      disableClusteringAtZoom: groupMarkersMaxZoomLevel,
    }) : new L.FeatureGroup();
    leafletMap.addLayer(markersLayerGroup);
    setMarkersLayer(markersLayerGroup);
    if (onSelect) {
      addBundlerSelectionControls(leafletMap);
    }
    return () => {
      setMap(undefined);
      try {
        leafletMap.remove();
      } catch (e) {
        // Ignore some annoying errors
        if (e instanceof Error && e.message !== 'Cannot read property \'__e3_\' of undefined') {
          Sentry.captureException(e);
        }
      }
      setIsDrawingShapeInProgress(false);
    };
  }, [
    groupMarkers,
    id,
    groupMarkersMaxZoomLevel,
    orgGeoLocation,
    zoom,
    withSearch,
    onSelect,
  ]);

  useEffect(() => {
    if (map && orgGeoLocation && homeTitle) {
      addHomeIcon(map, homeTitle, orgGeoLocation.toJSON());
    }
  }, [homeTitle, map, orgGeoLocation]);

  const onResize = useCallback(() => {
    if (map) {
      map.fire('resize');
    }
  }, [map]);

  useResizeObserver(id, onResize);

  // ID of a selected group of markers
  const [selectedGroups, setSelectedGroups] = useState<{ id: GID, name: string }[]>([]);

  // Previous data for deep-equality check,
  // to prevent re-rendering markers each time something changes
  const [previousData, setPreviousData] = useState<R[]>();

  // Cache of markers on a map, to be able to change their icons later
  const [mapMarkersCache, setMapMarkersCache] = useState<MarkersCacheItem<ID, GID>[]>([]);

  // Add markers
  useEffect(() => {
    if (map && markersLayer && !isEqual(previousData, data)) {
      const markers = convertDataIntoMarkers(data);
      setPreviousData(data);
      markersLayer.clearLayers();
      const cache: MarkersCacheItem<ID, GID>[] = [];
      markers.forEach((marker) => {
        if (!marker.lng || !marker.lat) {
          return;
        }
        const icon = getMapMarkerIcon(marker);
        const fullTitle = `<strong>${marker.title}</strong>`;
        const leafletPopup = L.popup();
        leafletPopup.setContent(fullTitle);
        const leafletMarker = L.marker(marker, { icon });
        if (!isDrawingShapeInProgress) {
          leafletMarker
            .on('click', () => {
              if (getItemById && setActiveItem && setActiveItemOnClick) {
                const item = getItemById(data, marker.id);
                if (item) {
                  setActiveItem(item);
                }
              }
              setSelectedGroups(marker.groups);
            })
            .bindPopup(leafletPopup)
            .bindTooltip(fullTitle);
          if (isFm) {
            leafletMarker.on('mouseover', async () => {
              const siteBundleIds = getMarkerBundleIds(marker);
              let items: FmBundlesSubmittedBids_fmBundles_items[] = [];
              if (siteBundleIds.length) {
                const result = await getBundlesSubmittedBids({
                  siteBundleIds,
                });
                items = result.data.fmBundles.items;
              }
              leafletMarker.setTooltipContent(`${fullTitle}${enableBidsTooltip
                ? markerTooltipStatData(items, marker.groups.length)
                : ''}`,
              );
            });
          }
        }
        markersLayer.addLayer(leafletMarker);
        // Update group bounds manually - just adding markers doesn't work.
        markersLayer.getBounds().extend(marker);
        // Add item to cache
        cache.push({
          marker,
          leafletMarker,
          leafletPopup,
        });
      });
      // Update cache
      setMapMarkersCache(cache);
    }
  }, [
    convertDataIntoMarkers,
    data,
    getBundlesSubmittedBids,
    getItemById,
    isDrawingShapeInProgress,
    map,
    markersLayer,
    previousData,
    setActiveItem,
    setActiveItemOnClick,
    isFm,
    profile.is_system_admin,
    enableBidsTooltip,
  ]);

  useEffect(() => {
    if (map && mapMarkersCache && onSelect) {
      const onDrawCreated = (e: L.LeafletEvent) => {
        const polygon = e.layer.toGeoJSON();
        type P = MapMarker<ID, GID>;
        const emptyFeatureCollection: FeatureCollection<Point, P> = {
          type: 'FeatureCollection',
          features: [],
        };
        const points: FeatureCollection<Point, P> = mapMarkersCache.reduce(
          (collection, item) => {
            const point: Feature<Point, P> = {
              ...item.leafletMarker.toGeoJSON(),
              properties: item.marker,
            };
            return {
              ...collection,
              features: collection.features.concat([point]),
            };
          },
          emptyFeatureCollection,
        );
        const pointSelected = pointsWithinPolygon(points, polygon);
        onSelect(pointSelected);
      };
      map.on(L.Draw.Event.CREATED, onDrawCreated);
      return () => {
        map.off(L.Draw.Event.CREATED, onDrawCreated);
      };
    }
    return () => {};
  }, [map, mapMarkersCache, onSelect]);

  const [boundsFitted, setBoundFitted] = useState(false);

  useEffect(() => {
    if (map && markersLayer && markersLayer.getBounds().isValid()) {
      const markers = convertDataIntoMarkers(data);
      if (markers.length === 0) {
        return;
      }
      if (!boundsFitted || !isEqual(previousData, data)) {
        map.fitBounds(markersLayer.getBounds(), { maxZoom: maxZoomWhenFitting });
        setBoundFitted(true);
      }
    }
  }, [
    boundsFitted,
    convertDataIntoMarkers,
    data,
    map,
    markersLayer,
    maxZoomWhenFitting,
    previousData,
  ]);

  // Update marker icons whenever a group (or multiple groups) is selected
  useEffect(() => {
    if (!groupMarkers) { // do nothing if groups are disabled
      return;
    }
    mapMarkersCache.forEach(({ marker, leafletMarker }) => {
      const icon = intersectionBy(marker.groups, selectedGroups, 'id').length > 0
        ? getPinIcon(marker.color)
        : getMapMarkerIcon(marker);
      leafletMarker.setIcon(icon);
    });
  }, [groupMarkers, mapMarkersCache, selectedGroups]);

  // Update marker popups whenever a group (or multiple groups) is selected
  useEffect(() => {
    if (createMarkerPopupContents) {
      mapMarkersCache.forEach(({ leafletPopup, marker }) => {
        leafletPopup.setContent(createMarkerPopupContents(marker));
      });
    }
  }, [
    createMarkerPopupContents,
    data,
    getItemById,
    mapMarkersCache,
    setActiveItem,
  ]);

  return (
    <>
      <Box component="div" className={clsx([classes.wrapper, wrapperClassName])}>
        <div
          id={id}
          ref={mapRef}
          className={classes.map}
          {...props}
          style={{ height }}
        />
        <LoadingOverlay
          mapElId={id}
          map={map}
          isLoading={loading}
        />
      </Box>
      <ErrorSnackbar {...geoErrorSnackbar}>
        Could not geographically locate an organization
      </ErrorSnackbar>
      <ErrorSnackbar {...faultyMarkersErrorSnackbar}>
        {faultyMarkersError}
      </ErrorSnackbar>
    </>
  );
}
