import { Typography } from '@mui/material';
import React from 'react';
import { CustomFieldType } from '../types';

interface CustomFieldLabelProps {
  field: CustomFieldType;
}

export const CustomFieldLabel: React.FC<CustomFieldLabelProps> = ({ field }) => (
  <Typography variant="h6">
    {field.context?.text || field.name}
  </Typography>
);
