import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useQuery } from '@apollo/react-hooks';
import { AppState } from 'api/types/AppState';
import { APP_STATE } from 'pages/AppStateProvider/AppStateQuery';

const { REACT_APP_SHORT_SHA: buildShortSha } = process.env;

const useAppRemoteMetadata = () => {
  const [meta, setMeta] = useState<{ version: string }>();

  const fetchMeta = useCallback(
    () => fetch('./meta.json')
      .then((res) => res.json())
      .then((data) => setMeta(data)),
    [],
  );

  useEffect(() => {
    fetchMeta();
    const interval = setInterval(fetchMeta, 60000);
    return () => clearInterval(interval);
  }, [fetchMeta]);

  return { meta };
};

export function useAppState() {
  const { meta } = useAppRemoteMetadata();
  const { data, loading, error } = useQuery<AppState>(APP_STATE, {
    fetchPolicy: 'no-cache',
    pollInterval: 60000,
  });
  const [hasNewVersion, setHasNewVersion] = useState(false);

  const value = useMemo(() => ({
    loading,
    globalSettings: data?.appState.globalSettings,
    getSetting: (key: string) => data?.appState.globalSettings?.find((s) => s.key === key)?.value,
  }), [loading, data]);

  const handleRefreshPage = useCallback(() => {
    localStorage.clear();
    window.location.reload();
  }, []);

  useEffect(() => {
    if (buildShortSha && meta?.version && buildShortSha !== meta?.version) {
      setHasNewVersion(true);
    }
  }, [meta]);

  return {
    value,
    loading,
    error,
    hasNewVersion,
    handleRefreshPage,
  };
}
