import L from 'leaflet';
import { colors } from '../../theme';

export function addMeasureControl(map: L.Map) {
  map.addControl(L.control.measure({
    position: 'topleft',
    activeColor: colors.orange,
    completedColor: colors.orange,
    primaryAreaUnit: 'sqfeet',
    secondaryAreaUnit: 'acres',
  }));
}
