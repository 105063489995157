import React, { useMemo } from 'react';
import {
  Box, Button,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { createStyles, makeStyles } from '@mui/styles';
import { useSpSidebarHandlers } from '../SpSidebarProvider';
import { FilesList } from '../../../../../documentRepository/filesList/FilesList';
import { SidebarProp } from '../../../../../sidebar/SidebarProp';
import { WhereOrganizationFileInput } from '../../../../../../api/types/globalTypes';
import { FileCategories } from '../../../../../documentRepository/queries/categories';

const useStyles = makeStyles((theme) => createStyles({
  orgFilesBlock: {
    marginTop: theme.spacing(1),
  },
}));

interface DocumentsSubPageProps {
  organizationId: number;
}

export const DocumentsSubPage = ({ organizationId }: DocumentsSubPageProps) => {
  const classes = useStyles();
  const { modifyExtra } = useSpSidebarHandlers();

  const coiFilesCondition: WhereOrganizationFileInput = useMemo(() => ({
    organization_id: { eq: organizationId },
    is_public: { eq: true },
    category_id: { eq: FileCategories.CertificateOfInsurance },
  }), [organizationId]);

  const w9FilesCondition: WhereOrganizationFileInput = useMemo(() => ({
    organization_id: { eq: organizationId },
    is_public: { eq: true },
    category_id: { eq: FileCategories.W9 },
  }), [organizationId]);

  const restOfPublicFilesCondition: WhereOrganizationFileInput = useMemo(() => ({
    organization_id: { eq: organizationId },
    is_public: { eq: true },
    category_id: { notIn: [FileCategories.CertificateOfInsurance, FileCategories.W9] },
  }), [organizationId]);

  return (
    <Box key="documents" m={2}>
      <Button
        size="large"
        variant="text"
        color="primary"
        startIcon={<ArrowBack />}
        onClick={() => modifyExtra((p) => ({ ...p, page: 'main' }))}
      >
        Documents
      </Button>

      <Box ml={1} mt={3}>
        <SidebarProp
          title="COI Requirements"
          titleVariant="subtitle1"
          titleFontWeight={600}
          value={(
            <Box component="div" className={classes.orgFilesBlock}>
              <FilesList
                small
                itemWidth={120}
                itemHeight={40}
                showDateAdded={false}
                queryCondition={coiFilesCondition}
                showCategory={false}
              />
            </Box>
          )}
        />
        <SidebarProp
          title="W9"
          titleVariant="subtitle1"
          titleFontWeight={600}
          value={(
            <Box component="div" className={classes.orgFilesBlock}>
              <FilesList
                small
                itemWidth={120}
                itemHeight={40}
                showDateAdded={false}
                queryCondition={w9FilesCondition}
                showCategory={false}
              />
            </Box>
          )}
        />
        <SidebarProp
          title="Suplimentary Documents"
          titleVariant="subtitle1"
          titleFontWeight={600}
          value={(
            <Box component="div" className={classes.orgFilesBlock}>
              <FilesList
                small
                itemWidth={120}
                itemHeight={40}
                showDateAdded={false}
                queryCondition={restOfPublicFilesCondition}
              />
            </Box>
          )}
        />
      </Box>
    </Box>
  );
};
