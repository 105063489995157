import React from 'react';
import { has } from 'lodash';
import { Link } from '@mui/material';

export type CoordinatesObject = { coordinates: [number, number] };
export type LatLngObject = { lat: number; lng: number; };
export type AnyLatLng = CoordinatesObject | LatLngObject;

export function getGoogleMapUrl(latlongOrPlaceId: AnyLatLng | string): string {
  if (typeof latlongOrPlaceId === 'string') {
    return `https://www.google.com/maps/search/?api=1&query_place_id=${latlongOrPlaceId}`;
  }
  if (has(latlongOrPlaceId, 'coordinates')) {
    const c = (latlongOrPlaceId as CoordinatesObject).coordinates;
    return `https://www.google.com/maps/search/?api=1&query=${c[1]},${c[0]}`;
  }
  if (has(latlongOrPlaceId, 'lat') && has(latlongOrPlaceId, 'lng')) {
    const c = latlongOrPlaceId as LatLngObject;
    return `https://www.google.com/maps/search/?api=1&query=${c.lat},${c.lng}`;
  }
  throw Error(`Cannot build a Google maps URL from this value: ${JSON.stringify(latlongOrPlaceId)}`);
}

export interface GoogleMapLinkProps {
  latlongOrPlaceId: AnyLatLng | string | null | undefined;
}

export const GoogleMapLink: React.FC<GoogleMapLinkProps> = ({
  latlongOrPlaceId,
  children,
}) => {
  if (latlongOrPlaceId) {
    return (
      <Link
        href={getGoogleMapUrl(latlongOrPlaceId)}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </Link>
    );
  }
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );
};
