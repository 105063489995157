import L from 'leaflet';
import './search.css';

export function createMap(
  id: string,
  zoom: number,
  center: google.maps.LatLng,
  withSearch: boolean = false,
): L.Map {
  const map = L.map(id, {
    fullscreenControl: {
      pseudoFullscreen: true,
    },
  } as any).setView({
    lat: center.lat(),
    lng: center.lng(),
  }, zoom);

  // Prevent selecting anything outside of "normal" coordinates: [-90, 90] lat, [-180, 180] long
  map.setMaxBounds(L.latLngBounds(
    L.latLng(-90, -180),
    L.latLng(90, 180),
  ));

  // Prevent zooming too far out, because it would be possible to click outside the bounds
  map.setMinZoom(3);

  // No attribution
  map.attributionControl.remove();

  // Resize handler
  const resizeHandler = () => {
    map.invalidateSize();
  };
  map.on('resize', resizeHandler);

  // Scale indicator
  const scaleControl = L.control.scale({ position: 'bottomright' });
  map.addControl(scaleControl);

  // Search component
  if (withSearch) {
    const GooglePlacesSearchBox = L.Control.extend({
      onAdd() {
        const wrapper = document.createElement('div');
        wrapper.classList.add('procursys-searchContainer');
        wrapper.addEventListener('mouseover', () => {
          map.dragging.disable();
        });
        wrapper.addEventListener('mouseout', () => {
          map.dragging.enable();
        });
        wrapper.addEventListener('dblclick', (e) => {
          e.stopPropagation();
        });
        wrapper.addEventListener('click', (e) => {
          e.stopPropagation();
        });

        const search = document.createElement('input');
        search.id = 'searchBox';
        search.classList.add('procursys-searchBox');
        search.placeholder = 'Search...';

        const clear = document.createElement('button');
        clear.setAttribute('type', 'button');
        clear.id = 'clearButton';
        clear.textContent = '✕';
        clear.classList.add('procursys-clearButton');
        clear.addEventListener('click', () => {
          search.value = '';
        });

        wrapper.appendChild(search);
        wrapper.appendChild(clear);

        return wrapper;
      },
    });
    (new GooglePlacesSearchBox({ position: 'topleft' })).addTo(map);

    const searchInput = document.getElementById('searchBox') as HTMLInputElement;

    if (searchInput) {
      const searchBox = new google.maps.places.SearchBox(searchInput);

      searchBox.addListener('places_changed', () => {
        const places = searchBox.getPlaces();
        if (!places || !places.length) {
          return;
        }

        const place = places[0];
        const viewpoint = place.geometry?.viewport;
        const location = place.geometry?.location;

        if (viewpoint) {
          const SW = viewpoint.getSouthWest();
          const NE = viewpoint.getNorthEast();
          const southWest = L.latLng(SW.lat(), SW.lng());
          const northEast = L.latLng(NE.lat(), NE.lng());
          const bounds = L.latLngBounds(southWest, northEast);
          map.fitBounds(bounds);
        } else if (location) {
          const lat = location.lat();
          const lng = location.lat();
          map.setView({ lat, lng }, zoom);
        }
      });
    }
  }

  return map;
}
