import React from 'react';
import {
  Box, Link, Typography, Alert,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { PATHS } from '../../paths';
import { useProfile } from '../../api/user/profile';

export const UserEmailNotVerifiedBanner: React.FC = () => {
  const { organization: { is_service_provider: isSP } } = useProfile();

  return (
    <Box mb={2}>
      <Alert severity="warning" variant="filled">
        <Typography variant="body1">
          Your email is not verified - please
          {' '}
          <Link
            component={RouterLink}
            color="inherit"
            to={isSP ? PATHS.sp.userProfile : PATHS.fm.userProfile}
          >
            verify your notification email address
          </Link>
          .
        </Typography>
      </Alert>
    </Box>
  );
};
