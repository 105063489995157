import {
  parseISO,
  addMinutes,
  setDate,
  setMonth,
  setYear,
  getYear,
  getMonth,
  getDate,
  setMinutes,
  setHours,
  getHours,
  getMinutes,
  format,
} from 'date-fns';
import { locale } from './number';

/**
 * This need only if input string is 00:00:00 time
 * Or if you get this simple date from db, ex. 21.09.2021 (that's it, not are full time string)
 * @param isoDateTime
 */
export function isoUTCDateToLocate(isoDateTime: string | Date): string {
  const date = typeof isoDateTime === 'string' ? new Date(isoDateTime) : isoDateTime;
  return date.toLocaleDateString(locale, { timeZone: 'UTC' });
}

export function isoDateToLocate(isoDateTime: string | Date): string {
  const date = typeof isoDateTime === 'string' ? new Date(isoDateTime) : isoDateTime;
  return date.toLocaleDateString();
}

export function isoDateTimeToLocale(isoDateTime: string | Date): string {
  const date = typeof isoDateTime === 'string' ? new Date(isoDateTime) : isoDateTime;
  return `${date.toLocaleDateString()}, ${date.toLocaleTimeString(
    'en-US',
    { hour: '2-digit', minute: '2-digit', timeZoneName: 'short' },
  )}`;
}

/**
 * If we work with date, and we need to get Date object with timezone shift
 *
 * @param isoDate
 */
export const isoUTCStringToDate = (isoDate: string): Date => {
  const parsedDate = parseISO(isoDate);
  return addMinutes(parsedDate, parsedDate.getTimezoneOffset());
};

// TODO: need more elegant implementation
/**
 * Append days, months and years to Date object
 *
 * @param baseDate
 * @param date
 */
export const modifyDate = (baseDate: Date, date: Date) => {
  if (!baseDate) {
    return date;
  }
  let result = baseDate;
  const day = getDate(date);
  const month = getMonth(date);
  const year = getYear(date);

  result = setDate(result, day);
  result = setMonth(result, month);
  result = setYear(result, year);

  return result;
};

/**
 * Append hours and time to Date object
 *
 * @param baseDate
 * @param time
 */
export const modifyTime = (baseDate: Date, time: Date) => {
  let result = baseDate;
  const minutes = getMinutes(time);
  const hours = getHours(time);

  result = setHours(result, hours);
  result = setMinutes(result, minutes);

  return result;
};

/**
 * Set date to the noon in UTC
 *
 * @param {Date} date
 * @returns {Date}
 */
export const setDateTimeToNoonUTC = (date: Date): Date => new Date(`${format(date, 'yyyy-MM-dd')}T12:00:00Z`);
