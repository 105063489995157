import { FileUploadContext, WhereOrganizationFileInput } from '../../api/types/globalTypes';

export const whereByContext = <T extends WhereOrganizationFileInput>(
  context: FileUploadContext,
): T => (
  (Object.keys(context) as Array<keyof FileUploadContext>).reduce((acc: T, key) => {
    acc[key] = { eq: context[key] };
    return acc;
  }, {} as T)
);
