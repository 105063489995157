import React, {
  useCallback, useMemo, useState, useEffect,
} from 'react';

import { WhereOperationsInput } from 'api/types/globalTypes';

import { Filter, isFilterSimple } from '../types';
import { FilterWidgetProps } from './FilterWidgetProps';
import { FilterWidgetDialog } from './FilterWidgetDialog';
import { StringFilter } from './StringFilter';

export const StringFilterWidget: React.FC<FilterWidgetProps> = ({
  tableId,
  field,
  filter: externalFilter,
  filterClearedTimestamp,
  title,
  onApply,
  onClear,
  onClose,
  open,
  isNullEnabled,
  isNullTitle,
  isNotNullEnabled,
  isNotNullTitle,
  apiFilterType,
}) => {
  const labelId = useMemo(
    () => `${tableId}-${field}-string-filter-operator-label`,
    [field, tableId],
  );

  const initialFilter: Filter = useMemo(() => externalFilter ?? {
    field,
    type: 'string',
    logic: undefined,
    where: apiFilterType === 'json' ? { jsArrayMatch: '' } : { contains: '' },
  }, [externalFilter, field, apiFilterType]);

  const [filter, setFilter] = useState<Filter>(initialFilter);

  useEffect(() => {
    if (filterClearedTimestamp) {
      setFilter(initialFilter);
    }
  }, [filterClearedTimestamp, initialFilter]);

  const op = useMemo(
    () => Object.keys(filter.where)[0] as keyof WhereOperationsInput,
    [filter],
  );

  const value = useMemo(() => {
    if (!isFilterSimple(filter)) {
      throw new Error(
        'Expected a simple filter,'
        + `got ${JSON.stringify(filter)} on a field '${field}' in table with id '${tableId}'`,
      );
    }
    return op === 'isNull'
      ? filter.where[op] as boolean
      : filter.where[op] as string;
  }, [field, filter, op, tableId]);

  // to prevent showing 'true' and 'false' strings when switching inNull filter
  const textFieldValue = useMemo(
    () => (typeof value === 'boolean' ? '' : value),
    [value],
  );

  const handleApply = useCallback(() => {
    const filterTrimmed = {
      ...filter,
      ...isFilterSimple(filter) && op !== 'isNull'
        ? {
          where: {
            ...filter.where,
            [op]: filter.where[op].trim(),
          },
        }
        : {},
    } as Filter;

    setFilter(filterTrimmed);
    onApply(field, filterTrimmed);
  }, [field, filter, onApply, op]);

  const handleSubmit = useCallback((ev) => {
    ev.preventDefault();
    handleApply();
    onClose(field);
  }, [handleApply, field, onClose]);

  return (
    <FilterWidgetDialog
      tableId={tableId}
      field={field}
      title={title}
      onApply={handleApply}
      onClear={() => onClear(field)}
      onClose={onClose}
      open={open}
    >
      <form onSubmit={handleSubmit}>
        <StringFilter
          apiFilterType={apiFilterType}
          field={field}
          labelId={labelId}
          value={value}
          textFieldValue={textFieldValue}
          op={op}
          setFilter={setFilter}
          isNullEnabled={isNullEnabled}
          isNullTitle={isNullTitle}
          isNotNullEnabled={isNotNullEnabled}
          isNotNullTitle={isNotNullTitle}
        />
        <input type="submit" hidden />
      </form>
    </FilterWidgetDialog>
  );
};
