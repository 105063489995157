import { createTheme, adaptV4Theme } from '@mui/material/styles';
import { ComponentsProps } from '@mui/material/styles/props';

export const colors = {
  blue: '#1b1c51',
  blueHover: '#4d4e9d',

  orange: '#f36a22',
  orangeHover: '#b15423',

  red: '#d60a0a',
  redHover: '#5a0909',

  bgBlue: '#e5f1fe',
  bgGrey: '#f5f5f5',
  bgLightGrey: '#f9f9f9',

  textBlack: '#080808',
  textGrey: '#7f7f7f',

  darkGrey: '#202020',
  grey: '#cccccc',
  lightGrey: '#dbdbdb',
  white: '#ffffff',

  yellowGreen: '#d0ff00',
  green: '#5cbf01',

  // status colors
  statusBlue: '#1976d2',
  statusLightBlue: '#90caf9',
  statusGreen: '#66bb6a',
  statusLightGrey: '#eeeeee',
  statusGrey: '#bdbdbd',
  statusYellow: '#ffee58',
  statusAmber: '#ffca28',
  statusOrange: '#ffa726',
  statusRed: '#ef5350',

  transparentBlack: 'rgba(0, 0, 0, 0.6)',
};

// intermediate theme object to use breakpoints
const theme = createTheme();

const props: ComponentsProps = {
  MuiButtonBase: {
    disableRipple: true,
  },
  MuiButton: {
    variant: 'contained',
    color: 'inherit',
    disableElevation: true,
  },
  MuiButtonGroup: {
    variant: 'contained',
    disableRipple: true,
    disableElevation: true,
  },
  MuiTextField: {
    variant: 'outlined',
  },
  MuiPaper: {
    variant: 'outlined',
  },
  MuiLink: {
    underline: 'always',
  },
  MuiTabs: {
    indicatorColor: 'primary',
    textColor: 'primary',
  },
};

export const mainTheme = createTheme(adaptV4Theme({
  palette: {
    primary: {
      main: colors.blue,
      light: colors.blueHover,
    },
    secondary: {
      main: colors.orange,
      light: colors.orangeHover,
    },
    text: {
      primary: colors.textBlack,
      secondary: colors.textGrey,
    },
    background: {
      default: colors.bgGrey,
    },
    divider: colors.lightGrey,
  },
  props,
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: colors.bgGrey,
        },
      },
    },
    MuiButton: {
      root: {
        minWidth: 'auto',
        whiteSpace: 'nowrap',
      },
      contained: {
        color: colors.blue,
        backgroundColor: colors.grey,
      },
      containedPrimary: {
        color: colors.white,
        backgroundColor: colors.blue,
        '&:hover': {
          backgroundColor: colors.blueHover,
        },
      },
      containedSecondary: {
        color: colors.white,
        backgroundColor: colors.orange,
        '&:hover': {
          backgroundColor: colors.orangeHover,
        },
      },
    },
    MuiButtonGroup: {
      contained: { // disableElevation is not available for ButtonGroup
        boxShadow: 'none',
      },
    },
    MuiLink: {
      underlineAlways: {
        '&:hover': {
          color: colors.blueHover,
        },
      },
    },
    MuiTabs: {
      root: {
        borderBottom: `1px solid ${colors.lightGrey}`,
        boxShadow: `0 1px 4px ${colors.lightGrey}`,
      },
    },
    MuiTab: {
      root: {
        [theme.breakpoints.up('sm')]: {
          minWidth: 160,
        },
        '&:hover': {
          color: colors.textBlack,
          backgroundColor: colors.bgBlue,
        },
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: '36px',
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '0.8rem',
        '& a, & a:hover': {
          color: colors.bgLightGrey,
        },
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiInputLabel: {
      root: {
        width: 'calc(100% - 46px)',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        '&.Mui-focused': {
          width: 'auto',
          overflow: 'visible',
        },
        '&.MuiFormLabel-filled': {
          width: 'auto',
          overflow: 'visible',
        },
      },
    },
  },
}));

export const drawerTheme = createTheme(adaptV4Theme({
  palette: {
    mode: 'dark',
    background: {
      paper: colors.blue,
    },
    secondary: {
      main: colors.orange,
      light: colors.orangeHover,
    },
  },
  props,
  overrides: {
    MuiButtonGroup: {
      contained: { // disableElevation is not available for ButtonGroup
        boxShadow: 'none',
      },
    },
  },
}));
