import React from 'react';
import { useForm } from 'react-hook-form';
import { getError, hasError, labelIsOnTop } from '../helpers';
import { SelectField, SelectOption } from '../../form/SelectField';
import { CustomFieldFormLabel } from '../CustomFieldFormLabel';
import { CustomFieldRenderComponentProps } from '../types';

interface CustomFieldSelectProps extends CustomFieldRenderComponentProps {
  value: string[];
}

export const CustomFieldSelect: React.FC<CustomFieldSelectProps> = ({
  field,
  value,
  disabled,
  onChange,
}) => {
  const { errors } = useForm();

  return (
    <>
      <CustomFieldFormLabel field={field} checkLabelOnTop />
      <SelectField
        disabled={disabled}
        value={value || ''}
        onChange={onChange}
        label={(labelIsOnTop(field) ? field.placeholder : field.name) || undefined}
        options={field.context.options as SelectOption[]}
        fullWidth
        error={hasError(errors, field)}
        helperText={getError(errors, field) || ' '}
      />
    </>
  );
};
