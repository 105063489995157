import React, { useLayoutEffect, useRef } from 'react';
import {
  ChannelList as DefaultChannelList, ChannelListProps as DefaultChannelListProps,
} from 'stream-chat-react';
import { ChannelSearch } from '../ChannelSearch/ChannelSearch';
import { ChannelPreview } from './ChannelPreview';
import { useChatContext } from '../ChatContext';
import { useAutoCreateChatRoom } from '../hooks/useAutoCreateChatRoom';
import { ErrorSnackbar, useErrorSnackbar } from '../../notifications/ErrorSnackbar';
import { Loading } from '../../Loading';
import { EmptyStateIndicator } from './EmptyStateIndicator';

export type ChannelListProps = Omit<DefaultChannelListProps, 'showChannelSearch' | 'ChannelSearch' | 'Preview' | 'Filters'>;

export const ChannelList: React.FC<ChannelListProps> = (props) => {
  const { filters: { apiFilters }, sort: { apiSort } } = useChatContext();
  const {
    loading: isRoomChecking,
    error: roomCheckingError,
  } = useAutoCreateChatRoom(props.customActiveChannel);

  // Easy way to make links in the last message in channel preview be opened in new tab
  const channelsWrapperRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    const selector = channelsWrapperRef.current;
    const onLinkClicked = (e: Event) => {
      if ((e.target as any).nodeName === 'A') {
        e.preventDefault();
        window.open((e.target as HTMLAnchorElement).href, '_blank')?.focus();
      }
    };
    selector?.addEventListener('click', onLinkClicked);
    return () => {
      selector?.removeEventListener('click', onLinkClicked);
    };
  }, []);

  const roomErrorSnackbar = useErrorSnackbar(roomCheckingError);

  if (isRoomChecking) {
    return <Loading center />;
  }

  return (
    <div ref={channelsWrapperRef}>
      <DefaultChannelList
        showChannelSearch
        ChannelSearch={ChannelSearch}
        Preview={ChannelPreview}
        filters={apiFilters}
        sort={apiSort}
        EmptyStateIndicator={EmptyStateIndicator}
        {...props}
      />
      <ErrorSnackbar {...roomErrorSnackbar}>
        Checking room existence error
      </ErrorSnackbar>
    </div>
  );
};
