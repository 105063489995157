import { useMemo } from 'react';
import { DataGridProps, isWithMapProps } from '../DataGridProps';

export function useMap(dataGridProps: DataGridProps) {
  const mapData = useMemo(
    () => (isWithMapProps(dataGridProps) ? dataGridProps.mapData?.data ?? [] : []),
    [dataGridProps],
  );

  const mapLoading = useMemo(
    () => (isWithMapProps(dataGridProps) ? dataGridProps.mapLoading : false),
    [dataGridProps],
  );

  const mapError = useMemo(
    () => (isWithMapProps(dataGridProps) ? dataGridProps.mapError : undefined),
    [dataGridProps],
  );

  return {
    mapData,
    mapLoading,
    mapError,
  };
}
