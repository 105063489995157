import React from 'react';
import { Grid, SvgIcon, Tooltip } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useHistory, generatePath } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { FmPreferenceButton } from '../../../fmSpStat/PreferenceButton/FmPreferenceButton';
import { PATHS } from '../../../../../paths';
import { sidebarInPopupPaths, useSpSidebarHandlers } from './SpSidebarProvider';
import { TooltipIconButton } from '../../../../buttons/TooltipIconButton';
import { NavTypeNoAdmin } from '../../../../nav/navType';

export interface SpSidebarTitleProps {
  navType: NavTypeNoAdmin;
  title: string;
  spOrganizationId?: number;
  logo?: string;
}

const useStyles = makeStyles((theme) => createStyles({
  logo: {
    height: '30px',
    margin: '0px 0 -8px',
  },
  title: {
    [theme.breakpoints.down('md')]: {
      maxWidth: 'calc(100vw - 150px)',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '450px',
    },
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

export const SpSidebarTitle: React.FC<SpSidebarTitleProps> = ({
  navType,
  title,
  spOrganizationId,
  logo,
}) => {
  const classes = useStyles();
  const { location: { pathname } } = useHistory();
  const { closeSidebar } = useSpSidebarHandlers();

  return (
    <Grid container spacing={2}>
      {logo ? (
        <Grid item>
          <img className={classes.logo} src={logo} alt={`${title} Logo`} />
        </Grid>
      ) : null}
      <Grid
        item
        className={classes.title}
      >
        <Tooltip title={title} arrow placement="top-start">
          <span>{title}</span>
        </Tooltip>
      </Grid>
      {navType === 'fm' ? (
        <Grid item>
          {spOrganizationId ? <FmPreferenceButton size="small" spOrganizationId={spOrganizationId} /> : null}
        </Grid>
      ) : null}
      {sidebarInPopupPaths.includes(pathname) ? null : (
        <Grid item>
          <TooltipIconButton
            title="Pop out SP profile in new browser window"
            size="small"
            onClick={() => {
              const path = generatePath(
                navType === 'fm' ? PATHS.fm.emptyPageForSidebar : PATHS.sp.emptyPageForSidebar,
              );
              const searchParams = new URLSearchParams({
                sb_type: 'sp',
                sb_id: `${spOrganizationId}`,
                sb_title: title,
                sb_extra: '',
              });
              const sidebarWidth = 600;
              const windowWidth = window.innerWidth;
              const windowHeight = window.innerHeight;
              const handle = window.open(
                `${document.location.origin}#${path}?${searchParams}`,
                `sp-popup-${spOrganizationId}`,
                `popup=yes,width=${sidebarWidth},height=${windowHeight},left=${windowWidth - sidebarWidth}`,
              );
              if (handle) {
                closeSidebar();
              }
            }}
          >
            <SvgIcon style={{ fontSize: '15px' }}>
              <FontAwesomeIcon icon="arrow-up-right-from-square" />
            </SvgIcon>
          </TooltipIconButton>
        </Grid>
      )}
    </Grid>
  );
};
