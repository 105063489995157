import React, { useMemo } from 'react';
import { Box, Button, Grid } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { createStyles, makeStyles } from '@mui/styles';
import { useQuery } from '@apollo/react-hooks';

import { useSpSidebarHandlers } from '../SpSidebarProvider';
import { ServiceFields, ServiceFieldsVariables } from '../../../../../../api/types/ServiceFields';
import { SERVICE_FIELDS } from '../../../../../../api/serviceFields/serviceFields';
import { convertValue, FieldType } from '../../../../../../api/customFields';
import { Loading } from '../../../../../Loading';
import { ErrorMessage } from '../../../../../notifications/ErrorMessage';
import { SidebarProp } from '../../../../../sidebar/SidebarProp';
import { NoData } from '../../../../../text/NoData';
import { ServiceProvider_organization } from '../../../../../../api/types/ServiceProvider';
import { ServiceFieldLevel } from '../../../../../../api/types/globalTypes';
import check from './icons/checkmark.svg';

const useStyles = makeStyles(() => createStyles({
  icon: {
    color: '#D9D9D9',
    height: 16,
    width: 16,
  },
  checkIcon: {
    backgroundSize: 'contain',
    background: `url(${check})`,
  },
  COIIcon: {
    color: '#00B3A6',
  },
}));
const diversityFieldTitlesMap = {
  Type: 'Entity',
  Classifications: 'Classification',
  ['If your company is at least 51 percent owned, controlled and operated by one or more of the following,'
  + ' please check all that apply:']: 'Diversity',
};

interface DiversitySubPageProps {
  // organizationId: number;
  organization: ServiceProvider_organization;
}

export const DiversitySubPage = ({ organization }: DiversitySubPageProps) => {
  const classes = useStyles();
  const { modifyExtra } = useSpSidebarHandlers();

  const {
    data: fieldsData,
    loading: fieldsLoading,
    error: fieldsError,
  } = useQuery<ServiceFields, ServiceFieldsVariables>(SERVICE_FIELDS, {
    fetchPolicy: 'no-cache',
    variables: {
      skip: 0,
      take: 300,
      where: {
        level: { eq: 'organization' },
        // service_id: { isNull: true },
      },
    },
  });

  const values = useMemo(() => organization
    .serviceFieldValues
    .filter(((sfv) => sfv.field.level === ServiceFieldLevel.OrganizationLevel))
    .reduce((acc: any, { field, value }) => {
      const { type, context } = field;
      const parsedValue = convertValue(value, type, context);
      return { ...acc, [field.field_id]: parsedValue.split(',').map((t) => t.trim()) };
    }, {}), [organization]);

  const serviceFields = useMemo(() => (
    fieldsData?._serviceFields?.items
      ? fieldsData._serviceFields.items.filter((field) => (
        field.type !== FieldType.label && field.type !== FieldType.html
      ))
      : []
  ), [fieldsData]);

  return (
    <Box key="documents" m={2}>
      <Button
        size="large"
        variant="text"
        color="primary"
        startIcon={<ArrowBack />}
        onClick={() => modifyExtra((p) => ({ ...p, page: 'main' }))}
      >
        Diversity
      </Button>

      <Box mt={3} ml={1}>
        {fieldsLoading ? (
          <Loading center />
        ) : null}
        {fieldsError ? (
          <ErrorMessage center devMessage={fieldsError.message}>
            Failed loading fields data
          </ErrorMessage>
        ) : null}
        <Grid container spacing={1}>
          {serviceFields.map((field) => (
            <Grid key={field.field_id} item xs={12}>
              <SidebarProp
                title={diversityFieldTitlesMap[field.name] ?? field.name}
                value={values[field.field_id] && values[field.field_id].length ? (
                  <Grid container direction="column" spacing={1} mt={0.5}>
                    {values[field.field_id].map((v: string) => (
                      <Grid key={v} container item spacing={1} alignItems="center">
                        <Grid item>
                          <div className={`${classes.icon} ${classes.COIIcon} ${classes.checkIcon}`} />
                        </Grid>
                        <Grid item>
                          {v}
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                ) : (<NoData>No answer</NoData>)}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};
