import React, { useCallback, useState } from 'react';
import { useChatContext } from 'stream-chat-react';
import { Button } from '@mui/material';
import { DoneAll } from '@mui/icons-material';
import { useChatUnreadCount } from 'components/chat/hooks/useChatUnreadCount';
import { useDialog } from '../Dialog';

export const MarkAllReadButton: React.FC = () => {
  const { client } = useChatContext();
  const { unreadMessagesCount } = useChatUnreadCount();
  const [isLoading, setLoading] = useState(false);
  const { confirm } = useDialog();

  const handleClick = useCallback(async () => {
    const confirmed = await confirm({
      title: 'Are you sure?',
      content: 'This action will mark all rooms as seen',
      okText: 'Yes, mark',
    });
    if (confirmed) {
      setLoading(true);
      await client.markChannelsRead();
      setLoading(false);
    }
  }, [client, confirm]);

  return (
    <Button
      startIcon={<DoneAll />}
      disabled={isLoading || unreadMessagesCount === 0}
      fullWidth
      onClick={handleClick}
      variant="text"
    >
      Mark all as read
    </Button>
  );
};
