import { useMutation, useQuery } from '@apollo/react-hooks';
import React, { useContext, useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { BoardingCompleteness } from '../../../../api/types/BoardingCompleteness';
import { BOARDING_COMPLETENESS } from './queries/BoardingCompletenessQuery';
import { FooterToolbar } from './components/FooterToolbar';
import { HelpSidebar } from './components/HelpSidebar/HelpSidebar';
import { PROFILE_QUERY, useProfile } from '../../../../api/user/profile';
import { BOARDING_VISITED } from './queries/BoardingVisitedMutation';
import { OnboardingContext } from './OnboardingContext';

const useStyles = makeStyles((theme) => createStyles({
  contentContainer: {
    margin: theme.spacing(1, 1, 1, 1),
    [theme.breakpoints.up('md')]: {
      paddingRight: 500,
      paddingBottom: 100,
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: 50,
      paddingBottom: 70,
    },
  },
}));

export const useCompleteness = () => {
  const { viewedState } = useContext(OnboardingContext);
  const { data, error } = useQuery<BoardingCompleteness>(
    BOARDING_COMPLETENESS,
    { fetchPolicy: 'no-cache' },
  );
  return {
    data,
    error,
    result: useMemo(() => ({
      profile_percentage: viewedState.profile
        && (data?.organization.onboarding?.profile_percentage || 0),
      key_contacts_percentage: viewedState.key_contacts
        && (data?.organization.onboarding?.key_contacts_percentage || 0),
      services_percentage: viewedState.services
        && (data?.organization.onboarding?.services_percentage || 0),
      diversity_percentage: viewedState.diversity
        && (data?.organization.onboarding?.diversity_percentage || 0),
      documents_percentage: viewedState.documents
        && (data?.organization.onboarding?.documents_percentage || 0),
    }), [data, viewedState]),
  };
};

export const OnBoardingContent: React.FC<{
  setCompleteness: React.Dispatch<React.SetStateAction<any>>,
  children: React.ReactNode,
}> = ({
  setCompleteness,
  children,
}) => {
  const classes = useStyles();
  const completeness = useCompleteness();

  useEffect(() => {
    setCompleteness(completeness.result);
    // eslint-disable-next-line
  }, [completeness.result]);

  const profile = useProfile();
  const [visited] = useMutation(BOARDING_VISITED, {
    refetchQueries: [PROFILE_QUERY],
  });
  useEffect(() => {
    if (!profile.organization.onboarding?.visited) {
      visited();
    }
  }, [profile, visited]);

  return (
    <HelpSidebar>
      <Box className={classes.contentContainer}>
        {children}
      </Box>
      <FooterToolbar />
    </HelpSidebar>
  );
};
