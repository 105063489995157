import React from 'react';
import {
  Checkbox,
  FormControlLabel,
  Grid,
} from '@mui/material';

interface Option {
  text: string;
  value: string;
}

interface CheckboxGroupProps {
  options: Option[];
  value?: string[];
  onChange?: (newValue: string[]) => void;
  disabled?: boolean;
}

export const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
  options,
  value,
  onChange,
  disabled = false,
}) => {
  const selectedValues = value || [];
  const handleChange = (name: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.checked
      ? [...selectedValues, name]
      : selectedValues.filter((v) => v !== name);
    if (onChange) onChange(newValue);
  };

  return (
    <Grid container>
      {options.map((option) => (
        <Grid key={option.value} item xs={12} sm={6}>
          <FormControlLabel
            label={option.text}
            control={(
              <Checkbox
                disabled={disabled}
                checked={selectedValues.includes(option.value as string)}
                onChange={handleChange(option.value as string)}
              />
            )}
          />
        </Grid>
      ))}
    </Grid>
  );
};
