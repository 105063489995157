import React from 'react';
import { Box, Typography } from '@mui/material';

export interface EmptyNotificationsPlaceholderProps {
  text?: string;
}

export const EmptyNotificationsPlaceholder: React.FC<EmptyNotificationsPlaceholderProps> = ({
  text = 'No notifications yet',
}) => (
  <Box p={2}>
    <Typography style={{ textAlign: 'center' }} color="textPrimary" variant="body2">{text}</Typography>
  </Box>
);
