export const { locale } = Intl.NumberFormat().resolvedOptions();

export const currencyFormatter = new Intl.NumberFormat(locale, {
  style: 'currency',
  currency: 'USD',
  currencyDisplay: 'narrowSymbol',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});
export const currencyFractionFormatter = new Intl.NumberFormat(locale, {
  style: 'currency',
  currency: 'USD',
  currencyDisplay: 'narrowSymbol',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const numberFormatter = new Intl.NumberFormat(locale, {});

const percentFormatter = new Intl.NumberFormat(locale, {
  style: 'percent',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

export function formatCurrency(amt: number | string): string | null {
  if (!amt || (typeof amt === 'string' && amt.trim() === '')) {
    return null;
  }
  const num = +(typeof amt === 'string'
    ? parseFloat(amt.replace(/,/g, ''))
    : amt);

  return num % 1 ? currencyFractionFormatter.format(num) : currencyFormatter.format(num);
}

export function formatPrice(num: number | null, fractionDigits = 2): string | null {
  if (num === null) return null;

  return num.toFixed(num % 1 === 0 ? 0 : fractionDigits);
}

export function formatNumber(num: number): string | null {
  return num ? numberFormatter.format(num) : null;
}

export function formatPercent(num: number): string | null {
  return num ? percentFormatter.format(num) : null;
}

export function parseCurrencyFloat(num: string): number {
  return parseFloat(num.replace(/,/g, ''));
}

export const bytesToMb = (
  bytes: number,
  fractionDigits?: number,
): string => (bytes / (1024 * 1024)).toFixed(fractionDigits ?? 1);
