import React, {
  createContext,
} from 'react';
import { useAppState } from 'pages/AppStateProvider/useAppState';
import { InfoSnackbar } from 'components/notifications/InfoSnackbar';
import { Loading } from 'components/Loading';
import { ErrorSnackbar, useErrorSnackbar } from 'components/notifications/ErrorSnackbar';
import { Button } from '@mui/material';
import { MaintenancePage } from './MaintenancePage';

interface AppStateValue {
  loading: boolean;
  globalSettings?: { key: string, value: string | null }[];
  getSetting(key: string): string | undefined | null;
}

export const AppStateContext = createContext<AppStateValue | null>(null);

export const AppStateProvider: React.FC = ({ children }) => {
  const {
    value, error, loading, hasNewVersion, handleRefreshPage,
  } = useAppState();

  const appStateErrorSnackbar = useErrorSnackbar(error);

  const inner = value.getSetting('MaintenanceMode') ? (<MaintenancePage />) : children;
  return (
    <AppStateContext.Provider value={value}>
      {loading
        ? <Loading fullScreen center />
        : inner}
      <ErrorSnackbar {...appStateErrorSnackbar}>
        Could not establish network connection, please check your internet connection.
      </ErrorSnackbar>
      <InfoSnackbar
        open={hasNewVersion}
        title="Update available"
        action={(
          <Button
            size="small"
            variant="outlined"
            onClick={handleRefreshPage}
          >
            Reload now
          </Button>
        )}
      >
        The app has been updated. Please reload the page to get the latest version.
      </InfoSnackbar>
    </AppStateContext.Provider>
  );
};
