import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import L from 'leaflet';

import { debounce } from 'lodash';

import { SERVICE_AREAS } from 'api/service/areas/serviceAreas';
import { ServiceAreas, ServiceAreasVariables } from 'api/types/ServiceAreas';
import { getMapBoundaries } from './getMapBoundaries';
import { useLoadingControl } from '../leaflet/loadingControl';
import { useServiceAreasRender } from './useServiceAreasRender';

export interface ServiceAreaMapDisabledConfig {
  mode: 'disabled';
  organizationId?: number;
  serviceId?: number;
}

export interface ServiceAreaMapGeneralConfig {
  mode: 'primary' | 'secondary';
  organizationId: number;
  serviceId: number;
}

export type ServiceAreaMapConfig = ServiceAreaMapGeneralConfig | ServiceAreaMapDisabledConfig;

export const useServiceAreas = (map: L.Map | undefined, {
  mode,
  organizationId,
  serviceId,
}: ServiceAreaMapConfig) => {
  const [firstLoad, setFirstLoad] = useState<boolean>(mode === 'primary');
  const [fetchServiceAreas, queryTuple] = useLazyQuery<
    ServiceAreas,
    ServiceAreasVariables
  >(SERVICE_AREAS, { fetchPolicy: 'no-cache' });

  const where = useMemo(() => ({
    organization_id: { eq: organizationId },
    service_id: { eq: serviceId },
  }), [organizationId, serviceId]);

  /**
   * On primary service areas we want to query areas right away and without boundaries
   * initial boundaries will be calculated in api
   */
  useEffect(() => {
    if (mode === 'primary') {
      fetchServiceAreas({ variables: { where } });
    }
  }, [fetchServiceAreas, mode, where]);

  useEffect(() => {
    if (queryTuple.data) {
      setFirstLoad(false);
    }
  }, [queryTuple.data]);

  /**
   * Loading control that will show loading snackbar on map
   */
  useLoadingControl(map, queryTuple.loading, 'Loading service area');

  /**
   * Render service areas hook
   */
  const { serviceAreasLayer, areaLayersCache, setAreaLayersCache } = useServiceAreasRender(
    map,
    queryTuple?.data?.organizationServiceAreas?.items,
    mode === 'primary' && firstLoad, // first load and primary mode
  );

  const fetch = useCallback(
    () => {
      if (!map || mode === 'disabled') {
        return;
      }
      setAreaLayersCache({});
      fetchServiceAreas({
        variables: { where, boundaries: getMapBoundaries(map) },
      });
    },
    [map, fetchServiceAreas, mode, where, setAreaLayersCache],
  );

  useEffect(() => {
    if (!map || mode === 'disabled') {
      return () => {};
    }
    let mounted = true;
    const refetchHandler = debounce(() => {
      if (mounted && !firstLoad) {
        fetch();
      }
    }, 800);
    map.on('resize', refetchHandler);
    map.on('zoom', refetchHandler);
    map.on('moveend', refetchHandler);
    return () => {
      mounted = false;
      map.off('resize', refetchHandler);
      map.off('zoom', refetchHandler);
      map.off('moveend', refetchHandler);
    };
  }, [map, firstLoad, mode, fetch]);

  return useMemo(() => ({
    fetch,
    queryTuple,

    serviceAreasLayer,
    areaLayersCache,
    setAreaLayersCache,
  }), [fetch, queryTuple, serviceAreasLayer, areaLayersCache, setAreaLayersCache]);
};
