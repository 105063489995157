import { useCallback, useState } from 'react';

type UseToggleHook = {
  open: boolean,
  onOpen: () => void,
  onClose: () => void,
  onToggle: () => void,
};

export const useModalControls = (initialState = false): UseToggleHook => {
  const [open, setOpen] = useState<boolean>(initialState);
  const onOpen = useCallback(() => setOpen(true), []);
  const onClose = useCallback(() => setOpen(false), []);
  const onToggle = useCallback(() => setOpen((v) => !v), []);
  return {
    open, onOpen, onClose, onToggle,
  };
};
