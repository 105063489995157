import React from 'react';
import {
  Snackbar, SnackbarCloseReason, SnackbarProps, Alert, AlertTitle,
} from '@mui/material';

export interface InfoSnackbarProps extends Omit<SnackbarProps, 'children'> {
  title?: string;
  onClose?: (event: React.SyntheticEvent<any> | Event, reason?: SnackbarCloseReason) => void;
  children: React.ReactNode;
}

export const InfoSnackbar: React.FC<InfoSnackbarProps> = ({
  title,
  onClose,
  children,
  ...snackbarProps
}) => (
  <Snackbar
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    autoHideDuration={snackbarProps.action ? null : 10000}
    onClose={onClose}
    {...snackbarProps}
  >
    <Alert severity="info" onClose={onClose} variant="filled" action={snackbarProps.action}>
      {title && (<AlertTitle>{title}</AlertTitle>)}
      {children}
    </Alert>
  </Snackbar>
);
