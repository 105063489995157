import React, { useState } from 'react';
import {
  AppBar, Button, Chip, IconButton, SvgIcon, Toolbar, Tooltip, Box, Grid,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import InfoIcon from '@mui/icons-material/Info';
import { AppBarProps } from '@mui/material/AppBar/AppBar';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Confetti from 'react-confetti';
import cn from 'classnames';
import { EnvLabel } from 'components/nav/EnvLabel';
import { useProfile } from 'api/user/profile';
import { PATHS } from 'paths';
import { Permissions } from 'api/permissions/permissions';
import { useResizeObserver } from 'utils/useResizeObserver';
import { LOGIN_AS_SEARCH_PARAM, useLoginAs } from 'pages/useLoginAs';
import { FmLogo } from 'components/organization/bid/view/FmLogo';
import useEasterEggs from 'components/nav/useEasterEggs';
import { colors } from '../theme';
import { MenuListItem } from './menu';
import { NavType } from './navType';
import { MenuIconButton } from './MenuIconButton';
import { Title, TitleProps } from '../layout/Title';
import { NotificationsIconButton } from '../userNotifications/NotificationsIconButton';

export const APP_BAR_HEIGHT = 8;

const useStyles = makeStyles((theme) => createStyles({
  appBar: {
    backgroundColor: colors.white,
    border: 'none',
    height: theme.spacing(APP_BAR_HEIGHT),
  },
  appBarShadow: {
    boxShadow: theme.shadows[4],
    // Transition is assigned in SmartDrawer - CSS limitation
  },
  appBarNoShadow: {
    boxShadow: theme.shadows[0],
    // Transition is assigned in SmartDrawer - CSS limitation
  },
  toolbar: {
    height: theme.spacing(APP_BAR_HEIGHT),
  },
  logo: {
    width: 150,
  },
  appBarLogoChristmasIcon: {
    color: '#e53112',
    fontSize: '1em',
    position: 'absolute',
    left: '68px',
    top: '8px',
  },
  spacer: {
    flexGrow: 1,
    marginRight: theme.spacing(1),
  },
  toolbarItem: {
    marginRight: theme.spacing(1),
  },
  loginAsCopyClipboardIcon: {
    marginLeft: theme.spacing(1),
  },
  viewingAs: {
    maxWidth: '20vw',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

export interface SignedInAppBarProps extends Omit<AppBarProps, 'title'> {
  navType?: NavType;
  menu?: MenuListItem[];
  titleProps?: TitleProps;
  onboardingNode?: React.ReactNode;
}

function drawSnowShape(this: any, ctx: any) {
  ctx.restore();
  ctx.save();
  this.context.translate(this.x, this.y);
  this.context.beginPath();
  this.context.fillStyle = this.color;
  this.context.strokeStyle = this.color;
  this.context.globalAlpha = this.getOptions().opacity;
  ctx.beginPath();
  ctx.rotate(0);
  ctx.arc(0, 0, 3, 0, 2 * Math.PI);
  ctx.fill();
  ctx.closePath();
}

export const SignedInAppBar: React.FC<SignedInAppBarProps> = ({
  navType,
  menu,
  titleProps,
  className,
  onboardingNode,
  ...props
}) => {
  const history = useHistory();
  const classes = useStyles();
  const { loginAs } = useLoginAs();

  const profile = useProfile();
  const isOrgOwner = !!profile.userPermissions.find(
    (p) => (p.permission_id === Permissions.ORGANIZATION_OWNER),
  );
  const isSubscribed = !!profile.organization?.subscription;
  const [supportWidgetLoading, setSupportWidgetLoading] = useState(false);
  const logoUrl = profile?.organization.logo_url;

  const [width, setWidth] = useState(window.innerWidth);
  useResizeObserver('toolbar', () => setWidth(window.innerWidth));

  const { isChristmasEgg, isIndependencyEgg, appLogo } = useEasterEggs();

  const viewingAsText = (
    <>
      Viewing as
      {' '}
      <strong>{profile.organization!.name}</strong>
    </>
  );

  return (
    <AppBar
      id="toolbar"
      position="fixed"
      variant="elevation"
      elevation={0}
      className={cn(
        classes.appBar,
        classes.appBarShadow,
        className,
      )}
      {...props}
    >
      <Toolbar className={classes.toolbar}>
        {isChristmasEgg && (
          <>
            <Confetti
              width={width}
              height={APP_BAR_HEIGHT * 8}
              colors={['#9696c8']}
              opacity={0.8}
              numberOfPieces={15}
              gravity={0.7}
              friction={0.4}
              drawShape={drawSnowShape}
            />
            {!logoUrl && <FontAwesomeIcon icon="hat-santa" className={classes.appBarLogoChristmasIcon} />}
          </>
        )}
        {isIndependencyEgg && (
          <Confetti
            width={width}
            height={APP_BAR_HEIGHT * 8}
            colors={[
              '#0049bf',
              '#00368c',
              '#002868',
              '#990926',
              '#bf0a30',
              '#e60b3b',
            ]}
            opacity={0.7}
            gravity={0.01}
            numberOfPieces={35}
            tweenDuration={10000}
          />
        )}
        {!logoUrl
          ? <img src={appLogo} alt="Procursys logo" className={classes.logo} />
          : <FmLogo logoUrl={logoUrl} />}
        {titleProps && <Title {...titleProps} />}
        <EnvLabel />
        <div className={classes.spacer} />
        {profile.is_login_as && (
          <Tooltip arrow title={viewingAsText} placement="bottom">
            <Chip
              color="secondary"
              icon={(<InfoIcon />)}
              className={classes.toolbarItem}
              label={(
                <Grid container wrap="nowrap" alignItems="center">
                  <Grid item>
                    <Box className={classes.viewingAs}>
                      {viewingAsText}
                    </Box>
                  </Grid>
                  <Grid item>
                    <IconButton
                      size="small"
                      className={classes.loginAsCopyClipboardIcon}
                      onClick={() => {
                        const { pathname, search } = history.location;
                        const searchParams = new URLSearchParams(search);
                        searchParams.set(LOGIN_AS_SEARCH_PARAM, loginAs?.toString() ?? '');
                        const text = `${window.location.origin}/#${pathname}?${searchParams}`;
                        navigator.clipboard.writeText(text);
                      }}
                    >
                      <FontAwesomeIcon icon="copy" color="#fff" fontSize="15px" />
                    </IconButton>
                  </Grid>
                </Grid>
              )}
            />
          </Tooltip>
        )}

        {isOrgOwner && navType === 'sp' ? (
          <div className={classes.toolbarItem}>{onboardingNode}</div>
        ) : null}
        {(isOrgOwner && !isSubscribed && navType === 'sp') && (
          <Button
            color="primary"
            size="small"
            className={classes.toolbarItem}
            onClick={() => history.push(PATHS.sp.billing)}
          >
            Subscribe
          </Button>
        )}
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          className={classes.toolbarItem}
          startIcon={(
            <SvgIcon>
              <FontAwesomeIcon icon="headset" />
            </SvgIcon>
          )}
          disabled={supportWidgetLoading}
          onClick={() => {
            setSupportWidgetLoading(true);
            const { fcWidget: widget } = window;
            if (!widget.isOpen()) {
              widget.open();
            }
            setTimeout(() => setSupportWidgetLoading(false), 5000);
          }}
        >
          Support
        </Button>
        <NotificationsIconButton />
        {menu && menu.map((item) => {
          if (!item.icon) return null;
          if (item.type === '_menu_item_url' || item.type === '_menu_item_onclick') {
            return (
              <MenuIconButton
                key={item.id}
                item={item}
              />
            );
          }
          return null;
        })}
      </Toolbar>
    </AppBar>
  );
};
