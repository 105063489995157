import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(2, 2),
  },
  checkboxGridItem: {
    padding: theme.spacing(2, 1, 0),
  },
  fieldLabel: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));
