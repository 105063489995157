import React, {
  useContext, useMemo, useState,
} from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import {
  Button, Grid, Toolbar, useMediaQuery,
} from '@mui/material';
import { colors } from '../../../../theme';
import { PATHS } from '../../../../../paths';
import { OnboardingContext } from '../OnboardingContext';
import { ButtonLoading } from '../../../../ButtonLoading';
import { useCurrentPath } from './useCurrentPath';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: colors.white,
    position: 'fixed',
    marginLeft: theme.spacing(-2),
    boxShadow: theme.shadows[4],
    zIndex: 1212,
    [theme.breakpoints.down('md')]: {
      top: 0,
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      bottom: 0,
      width: 'calc(100% - 288px)',
    },
  },
  toolbar: {
    [theme.breakpoints.down('md')]: {
      minHeight: '48px',
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '92px',
    },
  },
}));

const shouldShowBack = (path: string) => path !== PATHS.sp.onboarding.profile;

const getNextPath = (currentPath: string) => {
  switch (currentPath) {
    case PATHS.sp.onboarding.profile:
      return PATHS.sp.onboarding.key_contacts;
    case PATHS.sp.onboarding.key_contacts:
      return PATHS.sp.onboarding.services;
    case PATHS.sp.onboarding.services:
      return PATHS.sp.onboarding.diversity;
    case PATHS.sp.onboarding.diversity:
      return PATHS.sp.onboarding.documents;
    case PATHS.sp.onboarding.documents:
      return PATHS.sp.main;
    default: return null;
  }
};

export const FooterToolbar = () => {
  const history = useHistory();
  const boardingCtx = useContext(OnboardingContext);
  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const classes = useStyles();

  const currentPath = useCurrentPath();

  const showBack = useMemo(() => shouldShowBack(currentPath), [currentPath]);
  const nextPath = useMemo(() => getNextPath(currentPath), [currentPath]);

  return (
    <div className={classes.root}>
      <Toolbar classes={{ root: classes.toolbar }}>
        <Grid container spacing={1} justifyContent="space-between">
          <Grid item>
            {showBack ? (
              <Button
                variant="outlined"
                color="secondary"
                size={isDesktop ? 'large' : 'small'}
                onClick={() => history.goBack()}
              >
                Back
              </Button>
            ) : null}
          </Grid>
          <Grid item>
            {nextPath ? (
              <Button
                color="secondary"
                size={isDesktop ? 'large' : 'small'}
                onClick={async () => {
                  const { dirty, validateForm, submitForm } = boardingCtx;
                  if (dirty && validateForm && submitForm) {
                    setLoading(true);
                    const errors = await validateForm();
                    if (!Object.keys(errors).length) {
                      const response = await submitForm();
                      if (response && !response?.errors?.length) {
                        history.push(nextPath);
                      }
                    } else {
                      await submitForm();
                    }
                    setLoading(false);
                  } else if (validateForm && submitForm) {
                    const errors = await validateForm();
                    if (!Object.keys(errors).length) {
                      history.push(nextPath);
                    } else {
                      await submitForm();
                    }
                  } else {
                    history.push(nextPath);
                  }
                }}
                disabled={loading}
              >
                <ButtonLoading loading={loading} />
                {boardingCtx?.dirty && boardingCtx?.submitForm ? 'Save & ' : ''}
                Continue
              </Button>
            ) : null}
          </Grid>
        </Grid>
      </Toolbar>
    </div>
  );
};
