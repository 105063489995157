import { ChannelFilters } from 'stream-chat';
import { useMemo } from 'react';
import { ChatGenerics } from '../streamTypes';
import { useProfileChatData } from '../ChatProvider';

/**
 * This filter must be used to query getStream channel related data
 * otherwise an access violation error will be returned
 */
export const useChannelRequiredFilters = (): ChannelFilters<ChatGenerics> => {
  const { user_id: chatUserId } = useProfileChatData();

  return useMemo(() => ({
    members: { $in: chatUserId ? [chatUserId] : ['-1'] },
  }), [chatUserId]);
};
