import { gql } from '@apollo/client';

export const SP_BIDS_COUNT_QUERY = 'SpBidsCount';
export const SP_BIDS_COUNT = gql`
  query SpBidsCount($where: WhereBidInput) {
    spBids(where: $where) {
      total_count
    }
  }
`;
