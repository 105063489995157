import React from 'react';

import { ColumnProps } from '../../column/Column';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Filter, FilterType } from '../types';
import {
  isFilterWidgetPropsWithAutocompleteLoadFn,
  isFilterWidgetPropsWithOptions,
  isFilterWidgetPropsWithAutocompleteGql,
} from '../FilterWidgetProps';
import { StringFilterWidget } from './StringFilterWidget';
import { CheckboxesFilterWidget } from './CheckboxesFilterWidget';
import { DateFilterWidget } from './DateFilterWidget';
import { IntegerFilterWidget } from './IntegerFilterWidget';
import { FilterWidgetProps } from './FilterWidgetProps';
import { AutocompleteFilterWidget } from './AutocompleteFilterWidget';

const DummyFilterWidget: React.FC<FilterWidgetProps> = () => null;

export const FILTER_WIDGETS: Record<FilterType, React.FC<FilterWidgetProps>> = {
  string: StringFilterWidget,
  integer: IntegerFilterWidget,
  float: DummyFilterWidget, // TODO
  date: DateFilterWidget,
  datetime: DummyFilterWidget, // TODO
  boolean: DummyFilterWidget, // TODO
  checkboxes: CheckboxesFilterWidget,
  autocomplete: AutocompleteFilterWidget,
};

/**
 * Returns a widget component if a column has widget props (or a widget itself a ReactNode) set.
 * Otherwise, returns null.
 * Widgets will have `key` prop set to column field value,
 * so this function can be called inside `map`.
 */
export function buildFilterWidget(
  tableId: string,
  columnProps: ColumnProps,
  title: string,
  filter: Filter | undefined,
  filterClearedTimestamp: number,
  onApply: (field: string, filter: Filter) => void,
  onClear: (field: string) => void,
  open: boolean,
  onClose: (field: string) => void,
): React.ReactNode {
  const { filterWidget } = columnProps;
  if (!filterWidget) return null;
  // Select a filter widget by type
  const FilterWidget = FILTER_WIDGETS[filterWidget.filterType];
  return (
    <FilterWidget
      key={columnProps.field}
      loadAutocompleteOptions={isFilterWidgetPropsWithAutocompleteLoadFn(filterWidget)
        ? filterWidget.loadAutocompleteOptions
        : undefined}
      {...isFilterWidgetPropsWithAutocompleteGql(filterWidget)
        ? {
          query: filterWidget.query,
          getVariables: filterWidget.getVariables,
          mapResults: filterWidget.mapResults,
        }
        : {}
      }
      tableId={tableId}
      field={columnProps.field}
      title={title}
      filter={filter}
      filterClearedTimestamp={filterClearedTimestamp}
      onApply={onApply}
      onClear={onClear}
      open={open}
      onClose={onClose}
      filterOptions={isFilterWidgetPropsWithOptions(filterWidget) ? filterWidget.options : []}
      isNullEnabled={filterWidget.isNullEnabled}
      isNullTitle={filterWidget.isNullTitle}
      isNotNullEnabled={filterWidget.isNotNullEnabled}
      isNotNullTitle={filterWidget.isNotNullTitle}
      logicType={filterWidget.logicType}
      substituteOptionLabels={
        isFilterWidgetPropsWithOptions(filterWidget)
          ? filterWidget.substituteOptionLabels
          : false
      }
      apiFilterType={filterWidget.apiFilterType ?? 'regular'}
      multiple={filterWidget.multiple}
    />
  );
}
