import React, { useCallback } from 'react';
import { Button } from '@mui/material';
import { Check, Clear } from '@mui/icons-material';
import { CustomDialog } from '../../../CustomDialog';
import { NullableFilterWidgetProps } from '../FilterWidgetProps';

export interface FilterWidgetDialogProps extends NullableFilterWidgetProps {
  tableId: string;
  field: string;
  title: string;
  onApply: () => void;
  applyDisabled?: boolean;
  onClear: () => void;
  onClose: (field: string) => void;
  open: boolean;
}

export const FilterWidgetDialog: React.FC<FilterWidgetDialogProps> = ({
  tableId,
  field,
  title,
  onApply,
  applyDisabled,
  onClear,
  onClose,
  open,
  children,
}) => {
  const onCloseHandler = useCallback(() => {
    onClose(field);
  }, [field, onClose]);
  return (
    <CustomDialog
      id={`${tableId}-${field}`}
      title={title}
      loading={false}
      open={open}
      onClose={onCloseHandler}
      closeButtonTitle="Cancel"
      maxWidth="sm"
      fullWidth
      actions={(
        <>
          <Button
            variant="outlined"
            onClick={() => {
              onClear();
              onCloseHandler();
            }}
            startIcon={<Clear />}
          >
            Clear
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              onApply();
              onCloseHandler();
            }}
            disabled={applyDisabled}
            startIcon={<Check />}
          >
            Apply
          </Button>
        </>
      )}
    >
      {children}
    </CustomDialog>
  );
};
