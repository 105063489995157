import { includes } from 'lodash';
import L from 'leaflet';

export enum BaseLayer {
  Satellite = 'Satellite',
  Terrain = 'Terrain',
  Hybrid = 'Hybrid',
  Roadmap = 'Roadmap',
}

export function isSatelliteLike(type: BaseLayer | string): boolean {
  return includes([BaseLayer.Satellite, BaseLayer.Hybrid], type);
}

export function addGoogleMapLayers(map: L.Map, defaultLayer?: 'hybrid' | 'roadmap'): BaseLayer {
  const googleMapSatellite = L.gridLayer.googleMutant({ type: 'satellite' });
  const googleMapTerrain = L.gridLayer.googleMutant({ type: 'terrain' });
  const googleMapHybrid = L.gridLayer.googleMutant({ type: 'hybrid' });
  const googleMap = L.gridLayer.googleMutant({ type: 'roadmap' });
  if (defaultLayer === 'hybrid') {
    map.addLayer(googleMapHybrid);
  } else {
    map.addLayer(googleMap);
  }
  const layersControl = L.control.layers({
    [BaseLayer.Satellite]: googleMapSatellite,
    [BaseLayer.Terrain]: googleMapTerrain,
    [BaseLayer.Hybrid]: googleMapHybrid,
    [BaseLayer.Roadmap]: googleMap,
  });
  map.addControl(layersControl);

  return BaseLayer.Roadmap;
}
