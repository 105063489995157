import { gql } from '@apollo/client';

export const SERVICE_AREAS_QUERY = 'ServiceAreas';
export const SERVICE_AREAS = gql`
  query ServiceAreas(
    $where: WhereOrganizationServiceAreaInput!
    $boundaries: BoundariesWhereInput
  ) {
    organizationServiceAreas(where: $where, boundaries: $boundaries) {
      items {
        area_id

        # postal code
        postalCode {
          postal_code
          shape {
            postalShape: simplifiedShape(boundaries: $boundaries)
          }
        }

        # state
        state {
          state_code
          stateShape: simplifiedShape(boundaries: $boundaries)
        }
      }
    }
  }
`;
