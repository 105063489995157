import React from 'react';
import {
  Button, ButtonProps, Tooltip, TooltipProps,
} from '@mui/material';

export interface TooltipButtonProps extends Omit<ButtonProps, 'title'> {
  title?: TooltipProps['title'];
  tooltipProps?: Omit<TooltipProps, 'title' | 'children'>;
}

export const TooltipButton: React.FC<TooltipButtonProps> = React.forwardRef(({
  children,
  title,
  tooltipProps,
  ...buttonProps
}, ref) => {
  if (!title) {
    return (
      <Button ref={ref} {...buttonProps}>{children}</Button>
    );
  }
  // span is for Tooltip to work if the button is disabled
  return (
    <Tooltip arrow title={title} placement="top" {...tooltipProps}>
      <span>
        <Button ref={ref} {...buttonProps}>{children}</Button>
      </span>
    </Tooltip>
  );
});
