import React from 'react';
import {
  Box, Grid, IconButton, Input, InputAdornment, Paper,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { createStyles, makeStyles } from '@mui/styles';
import { useFormikContext } from 'formik';
import { SelectField, SelectOption } from '../../form/SelectField';
import { FileListPreview } from './FileListPreview';
import { colors } from '../../theme';
import { DocumentRepositoryForm, FileItem } from '../useDocumentRepository';
import { FileType } from '../../../api/types/globalTypes';

const useStyles = makeStyles(() => createStyles({
  gridContainer: {
    padding: '3px 10px',
  },
  removeIconContainer: {
    textAlign: 'center',
    paddingRight: 4,
  },
  label: {
    color: colors.blue,
    fontWeight: 'bold',
  },
  bottomBox: {
    backgroundColor: '#F0F0F0',
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  errorMessage: {
    color: colors.red,
    textAlign: 'center',
  },
  categorySelect: {
    padding: 4,
  },
}));

interface UploadFileItemProps {
  disabled?: boolean;
  item: FileItem;
  categoryOptions: SelectOption[];
  onRemove: () => void;
  index: number;
}

export const UploadFilesListItem: React.FC<UploadFileItemProps> = ({
  item,
  categoryOptions,
  disabled,
  index,
  onRemove,
}) => {
  const classes = useStyles();
  const {
    setFieldValue, values, getFieldProps, getFieldMeta,
  } = useFormikContext<DocumentRepositoryForm>();

  const nameField = `files[${index}].name`;
  const templateIdField = `files[${index}].docusign_template_id`;
  const categoryField = `files[${index}].category_id`;

  return (
    <Paper>
      <Grid container alignItems="center" spacing={2} className={classes.gridContainer}>
        <Grid item xs={10}>
          <Input
            {...getFieldProps(nameField)}
            error={!!getFieldMeta(nameField).error}
            placeholder="Enter file name"
            fullWidth
            startAdornment={(
              <InputAdornment position="start">
                <FileListPreview type={item.type} file={item.file} />
              </InputAdornment>
            )}
          />
        </Grid>
        <Grid item xs={2} className={classes.removeIconContainer}>
          <IconButton
            disabled={disabled}
            edge="end"
            aria-label="delete"
            onClick={onRemove}
            size="large"
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
        {item.type === FileType.DocuSignTemplate ? (
          <Grid item xs={12}>
            <Input
              {...getFieldProps(templateIdField)}
              error={!!getFieldMeta(templateIdField).error}
              placeholder="Enter DocuSign template id"
              fullWidth
            />
          </Grid>
        ) : null}
      </Grid>
      {(!values.categoryInitiallyHidden || item.show_in_repository) && (
        <Box className={classes.bottomBox}>
          {item.rejectionReason
            ? <span className={classes.errorMessage}>{item.rejectionReason}</span>
            : (
              <SelectField
                {...getFieldProps(nameField)}
                className={classes.categorySelect}
                formControlProps={{ variant: 'standard' }}
                placeholder="Choose category"
                options={categoryOptions}
                disabled={disabled}
                displayEmpty
                value={values.files[index]?.category_id ?? ''}
                onChange={(e) => {
                  setFieldValue(categoryField, e.target.value, true);
                }}
                error={!!getFieldMeta(categoryField).error}
              />
            )}
        </Box>
      )}
    </Paper>
  );
};
