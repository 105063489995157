import React, {
  createContext, useContext, useMemo, useState,
} from 'react';
import { useChannelFilters, UseChannelsFilterHook } from './ChannelList/useChannelFilters';
import { ErrorSnackbar, useErrorSnackbar } from '../notifications/ErrorSnackbar';
import { useChannelSort, UseChannelSortHook } from './ChannelList/useChannelSort';

type AdditionalPropsType = {
  defaultMessageValue?: string;
};

type ChatCtx = {
  filters: UseChannelsFilterHook;
  sort: UseChannelSortHook;
  additionalProps?: AdditionalPropsType;

  // ui thing for hide procursys bot messages
  botMessagesHidden: boolean;
  setBotMessagesHidden: React.Dispatch<React.SetStateAction<boolean>>;

  channelJumpTarget: {
    msgId: string | null;
    setMsgId: React.Dispatch<React.SetStateAction<string | null>>;
  },
};

export const ChatContext = createContext<ChatCtx | undefined>(undefined);

export const useChatContext = (): ChatCtx => {
  const store = useContext(ChatContext);
  if (store === undefined) {
    throw new Error('Collapsible table store is undefined in this context');
  }
  return store;
};

export const ChatProvider: React.FC<{
  channelId?: string,
  additionalProps?: AdditionalPropsType,
}> = ({ children, channelId, additionalProps }) => {
  const filters = useChannelFilters(channelId);
  const sort = useChannelSort();
  const [botMessagesHidden, setBotMessagesHidden] = useState(false);
  const [searchResultMsgId, setSearchResultMsgId] = useState<string | null>(null);

  const filterErrorSnackbar = useErrorSnackbar(filters.error);

  const ctxValue = useMemo<ChatCtx>(() => ({
    filters,
    additionalProps,
    sort,
    botMessagesHidden,
    setBotMessagesHidden,
    channelJumpTarget: {
      msgId: searchResultMsgId,
      setMsgId: setSearchResultMsgId,
    },
  }), [filters, additionalProps, sort, botMessagesHidden, searchResultMsgId]);

  return (
    <ChatContext.Provider value={ctxValue}>
      {children}
      <ErrorSnackbar {...filterErrorSnackbar}>
        Failed to load active bundles info for filters
      </ErrorSnackbar>
    </ChatContext.Provider>
  );
};
