import React, { useMemo, useState } from 'react';
import {
  Button, IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
} from '@mui/material';
import { FilterList } from '@mui/icons-material';
import { ColumnProps } from './column/Column';
import { Filter } from './filter/types';

export interface FiltersMenuButtonProps {
  filters: Filter[];
  toggleFilterWidget: (field: string, open: boolean) => void;
  columns: ColumnProps[];
  columnTitles: { [name: string]: string };
  tiny?: boolean;
}

export const FiltersMenuButton: React.FC<FiltersMenuButtonProps> = ({
  filters,
  toggleFilterWidget,
  columns,
  columnTitles,
  tiny,
}) => {
  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement>();
  const onMenuClose = () => {
    setMenuAnchor(undefined);
  };

  const tooltipText = useMemo(() => {
    if (!tiny) return undefined;

    return !filters.length ? 'Filters'
      : `Filters applied:
${filters.map((filter) => `
${columnTitles[filter.field] ?? filter.field}`)}`;
  }, [columnTitles, filters, tiny]);

  return (
    <>
      {tiny ? (
        <IconButton
          size="small"
          title={tooltipText}
          onClick={(e) => setMenuAnchor(e.currentTarget)}
        >
          <FilterList color={filters.length ? 'secondary' : undefined} />
        </IconButton>
      ) : (
        <Button
          variant="outlined"
          color="primary"
          startIcon={<FilterList />}
          onClick={(e) => setMenuAnchor(e.currentTarget)}
        >
          Filters
        </Button>
      )}
      <Popover
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={onMenuClose}
      >
        <List>
          {columns.filter((c) => c.filterWidget).map(({ field }) => {
            const filter = filters.find((f) => f.field === field);
            return (
              <ListItem
                key={field}
                button
                onClick={() => {
                  toggleFilterWidget(field, true);
                  onMenuClose();
                }}
              >
                <ListItemIcon>
                  <FilterList color={filter ? 'secondary' : undefined} />
                </ListItemIcon>
                <ListItemText
                  primary={columnTitles[field] ?? field}
                />
              </ListItem>
            );
          })}
        </List>
      </Popover>
    </>
  );
};
