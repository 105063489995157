import React, { forwardRef } from 'react';
import {
  IconButton, IconButtonProps, Tooltip, TooltipProps,
} from '@mui/material';

export interface TooltipIconButtonProps extends Omit<IconButtonProps, 'title'> {
  title?: TooltipProps['title'];
  tooltipProps?: Omit<TooltipProps, 'title' | 'children'>
}

export const TooltipIconButton: React.FC<TooltipIconButtonProps> = forwardRef(({
  children,
  title,
  tooltipProps,
  ...buttonProps
}, ref) => {
  if (!title) {
    return <IconButton ref={ref} {...buttonProps}>{children}</IconButton>;
  }
  // span is for Tooltip to work if the button is disabled
  return (
    <Tooltip arrow title={title} placement="top" {...tooltipProps}>
      <span>
        <IconButton ref={ref} {...buttonProps}>{children}</IconButton>
      </span>
    </Tooltip>
  );
});
