import { useProfile } from 'api/user/profile';
import { ChannelSort } from 'stream-chat';
import { useMemo, useState } from 'react';
import { getChannelArchivedKey } from '../utils';
import { ChatGenerics } from '../streamTypes';

export enum ChannelSortType {
  // Recent messages first
  RECENT_MESSAGE = 'recent_message',

  // Unread channels first
  UNREAD = 'unread',

  // Archived channels
  ARCHIVED = 'archived',
}

export interface UseChannelSortHook {
  sortBy: ChannelSortType;
  setSortBy: (sort: ChannelSortType) => void;
  /** Transformed to GetStream API sort **/
  apiSort: ChannelSort<ChatGenerics>;
}

export const useChannelSort = (): UseChannelSortHook => {
  const [sortBy, setSortBy] = useState<ChannelSortType>(ChannelSortType.RECENT_MESSAGE);
  const profile = useProfile();

  const apiSort = useMemo<ChannelSort<ChatGenerics>>(() => {
    const sortMap: Record<ChannelSortType, ChannelSort<ChatGenerics>> = {
      [ChannelSortType.RECENT_MESSAGE]: { last_message_at: -1 },
      [ChannelSortType.UNREAD]: { unread_count: -1 },
      [ChannelSortType.ARCHIVED]: { [getChannelArchivedKey(profile)]: -1 },
    };
    return sortMap[sortBy];
  }, [profile, sortBy]);

  return useMemo(() => ({ sortBy, setSortBy, apiSort }), [sortBy, setSortBy, apiSort]);
};
