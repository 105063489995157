import {
  ApolloClient,
  from,
  InMemoryCache,
  ServerError,
  ServerParseError,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError, ErrorResponse } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import firebase from 'firebase/app';
import { has } from 'lodash';
import { config } from '../config';
import { REDIRECT_PARAM } from '../components/auth/pages/login/hooks/useLoginSearchParams';
import { PATHS } from '../paths';
import { history } from '../App';
import { signOut } from '../utils/auth';

const authLink = setContext(async (request, { headers, ...other }) => {
  // get the authentication token from local storage if it exists
  const token = await firebase.auth().currentUser?.getIdToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      ...token ? { authorization: `Bearer ${token}` } : {},
    },
    ...other,
  };
});

function isServerError(error: ErrorResponse['networkError']): error is ServerError | ServerParseError {
  return !!error && has(error, 'statusCode');
}

const logoutLink = onError(({ networkError }) => {
  if (isServerError(networkError) && networkError.statusCode === 401) {
    signOut().then(() => {
      const { location } = history;
      const params = new URLSearchParams({
        [REDIRECT_PARAM]: location.pathname + location.search,
      });
      history.push(`${PATHS.home}?${params.toString()}`);
    });
  }
});

const uploadClientLink = createUploadLink({
  uri: config.apiUrl,
});

export const createClient = (loginAsId?: number | null) => {
  const loginAsContext = setContext((request, { headers }) => ({
    headers: {
      ...headers,
      ...(loginAsId ? { 'x-logged-in-as': loginAsId.toString() } : {}),
    },
  }));
  return new ApolloClient({
    link: from([loginAsContext, authLink, logoutLink, uploadClientLink]),
    cache: new InMemoryCache(),
  });
};
