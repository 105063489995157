import { gql } from '@apollo/client';

export enum BidStatus {
  Draft = 1,
  Submitted = 2,
  Declined = 3,
  Reopened = 4,
  AwardPending = 200,
  Awarded = 8,
  Rejected = 9,
  Invited = 10,
  Expired = 11,
  Negotiating = 20,
}

export const BID_DATE_TITLE: { [index: number]: string } = {
  [BidStatus.Draft]: 'Started On',
  [BidStatus.Invited]: 'Invited On',
  [BidStatus.Submitted]: 'Submitted On',
  [BidStatus.Negotiating]: 'Negotiating Started On',
  [BidStatus.Declined]: 'Declined On',
  [BidStatus.Reopened]: 'Reopened On',
  [BidStatus.Rejected]: 'Rejected On',
  [BidStatus.Expired]: 'Expired On',
  [BidStatus.AwardPending]: 'Award Pending On',
  [BidStatus.Awarded]: 'Awarded On',
};

export const BID_BY_TITLE: { [index: number]: string } = {
  [BidStatus.Draft]: 'Started By',
  [BidStatus.Invited]: 'Invited By',
  [BidStatus.Submitted]: 'Submitted By',
  [BidStatus.Negotiating]: 'Negotiation By',
  [BidStatus.Declined]: 'Declined By',
  [BidStatus.Reopened]: 'Reopened By',
  [BidStatus.Rejected]: 'Rejected By',
  [BidStatus.Expired]: 'Expired By',
  [BidStatus.AwardPending]: 'Awarded By',
  [BidStatus.Awarded]: 'Awarded By',
};

export const BID_STATUS_HELP_SP: { [index: number]: string } = {
  [BidStatus.Draft]: 'You have not submitted this bid yet, please proceed to review the prices',
  [BidStatus.Submitted]: 'You have submitted this bid to the Facility Manager to review',
  [BidStatus.Negotiating]: 'Facility Manager have started negotiation on this bid',
  [BidStatus.Declined]: 'You have declined to participate - message the facility manager to be re-invited.',
  [BidStatus.Reopened]: 'The bundle for this bid has been reopened after being paused, please proceed to review the changes and submit this bid again',
  [BidStatus.AwardPending]: 'Congratulations, your bid has won, Facility Manager is waiting for your accept!',
  [BidStatus.Awarded]: 'Congratulations, your bid has won!',
  [BidStatus.Rejected]: 'Sorry, your bid was rejected',
  [BidStatus.Invited]: 'You have been invited to bidding',
  [BidStatus.Expired]: 'You have not submitted this bid',
};

export const BID_STATUS_HELP_FM: { [index: number]: string } = {
  [BidStatus.Draft]: 'This bid has not been submitted by the Service Provider yet',
  [BidStatus.Submitted]: 'This bid was submitted for you to review',
  [BidStatus.Negotiating]: 'This bid was moved to negotiation stage',
  [BidStatus.Declined]: 'The Service Provider has declined to participate in this bundle',
  [BidStatus.Reopened]: 'You have reopened a bundle after being paused, so this bid can now be updated by the Service Provider',
  [BidStatus.AwardPending]: 'This Service Provider has to accept this bid',
  [BidStatus.Awarded]: 'This bid won',
  [BidStatus.Rejected]: 'This bid lost',
  [BidStatus.Invited]: 'You have invited this Service Provider to bidding',
  [BidStatus.Expired]: 'This Service Provider did not submit bid and lost',
};

export const BID_STATUSES = gql`
  query BidStatuses {
    bidStatuses {
      status_id
      name
      icon
      color
    }
  }
`;
