import L from 'leaflet';
import * as Sentry from '@sentry/browser';
import { getHomeIcon, MARKER_COLORS } from '../utils/marker';

export function addHomeIcon(map: L.Map, title: string, location: L.LatLngExpression) {
  try {
    const homeLayerGroup = new L.FeatureGroup();
    map.addLayer(homeLayerGroup);
    homeLayerGroup.addLayer(
      L.marker(location, { icon: getHomeIcon(MARKER_COLORS[0]) })
        .bindTooltip(title)
        .openTooltip(),
    );
  } catch (e: any) {
    Sentry.captureException(e);
  }
}
