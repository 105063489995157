import { gql } from '@apollo/client';

export const UPSERT_FM_STAT_MUTATION = gql`
    mutation UpsertFmStat ($sp_organization_id: Int!, $input: UpsertFmSpStatInput!) {
      upsertFmSpStat(organization_id: $sp_organization_id, input: $input) {
        fm_organization_id
        sp_organization_id
      }
    }
`;
