import React from 'react';
import { Typography } from '@mui/material';
import { Filter, FilterOption, isFilterSimple } from './types';
import { filterConditionToString } from './utils';

export interface FilterChipLabelProps {
  filter: Filter;
  columnTitles: { [name: string]: string };
  filterOptions?: FilterOption[];
  isNullTitle?: string;
}

const Text: React.FC<{ secondary?: boolean, spaceBefore?: boolean }> = ({
  secondary = false,
  spaceBefore,
  children,
}) => {
  const sBefore = typeof spaceBefore === 'boolean' ? spaceBefore : secondary;
  return (
    <Typography
      variant="inherit"
      component="span"
      color={secondary ? 'textSecondary' : undefined}
    >
      {sBefore && ' '}
      {children}
      {secondary && ' '}
    </Typography>
  );
};

export const FilterChipLabel: React.FC<FilterChipLabelProps> = ({
  filter,
  columnTitles,
  filterOptions,
  isNullTitle,
}) => {
  if (isFilterSimple(filter)) {
    const [op, ...values] = filterConditionToString(
      filter.where, filter.type, filterOptions, isNullTitle,
    );
    return (
      <>
        <Text>{columnTitles[filter.field] ?? filter.field}</Text>
        <Text secondary>{op}</Text>
        {values.map((v, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={`${v}-${idx}`}>
            <Text>{v}</Text>
            {idx < values.length - 1 && (
              <Text secondary spaceBefore={false}>,</Text>
            )}
          </React.Fragment>
        ))}
      </>
    );
  }

  return (
    <>
      {filter.where.map((condition, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={`${filter.field}-${filter.logic}-${idx}`}>
          <FilterChipLabel
            filter={{
              logic: undefined,
              field: filter.field,
              type: filter.type,
              where: condition,
            }}
            columnTitles={columnTitles}
            filterOptions={filterOptions}
            isNullTitle={isNullTitle}
          />
          {(idx < filter.where.length - 1) && (
            <Text secondary>{filter.logic}</Text>
          )}
        </React.Fragment>
      ))}
    </>
  );
};
