import React, { useEffect, useState } from 'react';
import { Skeleton, SkeletonProps } from '@mui/material';

interface PreviewImageComponentProps {
  retryDelay?: number; // retry delay in ms
  url: string;
  skeletonProps?: SkeletonProps;
}

type PreviewImageProps = PreviewImageComponentProps & React.ImgHTMLAttributes<HTMLImageElement>;

export const FileImageThumb: React.FC<PreviewImageProps> = ({
  alt,
  url,
  retryDelay = 3000,
  onError,
  skeletonProps,
  ...nativeImgProps
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleError = (error: React.SyntheticEvent<HTMLImageElement>) => {
    setLoading(true);
    if (onError) onError(error);
  };

  useEffect(() => {
    let timeout: any;
    if (loading) {
      timeout = setTimeout(() => {
        setLoading(false);
      }, retryDelay);
    }
    return () => clearTimeout(timeout);
  }, [loading, retryDelay]);

  if (loading) {
    return (
      <Skeleton
        variant="rectangular"
        width={skeletonProps?.width || '100%'}
        height={skeletonProps?.height || '100%'}
        {...skeletonProps}
      />
    );
  }

  return (
    <img
      src={url}
      alt={alt}
      onError={handleError}
      {...nativeImgProps}
    />
  );
};
