import React from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { useProfileWithAuth } from './useProfileWithAuth';
import { Loading } from '../components/Loading';
import { ErrorMessage } from '../components/notifications/ErrorMessage';
import { PATHS } from '../paths';

export type UserRole = 'fm' | 'sp' | 'admin';

type RedirectMapOption = [
  UserRole,
  (string | undefined), // a path explicitly provided via props
  string, // default/fallback path for this role
];

export interface RoleAwareRedirectProps {
  // Redirect paths
  to: {
    fm?: string;
    sp?: string;
    admin?: string;
  };
  // URL params converter
  getUrlParams?: <Params extends { [K in keyof Params]?: string } = {}>(
    params: { [P in keyof Params]: string },
    roles: UserRole[],
  ) => { [paramName: string]: string | number | boolean | undefined };
  // Ordered list of roles against which to do redirect.
  // Users can have multiple roles, and you can limit the access but not including certain roles.
  // If you include the role, you should also specify a redirect path for it.
  // If a role is allowed here, but no redirect path is specified, a fallback path will be used.
  preferredRolesOrder?: UserRole[];
}

export const RoleAwareRedirect: React.FC<RoleAwareRedirectProps> = ({
  to: { fm, sp, admin },
  getUrlParams,
  preferredRolesOrder = ['fm', 'sp', 'admin'],
}) => {
  const history = useHistory();
  const params = useParams();
  const {
    profileLoading,
    profileData,
    profileError,
  } = useProfileWithAuth(history);

  if (profileLoading) {
    return (
      <Loading center fullScreen />
    );
  }

  if (profileError) {
    return (
      <ErrorMessage center fullScreen devMessage={profileError.message}>
        Failed loading profile data
      </ErrorMessage>
    );
  }

  if (!profileData) {
    return (
      <ErrorMessage center fullScreen devMessage="data object is not set">
        Failed loading profile data
      </ErrorMessage>
    );
  }

  const { profile } = profileData;
  const { organization } = profile;

  const roles: UserRole[] = [];

  const isFM = organization.is_facility_manager;
  if (isFM) {
    roles.push('fm');
  }

  const isSP = organization.is_service_provider;
  if (isSP) {
    roles.push('sp');
  }

  const isAdmin = profile.is_system_admin;
  if (isAdmin) {
    roles.push('admin');
  }

  const redirectParams = getUrlParams ? getUrlParams(params, roles) : params;
  const redirectMap: RedirectMapOption[] = preferredRolesOrder.map((role) => {
    switch (role) {
      case 'fm': return [role, fm, PATHS.fm.main];
      case 'sp': return [role, sp, PATHS.sp.main];
      case 'admin': return [role, admin, PATHS.admin.main];
      default: return [role, undefined, PATHS.home];
    }
  });

  // First, check if there is a role (which user has) for which a path is provided via props
  const firstProvided = redirectMap.find(
    ([role, path]) => roles.includes(role) && path,
  );
  if (firstProvided && firstProvided[1]) {
    history.replace({
      pathname: generatePath(firstProvided[1], redirectParams),
      search: history.location.search,
    });
    return null;
  }

  // Second, if nothing was provided explicitly, or those provided did not match user's roles,
  // just use a fallback path of the first option with a matching role
  const firstFallback = redirectMap.find(
    ([role]) => roles.includes(role),
  );
  if (firstFallback) {
    history.push(generatePath(firstFallback[2], redirectParams));
    return null;
  }

  // Third, if no roles are matched, show an error.
  // This can happen if the list of preferred roles don't have all possible roles.
  // Effectively, this is a way to say "Access denied" for certain roles
  return (
    <ErrorMessage center fullScreen>
      You have no permissions to access this page
    </ErrorMessage>
  );
};
