import { useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import * as Sentry from '@sentry/browser';
import { CREATE_CHAT_ROOM_IF_NOT_EXISTS } from '../queries/createChatRoom';
import { CreateChatRoom, CreateChatRoomVariables } from '../../../api/types/CreateChatRoom';

export const useAutoCreateChatRoom = (roomId?: string) => {
  const [knockToChat, { error, loading }] = useMutation<
    CreateChatRoom, CreateChatRoomVariables
  >(CREATE_CHAT_ROOM_IF_NOT_EXISTS);

  useEffect(() => {
    if (roomId) {
      // If room exists - it will do nothing, otherwise creates a room between FM <-> SP
      knockToChat({
        variables: { roomId },
      }).catch((err) => {
        // We can swallow this, since it'll be handled using error that returned from useMutation
        // otherwise this can block UI
        Sentry.captureException(err);
      });
    }
  }, [knockToChat, roomId]);

  return { loading, error };
};
