import React, {
  useMemo, useState, useEffect, useCallback,
} from 'react';
import {
  FormControl, Grid, MenuItem, Select, TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { isArray, isNil } from 'lodash';
import { isValid } from 'date-fns';

import { WhereOperationsInput } from 'api/types/globalTypes';

import { Filter, isFilterSimple } from '../types';
import { FilterWidgetProps } from './FilterWidgetProps';
import { FilterWidgetDialog } from './FilterWidgetDialog';

const OPS_WITH_2_VALUES = ['between', 'notBetween'] as (keyof WhereOperationsInput)[];

export const DateFilterWidget: React.FC<FilterWidgetProps> = ({
  tableId,
  field,
  filter: externalFilter,
  filterClearedTimestamp,
  title,
  onApply,
  onClear,
  onClose,
  open,
}) => {
  const labelId = useMemo(
    () => `${tableId}-${field}-date-filter-label`,
    [field, tableId],
  );

  const now = useMemo(() => new Date(), []);

  const initialFilter: Filter = useMemo(() => externalFilter ?? {
    field,
    type: 'date',
    logic: undefined,
    where: { gt: now },
  }, [externalFilter, field, now]);
  const [filter, setFilter] = useState<Filter>(initialFilter);

  useEffect(() => {
    if (filterClearedTimestamp) {
      setFilter(initialFilter);
    }
  }, [filterClearedTimestamp, initialFilter]);

  const op = useMemo(
    () => Object.keys(filter.where)[0] as keyof WhereOperationsInput,
    [filter],
  );

  const values = useMemo(() => {
    if (!isFilterSimple(filter)) {
      throw new Error(
        'Expected a simple filter,'
        + `got ${JSON.stringify(filter)} on a field '${field}' in table with id '${tableId}'`,
      );
    }
    return isArray(filter.where[op])
      ? filter.where[op]
      : [filter.where[op]];
  }, [field, filter, op, tableId]);

  const twoValues = OPS_WITH_2_VALUES.includes(op);

  const applyDisabled = useMemo(
    () => !isValid(values[0]) || (values[1] && !isValid(values[1])),
    [values],
  );

  const handleSubmit = useCallback((ev) => {
    ev.preventDefault();
    onApply(field, filter);
    onClose(field);
  }, [field, filter, onApply, onClose]);

  return (
    <FilterWidgetDialog
      tableId={tableId}
      field={field}
      title={title}
      onApply={() => onApply(field, filter)}
      applyDisabled={applyDisabled}
      onClear={() => onClear(field)}
      onClose={onClose}
      open={open}
    >
      <form onSubmit={handleSubmit}>
        <Grid
          container
          spacing={1}
          direction="column"
          justifyContent="flex-start"
          alignContent="stretch"
          alignItems="stretch"
        >
          <Grid item>
            <FormControl fullWidth variant="outlined">
              <Select
                fullWidth
                labelId={labelId}
                value={op}
                onChange={(e) => {
                  const newOp = e.target.value as keyof WhereOperationsInput;
                  const newTwoValues = OPS_WITH_2_VALUES.includes(newOp);
                  const v1 = isNil(values[0]) ? now : values[0];
                  const v2 = isNil(values[1]) ? now : values[1];
                  setFilter({
                    type: 'date',
                    field,
                    logic: undefined,
                    where: {
                      [newOp]: newTwoValues ? [v1, v2] : v1,
                    },
                  });
                }}
              >
                <MenuItem value="gt">Greater than</MenuItem>
                <MenuItem value="gte">Greater than or equals</MenuItem>
                <MenuItem value="lt">Less than</MenuItem>
                <MenuItem value="lte">Less than or equals</MenuItem>
                <MenuItem value="between">Between</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <DatePicker
              autoFocus
              showToolbar={false}
              inputFormat="MM/dd/yyyy"
              label={twoValues ? 'Start value' : 'Value'}
              value={values[0]}
              onChange={(date) => {
                setFilter({
                  type: 'date',
                  field,
                  logic: undefined,
                  where: twoValues
                    ? { [op]: [date, values[1]] }
                    : { [op]: date },
                });
              }}
              renderInput={(props) => <TextField {...props} fullWidth variant="outlined" />}
            />
          </Grid>
          {twoValues && (
            <Grid item>
              <DatePicker
                showToolbar={false}
                inputFormat="MM/dd/yyyy"
                label="End value"
                value={values[1]}
                onChange={(date) => {
                  setFilter({
                    type: 'date',
                    field,
                    logic: undefined,
                    where: { [op]: [values[0], date] },
                  });
                }}
                renderInput={(props) => <TextField {...props} fullWidth variant="outlined" />}
              />
            </Grid>
          )}
        </Grid>
        <input type="submit" hidden />
      </form>
    </FilterWidgetDialog>
  );
};
