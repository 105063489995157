import React, { useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';
import { Chat, Streami18n } from 'stream-chat-react';
import { StreamChat } from 'stream-chat';
import { useQuery } from '@apollo/react-hooks';
import { Button } from '@mui/material';
import { config } from '../../config';
import { useProfile } from '../../api/user/profile';
import { Loading } from '../Loading';
import { ErrorSnackbar, useErrorSnackbar } from '../notifications/ErrorSnackbar';
import { ChatGenerics } from './streamTypes';
import { registerDevFunctions } from './utils';
import { ProfileChat } from '../../api/types/ProfileChat';
import { PROFILE_CHAT } from './queries/profileChat';
import { useLoginAs } from '../../pages/useLoginAs';

const client = StreamChat.getInstance<ChatGenerics>(config.getStream.key);

registerDevFunctions(client);

const i18nInstance = new Streami18n({ language: 'en' });

type ContextType = Partial<ProfileChat['chat']>;
const ChatTokenContext = React.createContext<ContextType | undefined>(undefined);

export const useProfileChatData = (): ContextType => {
  const ctx = useContext(ChatTokenContext);
  return ctx || {};
};

export const ChatProvider: React.FC = ({ children }) => {
  const { loginAs } = useLoginAs();

  // if we logged in as someone else set client to be ready
  // to prevent establishing new connection with another profile data
  const [isClientReady, setIsClientReady] = useState(() => !!loginAs);
  const [connectionError, setConnectionError] = useState<Error>();
  const profile = useProfile();

  const {
    loading: tokenLoading,
    error: loadTokenError,
    data: chatData,
    refetch,
  } = useQuery<ProfileChat>(PROFILE_CHAT, {
    fetchPolicy: 'no-cache',
  });

  const chatErrorSnackbar = useErrorSnackbar(loadTokenError);

  useEffect(() => {
    const setupClient = async () => {
      if (chatData) {
        const { chat: { token, user_id: chatUserId } } = chatData;
        const {
          login_email: loginEmail,
          first_name: firstName,
          last_name: lastName,
          organization: { organization_id: organizationId },
        } = profile;

        try {
          await client.connectUser(
            {
              id: chatUserId,
              name: `${firstName} ${lastName}`,
              email: loginEmail,
              first_name: firstName,
              last_name: lastName,
              organization_id: organizationId,
            },
            token,
          );
          setIsClientReady(true);
        } catch (e) {
          setConnectionError(e as Error);
        }
      }
    };

    if (!isClientReady) {
      setupClient();
    }
  }, [isClientReady, setIsClientReady, chatData, profile]);

  // disconnect only on unmount
  useEffect(() => () => {
    client.disconnectUser();
  },
  []);

  useEffect(() => {
    if (connectionError) {
      Sentry.captureException(connectionError);
    }
  }, [connectionError]);

  const connectionErrorSnackbar = useErrorSnackbar(connectionError);

  if ((!isClientReady || tokenLoading) && !connectionError && !loadTokenError) {
    return <Loading center fullScreen />;
  }

  return (
    <Chat client={client} i18nInstance={i18nInstance}>
      <ChatTokenContext.Provider value={chatData?.chat}>
        {children}
      </ChatTokenContext.Provider>
      <ErrorSnackbar {...connectionErrorSnackbar}>
        Chat connection failed
      </ErrorSnackbar>
      {loadTokenError && (
        <ErrorSnackbar
          {...chatErrorSnackbar}
          action={(
            <Button
              size="small"
              variant="outlined"
              onClick={() => refetch()}
            >
              Retry
            </Button>
          )}
        >
          Procursys messaging service connection failed
        </ErrorSnackbar>
      )}
    </Chat>
  );
};
