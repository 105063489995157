import React, {
  createContext, useCallback, useContext, useState, useMemo,
} from 'react';
import {
  Box,
  Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { isEmpty, trim } from 'lodash';
import { StringSchema } from 'yup';

const useStyles = makeStyles(() => ({
  dialogTitle: {
    paddingBottom: 0,
  },
}));

export interface DialogOptions {
  type: 'confirm' | 'info' | 'prompt';
  title?: JSX.Element | string;
  content?: JSX.Element | string;
  okText?: string;
  okColor?: 'inherit' | 'primary' | 'secondary';
  cancelText?: string;
  placeholder?: string;
  promptValueIsOptional?: boolean;
  promptDefaultValue?: string;
  promptSchema?: StringSchema;
}

export type Options = Omit<DialogOptions, 'type'>;

interface DialogContextData {
  confirm: (options: Options) => Promise<boolean>;
  info: (options: Options) => Promise<boolean>;
  prompt: (options: Options) => Promise<false | string>;
}

export const DialogContext = createContext<DialogContextData | undefined>(undefined);

export const useDialog = () => {
  const ctx = useContext(DialogContext);
  if (ctx === undefined) {
    throw new Error('Dialog is undefined in a context');
  }
  return ctx;
};

export const DialogProvider: React.FC = ({ children }) => {
  const classes = useStyles();
  const [options, setOptions] = useState<DialogOptions | undefined>();
  const [resolver, setResolver] = useState<Function | undefined>();

  const [validationError, setValidationError] = useState('');
  const validateValue = useCallback(async (val) => {
    if (options?.promptSchema) {
      try {
        await options.promptSchema.validate(val);
        setValidationError('');
      } catch (e: any) {
        setValidationError(e.message);
        return false;
      }
    }
    return true;
  }, [options?.promptSchema]);

  const confirm = useCallback((params: Options): Promise<boolean> => new Promise((resolve) => {
    setOptions({ ...params, type: 'confirm' });
    setResolver(() => resolve);
  }), []);

  const info = useCallback((params: Options): Promise<boolean> => new Promise((resolve) => {
    setOptions({ ...params, type: 'info' });
    setResolver(() => resolve);
  }), []);

  const [value, setValue] = useState('');
  const prompt = useCallback(
    (params: Options): Promise<false | string> => new Promise((resolve) => {
      setOptions({ ...params, type: 'prompt' });
      setValue(params.promptDefaultValue ?? '');
      setValidationError('');
      setResolver(() => resolve);
    }),
    [],
  );

  const handleClose = useCallback(() => {
    if (resolver) resolver(false);
    setOptions(undefined);
  }, [resolver, setOptions]);

  const handleOk = useCallback(async (promptValue?: string) => {
    const valid = await validateValue(promptValue ?? '');
    if (valid) {
      if (resolver) resolver(promptValue ?? true);
      setOptions(undefined);
    }
  }, [resolver, validateValue, setOptions]);

  const labelId = 'dialog-hook-modal';
  const contextParams = useMemo(() => ({ confirm, info, prompt }), [confirm, info, prompt]);

  return (
    <>
      <DialogContext.Provider value={contextParams}>
        {children}
      </DialogContext.Provider>
      {options && (
        <Dialog
          open={!!options}
          onClose={handleClose}
          aria-labelledby={labelId}
        >
          {options.title && (
            <DialogTitle id={labelId} className={classes.dialogTitle}>
              {options.title}
            </DialogTitle>
          )}
          {(options.content || options.type === 'prompt') && (
            <DialogContent>
              {options.content}
              {options.type === 'prompt' && (
                <Box marginTop={2}>
                  <TextField
                    fullWidth
                    autoFocus
                    value={value}
                    placeholder={options.placeholder}
                    onChange={(e) => {
                      setValue(e.target.value);
                      validateValue(e.target.value);
                    }}
                    onBlur={(e) => validateValue(e.target.value)}
                    error={!!validationError}
                    helperText={validationError}
                  />
                </Box>
              )}
            </DialogContent>
          )}
          <DialogActions>
            <Button
              color={options.okColor || 'primary'}
              onClick={() => handleOk(options.type === 'prompt' ? value : undefined)}
              disabled={options.type === 'prompt' && !options.promptValueIsOptional && isEmpty(trim(value))}
            >
              {options.okText ?? 'Ok'}
            </Button>
            {['confirm', 'prompt'].includes(options?.type) && (
              <Button onClick={handleClose}>
                {options.cancelText ?? 'Cancel'}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
