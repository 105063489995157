import { gql } from '@apollo/client';

export const MY_ORGANIZATION_QUERY = 'MyOrganization';
export const MY_ORGANIZATION = gql`
  query MyOrganization {
    organization: myOrganization {
      organization_id
      name
      is_service_provider
      is_facility_manager
      website_url
      phone_number
      about
      help

      created_on
      updated_on
      usersCount

      address {
        address_line_1
        address_line_2
        city
        state_code
        country_code
        postal_code
        latlong
        google_place_id
      }
      services {
        icon
        slug
        service_id
        name
      }
      serviceFieldValues {
        service_id
        field {
          field_id
          type
          name
        }
        value
      }

      contacts {
        contact_id
        type_id
        type {
          name
        }
        first_name
        last_name
        email
        phone
      }
    }
  }
`;
