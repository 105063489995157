import { gql } from '@apollo/client';

export const INVITE_USERS = gql`
mutation InviteUsers ($organization_id: Int!, $emails: [String!]!) {
  inviteUsers(
    organization_id: $organization_id,
    data: { emails: $emails }
  ) {
    invites {
      invite_id
    }
    warnings {
       email
       message
    }
  }
}
`;
