import React from 'react';
import { AppBar, Container, Toolbar } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { AppBarProps } from '@mui/material/AppBar/AppBar';
import cn from 'classnames';

import logo from '../../assets/logo-color.png';
import { colors } from '../theme';
import { APP_BAR_HEIGHT } from './SignedInAppBar';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    padding: theme.spacing(0, 1),
  },
  appBar: {
    backgroundColor: colors.white,
    border: 'none',
    height: theme.spacing(APP_BAR_HEIGHT),
  },
  toolbar: {
    height: theme.spacing(APP_BAR_HEIGHT),
    justifyContent: 'space-between',
  },
  logo: {
    width: 150,
  },
}));

export const AnonymousAppBar: React.FC<AppBarProps & { rightContent?: JSX.Element | null }> = (
  {
    className,
    rightContent,
    ...props
  },
) => {
  const classes = useStyles();
  return (
    <AppBar
      position="static"
      elevation={0}
      className={cn(classes.appBar, className)}
      {...props}
    >
      <Container maxWidth="md" className={classes.container}>
        <Toolbar className={classes.toolbar}>
          <img src={logo} alt="Procursys logo" className={classes.logo} />
          {rightContent}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
