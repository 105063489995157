import { useFeedContext } from 'react-activity-feed';
import { observer } from 'mobx-react';
import React from 'react';
import { FeedGenerics } from './feedTypes';
import { useNotificationBox } from './NotificationBoxState';
import { EmptyNotificationsPlaceholder } from './EmptyNotificationsPlaceholder';

export const NotificationBoxFooter = observer(() => {
  const feed = useFeedContext<FeedGenerics['userType'], FeedGenerics['activityType']>();
  const store = useNotificationBox();

  if (!store.showUnreadOnly || feed.unread) {
    return null;
  }

  return (<EmptyNotificationsPlaceholder text="You have no unread notifications" />);
});
