import React from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { TooltipButton } from '../../buttons/TooltipButton';

export interface SubmitDocumentsButtonProps {
  canSubmit: boolean;
  submitForm: () => void | Promise<void>;
  isSubmitting: boolean;
  totalFiles: number;
}

export const AttachDocumentsButton: React.FC<SubmitDocumentsButtonProps> = ({
  canSubmit, submitForm, isSubmitting, totalFiles,
}) => (
  <TooltipButton
    startIcon={<CloudUploadIcon />}
    disabled={!canSubmit}
    onClick={submitForm}
    title={canSubmit ? '' : 'Select/drop files to upload'}
    color="primary"
  >
    {isSubmitting ? 'Uploading...' : ''}
    {!isSubmitting && (
      totalFiles
        ? `Attach ${totalFiles} file${totalFiles > 1 ? 's' : ''}`
        : 'Select Files'
    )}
  </TooltipButton>
);
