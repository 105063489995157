const SMART_DRAWER_OPEN = 'smartDrawerOpen';

export function setSmartDrawerOpen(value: boolean) {
  window.localStorage.setItem(SMART_DRAWER_OPEN, JSON.stringify(value));
}

export function getSmartDrawerOpen(): boolean {
  const value = window.localStorage.getItem(SMART_DRAWER_OPEN);
  if (value) {
    return JSON.parse(value) as boolean;
  }
  return true;
}
