import { gql } from '@apollo/client';

export enum SiteStatuses {
  Active = 1,
  Archived = 2,
}

export const SITE_STATUS_HELP_FM = {
  [SiteStatuses.Active]: 'Site could be bundled and it\'s in active contract',
  [SiteStatuses.Archived]: 'Site were archived manually',
};

export const SITE_STATUSES = gql`
    query SiteStatuses {
      siteStatuses {
        status_id
        name
        icon
        color
        rank
      }
    }
`;
