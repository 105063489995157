import { gql } from '@apollo/client';

export const USERS_QUERY = 'Users';
export const USERS = gql`
  query Users(
    $skip: Int!
    $take: Int!
    $where: WhereUserInput
    $order: [OrderInput!]
  ) {
    _users (
      skip: $skip
      take: $take
      where: $where
      order: $order
    ) {
      items {
        user_id
        firebase_id
        login_email
        work_email
        first_name
        last_name
        fullName
        phone_number
        approved_on
        created_on
        updated_on
        last_action_on
        last_login_on
        deactivated_on
        status

        userPermissions {
          region_id
          permission_id
          permission {
            is_regional
            name
          }
        }
      }
      total_count
      has_more
    }
  }
`;
