import React, { useCallback, useMemo } from 'react';
import { Channel, MessageResponse } from 'stream-chat';
import {
  Divider, List, ListItem, ListItemText, Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import pick from 'lodash/pick';
import { getChannelName } from '../utils';
import { useProfile } from '../../../api/user/profile';
import { colors } from '../../theme';
import { useChatContext as useStreamChatContext } from '../hooks/streamHooks';
import { useChatContext } from '../ChatContext';
import { ChatGenerics } from '../streamTypes';

export interface SearchMessagesListProps {
  messages: MessageResponse<ChatGenerics>[];
}

type MessageWithChannelInstance = MessageResponse<ChatGenerics> & {
  channelInstance: Channel<ChatGenerics>
};

const useStyles = makeStyles(() => createStyles({
  messagesListItem: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colors.lightGrey,
    },
  },
  msgText: {
    '&> p': {
      display: 'inline',
    },
  },
}));

export const SearchMessagesList: React.FC<SearchMessagesListProps> = ({ messages }) => {
  const classes = useStyles();
  const profile = useProfile();
  const { client, setActiveChannel } = useStreamChatContext('ChannelSearch');
  const { channelJumpTarget: { setMsgId } } = useChatContext();

  const getChannelFromMessage = useCallback((msg: MessageResponse<ChatGenerics>) => {
    const { channel } = msg;
    if (!channel) {
      throw new Error('Channel is not defined in message');
    }
    const customData = pick(channel, ['fm_id', 'sp_id', 'fm_name', 'sp_name', 'has_real_messages']);
    // Don't use client.channel here as it adds the channel to state object
    // and add mess with channel.data when querying messages inside channel
    const ch = new Channel(client, channel.type, channel.id, customData);
    ch.initialized = true;
    return ch;
  }, [client]);

  const messagesPrepared = useMemo<MessageWithChannelInstance[]>(() => messages.map((message) => ({
    ...message,
    channelInstance: getChannelFromMessage(message),
  })), [getChannelFromMessage, messages]);

  const setActiveChannelFromMessage = async (msg: MessageWithChannelInstance) => {
    await msg.channelInstance.watch();
    setActiveChannel(msg.channelInstance);
    setMsgId(msg.id);
  };

  return (
    <List>
      {messagesPrepared.map((msg, idx) => (
        <React.Fragment key={msg.id}>
          <ListItem
            className={classes.messagesListItem}
            onClick={() => setActiveChannelFromMessage(msg)}
            alignItems="flex-start"
          >
            <ListItemText
              primary={getChannelName(msg.channelInstance, profile)}
              secondary={(
                <>
                  <Typography
                    component="span"
                    variant="body2"
                    color="textPrimary"
                  >
                    {msg.user?.name}
                  </Typography>
                  {' - '}
                  {/* eslint-disable-next-line react/no-danger */}
                  <span className={classes.msgText} dangerouslySetInnerHTML={{ __html: msg.html ?? msg.text ?? '' }} />
                </>
              )}
            />
          </ListItem>
          {idx < messages.length - 1 && (
            <Divider component="li" />
          )}
        </React.Fragment>
      ))}
    </List>
  );
};
