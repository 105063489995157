import React from 'react';
import { Box, Paper } from '@mui/material';
import { ErrorMessage } from './ErrorMessage';

export interface ErrorPageMessageProps {
  errorCode?: string | number;
  message: string;
}

export const ErrorPageMessage: React.FC<ErrorPageMessageProps> = ({
  errorCode,
  message,
}) => (
  <Box p={2}>
    <Paper>
      <Box p={2}>
        <ErrorMessage center>
          {errorCode && (
            <>
              <strong>{errorCode}</strong>
              {': '}
            </>
          )}
          {message}
        </ErrorMessage>
      </Box>
    </Paper>
  </Box>
);
