import { gql } from '@apollo/client';

// fixme: it has to be slugified after MVP
export enum FieldNames {
  ANNUAL_REVENUE = 'Typical annual revenue',
  YEARS_SERVICING = 'Years servicing commercial locations',
  CLASSIFICATIONS = 'Classifications',
  AT_LEAST_51_PERCENT_OWNED = 'If your company is at least 51 percent owned, controlled and operated by one or more of the following, please check all that apply:',
}

export const SERVICE_PROVIDERS_QUERY = 'ServiceProviders';
export const SERVICE_PROVIDERS = gql`
  query ServiceProviders(
    $skip: Int!
    $take: Int!
    $order: [OrderInput!]
    $where: WhereOrganizationInput
    $bundleId: Int!
    $serviceId: Int!
  ) {
    _organizations: fmOrganizations(
      skip: $skip
      take: $take
      order: $order
      where: $where
    ) {
      items {
        organization_id
        name
        invitedToBundleOn(bundle_id: $bundleId)

        procursysIndex {
          index_id
          name
          description
          icon
          color
        }

        businessType { name }

        fmSpStat {
          rank

          levels
          blacklisted
          sp_blacklisted
        }

        is_service_provider
        is_facility_manager
        sites_serviced_count: sitesManagedCount(bundle_id: $bundleId, service_id: $serviceId)
        bundleSitesInOrgState(bundle_id: $bundleId)
        annual_revenue: serviceFieldValue(name: "Typical annual revenue", service_id: $serviceId)
        years_servicing: serviceFieldValue(name: "Years servicing commercial locations", service_id: $serviceId)
        fulltime_employees: serviceFieldValue(name: "How many are full time?", service_id: $serviceId)
        parttime_employees: serviceFieldValue(name: "How many are part time?", service_id: $serviceId)
        business_entity_type: serviceFieldValue(name: "Type")
        business_classifications: serviceFieldValue(name: "Classifications")
        business_owned_by: serviceFieldValue(name: "If your company is at least 51 percent owned, controlled and operated by one or more of the following, please check all that apply:")
        usersCount
      }
      total_count
      has_more
    }
  }
`;
