import { gql } from '@apollo/client';

export const SP_QUERY_SIDEBAR = gql`
  query SpQuerySidebar($organizationId: Int!) {
    organization: spOrganization(organization_id: $organizationId) {
      organization_id
      name
      is_service_provider
      is_facility_manager
      website_url
      phone_number
      usersCount
      created_on
      created_by
      about
      createdBy {
        first_name
        last_name
        login_email
        work_email
        phone_number
      }

      spProfile {
        profile_id
        about
        years_in_business

        phone_number
        facebook
        instagram
        twitter
        linkedin

        files {
          file_id
          path
          type
          mime_type
          name
          type
          rank
          url
          thumb_url
        }
      }

      address {
        address_line_1
        address_line_2
        city
        state_code
        country_code
        postal_code
        latlong
        google_place_id
      }

      hasCOI: hasFileOfCategory(categoryId: 1, excludePrivate: true)
      hasW9: hasFileOfCategory(categoryId: 7, excludePrivate: true)

      services {
        service_id
        name
        icon
      }

      serviceFieldValues {
        service_id
        field {
          field_id
          level
          type
          name
          context
        }
        value
      }
    }
  }
`;
