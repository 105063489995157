import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  ChannelSearchProps,
  useChatContext,
} from 'stream-chat-react';
import { Box, Paper, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Channel, MessageResponse } from 'stream-chat';
import { MarkAllReadButton } from 'components/chat/MarkAllReadButton';
import { ChatGenerics } from '../streamTypes';
import { colors } from '../../theme';
import { ChannelPreview } from '../ChannelList/ChannelPreview';
import { Loading } from '../../Loading';
import { NoData } from '../../text/NoData';
import { SearchInput } from './SearchInput';
import { SearchMessagesList } from './SearchMessagesList';
import { useChannelRequiredFilters } from '../ChannelList/useChannelRequiredFilters';
import { ChannelSort } from '../ChannelList/ChannelSort';

type StyleProps = {
  navOpen?: boolean;
};

const useStyles = makeStyles((theme) => createStyles({
  wrapper: {
    padding: theme.spacing(2),
    paddingBottom: 0,
    position: 'relative',
    borderTopRightRadius: 0,
  },
  resultContainer: {
    position: 'absolute',
    width: ({ navOpen } : StyleProps) => (navOpen ? 300 : 350),
    zIndex: 2,
    backgroundColor: colors.white,
    marginLeft: -1,
    padding: theme.spacing(2),
    maxHeight: '75vh',
    overflowY: 'auto',
  },
}));

export const ChannelSearch: React.FC<ChannelSearchProps> = () => {
  const { client, navOpen } = useChatContext<ChatGenerics>('ChannelSearch');
  const classes = useStyles({ navOpen });

  const [search, setSearch] = useState('');
  const [searchResults, setSearchResults] = useState<{
    channels: Channel<ChatGenerics>[];
    messages: MessageResponse<ChatGenerics>[],
  }>({ channels: [], messages: [] });
  const [isSearching, setIsSearching] = useState(false);
  const [resultsShown, setResultsShown] = useState(false);
  const channelDefaultFilters = useChannelRequiredFilters();

  const onSearchFocus = useCallback(() => {
    const { channels, messages } = searchResults;
    setResultsShown(!!messages.length || !!channels.length);
  }, [searchResults, setResultsShown]);

  const clearSearchResult = useCallback(() => {
    setSearch('');
    setResultsShown(false);
  }, [setSearch, setResultsShown]);

  const debounceTimer = useRef<number>();
  const searchInputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    const clickListener = (event: MouseEvent) => {
      if (resultsShown && event.target instanceof HTMLElement) {
        const isInputClick = searchInputRef.current?.contains(event.target);
        if (!isInputClick) {
          setResultsShown(false);
        }
      }
    };
    document.addEventListener('click', clickListener);
    return () => document.removeEventListener('click', clickListener);
  }, [resultsShown, setResultsShown]);

  useEffect(() => {
    const doSearch = async (text: string) => {
      if (text.trim().length > 0) {
        setResultsShown(true);

        try {
          const [channels, { results: messages }] = await Promise.all([
            client.queryChannels({
              ...channelDefaultFilters,
              name: { $autocomplete: text },
            }),
            client.search(channelDefaultFilters, {
              text: { $autocomplete: text },
            }),
          ]);
          setSearchResults({ channels, messages: messages.map((m) => m.message) });
        } finally {
          setIsSearching(false);
        }
      }
    };

    const hasSearchText = !!search.trim().length;
    setIsSearching(hasSearchText);
    setResultsShown(hasSearchText);

    clearTimeout(debounceTimer.current);

    if (hasSearchText) {
      debounceTimer.current = setTimeout(() => {
        doSearch(search);
      }, 400) as unknown as number;
    }

    return () => {
      clearTimeout(debounceTimer.current);
    };
  }, [channelDefaultFilters, client, search]);

  const { channels, messages } = searchResults;

  return (
    <Paper className={classes.wrapper}>
      <SearchInput
        value={search}
        onChange={(event) => {
          setSearch(event.target.value);
        }}
        onClear={clearSearchResult}
        onFocus={onSearchFocus}
        inputRef={searchInputRef}
      />

      {resultsShown && (
        <Paper className={classes.resultContainer}>
          {isSearching && (
            <Loading center />
          )}

          {!isSearching && !channels.length && !messages.length && (
            <NoData>
              No rooms or messages found that matching
              {' '}
              <strong>
                &quot;
                {search}
                &quot;
              </strong>
            </NoData>
          )}

          {channels.length > 0 && (
            <>
              <Typography color="textSecondary" variant="subtitle1">
                Rooms
              </Typography>
              {channels.map((channel) => (
                <ChannelPreview
                  key={channel.id}
                  channel={channel as any}
                />
              ))}
            </>
          )}

          {messages.length > 0 && (
            <>
              <Typography color="textSecondary" variant="subtitle1">
                Messages
              </Typography>
              <SearchMessagesList messages={messages} />
            </>
          )}
        </Paper>
      )}
      {/**
        @note Not the best place for this , but otherwise we need to implement own channel list
       */}
      <Box pt={1}>
        <ChannelSort />
      </Box>
      <Box pt={1} pb={1}>
        <MarkAllReadButton />
      </Box>

    </Paper>
  );
};
