import { Profile_profile } from '../../api/types/Profile';
import { Files__organizationFiles_items } from '../../api/types/Files';
import { Permissions } from '../../api/permissions/permissions';

export const canModifyFile = (
  profile: Profile_profile, file: Files__organizationFiles_items,
) => {
  const isOrgOwner = file.organization_id === profile.organization.organization_id
    && !!profile.userPermissions.find((p) => p.permission_id === Permissions.ORGANIZATION_OWNER);
  return profile.user_id === file.created_by || isOrgOwner;
};
