import React, { useMemo } from 'react';
import { Tooltip } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { orange } from '@mui/material/colors';
import {
  BidEnvelopeStatuses,
} from 'components/envelope/EnvelopeBidAction/gql/EnvelopeDataQuery';
import { StatusChip } from 'components/organization/StatusChip';
import { StyledRouterLink } from '../StyledRouterLink';
import { PATHS } from '../../paths';
import { NavTypeNoAdmin } from '../nav/navType';
import { Profile_profile_organization } from '../../api/types/Profile';
import { colors } from '../theme';

interface EnvelopeBidBadgeEntity {
  envelope_id: number;
  status: {
    status_id: number;
    name: string;
    icon: string | null;
    color: string | null;
  };
}

type EnvelopeBidBadgeProps = {
  navType: 'fm';
  envelope: EnvelopeBidBadgeEntity | null;
} | {
  navType: 'sp';
  envelope: EnvelopeBidBadgeEntity;
};

const useStyles = makeStyles<any, { pointer: boolean }>(() => createStyles({
  envelopeSignRoot: ({ pointer }) => ({
    cursor: pointer ? 'pointer' : 'inherit',
    width: '32px',
    paddingLeft: '9px',
  }),
}));

export const canShowEnvelopeBidBadge = (
  navType: NavTypeNoAdmin,
  isBidAwarded: boolean,
  org: Profile_profile_organization,
  env: EnvelopeBidBadgeEntity | null,
): env is EnvelopeBidBadgeEntity => (
  isBidAwarded && (
    (navType === 'sp' && !!env && env.status.status_id !== BidEnvelopeStatuses.Draft)
    || (navType === 'fm' && org.fm_docusign_access > 0)
  )
);

export const EnvelopeBidBadge: React.FC<EnvelopeBidBadgeProps> = ({
  navType,
  envelope,
}) => {
  const classes = useStyles({ pointer: !!envelope });
  const tooltip = useMemo(() => {
    if (navType === 'fm' && (!envelope || envelope?.status.status_id === BidEnvelopeStatuses.Draft)) {
      return `E-Contract has not been ${!envelope ? 'created' : 'sent to SP'}.`;
    }
    if (envelope?.status.status_id === BidEnvelopeStatuses.Completed) {
      return (
        <>
          E-Contract is completed.
          <br />
          Go to the E-Contracts page to view details.
        </>
      );
    }
    if (envelope?.status.status_id === BidEnvelopeStatuses.Waiting) {
      return navType === 'fm'
        ? 'Service Provider is waiting for your signature.'
        : 'Facility Manager is waiting for your signature to proceed contract agreement.';
    }
    if (envelope?.status.status_id === BidEnvelopeStatuses.Sent) {
      return navType === 'fm'
        ? 'Service Provider did not sign yet, waiting for their signature'
          + ' to proceed contract agreement.'
        : 'Waiting for Facility Manager to sign.';
    }
    return (
      <>
        {'Status is '}
        <StatusChip size="tiny" status={envelope!.status} />
      </>
    );
  }, [navType, envelope]);

  const chip = useMemo(() => ({
    status_id: -1,
    icon: 'signature',
    name: '',
    color: (() => {
      switch (true) {
        case !envelope:
          return colors.grey;
        case envelope?.status.status_id === BidEnvelopeStatuses.Waiting:
          return orange['400'];
        default:
          return orange['100'];
      }
    })(),
  }), [envelope]);

  const url = useMemo(() => {
    if (!envelope) {
      return null;
    }
    const rolePath = navType === 'fm' ? PATHS.fm.organizationEnvelopes : PATHS.sp.envelopes;
    const search = `?type=view&envelopeId=${envelope.envelope_id}`;
    return { pathname: rolePath, search };
  }, [navType, envelope]);

  return (
    <Tooltip arrow title={tooltip} placement="top">
      <span>
        {url ? (
          <StyledRouterLink to={url}>
            <StatusChip status={chip} classes={{ root: classes.envelopeSignRoot }} />
          </StyledRouterLink>
        ) : (
          <StatusChip status={chip} classes={{ root: classes.envelopeSignRoot }} />
        )}
      </span>
    </Tooltip>
  );
};
